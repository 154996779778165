import {Map} from 'immutable';
import * as t from '../actions/actionTypes/contentActionTypes';
import * as loginActions from '../actions/actionTypes/loginActionTypes';

const initialState = Map({
  lessonSession: null,
  container: null,
  loadingContainer: false,
  nextTopic: null,
});

const user = function (state = initialState, action) {
  switch (action.type) {
    case t.LESSON_SESSION:
      return state.set('lessonSession', action.value);
    case t.SET_CONTAINER:
      return state.set('container', action.value);
    case t.LOADING_CONTAINER:
      return state.set('loadingContainer', action.value);
    case t.SET_NEXT_TOPIC_CONTENT:
      return state.set('nextTopic', action.value);
    case loginActions.SIGN_OUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default user;
