const Immutable = require('immutable');
const Serialize = require('remotedev-serialize');
const reduxPersist = require('redux-persist');

export default function immutableTransform(config) {
  config = config || {};

  const serializer = Serialize.immutable(Immutable, config.records);

  return reduxPersist.createTransform(serializer.stringify, serializer.parse, config);
}
