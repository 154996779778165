export const stdGetUserValidationQuerie = /* GraphQL */ `
  query stdGetUserValidationQuerie($organizationId: ID!, $studentId: ID!) {
    getStudent(id: $studentId, organizationID: $organizationId) {
      id
      firstLogin
    }
  }
`;

export const stdGetAvailableStudentCoursesQuerie = /* GraphQL */ `
  query stdGetAvailableStudentCoursesQuerie($studentID: ID!, $organizationID: ID!) {
    getStudent(id: $studentID, organizationID: $organizationID) {
      courseIDs
      organizationCustomers {
        organizationID
        customerID
        subscriptions {
          courseID
          subscriptionID
          active
          dateSubscribed
          autoRenewal
        }
      }
    }
  }
`;

export const stdGetAvailableCoursesQuerie = /* GraphQL */ `
  query stdGetAvailableCoursesQuerie($courseID: ID!, $organizationID: ID!) {
    getCourse(id: $courseID, organizationID: $organizationID) {
      id
      name
      organizationID
      status
      stripeProductID
    }
  }
`;

export const stdGetTeamMemberQuerie = /* GraphQL */ `
  query stdGetTeamMemberQuerie($userId: ID!, $organizationID: ID!) {
    getTeamMember(id: $userId, organizationID: $organizationID) {
      id
      name
      email
      role
      courseIDs
    }
  }
`;

export const stdGetAllTheCoursesQuerie = /* GraphQL */ `
  query stdGetAllTheCoursesQuerie($organizationId: ID!) {
    listCourses(organizationID: $organizationId, limit: 1500) {
      items {
        id
        name
        status
      }
    }
  }
`;

export const stdGetStudentCoursesQuerie = /* GraphQL */ `
  query stdGetStudentCoursesQuerie($studentId: ID!, $organizationId: ID!) {
    getStudent(id: $studentId, organizationID: $organizationId) {
      id
      lastCourseId
      organizationCustomers {
        organizationID
        customerID
        subscriptions {
          courseID
          subscriptionID
          active
          freeAccess
          dateSubscribed
          coursePreview
          questionsLeft
          autoRenewal
        }
      }
    }
  }
`;

export const stdListAllCoursesOrganizationQuerie = /* GraphQL */ `
  query stdListAllCoursesOrganizationQuerie($organizationID: ID!, $nextToken: String) {
    listCourses(
      organizationID: $organizationID
      nextToken: $nextToken
      limit: 1500
      filter: { status: { ne: DELETED } }
    ) {
      nextToken
      items {
        id
        name
        organizationID
        status
        stripeProductID
        url
        actionRequired
        hiden
      }
    }
  }
`;
