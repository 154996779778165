import * as t from './actionTypes/contentActionTypes';

export const getContainer = value => ({
  type: t.GET_CONTAINER,
  value
});

export const setContainer = value => ({
  type: t.SET_CONTAINER,
  value
});

export const setContainersLength = value => ({
  type: t.SET_CONTAINERS_LENGTH,
  value
});

export const loadingContainer = value => ({
  type: t.LOADING_CONTAINER,
  value
});

export const getNextTopicContent = value => ({
  type: t.GET_NEXT_TOPIC_CONTENT,
  value
});

export const setNextTopicContent = value => ({
  type: t.SET_NEXT_TOPIC_CONTENT,
  value
});

export const markRead = value => ({
  type: t.MARK_READ,
  value
});

export const lessonSession = value => ({
  type: t.LESSON_SESSION,
  value
});

export const startLessonSession = payload => ({
  type: t.START_LESSON_SESSION,
  payload
});

export const exitLessonSession = payload => ({
  type: t.EXIT_LESSON_SESSION,
  payload
});

export const trackStudentStats = payload => ({
  type: t.TRACK_STUDENT_STATS,
  payload
});
