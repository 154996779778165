import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import styles from '../../../assets/jss/components/Common/pillButtonStyles';
import { Typography, CircularProgress } from '@mui/material';
import { Lock } from 'react-feather';

function PillButton(props) {
  const classes = styles(props);
  const {
    label,
    color,
    handleClick,
    loading,
    disabled,
    showLockIcon,
    flat,
    dataCypress,
    carouselTextButton
  } = props; //Flat prop will make the button look longer with and extra padding
  const [circularColor, setCircularColor] = useState('inherit');
  const [colorBtn, setColorBtn] = useState(color);

  useEffect(() => {
    if (color === ' dark') setCircularColor('inherit');
    else setCircularColor('inherit');

    if (disabled) setColorBtn('gray');
    else setColorBtn(color);
  }, [color, disabled]);

  return (
    <Button
      className={`${classes.rootButton} ${colorBtn} ${flat} ${classes.flatBtn}`}
      disabled={disabled}
      onClick={handleClick}
      data-cy={dataCypress}
    >
      {loading ? (
        <CircularProgress color={circularColor} size="20px" />
      ) : showLockIcon ? (
        <div className={classes.lockedGrid}>
          <Lock className={classes.lockIcon} />
          <Typography
            className={carouselTextButton ? classes.carouselTextButton : classes.textButtons}
          >
            {label}
          </Typography>
        </div>
      ) : (
        <Typography
          className={carouselTextButton ? classes.carouselTextButton : classes.textButtons}
        >
          {label}
        </Typography>
      )}
    </Button>
  );
}

export default PillButton;
