import { takeLatest, all, put, select } from 'redux-saga/effects';
// Actions
import * as types from '../actions/actionTypes/appActionTypes';
import * as appActions from '../actions/appActions';
import { handleCatchError } from '../actions/errorHandlerActions';
// Selectors
import { getOrganizationDataReducer } from '../selectors/organizationSelector';
import { getStudentDataReducer, getPracticeExamId } from '../selectors/userSelectors';
import { getCourseDataReducer, getTopicDataReducer } from '../selectors/Course/courseSelector';
import { getActualQuestionReducer as getActualQuestionStudySession } from '../selectors/studySessionSelectors';
import {
  getActualQuestionReducer as getActualQuestionExam,
  getExamDataReducer
} from '../selectors/Course/examSelector';
// Queries
import { stdGetAppThemeSettingsQuerie } from '../graphql/queries/appQueries';
// Mutations
import { sendIntercomAutomaticMessageMutation } from '../graphql/queries/callsToLambdaFunctions';
// Other files
import GraphOp from '../sagas/common/GraphOp';
import { defaultTheme } from '../../src/components/Common/const';

// SAGA FUNCTIONS
function* loadAppThemeSagas(action) {
  try {
    let studentAppTheme;
    if (action && action.value) {
      studentAppTheme = yield GraphOp(stdGetAppThemeSettingsQuerie, {
        organizationId: action.value
      });
    }
    studentAppTheme =
      studentAppTheme &&
        studentAppTheme.data &&
        studentAppTheme.data.getOrganization &&
        studentAppTheme.data.getOrganization.appSetting
        ? studentAppTheme.data.getOrganization.appSetting
        : null;
    // In case the app theme does not exist we use everprep's default ones.
    if (!studentAppTheme) {
      studentAppTheme = defaultTheme;
    }
    yield put(appActions.setAppTheme(studentAppTheme));
  } catch (err) {
    yield put(handleCatchError(err, 'loadAppThemeSagas'));
  }
}

function* sendIntercomAutomaticMessageSagas(action) {
  try {
    let [
      studentData,
      organizationData,
      courseData,
      questionSS,
      questionExam,
      practiceExamId,
      examData,
      selectedTopic
    ] = yield all([
      select(getStudentDataReducer),
      select(getOrganizationDataReducer),
      select(getCourseDataReducer),
      select(getActualQuestionStudySession),
      select(getActualQuestionExam),
      select(getPracticeExamId),
      select(getExamDataReducer),
      select(getTopicDataReducer)
    ]);
    const { feedback, type, email } = action.payload;
    const contentId = selectedTopic && selectedTopic.id ? selectedTopic.id : '';
    const contentName = selectedTopic && selectedTopic.name ? selectedTopic.name : '';
    // If the issue is TECHNICAL_ISSUE send the message to everprep intercom account
    const accessToken =
      type === 'TECHNICAL_ISSUE' &&
        organizationData &&
        organizationData.intercom &&
        organizationData.intercom.accessToken
        ? organizationData.intercom.accessToken
        : null;
    if (accessToken) {
      // Create an according message
      const message = 'Hi, i have a ' + type + ' issue:\n ' + feedback;
      const customAttributes = {
        user_id: studentData && studentData.id ? studentData.id : '',
        name: studentData && studentData.name ? studentData && studentData.name : '',
        email,
        courseId: courseData && courseData.id ? courseData.id : '',
        courseName: courseData && courseData.name ? courseData.name : '',
        actualQuestionIdStudySession: questionSS && questionSS.id ? questionSS.id : '',
        actualQuestionNameStudySession: questionSS && questionSS.name ? questionSS.name : '',
        actualQuestionIdExam: questionExam && questionExam.id ? questionExam.id : '',
        actualQuestionNameExam: questionExam && questionExam.name ? questionExam.name : '',
        practiceExamId: practiceExamId,
        examName: examData && examData.title ? examData.title : '',
        ...(selectedTopic && selectedTopic.type === 'LESSON'
          ? {
            lessonId: contentId,
            lessonName: contentName
          }
          : {
            questionSetId: contentId,
            questionSetName: contentName
          }),
        pageview: window.location.pathname
      };
      yield GraphOp(sendIntercomAutomaticMessageMutation, {
        email,
        message,
        accessToken,
        customAttributes: JSON.stringify(customAttributes)
      });
    }
  } catch (err) {
    yield put(handleCatchError(err, 'sendIntercomAutomaticMessageSagas'));
  }
}

// WATCHERS
function* loadAppThemeWatcher() {
  yield takeLatest(types.LOAD_APP_THEME, loadAppThemeSagas);
}

function* sendIntercomAutomaticMessageWatcher() {
  yield takeLatest(types.SEND_INTERCOM_MESSAGE, sendIntercomAutomaticMessageSagas);
}

export default function* sagas() {
  yield all([loadAppThemeWatcher(), sendIntercomAutomaticMessageWatcher()]);
}
