import { combineReducers } from 'redux';
import Login from './loginReducer';
import User from './userReducer';
import Course from './courseReducer';
import Organization from './organizationReducer';
import Exam from './examReducer';
import ErrorHandler from './errorHandlerReducer';
import MyAccount from './myAccountReducer';
import StudySession from './studySessionReducer';
import Content from './contentReducer';
import App from './appReducer';
import LoaderHandler from './loaderHandlerReducer';

export default routerReducer =>
  combineReducers({
    router: routerReducer,
    Login,
    User,
    Course,
    Organization,
    Exam,
    ErrorHandler,
    MyAccount,
    StudySession,
    Content,
    App,
    LoaderHandler
  });
