import { Map } from 'immutable';
import * as t from '../actions/actionTypes/courseActionTypes';
import * as loginActions from '../actions/actionTypes/loginActionTypes';

const initialState = Map({
  courseData: null,
  courseName: '',
  sections: [],
  topicData: null,
  loadingTopicData: false,
  studyPerformance: null,
  timeStudied: [],
  questionsAnswered: [],
  loginStreak: [],
  availableCourses: null, // null means not loaded yet, [] means the user has no courses
  loadingAvailableCourses: false,
  errorAvailableCourses: null,
  stripeProduct: null,
  selectedTab: 0,
  freeTrial: null,
  previewQuestionsNumber: null, //This is for the preivew mode, when the user can see the course info without being logged
  previewExamsNumber: null, //This is for the preivew mode, when the user can see the course info without being logged
  percentageLoadingScreen: 0,
  loadingScreen: false,
  loadingStudyModes: false,
  courseQuestions: null,
  courseContents: [],
  generateStripePaymentError: null,
  coursesAvailableForPurchase: null
});

const Course = function (state = initialState, action) {
  switch (action.type) {
    case t.SET_COURSE_DATA:
      return state.set('courseData', action.value);
    case t.SECTIONS:
      let cloneSections = [...action.value];
      return state.set('sections', cloneSections);
    case t.SET_TOPIC_DATA:
      return state.set('topicData', action.value);
    case t.LOADING_TOPIC_DATA:
      return state.set('loadingTopicData', action.value);
    case t.SET_STUDY_PERFORMANCE:
      return state.set('studyPerformance', action.value);
    case t.SET_LOGIN_STREAK:
      return state.set('loginStreak', action.value);
    case t.SET_QUESTIONS_ANSWERED:
      return state.set('questionsAnswered', action.value);
    case t.SET_TIME_STUDIED:
      return state.set('timeStudied', action.value);
    case t.SET_AVAILABLE_COURSES:
      return state.set('availableCourses', action.value);
    case t.LOADING_AVAILABLE_COURSES:
      return state.set('loadingAvailableCourses', action.value);
    case t.ERROR_AVAILABLE_COURSES:
      return state.set('errorAvailableCourses', action.value);
    case t.SET_STRIPE_COURSE_PRODUCT:
      return state.set('stripeProduct', action.payload);
    case t.SET_SELECTED_TAB:
      return state.set('selectedTab', action.payload);
    case t.SET_COURSE_FREE_TRIAL:
      return state.set('freeTrial', action.payload);
    case t.SWITCH_COURSE:
      return initialState;
    case loginActions.SIGN_OUT_USER:
      return initialState;
    case t.COURSE_NAME:
      return state.set('courseName', action.value);
    case t.SET_PRECENTAGE_LOADING_SCREEN:
      return state.set('percentageLoadingScreen', action.value);
    case t.SET_LOADING_SCREEN:
      return state.set('loadingScreen', action.value);
    case t.LOADING_STUDY_MODES:
      return state.set('loadingStudyModes', action.value);
    case t.COURSE_QUESTIONS:
      return state.set('courseQuestions', action.value);
    case t.COURSE_CONTENTS:
      return state.set('courseContents', action.value);
    case t.GENERATE_STRIPE_PAYMENT_ERROR:
      return state.set('generateStripePaymentError', action.value);
    case t.COURSES_AVAILABLE_FOR_PURCHASE:
      return state.set('coursesAvailableForPurchase', action.value);
    default:
      return state;
  }
};

export default Course;
