export const LOADING_SIGNIN_USER = 'LOADING_SIGNIN_USER';
export const ERROR_SIGNIN_USER = 'ERROR_SIGNIN_USER';
export const LOADING_PASSWORD_CHANGE = 'LOADING_PASSWORD_CHANGE';
export const ERROR_PASSWORD_CHANGE = 'ERROR_PASSWORD_CHANGE';
export const SUCCESS_PASSWORD_CHANGE = 'SUCCESS_PASSWORD_CHANGE';
export const SIGN_OUT_USER = 'SIGN_OUT_USER';
export const VERIFY_USER = 'VERIFY_USER';
export const POOL_DATA = 'POOL_DATA';
export const SET_SIGN_IN_USER = 'SET_SIGN_IN_USER';
export const SET_FORGOT_PASSWORD_EMAIL = 'SET_FORGOT_PASSWORD_EMAIL';
