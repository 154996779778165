export const GET_ORGANIZATION_DATA = 'organization/GET_ORGANIZATION_DATA';
export const SET_ORGANIZATION_DATA = 'organization/SET_ORGANIZATION_DATA';
export const LOADING_ORGANIZATION_DATA = 'organization/LOADING_ORGANIZATION_DATA';
export const GET_ORGANIZATION_LOGO = 'organization/GET_ORGANIZATION_LOGO';
export const GET_ALL_COURSES_ORGANIZATION = 'organization/GET_ALL_COURSES_ORGANIZATION';
export const SET_ORGANIZATION_LOGO = 'organization/SET_ORGANIZATION_LOGO';
export const LOADING_ORGANIZATION_LOGO = 'organization/LOADING_ORGANIZATION_LOGO';
export const GET_ORGANIZATION_BUNDLES = 'organization/GET_ORGANIZATION_BUNDLES';
export const SET_ORGANIZATION_BUNDLES = 'organization/SET_ORGANIZATION_BUNDLES';
export const GET_ORGANIZATION_SETTINGS = 'organization/GET_ORGANIZATION_SETTINGS';
export const SET_ORGANIZATION_SETTINGS = 'organization/SET_ORGANIZATION_SETTINGS';
export const SEND_REPORT_EMAIL_TO_ORGANIZATION = 'organization/SEND_REPORT_EMAIL_TO_ORGANIZATION';
export const SET_ORGANIZATION_FAVICON = 'organization/SET_ORGANIZATION_FAVICON';
export const SET_ORGANIZATION_LOGO_BLOB_URL = 'organization/SET_ORGANIZATION_LOGO_BLOB_URL';
