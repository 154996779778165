import { getStateWithOnlyOneLevel } from '../utils/StateSelectorHelper';

const getState = state => getStateWithOnlyOneLevel(state, 'StudySession');

export const getLoadingQuestions = state => getState(state).get('loadingQuestions');
export const getStackedQuestions = state => getState(state).get('stackedQuestions');
export const getStackedAnswers = state => getState(state).get('stackedAnswers');
export const getNotElapsedQuestions = state => getState(state).get('notElapsedQuestions');
export const getActualQuestionReducer = state => getState(state).get('actualQuestion');
export const getLoadingActualQuestionReducer = state =>
  getState(state).get('loadingActualQuestion');
export const getStudySessionData = state => getState(state).get('studySessionData');
export const getStudySessionResultsReducer = state => getState(state).get('studySessionResults');
export const getStudyModesReducer = state => getState(state).get('studyModes');
export const getActualAnswerReducer = state => getState(state).get('actualAnswer');
export const getRespondedCorrectlyReducer = state => getState(state).get('respondedCorrectly');
export const getShowSubmitReducer = state => getState(state).get('showSubmit');
export const getShowResultsReducer = state => getState(state).get('showResults');
export const getShowFinalResultsReducer = state => getState(state).get('showFinalResults');
export const getStudentAnswerReducer = state => getState(state).get('studentAnswer');
export const getExpiredPreview = state => getState(state).get('expiredPreview');
export const getPreloadedQuestions = state => getState(state).get('preloadedQuestions');
export const getBtnSubmitReducer = state => getState(state).get('btnSubmit');
export const getStoredSelfGradedReducer = state => getState(state).get('storedSelfGraded');
export const getPreloadedFirstQuestionsReducer = state =>
  getState(state).get('preloadedFirstQuestions');
export const getLoadedQuestionsReducer = state => getState(state).get('loadedQuestions');
export const getAnchoredNumberReducer = state => getState(state).get('anchoredNumber');
export const getAnchoredAnswersReducer = state => getState(state).get('anchoredAnswers');
