import { Map } from 'immutable';
import * as t from '../actions/actionTypes/studySessionActionTypes';
import * as loginActions from '../actions/actionTypes/loginActionTypes';

const initialState = Map({
  studySessionData: null,
  stackedQuestions: [],
  stackedAnswers: [],
  studyModes: [],
  loadingQuestions: false,
  actualQuestion: null,
  studySessionResults: null,
  actualAnswer: null,
  respondedCorrectly: '',
  showSubmit: false,
  showResults: false,
  showFinalResults: false,
  studentAnswer: null,
  expiredPreview: false,
  preloadedQuestions: [],
  storedSelfGraded: false,
  preloadedFirstQuestions: [],
  loadedQuestions: [],
  anchoredNumber: null,
  anchoredAnswers: [],
  notElapsedQuestions: []
});

const StudySession = function (state = initialState, action) {
  switch (action.type) {
    case t.SET_BTN_SUBMIT:
      return state.set('btnSubmit', action.value);
    case t.SET_LOADING_QUESTIONS:
      return state.set('loadingQuestions', action.value);
    case t.SET_STACKED_QUESTIONS:
      return state.set('stackedQuestions', action.payload);
    case t.SET_STACKED_ANSWERS:
      return state.set('stackedAnswers', action.value);
    case t.STUDY_MODES:
      return state.set('studyModes', action.value);
    case t.ACTUAL_QUESTION:
      return state.set('actualQuestion', action.value);
    case t.STUDY_SESSION_DATA:
      return state.set('studySessionData', action.value);
    case t.STUDY_SESSION_RESULTS:
      return state.set('studySessionResults', action.value);
    case t.ACTUAL_ANSWER:
      return state.set('actualAnswer', action.value);
    case t.RESPONDED_CORRECTLY:
      return state.set('respondedCorrectly', action.value);
    case t.SHOW_SUBMIT:
      return state.set('showSubmit', action.value);
    case t.SHOW_RESULTS:
      return state.set('showResults', action.value);
    case t.SHOW_FINAL_RESULTS:
      return state.set('showFinalResults', action.value);
    case t.STUDENT_ANSWER:
      return state.set('studentAnswer', action.value);
    case t.EXPIRED_PREVIEW:
      return state.set('expiredPreview', action.value);
    case t.PRELOADED_QUESTIONS:
      return state.set('preloadedQuestions', action.value);
    case t.STORED_SELF_GRADED:
      return state.set('storedSelfGraded', action.value);
    case t.PRELOADED_FIRST_QUESTIONS:
      return state.set('preloadedFirstQuestions', action.value);
    case t.LOADED_QUESTIONS:
      return state.set('loadedQuestions', action.value);
    case t.ANCHORED_NUMBER:
      return state.set('anchoredNumber', action.value);
    case t.ANCHORED_ANSWERS:
      return state.set('anchoredAnswers', action.value);
    case loginActions.SIGN_OUT_USER:
      return initialState;
    case t.SET_NOT_ELAPSED_QUESTIONS:
      return state.set('notElapsedQuestions', action.value);
    default:
      return state;
  }
};

export default StudySession;
