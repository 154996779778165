export const stdGetNotificationPreferencesQuerie = /* GraphQL */ `
  query stdGetNotificationPreferencesQuerie($studentId: ID!, $organizationId: ID!) {
    getStudent(id: $studentId, organizationID: $organizationId) {
      notificationPreferences {
        courseID
        newQuestionEmail
        newQuestionPush
        weeklyPerformanceEmail
        weeklyPerformancePush
      }
    }
  }
`;

export const stdGetStudentFeedbacksQuerie = /* GraphQL */ `
  query stdGetStudentFeedbacksQuerie($studentId: ID!, $organizationId: ID!) {
    getStudent(id: $studentId, organizationID: $organizationId) {
      feedbacks {
        createdAt
        feedback
        receptorUserId
        reporterUserId
        status
        type
        updatedAt
      }
    }
  }
`;

export const stdGetStudentDataQuerie = /* GraphQL */ `
  query stdGetStudentDataQuerie($studentId: ID!, $organizationId: ID!) {
    getStudent(id: $studentId, organizationID: $organizationId) {
      id
      email
      name
      createdAt
      updatedAt
      lessonsRead
      lastCourseId
      organizationCustomers {
        organizationID
        customerID
        subscriptions {
          courseID
          subscriptionID
          active
          freeAccess
          dateSubscribed
          coursePreview
          questionsLeft
          autoRenewal
        }
      }
      activeCoursesByPeriod {
        courseID
        addedByCreator
        periods {
          isActive
          beginningOfPeriod
          endOfPeriod
        }
      }
    }
  }
`;

export const stdGetCourseNotificationsQuerie = /* GraphQL */ `
  query stdGetCourseNotificationsQuerie($studentId: ID!, $organizationId: ID!) {
    getStudent(id: $studentId, organizationID: $organizationId) {
      notificationPreferences {
        courseID
        newQuestionEmail
        newQuestionPush
        weeklyPerformanceEmail
        weeklyPerformancePush
        sendNQE
      }
    }
  }
`;

export const stdGetCourseLoginQuerie = /* GraphQL */ `
  query stdGetCourseLoginQuerie($studentId: ID!, $organizationId: ID!) {
    getStudent(id: $studentId, organizationID: $organizationId) {
      lastLoginCourses {
        courseID
        lastLogin
      }
    }
  }
`;

export const stdGetStudentCoursesIdsQuerie = /* GraphQL */ `
  query stdGetStudentCoursesIdsQuerie($studentId: ID!, $organizationId: ID!) {
    getStudent(id: $studentId, organizationID: $organizationId) {
      courseIDs
    }
  }
`;

export const stdGetStudentByEmailQuerie = /* GraphQL */ `
  query stdGetStudentByEmailQuerie(
    $studentEmail: String
    $organizationId: ID!
    $nextToken: String
  ) {
    getOrganization(id: $organizationId) {
      id
      students(limit: 1000, nextToken: $nextToken, filter: {email: {eq: $studentEmail}}) {
        items {
          name
          id
          email
          courseIDs
          organizationCustomers {
            customerID
            organizationID
          }
        }
        nextToken
      }
    }
  }
`;

export const stdGetStudentActiveCoursesQuerie = /* GraphQL */ `
  query stdGetStudentActiveCoursesQuerie($organizationId: ID!, $studentId: ID!) {
    getStudent(organizationID: $organizationId, id: $studentId) {
      activeCoursesByPeriod {
        courseID
        addedByCreator
        periods {
          isActive
          beginningOfPeriod
          endOfPeriod
        }
      }
    }
  }
`;

export const stdListStudentsWithThisEmailQuerie = /* GraphQL */ `
  query stdListStudentsWithThisEmailQuerie($organizationId: ID!, $studentEmail:String, $nextToken: String) {
    listStudents(
      organizationID: $organizationId,
      nextToken: $nextToken,
      limit: 1500,
      filter: {  email: { eq: $studentEmail } }
    ) {
      items {
        id
        name
        email
      }
      nextToken
    }
  }
`;