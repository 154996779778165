export const stdGetCourseDataQuerie = /* GraphQL */ `
  query stdGetCourseDataQuerie($courseId: ID!, $organizationID: ID!) {
    getCourse(id: $courseId, organizationID: $organizationID) {
      id
      name
      stripeProductID
      status
      expandedContent
      url
      pricing {
        active
        subscriptions {
          active
          period
          autoRenewal
          price
          stripePriceId
        }
        freeTrial {
          active
          duration
          period
        }
      }
      certificate {
        examId
        percentage
        active
        __typename
      }
      examTitle
    }
  }
`;

export const stdGetPreviewCourseQuestionsQuerie = /* GraphQL */ `
  query stdGetPreviewCourseQuestionsQuerie($courseId: ID!, $nextToken: String) {
    listQuestions(
      courseID: $courseId
      filter: { status: { eq: ACTIVE } }
      nextToken: $nextToken
      limit: 1500
    ) {
      items {
        topicIDs
        id
        status
      }
      nextToken
    }
  }
`;

export const stdGetCourseSectionsQuerie = /* GraphQL */ `
  query stdGetCourseSectionsQuerie($courseId: ID!, $organizationId: ID!) {
    getCourse(id: $courseId, organizationID: $organizationId) {
      sections {
        id
        name
        order
        topicsIds
      }
    }
  }
`;

export const stdGetContentDataQuerie = /* GraphQL */ `
  query stdGetContentDataQuerie($courseId: ID!, $topicId: ID!) {
    getTopic(courseID: $courseId, id: $topicId) {
      id
      name
      type
      read {
        studentID
        read
      }
    }
  }
`;

export const stdGetCourseContentsQuerie = /* GraphQL */ `
  query stdGetCourseContentsQuerie($courseId: ID!, $nextToken: String) {
    listTopics(
      nextToken: $nextToken,
      courseID: $courseId,
      filter: { status: { eq: ACTIVE } }
      limit: 1500
    ) {
      nextToken
      items {
        id
        name
        order
        type
        status
      }
    }
  }
`;

/* Deleted parts of stdGetCourseContentsQuerie delete also the type condition 
  read {
    studentID
    read
  }
  contentContainers {
    items {
      id
        topicID
        contents {
          id
        }
      }
  }
*/

export const stdGetLoginStreakQuerie = /* GraphQL */ `
  query stdGetLoginStreakQuerie($studentID: ID!, $organizationID: ID!) {
    getStudent(id: $studentID, organizationID: $organizationID) {
      loginStreaks {
        id
        courseID
        days
        loginDate
        longest
      }
    }
  }
`;

export const stdGetStudentPerfomanceQuerie = /* GraphQL */ `
  query stdGetStudentPerfomanceQuerie($studentId: ID!, $organizationId: ID!) {
    getStudent(id: $studentId, organizationID: $organizationId) {
      todayPerformance {
        courseID
        day
        msStudied
        questionsAnswered
        correctAnswers
      }
      weekPerformance {
        courseID
        numberOfWeek
        msStudied
        questionsAnswered
        correctAnswers
      }
      monthPerformance {
        courseID
        numberOfMonth
        msStudied
        questionsAnswered
        correctAnswers
      }
      yearPerformance {
        courseID
        year
        msStudied
        questionsAnswered
        correctAnswers
      }
      allTimePerformance {
        courseID
        msStudied
        correctAnswers
        questionsAnswered
      }
    }
  }
`;

export const stdGetCourseFreeTrialQuerie = /* GraphQL */ `
  query stdGetCourseFreeTrialQuerie($courseId: ID!, $organizationId: ID!) {
    getCourse(id: $courseId, organizationID: $organizationId) {
      pricing {
        freeTrial {
          period
          duration
          active
        }
      }
    }
  }
`;
export const stdGetCoursePricingQuerie = /* GraphQL */ `
  query stdGetCoursePricingQuerie($courseId: ID!, $organizationId: ID!) {
    getCourse(id: $courseId, organizationID: $organizationId) {
      id
      name
      stripeProductID
      pricing {
        active
        subscriptions {
          stripePriceId
        }
        freeTrial {
          period
          duration
          active
        }
      }
    }
  }
`;

export const stdGetCourseActionRequiredQuerie = /* GraphQL */ `
  query stdGetCourseActionRequiredQuerie($courseId: ID!, $organizationId: ID!) {
    getCourse(id: $courseId, organizationID: $organizationId) {
      id
      actionRequired
    }
  }
`;

export const getCertificatesByStudent = /* GraphQL */ `
  query GetCertificatesByStudent($studentID: ID!) {
    certificatesByStudent(studentID: $studentID) {
      items {
        id
        studentID
        courseID
        organizationId
        studentName
        date
      }
    }
  }
`;
