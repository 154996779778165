export const createLoginStreak = /* GraphQL */ `
  mutation createLoginStreak($courseID: ID!, $days: Int, $loginDate: AWSDateTime, $longest: Int) {
    createLoginStreak(
      input: { courseID: $courseID, days: $days, loginDate: $loginDate, longest: $longest }
    ) {
      id
      days
      courseID
      loginDate
      longest
    }
  }
`;

export const updateStudent = /* GraphQL */ `
  mutation updateStudent($organizationID: ID!, $studentID: ID!, $loginStreak: [LoginStreakInput]) {
    updateStudent(
      input: { organizationID: $organizationID, id: $studentID, loginStreaks: $loginStreak }
    ) {
      id
      loginStreaks {
        courseID
        days
        id
        loginDate
        longest
      }
    }
  }
`;

export const updateExamHistory = /* GraphQL */ `
  mutation updateExamHistory(
    $organizationID: ID!
    $studentID: ID!
    $examHistory: [ExamHistoryInput]
  ) {
    updateStudent(
      input: { id: $studentID, organizationID: $organizationID, examHistory: $examHistory }
    ) {
      examHistory {
        accuracy
        courseID
        date
        examID
        score
        time
        title
        totalQuestions
      }
    }
  }
`;

export const updateNotificationPreferences = /* GraphQL */ `
  mutation updateNotificationPreferences(
    $organizationId: ID!
    $studentId: ID!
    $notificationPreferences: [NotificationPreferencesInput]
  ) {
    updateStudent(
      input: {
        organizationID: $organizationId
        id: $studentId
        notificationPreferences: $notificationPreferences
      }
    ) {
      id
      notificationPreferences {
        newQuestionEmail
        newQuestionPush
        weeklyPerformanceEmail
        weeklyPerformancePush
      }
    }
  }
`;

export const createStudentFeedback = /* GraphQL */ `
  mutation createStudentFeedback(
    $studentId: ID!
    $organizationId: ID!
    $feedback: [QuestionFeedbackInput]
  ) {
    updateStudent(
      input: { organizationID: $organizationId, id: $studentId, feedbacks: $feedback }
    ) {
      id
    }
  }
`;

export const manageStudentPerformance = /* GraphQL */ `
  mutation manageStudentPerformance(
    $studentId: ID!
    $organizationId: ID!
    $todayPerformance: [TodayPerformanceInput]
    $weekPerformance: [WeekPerformanceInput]
    $monthPerformance: [MonthPerformanceInput]
    $yearPerformance: [YearPerformanceInput]
    $allTimePerformance: [AllTimePerformanceInput]
  ) {
    updateStudent(
      input: {
        id: $studentId
        organizationID: $organizationId
        todayPerformance: $todayPerformance
        weekPerformance: $weekPerformance
        monthPerformance: $monthPerformance
        yearPerformance: $yearPerformance
        allTimePerformance: $allTimePerformance
      }
    ) {
      id
    }
  }
`;

export const updateQuestionsNotifications = /* GraphQL */ `
  mutation updateQuestionsNotifications(
    $studentId: ID!
    $organizationId: ID!
    $questionNotifications: [QuestionNotificationInput]!
  ) {
    updateStudent(
      input: {
        id: $studentId
        organizationID: $organizationId
        questionNotifications: $questionNotifications
      }
    ) {
      id
    }
  }
`;

export const updateLastLogin = /* GraphQL */ `
  mutation updateLastLogin(
    $studentId: ID!
    $organizationId: ID!
    $lastLogin: AWSDateTime
    $lastLoginCourses: [LastLoginCourseInput]
    $ipAddress: String
    $firstLogin: AWSDateTime
  ) {
    updateStudent(
      input: {
        id: $studentId
        organizationID: $organizationId
        lastLogin: $lastLogin
        lastLoginCourses: $lastLoginCourses
        ipAddress: $ipAddress
        firstLogin: $firstLogin
      }
    ) {
      id
    }
  }
`;

export const updateOrganizationCustomer = /* GraphQL */ `
  mutation updateOrganizationCustomer(
    $organizationID: ID!
    $id: ID!
    $organizationCustomers: [OrganizationCustomerInput]
  ) {
    updateStudent(
      input: {
        organizationID: $organizationID
        id: $id
        organizationCustomers: $organizationCustomers
      }
    ) {
      id
      email
      name
      createdAt
      updatedAt
      organizationCustomers {
        organizationID
        customerID
        subscriptions {
          courseID
          subscriptionID
          active
          freeAccess
          dateSubscribed
          coursePreview
          questionsLeft
          autoRenewal
        }
      }
    }
  }
`;

export const updateCurseSubscription = /* GraphQL */ `
  mutation updateCurseSubscription(
    $organizationID: ID
    $id: ID
    $courseSubscriptions: [CourseSubscriptionInput]
  ) {
    updateStudent(
      input: { organizationID: $organizationID, id: $id, courseSubscriptions: $courseSubscriptions }
    ) {
      id
    }
  }
`;

export const updateStudentEmail = /* GraphQL */ `
  mutation updateStudentEmail($organizationId: ID!, $studentId: ID!, $email: String!) {
    updateStudent(input: { organizationID: $organizationId, id: $studentId, email: $email }) {
      id
      email
      name
      createdAt
      updatedAt
      organizationCustomers {
        organizationID
        customerID
        subscriptions {
          courseID
          subscriptionID
          active
        }
      }
    }
  }
`;

export const updateStudentName = /* GraphQL */ `
  mutation updateStudentName($organizationId: ID!, $studentId: ID!, $name: String!) {
    updateStudent(input: { organizationID: $organizationId, id: $studentId, name: $name }) {
      id
      email
      name
      createdAt
      updatedAt
      organizationCustomers {
        organizationID
        customerID
        subscriptions {
          courseID
          subscriptionID
          active
        }
      }
    }
  }
`;

export const createStudentMutation = /* GraphQL */ `
  mutation createStudentMutation(
    $organizationId: ID!
    $id: ID!
    $name: String
    $email: String
    $courseIDs: [String]
    $firstLogin: AWSDateTime
    $activeCoursesByPeriod: [ActiveCoursesByPeriodInput]
  ) {
    createStudent(
      input: {
        organizationID: $organizationId
        id: $id
        name: $name
        email: $email
        courseIDs: $courseIDs
        firstLogin: $firstLogin
        activeCoursesByPeriod: $activeCoursesByPeriod
      }
    ) {
      id
      email
      name
      createdAt
      updatedAt
      organizationCustomers {
        organizationID
        customerID
        subscriptions {
          courseID
          subscriptionID
          active
          freeAccess
          dateSubscribed
        }
      }
    }
  }
`;

export const deleteStudent = /* GraphQL */ `
  mutation MyMutation2($organizationId: ID!, $id: ID!) {
    deleteStudent(input: { organizationID: $organizationId, id: $id }) {
      id
    }
  }
`;

export const stdCreateAdminStudentMutation = /* GraphQL */ `
  mutation stdCreateAdminStudentMutation(
    $organizationId: ID!
    $id: ID!
    $name: String
    $email: String
    $courseIDs: [String]
  ) {
    createStudent(
      input: {
        organizationID: $organizationId
        id: $id
        name: $name
        email: $email
        courseIDs: $courseIDs
      }
    ) {
      id
    }
  }
`;

export const updateCourseStudent = /* GraphQL */ `
  mutation updateCourseStudent(
    $studentId: ID!
    $organizationId: ID!
    $courseIDs: [String]
    $activeCoursesByPeriod: [ActiveCoursesByPeriodInput]
  ) {
    updateStudent(
      input: {
        id: $studentId
        organizationID: $organizationId
        courseIDs: $courseIDs
        activeCoursesByPeriod: $activeCoursesByPeriod
      }
    ) {
      id
      email
      name
      createdAt
      updatedAt
      organizationCustomers {
        organizationID
        customerID
        subscriptions {
          courseID
          subscriptionID
          active
          freeAccess
          dateSubscribed
        }
      }
    }
  }
`;

export const stdUpdateLastCourseStudent = /* GraphQL */ `
  mutation stdUpdateLastCourseStudent(
    $organizationId: ID!
    $studentId: ID!
    $lastCourseId: String
  ) {
    updateStudent(
      input: { organizationID: $organizationId, id: $studentId, lastCourseId: $lastCourseId }
    ) {
      id
    }
  }
`;
