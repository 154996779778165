import * as types from '../actions/actionTypes/examActionTypes';
import uuid4 from 'uuid4';
import { put, takeLatest, all, call, select } from 'redux-saga/effects';
import { API, graphqlOperation, Storage } from 'aws-amplify';
//Queries
import * as query from '../graphql/queries/examQueries';
import * as orgQueries from '../graphql/queries/organizationQueries';
import * as studySessionQueries from '../graphql/queries/studySessionQueries';
import { stdGetFileDataQuerie } from '../graphql/queries/contentQueries';
import {
  stdGetCourseActionRequiredQuerie,
  stdGetCourseDataQuerie
} from '../graphql/queries/courseQueries';
import { generateCertificateS3Link } from '../graphql/queries/callsToLambdaFunctions';
//Selectors
import {
  getExamProgressReducer,
  getExamDataReducer,
  getHistoryExamsReducer,
  getStackedQuestionsReducer,
  getPreloadedQuestions,
  getPreviousQuestions,
  getPreloadedExamFirstQuestions
} from '../selectors/Course/examSelector';
import {
  getCourseIdReducer,
  getUserOrganizationId,
  getUserData,
  getPracticeExamId,
  getStudentDataReducer
} from '../selectors/userSelectors';
import { getAppTheme } from '../selectors/appSelectors';
import {
  getCourseDataReducer,
  getStudyPerformanceReducer,
  getTimeStudiedReducer
} from '../selectors/Course/courseSelector';
import {
  getOrganizationDataReducer,
  getOrganizationLogoReducer
} from '../selectors/organizationSelector';
//Actions
import { startLoading, stopLoading } from '../actions/loaderHandlerActions';
import { sendReportEmailToOrganization } from '../actions/organizationActions';
import * as notificationsActions from '../actions/errorHandlerActions';
import * as examActions from '../actions/examActions';
import * as studySessionAction from '../actions/studySessionActions';
import { setSelectedCourse, sendEmail } from '../actions/userActions';
import { setCourseData } from '../actions/courseActions';
//Mutations
import * as examMutations from '../graphql/mutationsExam';
import * as studySessionMutations from '../graphql/mutationsStudySession';
import { updateCourseActionRequired } from '../graphql/mutationsCourse';
//External Files
import GraphOp from '../sagas/common/GraphOp';
import StorageGet from '../sagas/common/StorageGet';
import getOrdinalNumber from '../utils/getOrdinalSuffix';
const numberOfPreloadedQts = 20;

function* getOpenExamsSagas(action) {
  try {
    const [organizationId, courseIdReducer, student, courseData] = yield all([
      select(getUserOrganizationId),
      select(getCourseIdReducer),
      select(getUserData),
      select(getCourseDataReducer),
      put(examActions.loadingOpenExam(true))
    ]);
    let { courseId } = action.value;
    if (!courseId) {
      if (courseData && courseData.id) courseId = courseData.id;
      else if (courseIdReducer) courseId = courseIdReducer;
    }
    if (courseId) yield put(setSelectedCourse(courseId));
    const studentID = student && student.username ? student.username : '';
    const arrayOfQueries = [];
    if (courseId && studentID && organizationId) {
      // Get exams
      arrayOfQueries.push(
        call(
          [API, 'graphql'],
          graphqlOperation(query.stdGetOpenExamDataQuerie, {
            courseID: courseId
          })
        )
      );
      // Get student progress
      arrayOfQueries.push(
        call(
          [API, 'graphql'],
          graphqlOperation(query.stdGetExamProgressQuerie, {
            studentID,
            organizationID: organizationId
          })
        )
      );
      // Get exams history
      arrayOfQueries.push(
        call(
          [API, 'graphql'],
          graphqlOperation(query.stdGetExamHistoryQuerie, {
            studentID,
            organizationID: organizationId
          })
        )
      );
    }
    const [examsResponse, examProgressResponse, examHistoryResponse] = yield all(arrayOfQueries);
    let openExamsArray =
      examsResponse &&
      examsResponse.data &&
      examsResponse.data.listExams &&
      examsResponse.data.listExams.items &&
      examsResponse.data.listExams.items.length > 0
        ? examsResponse.data.listExams.items
        : [];
    const examProgress =
      examProgressResponse &&
      examProgressResponse.data &&
      examProgressResponse.data.getStudent &&
      examProgressResponse.data.getStudent.examProgress
        ? examProgressResponse.data.getStudent.examProgress
        : [];
    const historyExams =
      examHistoryResponse &&
      examHistoryResponse.data &&
      examHistoryResponse.data.getStudent &&
      examHistoryResponse.data.getStudent.examHistory
        ? examHistoryResponse.data.getStudent.examHistory
        : [];
    /*
    // In case of data corruption 
    // This mutations resets the exam progress and the exam history
    examProgress = null;
    yield GraphOp(examMutations.deleteExamProgress, {
      studentID: student.username,
      organizationID: organizationId,
      examProgress: null,
    });
    yield GraphOp(examMutations.updateExamHistory, {
      studentID: student.username,
      organizationID: organizationId,
      examHistory: [],
    });
    yield all([put(examActions.examProgress(null))]);
    */
    // We sort the open exams by date from newest to oldest
    let openExamsStarted = [];
    let openExamsNotStarted = [];
    const firstQuestions = [];
    if (openExamsArray && openExamsArray.length > 0) {
      openExamsArray.forEach(oe => {
        if (oe && oe.id) {
          let firstQuestion = null;
          // We get the first question of the exam
          if (oe.questionsIDs && oe.questionsIDs.length > 0 && oe.questionsIDs[0]) {
            firstQuestion = oe.questionsIDs[0];
          }
          if (examProgress && examProgress.length > 0) {
            const isStarted = examProgress.find(ep => ep && ep.examID === oe.id);
            if (isStarted) {
              // If the exam has already been started we preload the last question that was available to answer
              if (isStarted.lastQuestionAnswered) firstQuestion = isStarted.lastQuestionAnswered;
              openExamsStarted.push(oe);
            } else openExamsNotStarted.push(oe);
          } else openExamsNotStarted.push(oe);
          if (firstQuestion) firstQuestions.push(firstQuestion);
        }
      });
      if (openExamsStarted && openExamsStarted.length > 0) {
        openExamsStarted.sort(
          (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      }
      if (openExamsNotStarted && openExamsNotStarted.length > 0) {
        openExamsNotStarted.sort(
          (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      }
      openExamsArray = openExamsStarted.concat(openExamsNotStarted);
    }
    yield all([
      put(examActions.setOpenExams(openExamsArray)),
      put(examActions.examProgress(examProgress)),
      put(examActions.getHistoryExams({ historyExams })),
      put(examActions.loadingOpenExam(false))
    ]);
    if (firstQuestions && firstQuestions.length < numberOfPreloadedQts) {
      yield all([put(examActions.preloadExamFirstQuestions({ questions: firstQuestions }))]);
    }
  } catch (err) {
    yield put(examActions.loadingOpenExam(false));
    yield put(notificationsActions.handleCatchError(err, 'getOpenExamsSagas'));
  }
}

function* getPreloadExamFirstQuestionsSagas(action) {
  try {
    const { questions } = action.payload;
    let [preloaded, courseId, courseData] = yield all([
      select(getPreloadedExamFirstQuestions),
      select(getCourseIdReducer),
      select(getCourseDataReducer)
    ]);
    if (!courseId) courseId = courseData && courseData.id;
    const arrayOfQueries = [];
    const cleanedArray = [];
    if (questions && questions.length > 0 && courseId) {
      questions.forEach(id => {
        if (id) {
          let thisQuestion = null;
          // We check if it is already preloaded.
          if (preloaded && preloaded.length > 0) {
            thisQuestion = preloaded.find(item => item && item.id && item.id === id);
            // If we find it, we do not need to load it again.
            if (thisQuestion) cleanedArray.push(thisQuestion);
          }
          // If we don't find it then we have to make a query to look for it.
          if (!thisQuestion) {
            arrayOfQueries.push(
              call(
                [API, 'graphql'],
                graphqlOperation(studySessionQueries.stdGetQuestionQuerie, {
                  questionId: id,
                  courseId
                })
              )
            );
          }
        }
      });
    }
    if (arrayOfQueries && arrayOfQueries.length > 0) {
      const response = yield all(arrayOfQueries);
      if (response && response.length > 0) {
        response.forEach(item => {
          if (item && item.data && item.data.getQuestion && item.data.getQuestion.id) {
            cleanedArray.push(item.data.getQuestion);
          }
        });
      }
    }
    yield all([put(examActions.preloadedExamFirstQuestions(cleanedArray))]);
  } catch (err) {
    yield put(notificationsActions.handleCatchError(err, 'getPreloadExamFirstQuestionsSagas'));
  }
}

function* getHistoryExamsSagas(action) {
  try {
    let [courseId, courseData] = yield all([
      select(getCourseIdReducer),
      select(getCourseDataReducer),
      put(examActions.loadingHistoryExam(true))
    ]);
    const { historyExams } = action.payload;
    if (!courseId) courseId = courseData && courseData.id;
    let history = [];
    // Filter the history exams by course id
    if (historyExams && historyExams.length > 0) {
      history = historyExams.filter(exam => exam && exam.courseID && exam.courseID === courseId);
      if (history && history.length > 0) {
        history.sort((a, b) => (b.date > a.date ? 1 : a.date > b.date ? -1 : 0));
      } else history = [];
    }
    yield all([
      put(examActions.setHistoryExams(history)),
      put(examActions.loadingHistoryExam(false))
    ]);
  } catch (err) {
    yield put(examActions.loadingHistoryExam(false));
    yield put(notificationsActions.handleCatchError(err, 'getHistoryExamsSagas'));
  }
}

function* getExamResultsSagas(action) {
  try {
    let [courseId, examID, courseData] = yield all([
      select(getCourseIdReducer),
      select(getPracticeExamId),
      select(getCourseDataReducer),
      put(startLoading())
    ]);
    const { started, studentResponses, examQuestions, timeOut } = action.value;
    if (!courseId) courseId = courseData && courseData.id;
    // I first determine the number of questions on the exam.
    // Also the number of student responses
    const totalQuestions = examQuestions ? examQuestions.length : 0;
    const questionsAnswered = studentResponses ? studentResponses.length : 0;
    // Obtain the questions and the answers to each question.
    const questionResponses = [];
    if (examQuestions && examQuestions.length > 0 && courseId) {
      const arrayOfCalls = [];
      examQuestions.forEach(questionId => {
        arrayOfCalls.push(
          call(
            [API, 'graphql'],
            graphqlOperation(studySessionQueries.stdGetQuestionQuerie, {
              courseId,
              questionId
            })
          )
        );
      });
      const response = yield all(arrayOfCalls);
      // Store the questions and their answers in the questionResponses array.
      if (response && response.length > 0) {
        response.forEach(r => {
          if (r && r.data && r.data.getQuestion) {
            questionResponses.push(r.data.getQuestion);
          }
        });
      }
    }
    // Once we have obtained the answers to each question, we must compare them with the student's answer.
    let correctlyAnswered = 0;
    const qtAndResponses = [];
    if (
      studentResponses &&
      questionResponses &&
      studentResponses.length > 0 &&
      questionResponses.length > 0
    ) {
      studentResponses.forEach(studentAnswer => {
        // The student answered the question so we have to determine if it was correct
        // The current question is the actual question that has the answers
        const currentQuestion = questionResponses.find(
          answer => answer.id === studentAnswer.questionID
        );
        /* 
          These flags will serve to
          Determine if all of the student's answers are correct.
          Determine if all available correct answers have been answered
          Determine if the answer is correct and complete
        */
        let allCorrect = true;
        let allAnswered = true;
        let isCorrect = true;
        if (studentAnswer && currentQuestion && currentQuestion.id) {
          const id = currentQuestion.id;
          switch (currentQuestion.type) {
            case 'MULTI_CHOICE':
              // Map the student's response and determine if the answers he/she chose are correct.
              if (
                studentAnswer.multipleChoiceTextAnswer &&
                studentAnswer.multipleChoiceTextAnswer.length > 0
              ) {
                studentAnswer.multipleChoiceTextAnswer.forEach(item => {
                  if (item && !item.correct) allCorrect = false;
                });
              }
              // Map the correct answers to the question and determine if all correct answers were selected by the student.
              if (
                currentQuestion.multipleChoiceTextAnswer &&
                currentQuestion.multipleChoiceTextAnswer.length > 0 &&
                studentAnswer.multipleChoiceTextAnswer &&
                studentAnswer.multipleChoiceTextAnswer.length > 0
              ) {
                currentQuestion.multipleChoiceTextAnswer.forEach(answer => {
                  if (answer && answer.correct && answer.choice) {
                    const isAnswered = studentAnswer.multipleChoiceTextAnswer.find(
                      item => item && item.choice === answer.choice
                    );
                    if (!isAnswered) allAnswered = false;
                  }
                });
              }
              if (allAnswered && allCorrect) {
                correctlyAnswered++;
                qtAndResponses.push({ id, correct: true });
              } else {
                qtAndResponses.push({ id, correct: false });
              }
              break;
            case 'MULTI_IMAGE_CHOICE':
              // I map the student's answers and determine if what he/she chose is correct.
              if (
                studentAnswer.multipleChoiceImageAnswer &&
                studentAnswer.multipleChoiceImageAnswer.length > 0
              ) {
                studentAnswer.multipleChoiceImageAnswer.forEach(item => {
                  if (item && !item.correct) allCorrect = false;
                });
              }
              // I map the answers and see if he/she forgot to answer any correct ones.
              if (
                currentQuestion.multipleChoiceImageAnswer &&
                currentQuestion.multipleChoiceImageAnswer.length > 0 &&
                studentAnswer.multipleChoiceImageAnswer &&
                studentAnswer.multipleChoiceImageAnswer.length > 0
              ) {
                currentQuestion.multipleChoiceImageAnswer.forEach(answer => {
                  if (answer && answer.correct && answer.choiceId) {
                    const isAnswered = studentAnswer.multipleChoiceImageAnswer.find(
                      item => item && item.choiceId === answer.choiceId
                    );
                    if (!isAnswered) allAnswered = false;
                  }
                });
              }
              if (allCorrect && allAnswered) {
                correctlyAnswered++;
                qtAndResponses.push({ id, correct: true });
              } else {
                qtAndResponses.push({ id, correct: false });
              }
              break;
            case 'ORDER':
              if (
                studentAnswer.orderTypeAnswer &&
                currentQuestion.orderTypeAnswer &&
                studentAnswer.orderTypeAnswer.length > 0 &&
                currentQuestion.orderTypeAnswer.length > 0
              ) {
                studentAnswer.orderTypeAnswer.forEach((item, index) => {
                  const aux = currentQuestion.orderTypeAnswer[index].title;
                  if (item.title && item.title !== aux) isCorrect = false;
                });
              }
              if (isCorrect) {
                correctlyAnswered++;
                qtAndResponses.push({ id, correct: true });
              } else {
                qtAndResponses.push({ id, correct: false });
              }
              break;
            case 'MULTI_MATCHING_CHOICE':
              if (
                studentAnswer.matchingAnswer &&
                currentQuestion.matchingAnswer &&
                studentAnswer.matchingAnswer.length > 0 &&
                currentQuestion.matchingAnswer.length > 0
              ) {
                Object.values(studentAnswer.matchingAnswer).forEach((item, index) => {
                  const correct = currentQuestion.matchingAnswer[index].match;
                  if (item.match === correct) item.correct = true;
                  else {
                    item.correct = false;
                    isCorrect = false;
                  }
                });
                if (isCorrect) {
                  correctlyAnswered++;
                  qtAndResponses.push({ id, correct: true });
                } else {
                  qtAndResponses.push({ id, correct: false });
                }
              }
              break;
            default:
              break;
          }
        }
      });
    }
    // Once we have the number of correct questions we can determine the accuracy of the questions.
    let accuracy = 0;
    if (correctlyAnswered > 0 && totalQuestions > 0) {
      accuracy = Math.ceil((correctlyAnswered * 100) / totalQuestions);
    }
    // Lastly, we determine the time the exam lasted.
    let timeElapsed = null;
    const now = new Date();
    const nowInMs = now.getTime();
    /* 
      The toISOString() method returns a string in simplified extended ISO format (ISO 8601), 
      which is always 24 or 27 characters long (YYYY-MM-DDTHH:mm:ss.sssZ or ±YYYYYY-MM-DDTHH:mm:ss.sssZ, respectively) 
      The substr() method returns a portion of the string, starting at the specified index and extending for a given number of characters afterwards.
      In this case, it returns the portion related to the time elapsed of the exam
    */
    if (timeOut) {
      // If the test was terminated by time out, the started will be ignored and the time will be saved.
      const startedAux = new Date(nowInMs - timeOut);
      timeElapsed = new Date(nowInMs - startedAux).toISOString().substr(11, 8);
    } else {
      if (started) {
        const startedMs = new Date(started).getTime();
        timeElapsed = new Date(nowInMs - startedMs).toISOString().substr(11, 8);
      }
    }
    // We already have everything calculated to determine the result to be displayed on the screen.
    const finalResults = {
      accuracy,
      timeElapsed,
      questionsAnswered,
      totalQuestions,
      correctAnswers: correctlyAnswered
    };
    // Now we continue updating the exam history
    const thisExam = {};
    thisExam.date = now;
    thisExam.score = correctlyAnswered;
    thisExam.totalQuestions = totalQuestions;
    thisExam.time = timeElapsed;
    thisExam.studentAnswers = studentResponses;
    thisExam.accuracy = accuracy;
    thisExam.qtAndResponses = qtAndResponses;
    const examHistoryAux = {
      examID,
      studentAnswers: studentResponses
    };
    // With this data we can now display the final screen
    yield all([
      put(examActions.finished(true)),
      put(examActions.examResults(finalResults)),
      put(examActions.questionsAndAnswers(qtAndResponses)),
      put(examActions.setCurrentExamHistory(examHistoryAux)),
      put(examActions.updateHistoryExams(thisExam)),
      put(stopLoading())
    ]);
  } catch (err) {
    yield all([put(stopLoading())]);
    yield put(notificationsActions.handleCatchError(err, 'getExamResultsSagas'));
  }
}

function* updateHistoryExamsSagas(action) {
  try {
    const [courseData, practiceExamId, examData, historyExams, organizationId, userData] =
      yield all([
        select(getCourseDataReducer),
        select(getPracticeExamId),
        select(getExamDataReducer),
        select(getHistoryExamsReducer),
        select(getUserOrganizationId),
        select(getUserData)
      ]);
    let {
      date,
      score,
      totalQuestions,
      time,
      studentAnswers,
      title,
      redirect,
      accuracy,
      qtAndResponses
    } = action.value;
    if (!title) title = examData && examData.title ? examData.title : '';
    const courseId = courseData && courseData.id ? courseData.id : '';
    const newExam = {};
    newExam.examID = practiceExamId;
    newExam.courseID = courseId;
    newExam.title = title;
    newExam.date = date ? date : new Date();
    newExam.score = score ? score : 0;
    newExam.totalQuestions = totalQuestions ? totalQuestions : 0;
    newExam.time = time;
    newExam.studentAnswers = studentAnswers;
    if (!accuracy) {
      let acc = 0;
      if (score && totalQuestions && score > 0 && totalQuestions > 0) {
        acc = Math.ceil((score * 100) / totalQuestions);
      }
      newExam.accuracy = acc;
    } else {
      newExam.accuracy = accuracy;
    }
    // Exam certificate handle
    newExam.certificateEarned = 'N/A';
    if (
      courseData &&
      courseData.certificate &&
      courseData.certificate.active &&
      courseData.certificate.examId &&
      courseData.certificate.examId === practiceExamId
    ) {
      if (newExam.accuracy >= courseData.certificate.percentage) {
        newExam.certificateEarned = 'Yes';
      } else {
        newExam.certificateEarned = 'No';
      }
    }
    // Accuracy of question sets
    if (
      examData &&
      examData.questionSource === 'PERCENTAGE' &&
      !examData.repeatedQuestions &&
      examData.examTopics &&
      examData.examTopics.length > 0
    ) {
      const questionSetAndAccuracy = [];
      examData.examTopics.forEach(qs => {
        // I get the questions from each one
        if (qs && qs.questions && qs.questions.length > 0) {
          let correctsAnswers = 0;
          // Mapping the questions
          qs.questions.forEach(questionId => {
            // I look for that question and see if it is well answered.
            const thisQt = qtAndResponses.find(qa => qa && qa.id === questionId);
            if (thisQt && thisQt.correct) correctsAnswers++;
          });
          // Get the accuracy of this question set
          const acc =
            correctsAnswers > 0 && qs.questions.length > 0
              ? Math.ceil((correctsAnswers / qs.questions.length) * 100)
              : 0;
          questionSetAndAccuracy.push({ id: qs.id, accuracy: acc });
        }
        newExam.questionSetAccuracy = questionSetAndAccuracy;
      });
    }
    historyExams.push(newExam);
    if (
      userData &&
      userData.username &&
      organizationId &&
      historyExams &&
      historyExams.length > 0
    ) {
      yield GraphOp(examMutations.updateExamHistory, {
        studentID: userData.username,
        organizationID: organizationId,
        examHistory: historyExams
      });
      yield all([
        put(examActions.setHistoryExams(historyExams)),
        put(examActions.updateExamProgress()),
        put(examActions.getOpenExams({ courseId }))
      ]);
      if (redirect) redirect();
    }
  } catch (err) {
    yield put(notificationsActions.handleCatchError(err, 'updateHistoryExamsSagas'));
  }
}

function* getExamDataSagas(action) {
  try {
    const [examProgress, courseID, courseData, orgData] = yield all([
      select(getExamProgressReducer),
      select(getCourseIdReducer),
      select(getCourseDataReducer),
      select(getOrganizationDataReducer),
      put(startLoading()),
      put(examActions.finished(false)),
      put(examActions.preloadedQuestions([])),
      put(examActions.previousQuestions([])),
      put(examActions.examResults(null))
    ]);
    const { examID, redirect, results } = action.payload;
    let courseId = null;
    if (courseData && courseData.id) courseId = courseData.id;
    else {
      if (courseID) courseId = courseID;
    }
    const organizationID = orgData && orgData.id ? orgData.id : null;
    if (examID && courseId && organizationID) {
      const arrayOfQueries = [];
      arrayOfQueries.push(
        call(
          [API, 'graphql'],
          graphqlOperation(query.stdGetExamDataQuerie, {
            courseID: courseId,
            examID
          })
        )
      );
      arrayOfQueries.push(
        call(
          [API, 'graphql'],
          graphqlOperation(stdGetCourseDataQuerie, {
            courseId,
            organizationID
          })
        )
      );
      const [examResponse, courseResponse] = yield all(arrayOfQueries);
      const examData =
        examResponse && examResponse.data && examResponse.data.getExam
          ? examResponse.data.getExam
          : null;
      const certificate =
        courseResponse &&
        courseResponse.data &&
        courseResponse.data.getCourse &&
        courseResponse.data.getCourse.certificate
          ? courseResponse.data.getCourse.certificate
          : null;
      if (courseData) {
        const updatedCourseData = { ...courseData, certificate: certificate };
        yield put(setCourseData(updatedCourseData));
      }
      let startedDate = new Date();
      let questionStack = [];
      let firstQuestion = null;
      // Original exam data
      if (examData && examData.questionsIDs && examData.questionsIDs.length > 0) {
        questionStack = examData.questionsIDs;
        firstQuestion = examData.questionsIDs[0];
      }
      // We see the current status of the exams answered by the student.
      if (examProgress && examProgress.length > 0 && !results) {
        let thisExam = examProgress.find(exam => exam && exam.examID && exam.examID === examID);
        if (thisExam) {
          //A question may have been eliminated in the process in which an exam started and is still in course
          //In that case, the questions are the same that the exam began with
          //Once the exam is finished, the examQuestionsIDs is set empty and the questions are re-calculated
          if (thisExam.examQuestionsIDs && thisExam.examQuestionsIDs.length > 0) {
            questionStack = thisExam.examQuestionsIDs;
          }
          // This is used to restart the exam at the question we were at when we left.
          if (thisExam.lastQuestionAnswered) {
            firstQuestion = thisExam.lastQuestionAnswered;
          }
          // This brings the start date back to the time when the test actually started.
          if (thisExam.started) startedDate = thisExam.started;
        }
      }
      yield put(examActions.setStackedQuestions(questionStack));
      // Case in which the exam is by percentage and without repeated questions.
      if (
        examData &&
        examData.examTopics &&
        examData.questionSource &&
        examData.questionSource === 'PERCENTAGE' &&
        examData.repeatedQuestions === false &&
        examData.examTopics.length > 0
      ) {
        const arrayOfQueries = [];
        // The name of each question set must be obtained to be displayed at the end of the exam.
        examData.examTopics.forEach(item => {
          if (item && item.id) {
            arrayOfQueries.push(
              call(
                [API, 'graphql'],
                graphqlOperation(query.stdGetQuestionSetNameQuerie, {
                  contentId: item.id,
                  courseID: courseId
                })
              )
            );
          }
        });
        const response = yield all(arrayOfQueries);
        if (response && response.length > 0) {
          examData.examTopics.forEach(item => {
            if (item && item.id) {
              response.forEach(content => {
                if (
                  content &&
                  content.data &&
                  content.data.getTopic &&
                  content.data.getTopic.id &&
                  content.data.getTopic.name &&
                  content.data.getTopic.id === item.id
                ) {
                  item.name = content.data.getTopic.name;
                }
              });
            }
          });
        }
      }
      yield all([
        put(examActions.setExamData(examData)),
        put(examActions.started(startedDate)),
        put(examActions.setActualQuestion(firstQuestion, null, true))
      ]);
      if (redirect) redirect();
    }
    // Save the results of this exam if we are working with the exams history
    if (results) {
      yield all([put(examActions.examResults(results))]);
    }
    yield put(stopLoading());
  } catch (err) {
    yield all([
      put(stopLoading()),
      put(notificationsActions.handleCatchError(err, 'getExamDataSagas'))
    ]);
  }
}

function* getStackedAnswersSagas(action) {
  try {
    const [courseData] = yield all([select(getCourseDataReducer)]);
    let questionIds = [];
    const arrayOfCalls = [];
    const questions = [];
    if (action && action.value && action.value.length > 0) {
      questionIds = action.value;
    }
    if (courseData && courseData.id && questionIds && questionIds.length > 0) {
      questionIds.forEach(questionId => {
        if (questionId) {
          arrayOfCalls.push(
            call(
              [API, 'graphql'],
              graphqlOperation(studySessionQueries.stdGetQuestionQuerie, {
                courseId: courseData.id,
                questionId
              })
            )
          );
        }
      });
      if (arrayOfCalls && arrayOfCalls.length > 0) {
        const response = yield all(arrayOfCalls);
        if (response && response.length > 0) {
          response.forEach(r => {
            if (r && r.data && r.data.getQuestion && r.data.getQuestion.id) {
              questions.push(r.data.getQuestion);
            }
          });
        }
      }
    }
    yield all([put(examActions.setStackedAnswers(questions))]);
  } catch (err) {
    yield put(notificationsActions.handleCatchError(err, 'getStackedAnswersSagas'));
  }
}

function* setActualQuestionSagas(action) {
  try {
    let [
      courseId,
      examData,
      stackedQuestions,
      preloadedQuestions,
      previousQuestions,
      examProgress,
      preloadedExamFirstQuestions,
      courseData
    ] = yield all([
      select(getCourseIdReducer),
      select(getExamDataReducer),
      select(getStackedQuestionsReducer),
      select(getPreloadedQuestions),
      select(getPreviousQuestions),
      select(getExamProgressReducer),
      select(getPreloadedExamFirstQuestions),
      select(getCourseDataReducer),
      put(startLoading()),
      put(studySessionAction.actualAnswer(null)),
      put(studySessionAction.respondedCorrectly(false)),
      put(studySessionAction.showSubmit(false)),
      put(studySessionAction.showResults(false)),
      put(studySessionAction.showFinalResults(false))
    ]);
    // Constants
    const questionId = action.value;
    const firstQuestion = action.firstQuestion;
    const examId = examData && examData.id ? examData.id : '';
    if (!courseId) {
      courseId = courseData && courseData.id ? courseData.id : null;
      yield put(setSelectedCourse(courseId));
    }
    // Values to be stored
    let question = null;
    let preloaded = [];
    let previous = [...previousQuestions];
    // Auxiliary data
    let nextQts = [];
    let indexQt = -1;
    let fillPreloadedArray = false;
    if (questionId) {
      // Empty preloaded
      if (!preloadedQuestions || preloadedQuestions.length === 0) {
        fillPreloadedArray = true;
        if (
          firstQuestion &&
          preloadedExamFirstQuestions &&
          preloadedExamFirstQuestions.length > 0
        ) {
          const belongs = preloadedExamFirstQuestions.find(
            qt => qt && qt.id && qt.id === questionId
          );
          if (belongs) question = belongs;
        }
        if (!question) {
          const response = yield GraphOp(studySessionQueries.stdGetQuestionQuerie, {
            courseId,
            questionId
          });
          question =
            response && response.data && response.data.getQuestion
              ? response.data.getQuestion
              : null;
        }
      } else {
        // We search in the preloaded in case we advance in the exam,
        indexQt = preloadedQuestions.findIndex(qt => qt && qt.id === questionId);
        if (indexQt !== -1) {
          const thisQt = preloadedQuestions.splice(indexQt, 1);
          if (thisQt && thisQt.length > 0) {
            question = thisQt.pop();
            previous.push(question);
          }
        } else {
          // Let's look at the previous ones in case we are going backwards
          if (previous && previous.length > 0) {
            indexQt = previous.findIndex(qt => qt && qt.id === questionId);
            if (indexQt !== -1) question = previous[indexQt];
          }
          if (!question) {
            // Force the query and get the question
            const forceResponse = yield GraphOp(studySessionQueries.stdGetQuestionQuerie, {
              courseId,
              questionId
            });
            question =
              forceResponse && forceResponse.data && forceResponse.data.getQuestion
                ? forceResponse.data.getQuestion
                : null;
          }
        }
        preloaded = [...preloadedQuestions];
      }
      if (question) {
        // Handle for files
        if (question.questionFileId || question.furtherReadingFileId) {
          if (question.questionFileId) {
            const fileId = question.questionFileId;
            let fileData = yield GraphOp(stdGetFileDataQuerie, {
              courseId,
              fileId
            });
            if (fileData) {
              fileData = fileData.data.getCourseFile;
              if (fileData) {
                const { fileKey, name } = fileData;
                if (fileKey && name) {
                  const filePath = `${fileKey}/${fileId}_${name}`;
                  fileData.awsLink = yield StorageGet(filePath);
                  delete question.questionFileId;
                  question.questionFile = fileData;
                }
              }
            }
          }
          if (question.furtherReadingFileId) {
            const fileId = question.furtherReadingFileId;
            let fileData = yield GraphOp(stdGetFileDataQuerie, {
              courseId,
              fileId
            });
            if (fileData) {
              fileData = fileData.data.getCourseFile;
              if (fileData) {
                const { fileKey, name } = fileData;
                if (fileKey && name) {
                  const filePath = `${fileKey}/${fileId}_${name}`;
                  fileData.awsLink = yield StorageGet(filePath);
                  delete question.furtherReadingFileId;
                  question.furtherReadingFile = fileData;
                }
              }
            }
          }
        }
        // In case the question is of the type Multiple choice the questions should be in random order except those that are blocked
        if (
          question.type === 'MULTI_CHOICE' &&
          question.multipleChoiceTextAnswer &&
          question.multipleChoiceTextAnswer.length > 0
        ) {
          const numberOfCorrectAnswers = question.multipleChoiceTextAnswer.filter(
            option => option.correct
          ).length;
          if (numberOfCorrectAnswers === 1) {
            question.choiceText = 'Select the correct answer';
            question.oneOption = true;
          } else {
            question.choiceText = 'Select all answers that apply';
            question.oneOption = false;
          }
          const answersArray = [];
          const lockedQuestions = question.multipleChoiceTextAnswer.filter(
            option => option && option.locked
          );
          let randomQuestions = question.multipleChoiceTextAnswer.filter(
            option => option && !option.locked
          );
          if (randomQuestions && randomQuestions.length > 0) {
            randomQuestions = randomQuestions.sort(() => Math.random() - 0.5);
          }
          // Now I have two arrays: one with the random positions and one with the blocked positions.
          // That's why we will have to leave the desired answers fixed in the array and the rest of the questions will go in a random order
          if (lockedQuestions.length > 0) {
            const totalOfQuestions = question.multipleChoiceTextAnswer.length;
            const lockedPositions = [];
            const randomPositions = [];
            lockedQuestions.forEach(item => {
              answersArray[item.order] = item;
              lockedPositions.push(item.order);
            });
            for (let i = 1; i <= totalOfQuestions; i++) {
              const filter = lockedPositions.filter(position => position === i);
              if (filter.length === 0) randomPositions.push(i);
            }
            if (randomPositions.length > 0) {
              randomPositions.forEach(i => {
                const questionNotLocked = randomQuestions.pop();
                questionNotLocked.order = i;
                answersArray[i] = questionNotLocked;
              });
            }
          } else {
            randomQuestions.forEach((item, index) => {
              item.order = index + 1;
              answersArray[index + 1] = item;
            });
          }
          // Delete answerArray[0]
          answersArray.shift();
          question.multipleChoiceTextAnswer = answersArray;
        }
        // Here you have to get the images for the multiple choice answers.
        if (
          question.type === 'MULTI_IMAGE_CHOICE' &&
          question.multipleChoiceImageAnswer &&
          question.multipleChoiceImageAnswer.length > 0
        ) {
          const numberOfCorrectAnswers = question.multipleChoiceImageAnswer.filter(
            option => option.correct
          ).length;
          if (numberOfCorrectAnswers === 1) {
            question.choiceText = 'Select the correct image';
            question.oneOption = true;
          } else {
            question.choiceText = 'Select all images that apply';
            question.oneOption = false;
          }
          const imageAnswer = [];
          const queriesArray = [];
          const previewsURLS = [];
          // Map the ids to get the file information.
          question.multipleChoiceImageAnswer.forEach(item => {
            // This object will form the response that will be sent to the component.
            const image = {};
            image.choiceId = item.choiceId;
            image.order = item.order;
            image.correct = item.correct;
            imageAnswer.push(image);
            // In this array we will store the queries to obtain the file information.
            queriesArray.push(
              call(
                [API, 'graphql'],
                graphqlOperation(stdGetFileDataQuerie, {
                  courseId,
                  fileId: item.choiceId
                })
              )
            );
          });
          // Get the files
          const files = yield all(queriesArray);
          // Map the files to obtain the preview urls
          if (files.length > 0) {
            files.forEach(item => {
              if (item.data.courseFile !== null) {
                const { fileKey, id, name } = item.data.getCourseFile;
                if (fileKey && id && name) {
                  const filePath = `${fileKey}/${id}_${name}`;
                  previewsURLS.push(call([Storage, 'get'], filePath));
                }
              }
            });
          }
          const urls = yield all(previewsURLS);
          // Finally, I map the options and match with the url
          imageAnswer.forEach((item, index) => {
            // They should come ordered, I'm going to add one more control to make sure.
            const link = urls[index];
            let match = link.includes(item.choiceId);
            if (match) item.link = link;
            else {
              urls.forEach(url => {
                match = url.includes(item.choiceId);
                if (match) item.link = link;
              });
            }
            // If the link was not found it is because the file has been deleted, so I remove the option from the available ones.
            if (!item.link) imageAnswer.splice(index, 1);
          });
          delete question.multipleChoiceImageAnswer;
          question.multipleChoiceImageAnswer = imageAnswer;
        }
        // Get the answer (if is already answered on this exam)
        if (examProgress && examProgress.length > 0) {
          // Search this exam progress
          const thisExam = examProgress.find(ep => ep && ep.examID && ep.examID === examId);
          // If the exam progress exists, and there is one for the current exam
          // And if there are some answers that the student already has made
          if (thisExam && thisExam.studentAnswers && thisExam.studentAnswers.length > 0) {
            // And if there is an answer for the actual question
            const studentAnswer = thisExam.studentAnswers.find(
              sa => sa && sa.questionID && sa.questionID === questionId
            );
            // If the student already answered the question, then the student answer is saved
            if (studentAnswer) question.studentAnswer = studentAnswer;
          }
        }
      }
    }
    if (action.resetFunction) action.resetFunction();
    yield all([put(stopLoading()), put(examActions.actualQuestion(question))]);
    if (fillPreloadedArray) {
      const queriesQuestion = [];
      // Next questions
      if (stackedQuestions && stackedQuestions.length > 0) {
        indexQt = stackedQuestions.findIndex(id => id === questionId);
        if (indexQt !== -1) {
          nextQts = stackedQuestions.slice(indexQt + 1, indexQt + numberOfPreloadedQts);
        }
        if (nextQts && nextQts.length > 0) {
          nextQts.forEach(qtId => {
            queriesQuestion.push(
              call(
                [API, 'graphql'],
                graphqlOperation(studySessionQueries.stdGetQuestionQuerie, {
                  courseId,
                  questionId: qtId
                })
              )
            );
          });
        }
      }
      if (queriesQuestion && queriesQuestion.length > 0) {
        const response = yield all(queriesQuestion);
        if (response && response.length > 0) {
          response.forEach(item => {
            if (item && item.data && item.data.getQuestion && item.data.getQuestion.id) {
              // Question information
              const qt = item.data.getQuestion;
              // Delete unnecessary attributes on exams
              delete qt.actualVersion;
              delete qt.fillInTheBlankAnswer;
              delete qt.flashcardAnswer;
              delete qt.freeTypeAnswer;
              preloaded.push(qt);
            }
          });
        }
      }
    }
    yield all([
      put(examActions.preloadedQuestions(preloaded)),
      put(examActions.previousQuestions(previous))
    ]);
  } catch (err) {
    yield all([put(stopLoading())]);
    yield put(notificationsActions.handleCatchError(err, 'setActualQuestionSagas > examSession'));
  }
}

function* setExamProgressSagas(action) {
  try {
    const [organizationId, student] = yield all([
      select(getUserOrganizationId),
      select(getUserData)
    ]);
    const studentID = student && student.username ? student.username : null;
    let updatedExamProgress = [];
    if (action.value && action.value.length > 0) {
      let examProgress = [];
      if (action && action.value && action.value.examProgress) {
        examProgress = action.value.examProgress;
      } else {
        examProgress = action.value;
      }
      // Each exam progress have this structure:
      // [
      //   {
      //     available: Boolean not null,
      //     examID: Any data not null,
      //     examQuestionsIDs:Any data not null,
      //     timeElapsed: Any data not null,
      //     started: Any data not null,
      //     remainingAttempts: Any data not null,
      //     lastQuestionAnswered: Any data not null,
      //     studentAnswers: Array(1) [
      //       {
      //         studentID: Any data not null,
      //         questionID: Any data not null,
      //         orderTypeAnswer: Any data not null,
      //         multipleChoiceTextAnswer: Any data not null,
      //         multipleChoiceImageAnswer: Any data not null,
      //         matchingAnswer: Any data not null,
      //         freeTypeAnswer: Any data not null,
      //         flashcardAnswer: Any data not null,
      //         fillInTheBlankAnswer: Any data not null,
      //         examID: Any data not null
      //       }
      //     ],
      //     studentID: Any data not null
      //   },
      // ]
      // If some of you values are null, graphql will not update the value, because the mutation returns an error
      // We need to delete all null values before send the mutation
      if (examProgress && examProgress.length > 0) {
        examProgress.forEach(item => {
          if (item) {
            if (!item.available) {
              delete item.available;
              item.available = false;
            }
            if (!item.examID) delete item.examID;
            if (!item.examQuestionsIDs) delete item.examQuestionsIDs;
            if (!item.timeElapsed) delete item.timeElapsed;
            if (!item.started) delete item.started;
            if (!item.remainingAttempts) delete item.remainingAttempts;
            if (!item.lastQuestionAnswered) delete item.lastQuestionAnswered;
            if (!item.studentAnswers) delete item.studentAnswers;
            // For studentAnswers if must delete all null values
            if (item.studentAnswers && item.studentAnswers.length > 0) {
              item.studentAnswers.forEach(sa => {
                if (sa) {
                  if (!sa.studentID) delete sa.studentID;
                  if (!sa.questionID) delete sa.questionID;
                  if (!sa.orderTypeAnswer) delete sa.orderTypeAnswer;
                  // If order and correctOption are null, then we delete the attribute
                  if (sa.orderTypeAnswer && sa.orderTypeAnswer.length > 0) {
                    sa.orderTypeAnswer.forEach(ota => {
                      if (ota) {
                        if (!ota.correctOption) delete ota.correctOption;
                      }
                    });
                  }
                  if (!sa.multipleChoiceTextAnswer) delete sa.multipleChoiceTextAnswer;
                  if (!sa.multipleChoiceImageAnswer) delete sa.multipleChoiceImageAnswer;
                  if (!sa.matchingAnswer) delete sa.matchingAnswer;
                  // If Matching answer but match === "emptyChoicePlaceholder", thats mean that the user didn't answer the question, delete matchingAnswer
                  if (sa.matchingAnswer && sa.matchingAnswer.length > 0) {
                    sa.matchingAnswer.forEach((ma, index) => {
                      if (ma) {
                        if (!ma.order) ma.order = index + 1;
                        if (!ma.match) delete sa.match;
                        if (ma.match === 'emptyChoicePlaceholder') {
                          delete sa.matchingAnswer;
                        }
                      }
                    });
                  }
                  if (!sa.freeTypeAnswer) delete sa.freeTypeAnswer;
                  if (!sa.flashcardAnswer) delete sa.flashcardAnswer;
                  if (!sa.fillInTheBlankAnswer) delete sa.fillInTheBlankAnswer;
                  if (!sa.examID) delete sa.examID;
                }
              });
            }
            if (!item.studentID) delete item.studentID;
          }
        });
      }
      // If the exam progress is not empty, then we send the mutation
      if (examProgress && examProgress.length > 0) {
        examProgress.forEach(item => {
          if (item && !item.studentID) item.studentID = studentID;
        });
        if (organizationId && studentID) {
          const response = yield GraphOp(examMutations.updateExamProgress, {
            studentID,
            organizationID: organizationId,
            examProgress
          });
          updatedExamProgress =
            response &&
            response.data &&
            response.data.updateStudent &&
            response.data.updateStudent.examProgress
              ? response.data.updateStudent.examProgress
              : [];
        }
      }
    }
    if (updatedExamProgress && updatedExamProgress.length > 0) {
      yield put(examActions.examProgress(updatedExamProgress));
    }
  } catch (err) {
    yield put(notificationsActions.handleCatchError(err, 'setExamProgressSagas'));
  }
}

function* sendExamFeedbackSagas(action) {
  const { courseId, examId, feedback, type, setLoading, closeModal, emailPayload } = action.value;
  try {
    if (setLoading) setLoading(true);
    const [selectedCourse, org, studentData] = yield all([
      select(getCourseDataReducer),
      select(getOrganizationDataReducer),
      select(getStudentDataReducer)
    ]);
    // Constants assignation
    const organizationId = org && org.id ? org.id : '';
    const arrayOfQueries = [];
    const arrayOfMutations = [];
    // Get course action required status
    arrayOfQueries.push(
      call(
        [API, 'graphql'],
        graphqlOperation(stdGetCourseActionRequiredQuerie, {
          courseId,
          organizationId
        })
      )
    );
    // Get exam feedbacks
    arrayOfQueries.push(
      call(
        [API, 'graphql'],
        graphqlOperation(query.stdGetExamFeedbacksQuerie, {
          courseId,
          examId
        })
      )
    );
    //Get list of notifications
    arrayOfQueries.push(
      call(
        [API, 'graphql'],
        graphqlOperation(orgQueries.stdGetOrganizationNotificationsQuerie, {
          organizationID: organizationId
        })
      )
    );
    const [actionRequiredResponse, examFeedbacksResponse, orgResponse] = yield all(arrayOfQueries);
    // PART 1: Course action required handle
    const actionRequired =
      actionRequiredResponse &&
      actionRequiredResponse.data &&
      actionRequiredResponse.data.getCourse &&
      actionRequiredResponse.data.getCourse.actionRequired &&
      actionRequiredResponse.data.getCourse.actionRequired.length > 0
        ? actionRequiredResponse.data.getCourse.actionRequired
        : [];
    let courseAR = [];
    let executeMutation = false;
    if (actionRequired && actionRequired.length > 0 && !actionRequired.includes('EXAM')) {
      courseAR = actionRequired;
      courseAR.push('EXAM');
      executeMutation = true;
    }
    if (executeMutation) {
      arrayOfMutations.push(
        call(
          [API, 'graphql'],
          graphqlOperation(updateCourseActionRequired, {
            courseId,
            organizationId,
            actionRequired: courseAR
          })
        )
      );
    }
    // PART 2: Exam feedbacks handle
    let feedbacksArray = [];
    if (
      examFeedbacksResponse &&
      examFeedbacksResponse.data &&
      examFeedbacksResponse.data.getExam &&
      examFeedbacksResponse.data.getExam.feedbacks &&
      examFeedbacksResponse.data.getExam.feedbacks.length > 0
    ) {
      examFeedbacksResponse.data.getExam.feedbacks.forEach(feedback => {
        if (feedback) feedbacksArray.push(feedback);
      });
    }
    // Push the new feedback
    const feedbackId = uuid4();
    feedbacksArray.push({
      feedbackId,
      feedback,
      type,
      status: 'ACTIVE',
      createdAt: new Date().toISOString(),
      reporterUserId: studentData && studentData.id ? studentData.id : '',
      reporterUserName: studentData && studentData.name ? studentData.name : '',
      reporterUserEmail: studentData && studentData.email ? studentData.email : '',
      page: window.location.href
    });
    arrayOfMutations.push(
      call(
        [API, 'graphql'],
        graphqlOperation(examMutations.createExamFeedback, {
          examId,
          courseId,
          feedback: feedbacksArray
        })
      )
    );
    // PART 3: Notifications to the team members
    const notificationsArray =
      orgResponse &&
      orgResponse.data &&
      orgResponse.data.getOrganization &&
      orgResponse.data.getOrganization.notifications &&
      orgResponse.data.getOrganization.notifications.length > 0
        ? orgResponse.data.getOrganization.notifications
        : [];
    // Create a new notification
    const newNotification = {
      id: uuid4(),
      text: 'Exam issue has been reported',
      type: 'EXAM_REPORTED',
      createdAt: new Date().toISOString(),
      organizationName: org && org.name ? org.name : '',
      courseName: selectedCourse && selectedCourse.name ? selectedCourse.name : '',
      courseId: selectedCourse && selectedCourse.id ? selectedCourse.id : '',
      examId,
      readedBy: []
    };
    notificationsArray.push(newNotification);
    // Perform mutation
    yield GraphOp(studySessionMutations.addNotificationMutation, {
      organizationId,
      notifications: notificationsArray
    });
    if (setLoading) setLoading(false);
    if (closeModal) closeModal();
    // Call the sagas that sends emails to support
    if (emailPayload) {
      emailPayload.feedbackId = feedbackId;
      yield all([put(sendReportEmailToOrganization(emailPayload))]);
    }
  } catch (err) {
    if (setLoading) setLoading(false);
    yield put(
      notificationsActions.setNotification({
        message: 'Unable to complete request. Please contact us for support.',
        severity: 'error'
      })
    );
    yield put(notificationsActions.handleCatchError(err, 'sendExamFeedbackSagas'));
  }
}

function* updateExamProgressSagas(action) {
  try {
    /* 
      The idea behind this logic is to handle the exam progress by setting in cero those values that have to be once the exam finishes, 
      so that the student can take the exam again
      But it has to be validated if the exam is still available to be taken again. That is, if there is a max attempts restitrction, the exam should 
      have attemtps to try in order for the exam to be available again
    */
    const examId =
      action && action.value && action.value.examId
        ? action.value.examId
        : yield select(getPracticeExamId);
    const examData =
      action && action.value && action.value.examData
        ? action.value.examData
        : yield select(getExamDataReducer);
    const [organizationId, student, examProgress] = yield all([
      select(getUserOrganizationId),
      select(getUserData),
      select(getExamProgressReducer)
    ]);
    const updatedExamProgress = [];
    if (examProgress && examProgress.length > 0) {
      examProgress.forEach(item => {
        if (item) {
          if (item.examID === examId) {
            //If the exam data has a max attempts restriction, we decrement in 1 when the exam is over
            if (examData && examData.maxAttempts !== null && item.remainingAttempts) {
              const newRemainingAttempts = item.remainingAttempts - 1;
              item.remainingAttempts = newRemainingAttempts;
              //If the exam has a max attempts restriction
              if (newRemainingAttempts <= 0) {
                item.available = false;
              }
            }
            item.started = null;
            item.timeElapsed = null;
            item.studentAnswers = [];
            item.lastQuestionAnswered = null;
            item.examQuestionsIDs = [];
          }
          updatedExamProgress.push(item);
        }
      });
    }
    if (updatedExamProgress.length > 0) {
      yield GraphOp(examMutations.updateExamProgress, {
        studentID: student.username,
        organizationID: organizationId,
        examProgress: updatedExamProgress
      });
      yield all([put(examActions.examProgress(updatedExamProgress))]);
    }
  } catch (err) {
    yield put(notificationsActions.handleCatchError(err, 'updateExamProgressSagas'));
  }
}

function* preloadAllQuestionsSagas(action) {
  try {
    const [preloadedQuestions, courseData] = yield all([
      select(getPreloadedQuestions),
      select(getCourseDataReducer)
    ]);
    const { missingQuestions } = action.payload;
    // The ones we already have preloaded
    const newPreloaded = [...preloadedQuestions];
    const courseId = courseData && courseData.id ? courseData.id : null;
    if (missingQuestions && missingQuestions.length > 0 && courseId) {
      // Array where we will load the queries
      const arrayOfQueries = [];
      // Determine if we can do it in one call or if we will have to make several calls
      const oneCallIsEnough = missingQuestions.length <= numberOfPreloadedQts;
      // All the queries
      missingQuestions.forEach(questionId => {
        arrayOfQueries.push(
          call(
            [API, 'graphql'],
            graphqlOperation(studySessionQueries.stdGetQuestionQuerie, {
              courseId,
              questionId
            })
          )
        );
      });
      if (arrayOfQueries && arrayOfQueries.length > 0) {
        if (oneCallIsEnough) {
          const response = yield all(arrayOfQueries);
          if (response && response.length > 0) {
            response.forEach(item => {
              if (item && item.data && item.data.getQuestion && item.data.getQuestion.id) {
                // Question information
                const qt = item.data.getQuestion;
                // Delete unnecessary attributes on exams
                delete qt.actualVersion;
                delete qt.fillInTheBlankAnswer;
                delete qt.flashcardAnswer;
                delete qt.freeTypeAnswer;
                newPreloaded.push(qt);
              }
            });
          }
        } else {
          // Prevent overloading the system we will only make the default number of calls.
          let calls = arrayOfQueries.slice(0, numberOfPreloadedQts);
          let remainingCalls = arrayOfQueries.slice(numberOfPreloadedQts);
          let continueExecution = true;
          while (continueExecution) {
            const response = yield all(calls);
            if (response && response.length > 0) {
              response.forEach(item => {
                if (item && item.data && item.data.getQuestion && item.data.getQuestion.id) {
                  // Question information
                  const qt = item.data.getQuestion;
                  // Delete unnecessary attributes on exams
                  delete qt.actualVersion;
                  delete qt.fillInTheBlankAnswer;
                  delete qt.flashcardAnswer;
                  delete qt.freeTypeAnswer;
                  newPreloaded.push(qt);
                }
              });
            }
            calls = remainingCalls.slice(0, numberOfPreloadedQts);
            remainingCalls = remainingCalls.slice(numberOfPreloadedQts);
            // With this condition we make sure that all calls have been made and all questions have been loaded.
            if (calls.length === 0 && remainingCalls.length === 0) continueExecution = false;
          }
        }
      }
      yield all([put(examActions.preloadedQuestions(newPreloaded))]);
    }
  } catch (err) {
    yield put(notificationsActions.handleCatchError(err, 'preloadAllQuestionsSagas'));
  }
}

function* generateCertificateSagas(action) {
  try {
    const [
      organizationId,
      userData,
      appTheme,
      org,
      organizationLogo,
      studyPerformance,
      timeStudiedReducer,
      examHistory
    ] = yield all([
      select(getUserOrganizationId),
      select(getStudentDataReducer),
      select(getAppTheme),
      select(getOrganizationDataReducer),
      select(getOrganizationLogoReducer),
      select(getStudyPerformanceReducer),
      select(getTimeStudiedReducer),
      select(getHistoryExamsReducer)
    ]);
    const {
      examData,
      score,
      courseData,
      certificateName,
      setLoading,
      setCertificateReady,
      generatePdf
    } = action.payload;
    // Constants assignacion
    const studentName = certificateName
      ? certificateName
      : userData && userData.name
      ? userData.name
      : '';
    const courseID = courseData && courseData.id ? courseData.id : '';
    const studentID = userData && userData.id ? userData.id : '';
    const examId = examData && examData.id ? examData.id : null;
    if (courseID && studentID && organizationId) {
      const certificateID = uuid4();
      const certificate = {
        id: certificateID,
        studentID,
        courseID,
        organizationId,
        studentName,
        date: new Date()
      };
      const response = yield GraphOp(examMutations.createCertificateMutation, {
        input: certificate
      });
      if (
        response &&
        response.data &&
        response.data.createCertificates &&
        response.data.createCertificates.id
      ) {
        setLoading(false);
        yield all([
          put(examActions.setCertificate(certificate)),
          put(
            notificationsActions.setNotification({
              message: 'Certificate generated',
              severity: 'success'
            })
          )
        ]);
        // Generate s3 bucket url certificateLink
        // generatePdf is a function, certificate is a parameter
        const base64 = yield call(generatePdf, certificate);
        // Make sure base64 are defined
        if (!base64) throw new Error('certificateID and base64 must be defined');
        // Generate and download certificate
        const linkPdf = yield GraphOp(generateCertificateS3Link, {
          certificateID,
          blobBase64: base64
        });
        if (setCertificateReady) setCertificateReady(true);
        const certificateLink =
          linkPdf && linkPdf.data && linkPdf.data.generateCertificateS3Link
            ? linkPdf.data.generateCertificateS3Link
            : null;
        // Email constants
        const studentEmail = userData && userData.email ? userData.email : null;
        const organizationSupportEmail = org && org.supportEmail ? org.supportEmail : '';
        const organizationName = org && org.name ? org.name : '';
        let backgroundEmail = appTheme && appTheme.length > 0 ? appTheme[0].customColor : '#052D30';
        if (appTheme && appTheme.length > 0) {
          let accentTheme = appTheme.find(t => t && t.section === 'ACCENT');
          if (accentTheme && accentTheme.customColor) backgroundEmail = accentTheme.customColor;
        }
        // Send email to the student
        if (studentEmail && certificateLink) {
          const payload = {
            type: 'studentGetCertificate',
            email: studentEmail,
            courseName: courseData.name,
            backgroundEmail,
            organizationLogo,
            organizationSupportEmail,
            organizationName,
            organizationId,
            certificateLink
          };
          // Process the mail and send it
          yield put(sendEmail(payload));
        }
        // Study sessions performance
        let accuracy = 0;
        let questionsAnswered = 0;
        if (studyPerformance && studyPerformance.length > 0) {
          const progress = studyPerformance.find(item => item && item.period === 'All Time');
          if (progress) {
            accuracy = progress.percentage;
            questionsAnswered = progress.correct + progress.incorrect;
          }
        }
        let timeStudied = 0;
        if (timeStudiedReducer && timeStudiedReducer.allTime) {
          const valueInMin = Math.ceil(timeStudiedReducer.allTime / 60000);
          if (valueInMin < 60) {
            if (valueInMin === 1) timeStudied = `1 Minute`;
            else timeStudied = `${valueInMin} Minutes`;
          } else {
            const hours = Math.floor(valueInMin / 60);
            const mins = valueInMin - 60 * hours;
            timeStudied = `${hours} hr ${mins} mins`;
          }
        }
        // Number of attempt to pass the exam
        let nAttempt = 1;
        if (examHistory && examHistory.length > 0 && examId) {
          const previousAttemps = examHistory.filter(
            item => item && item.examID && item.examID === examId
          );
          if (previousAttemps && previousAttemps.length > 0) {
            nAttempt = previousAttemps.length;
          }
        }
        nAttempt = getOrdinalNumber(nAttempt);
        // Creation date
        let studentCreationDate = '';
        if (userData && userData.createdAt) {
          const date = new Date(userData.createdAt);
          studentCreationDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date
            .getDate()
            .toString()
            .padStart(2, '0')}/${date.getFullYear()}`;
        }
        // Send email to the Creator
        if (organizationSupportEmail) {
          const payload = {
            accuracy,
            backgroundEmail,
            nAttempt,
            organizationId,
            organizationLogo,
            organizationName,
            organizationSupportEmail,
            questionsAnswered,
            score,
            studentCreationDate,
            studentEmail,
            studentName,
            timeStudied,
            type: 'studentGetCertificateToCreator'
          };
          // Process the mail and send it
          yield put(sendEmail(payload));
        }
      }
    }
  } catch (err) {
    yield put(
      notificationsActions.setNotification({
        message: 'Error generating certificate',
        severity: 'error'
      })
    );
    yield put(notificationsActions.handleCatchError(err, 'generateCertificateSagas'));
  }
}

// Watchers
function* getOpenExamsDataWatcher() {
  yield takeLatest(types.GET_OPEN_EXAMS, getOpenExamsSagas);
}
function* getPreloadExamFirstQuestionsWatcher() {
  yield takeLatest(types.PRELOAD_EXAM_FIRST_QUESTIONS, getPreloadExamFirstQuestionsSagas);
}
function* getHistoryExamsDataWatcher() {
  yield takeLatest(types.GET_HISTORY_EXAMS, getHistoryExamsSagas);
}
function* getExamResultsWatcher() {
  yield takeLatest(types.GET_EXAM_RESULTS, getExamResultsSagas);
}
function* updateHistoryExamsDataWatcher() {
  yield takeLatest(types.UPDATE_HISTORY_EXAMS, updateHistoryExamsSagas);
}
function* getExamDataWatcher() {
  yield takeLatest(types.GET_EXAM_DATA, getExamDataSagas);
}
function* setActualQuestionWatcher() {
  yield takeLatest(types.SET_ACTUAL_QUESTION, setActualQuestionSagas);
}
function* getStackedAnswersWatcher() {
  yield takeLatest(types.GET_STACKED_ANSWERS, getStackedAnswersSagas);
}
function* sendExamFeedbackWatcher() {
  yield takeLatest(types.SEND_EXAM_FEEDBACK, sendExamFeedbackSagas);
}
function* setExamProgressWatcher() {
  yield takeLatest(types.SET_EXAM_PROGRESS, setExamProgressSagas);
}
function* updateExamProgressWatcher() {
  yield takeLatest(types.UPDATE_EXAM_PROGRESS, updateExamProgressSagas);
}
function* preloadAllQuestionsWatcher() {
  yield takeLatest(types.PRELOAD_ALL_QUESTIONS, preloadAllQuestionsSagas);
}
function* generateCertificateWatcher() {
  yield takeLatest(types.GENERATE_CERTIFICATE, generateCertificateSagas);
}

// Exports the sagas
export default function* sagas() {
  yield all([
    getOpenExamsDataWatcher(),
    generateCertificateWatcher(),
    getPreloadExamFirstQuestionsWatcher(),
    getHistoryExamsDataWatcher(),
    getExamDataWatcher(),
    setActualQuestionWatcher(),
    sendExamFeedbackWatcher(),
    setExamProgressWatcher(),
    updateHistoryExamsDataWatcher(),
    updateExamProgressWatcher(),
    getStackedAnswersWatcher(),
    getExamResultsWatcher(),
    preloadAllQuestionsWatcher()
  ]);
}
