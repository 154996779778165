import { takeLatest, all, call, put, select } from 'redux-saga/effects';
import { API, graphqlOperation } from 'aws-amplify';
// Actions
import * as types from '../actions/actionTypes/loginActionTypes';
import * as courseActions from '../actions/courseActions';
import * as notificationsActions from '../actions/errorHandlerActions';
import * as userActions from '../actions/userActions';
import * as courseTypes from '../actions/actionTypes/courseActionTypes';
// import { expiredPreview } from "../actions/studySessionActions";
// Queries
import * as loginQueries from '../graphql/queries/loginQueries';
import { stdRetriveStripeSubscriptionsQuerie } from '../graphql/queries/callsToLambdaFunctions';
// import { stdGetStudentsAnswersQuerie } from "../graphql/queries/studySessionQueries";
// Mutations
import { updateLastLogin, stdCreateAdminStudentMutation } from '../graphql/mutationsStudent';
// Selectors
import {
  getStudentDataReducer,
  getUserOrganizationId,
  getUserData
} from '../selectors/userSelectors';
import { getOrganizationDataReducer } from '../selectors/organizationSelector';
// Aws
import {
  CognitoIdentityProviderClient,
  AdminInitiateAuthCommand,
  GlobalSignOutCommand
} from '@aws-sdk/client-cognito-identity-provider';
import getAWSConfigObject from '../utils/getAWSConfigObject';
import GraphOp from '../sagas/common/GraphOp';
// Another files
import { envName, managePeopleRoles } from '../components/Common/const';
import axiosApiCallGetIp from '../utils/axiosApiCallGetIp';
import verifyStripeConnect from '../utils/verifyStripeConnect';

function* verifyUserSagas(action) {
  try {
    // The mean of this function is to decide if the user is or is not an available student for the site
    let { studentId, organizationId, resolve, reject } = action.payload;
    if (organizationId && studentId) {
      const userResponse = yield GraphOp(loginQueries.stdGetUserValidationQuerie, {
        studentId,
        organizationId
      });
      const student =
        userResponse && userResponse.data && userResponse.data.getStudent
          ? userResponse.data.getStudent
          : null;
      // Constants to be updated
      const lastLogin = new Date();
      let userFound = false;
      let firstLogin = lastLogin;
      if (student) {
        userFound = true;
        // We find the student, solve and continue running backwards.
        if (resolve) resolve(student);
        if (student.firstLogin) firstLogin = student.firstLogin;
      } else {
        const arrayOfQueries = [];
        // In case the student does not exist, we should validate that he/she is not a team member.
        if (studentId && organizationId) {
          arrayOfQueries.push(
            call(
              [API, 'graphql'],
              graphqlOperation(loginQueries.stdGetTeamMemberQuerie, {
                userId: studentId,
                organizationID: organizationId
              })
            )
          );
          // Get all the courses from the organization
          arrayOfQueries.push(
            call(
              [API, 'graphql'],
              graphqlOperation(loginQueries.stdGetAllTheCoursesQuerie, {
                organizationId
              })
            )
          );
        }
        if (arrayOfQueries && arrayOfQueries.length > 0) {
          let [memberData, allCourses] = yield all(arrayOfQueries);
          memberData =
            memberData && memberData.data && memberData.data.getTeamMember
              ? memberData.data.getTeamMember
              : null;
          allCourses =
            allCourses && allCourses.data && allCourses.data.listCourses
              ? allCourses.data.listCourses.items
              : [];
          // Let's verify that the user is admin or owner
          if (
            memberData &&
            memberData.id &&
            memberData.role &&
            memberData.email &&
            memberData.name &&
            managePeopleRoles &&
            managePeopleRoles.length > 0 &&
            managePeopleRoles.includes(memberData.role)
          ) {
            if (allCourses && allCourses.length > 0) {
              const coursesId = [];
              // Form the courses ids for the new student
              allCourses.forEach(item => {
                if (item && item.id) coursesId.push(item.id);
              });
              studentId = memberData.id;
              const response = yield GraphOp(stdCreateAdminStudentMutation, {
                id: studentId,
                email: memberData.email.trim().toLowerCase(),
                organizationId,
                name: memberData.name,
                courseIDs: coursesId
              });
              const student =
                response && response.data && response.data.createStudent
                  ? response.data.createStudent
                  : null;
              if (student) {
                userFound = true;
                if (resolve) resolve(student);
              } else {
                if (reject) reject('An error has occurred. Please try again later.');
              }
            } else {
              if (reject) {
                reject('There are no available courses right now. Please try again later.');
              }
            }
          } else {
            if (reject) reject("There's no student with such email.");
          }
        }
      }
      if (userFound) {
        // Get ip address
        let ipAddress = yield call(axiosApiCallGetIp);
        if (!ipAddress) ipAddress = '';
        // Get the organization IDs that use stripe oAuth
        const orgIdEverprep = yield call(verifyStripeConnect);
        let stripeoAuth;
        if (orgIdEverprep && orgIdEverprep.length > 0) {
          stripeoAuth = orgIdEverprep.find(obj => obj === organizationId);
        }
        if (stripeoAuth) yield put(userActions.setStripeConnect(false));
        else yield put(userActions.setStripeConnect(true));
        // Update user with his last login and ipAddress
        yield GraphOp(updateLastLogin, {
          studentId,
          organizationId,
          lastLogin,
          ipAddress,
          firstLogin
        });
      }
    }
  } catch (err) {
    yield put(notificationsActions.setNotification(err));
    yield put(notificationsActions.handleCatchError(err, 'verifyUserSagas'));
  }
}

function* getAvailableCoursesSagas(action) {
  try {
    const [studentData, organizationId, organizationData, userData] = yield all([
      select(getStudentDataReducer),
      select(getUserOrganizationId),
      select(getOrganizationDataReducer),
      select(getUserData),
      put(courseActions.loadingAvailableCourses(true)),
      put(courseActions.errorAvailableCourses(null))
    ]);
    let arrayOfQueries = [];
    const studentCourses = [];
    const availableForPurchase = [];
    const { studentId } = action.payload;
    const studentID = studentId
      ? studentId
      : userData && userData.username
      ? userData.username
      : '';
    if (studentID) {
      // Constants asignation
      const organizationID = organizationId
        ? organizationId
        : action && action.payload && action.payload.organizationId
        ? action.payload.organizationId
        : '';
      const stripeId =
        organizationData && organizationData.stripeId ? organizationData.stripeId : '';
      let allCourses = [];
      let arrayOfDisabledCourses = [];
      let studentCoursesIds;
      let orgCustomer;
      if (
        studentData &&
        studentData.organizationCustomers &&
        studentData.organizationCustomers.length > 0
      ) {
        orgCustomer = studentData.organizationCustomers.find(
          item => item && item.organizationID && item.organizationID === organizationId
        );
      }
      // Get the courses the student is registered in
      if (studentID && organizationId) {
        arrayOfQueries.push(
          call(
            [API, 'graphql'],
            graphqlOperation(loginQueries.stdGetAvailableStudentCoursesQuerie, {
              studentID,
              organizationID
            })
          )
        );
        arrayOfQueries.push(
          call(
            [API, 'graphql'],
            graphqlOperation(loginQueries.stdListAllCoursesOrganizationQuerie, {
              organizationID
            })
          )
        );
      }
      if (arrayOfQueries && arrayOfQueries.length > 0) {
        let nextToken = null;
        const [studentResponse, coursesResponse] = yield all(arrayOfQueries);
        if (studentResponse && studentResponse.data && studentResponse.data.getStudent) {
          studentCoursesIds =
            studentResponse.data.getStudent.courseIDs &&
            studentResponse.data.getStudent.courseIDs.length > 0
              ? studentResponse.data.getStudent.courseIDs
              : [];
          if (!orgCustomer) {
            orgCustomer =
              studentResponse.data.getStudent.organizationCustomers &&
              studentResponse.data.getStudent.organizationCustomers.length > 0
                ? studentResponse.data.getStudent.organizationCustomers.find(
                    item => item && item.organizationID === organizationId
                  )
                : null;
          }
        }
        if (coursesResponse && coursesResponse.data && coursesResponse.data.listCourses) {
          allCourses = coursesResponse.data.listCourses.items
            ? coursesResponse.data.listCourses.items
            : [];
          nextToken = coursesResponse.data.listCourses.nextToken
            ? coursesResponse.data.listCourses.nextToken
            : null;
        }
        if (nextToken) {
          while (nextToken) {
            const response = yield GraphOp(loginQueries.stdListAllCoursesOrganizationQuerie, {
              organizationID,
              nextToken
            });
            if (response && response.data && response.data.listCourses) {
              if (response.data.listCourses.items && response.data.listCourses.items.length > 0) {
                allCourses = allCourses.concat(response.data.listCourses.items);
              }
              nextToken = response.data.listCourses.nextToken
                ? response.data.listCourses.nextToken
                : null;
            } else nextToken = null;
          }
        }
      }
      // Sort subs according date to the newest
      if (orgCustomer && orgCustomer.subscriptions && orgCustomer.subscriptions.length > 0) {
        // First i remove the null dateSubscribed
        orgCustomer.subscriptions = orgCustomer.subscriptions.filter(
          sub => sub && sub.dateSubscribed
        );
        orgCustomer.subscriptions = orgCustomer.subscriptions.sort(function (a, b) {
          return new Date(b.dateSubscribed) - new Date(a.dateSubscribed);
        });
      }
      if (allCourses && allCourses.length > 0) {
        allCourses.forEach(course => {
          if (course && course.id && course.status) {
            if (course.status === 'ACTIVE') {
              if (
                studentCoursesIds &&
                studentCoursesIds.length > 0 &&
                studentCoursesIds.includes(course.id)
              ) {
                studentCourses.push(course);
              } else {
                if (!course.hiden) availableForPurchase.push(course);
              }
            } else {
              // If the course is set to disabled, the course should appear if the student has an active subscription
              // LastSub is the last subscription from the student
              // It can be a stripe subscription (the user has paid) or free access (the user did not pay)
              const lastSub =
                orgCustomer &&
                orgCustomer.subscriptions &&
                orgCustomer.subscriptions.length > 0 &&
                orgCustomer.subscriptions.find(sub => sub && sub.courseID === course.id);
              const subId = lastSub && lastSub.stripeProductID ? lastSub.stripeProductID : null;
              if (subId && stripeId) {
                arrayOfDisabledCourses.push(
                  call(
                    [API, 'graphql'],
                    graphqlOperation(stdRetriveStripeSubscriptionsQuerie, {
                      subscriptionId: subId,
                      stripeAccount: stripeId,
                      envName
                    })
                  )
                );
              }
            }
          }
        });
        if (arrayOfDisabledCourses && arrayOfDisabledCourses.length > 0) {
          let response;
          try {
            response = yield all(arrayOfDisabledCourses);
          } catch (error) {
            yield all([
              put(
                notificationsActions.setNotification({
                  message: 'There was an error trying to obtain the subscription please try again.',
                  severity: 'error'
                })
              ),
              put(
                notificationsActions.handleCatchError(
                  error,
                  'getAvailableCoursesSagas > RetriveStripeSubscriptions'
                )
              )
            ]);
          }
          if (response && response.length > 0) {
            response.forEach(item => {
              if (
                item &&
                item.data &&
                item.data.retriveStripeSubscriptions !== 'subscription not found' &&
                item.data.retriveStripeSubscriptions !== 'not found'
              ) {
                item = JSON.parse(item.data.retriveStripeSubscriptions);
                if (
                  item &&
                  (item.object === 'payment_intent' ||
                    item.status === 'active' ||
                    item.status === 'trialing')
                ) {
                  // The subscription is active
                  // We find the course product in the response and set the course as visible
                  if (item.plan && item.plan.product) {
                    let courseWithActiveSubscription = allCourses.find(
                      course => course && course.stripeProductID === item.plan.product
                    );
                    if (courseWithActiveSubscription) {
                      courseWithActiveSubscription = courseWithActiveSubscription.data.getCourse;
                      delete courseWithActiveSubscription.stripeProductID;
                      studentCourses.push(courseWithActiveSubscription);
                    }
                  } else {
                    if (item.metadata && item.metadata.courseId) {
                      let courseWithActiveSubscription = allCourses.find(
                        course => course && course.id === item.metadata.courseId
                      );
                      if (
                        courseWithActiveSubscription &&
                        courseWithActiveSubscription.data &&
                        courseWithActiveSubscription.data.getCourse
                      ) {
                        studentCourses.push(courseWithActiveSubscription.data.getCourse);
                      }
                    }
                  }
                }
              }
            });
          }
        }
      }
    }
    if (availableForPurchase.length === 0 && studentCourses.length === 0) {
      yield put(
        courseActions.errorAvailableCourses(
          'There are no available courses right now. Please try again later.'
        )
      );
    }
    yield all([
      put(courseActions.setAvailableCourses(studentCourses)),
      put(courseActions.coursesAvailableForPurchase(availableForPurchase)),
      put(courseActions.loadingAvailableCourses(false))
    ]);
  } catch (err) {
    yield all([
      put(
        courseActions.errorAvailableCourses(
          'There was a problem loading the courses. Please try again.'
        )
      ),
      put(notificationsActions.handleCatchError(err, 'getAvailableCoursesSagas')),
      put(courseActions.loadingAvailableCourses(false))
    ]);
  }
}

function* signOutSagas() {
  try {
    const refreshToken = JSON.parse(localStorage.getItem('RefreshToken'));
    const UserPoolId = JSON.parse(localStorage.getItem('UserPoolId'));
    const ClientId = JSON.parse(localStorage.getItem('ClientId'));
    if (refreshToken && UserPoolId && ClientId) {
      const params = {};
      params.ClientId = ClientId;
      params.UserPoolId = UserPoolId;
      params.AuthFlow = 'REFRESH_TOKEN_AUTH';
      params.AuthParameters = { REFRESH_TOKEN: refreshToken };
      const cognitoClient = new CognitoIdentityProviderClient(getAWSConfigObject());
      cognitoClient.send(new AdminInitiateAuthCommand(params), (err, data) => {
        if (err) {
          put(notificationsActions.handleCatchError(err, 'signOutSagas > initiateAuth > Signout'));
        } else {
          const signOutParams = {
            AccessToken: data.AuthenticationResult.AccessToken
          };
          cognitoClient.send(new GlobalSignOutCommand(signOutParams), signOutErr => {
            if (signOutErr) {
              put(
                notificationsActions.handleCatchError(err, 'signOutSagas > initiateAuth > Signout')
              );
            } else {
              //signOut success
              localStorage.removeItem('RefreshToken');
              localStorage.removeItem('UserPoolId');
              localStorage.removeItem('ClientId');
              localStorage.removeItem('QuestionsTable');
              localStorage.removeItem('AnswersTable');
              localStorage.removeItem('persist:root');
            }
          });
        }
      });
    }
    window.analytics.track('Click log out');
    yield put(userActions.setStudentData(null));
  } catch (err) {
    yield put(notificationsActions.setNotification(err));
    yield put(notificationsActions.handleCatchError(err, 'signOutSagas'));
  }
}

function* getStudentCoursesSagas(action) {
  const { studentId, orgId, redirectToChooseCourse, redirectToCourseHome, setLoadingButton } =
    action.payload;
  if (setLoadingButton) setLoadingButton(true);
  try {
    if (studentId && orgId) {
      const response = yield GraphOp(loginQueries.stdGetStudentCoursesQuerie, {
        studentId,
        organizationId: orgId
      });
      if (response && response.data && response.data.getStudent && orgId) {
        // Get the student's organization curstomer
        const orgSubs =
          response.data.getStudent.organizationCustomers &&
          response.data.getStudent.organizationCustomers.length > 0
            ? response.data.getStudent.organizationCustomers.find(
                org => org.organizationID === orgId
              )
            : [];
        // Process subscriptions
        if (orgSubs && orgSubs.subscriptions && orgSubs.subscriptions.length > 0) {
          // We filter the active subscriptions for this organization
          const activeSubs = orgSubs.subscriptions.filter(sub => sub && sub.active);
          // Data required for processing
          const redirectToCourseId = response.data.getStudent.lastCourseId
            ? response.data.getStudent.lastCourseId
            : null;
          const totalSubscriptions = activeSubs && activeSubs.length > 0 ? activeSubs.length : 0;
          const firtstActiveCourse = activeSubs && activeSubs[0] ? activeSubs[0].courseID : null;
          // Payload to redirect to the course directly
          const payload = {};
          payload.studentId = studentId;
          payload.organizationId = orgId;
          payload.redirect = redirectToCourseHome;
          payload.fromLoginView = true;
          if (redirectToCourseId) {
            payload.courseId = redirectToCourseId;
            yield all([
              put(courseActions.getCourseData(payload)),
              put(userActions.setSelectedCourse(redirectToCourseId)),
              put(courseActions.setSelectedTab(0))
            ]);
          } else {
            // If we have more than one subscription and the lastCourseId is not saved in the database then we go to choose course
            if (totalSubscriptions > 1 && redirectToChooseCourse) {
              redirectToChooseCourse();
            } else {
              if (firtstActiveCourse) {
                payload.courseId = firtstActiveCourse;
                yield all([
                  put(courseActions.getCourseData(payload)),
                  put(userActions.setSelectedCourse(firtstActiveCourse)),
                  put(courseActions.setSelectedTab(0))
                ]);
              } else if (redirectToChooseCourse) redirectToChooseCourse();
            }
          }
        } else {
          if (redirectToChooseCourse) redirectToChooseCourse();
        }
      } else {
        if (redirectToChooseCourse) redirectToChooseCourse();
      }
    }
    if (setLoadingButton) setLoadingButton(false);
  } catch (err) {
    if (setLoadingButton) setLoadingButton(false);
    yield all([
      put(notificationsActions.setNotification(err)),
      put(notificationsActions.handleCatchError(err, 'getStudentCoursesSagas'))
    ]);
  }
}

// Watchers
function* verifyUserWatcher() {
  yield takeLatest(types.VERIFY_USER, verifyUserSagas);
}
function* getAvailableCoursesWatcher() {
  yield takeLatest(courseTypes.GET_AVAILABLE_COURSES, getAvailableCoursesSagas);
}
function* signOutWatcher() {
  yield takeLatest(types.SIGN_OUT_USER, signOutSagas);
}

function* getStudentCoursesWatcher() {
  yield takeLatest(courseTypes.GET_STUDENT_COURSES, getStudentCoursesSagas);
}

// Exports the sagas
export default function* sagas() {
  yield all([
    verifyUserWatcher(),
    getAvailableCoursesWatcher(),
    signOutWatcher(),
    getStudentCoursesWatcher()
  ]);
}
