import * as type from './actionTypes/organizationActionTypes';

export const getOrganizationData = payload => ({
  type: type.GET_ORGANIZATION_DATA,
  payload
});

export const setOrganizationData = value => ({
  type: type.SET_ORGANIZATION_DATA,
  value
});

export const loadingOrganizationData = value => ({
  type: type.LOADING_ORGANIZATION_DATA,
  value
});

export const getOrganizationLogo = value => ({
  type: type.GET_ORGANIZATION_LOGO,
  value
});

export const getAllCoursesOrganization = value => ({
  type: type.GET_ALL_COURSES_ORGANIZATION,
  value
});

export const sendReportEmailToOrganization = payload => ({
  type: type.SEND_REPORT_EMAIL_TO_ORGANIZATION,
  payload
});

export const setOrganizationLogo = value => ({
  type: type.SET_ORGANIZATION_LOGO,
  value
});

export const setOrganizationLogoBlobUrl = value => ({
  type: type.SET_ORGANIZATION_LOGO_BLOB_URL,
  value
});

export const loadingOrganizationLogo = value => ({
  type: type.LOADING_ORGANIZATION_LOGO,
  value
});

export const getOrganizationBundles = value => ({
  type: type.GET_ORGANIZATION_BUNDLES,
  value
});

export const setOrganizationBundles = value => ({
  type: type.SET_ORGANIZATION_BUNDLES,
  value
});

export const setOrganizationFavicon = value => ({
  type: type.SET_ORGANIZATION_FAVICON,
  value
});
