import {Map} from 'immutable';
import * as t from '../actions/actionTypes/loaderHandlerActionTypes';
import * as loginActions from '../actions/actionTypes/loginActionTypes';

const initialState = Map({
  loadingHandler: 0,
});

const ErrorHandler = function (state = initialState, action) {
  switch (action.type) {
    case t.START_LOADING:
      let startLoading = state.get('loadingHandler') + 1;
      return state.set('loadingHandler', startLoading);
    case t.STOP_LOADING:
      let stopLoading;
      if (state.get('loadingHandler') > 0) {
        stopLoading = state.get('loadingHandler') - 1;
      } else {
        stopLoading = state.get('loadingHandler');
      }
      return state.set('loadingHandler', stopLoading);
    case loginActions.SIGN_OUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default ErrorHandler;
