export const createExamFeedback = /* GraphQL */ `
  mutation createExamFeedback($examId: ID!, $courseId: ID!, $feedback: [QuestionFeedbackInput]) {
    updateExam(input: {courseID: $courseId, id: $examId, feedbacks: $feedback}) {
      id
    }
  }
`;
export const createCertificateMutation = /* GraphQL */ `
mutation CreateCertificate($input: CreateCertificatesInput!) {
  createCertificates(input: $input) {
    id
    studentID
    courseID
    organizationId
    studentName
    date
  }
}
`;
export const updateExamHistory = /* GraphQL */ `
  mutation updateExamHistory(
    $organizationID: ID!
    $studentID: ID!
    $examHistory: [ExamHistoryInput]
  ) {
    updateStudent(
      input: {id: $studentID, organizationID: $organizationID, examHistory: $examHistory}
    ) {
      examHistory {
        examID
        courseID
        accuracy
        title
        date
        totalQuestions
        score
        time
        certificateEarned
        studentAnswers {
          examID
          questionID
          studentID
          orderTypeAnswer {
            title
            order
            correctOption
          }
          matchingAnswer {
            title
            order
            match
            correctOrder
            correct
          }
          multipleChoiceTextAnswer {
            order
            locked
            correct
            choice
          }
          multipleChoiceImageAnswer {
            order
            correct
            choiceId
            link
          }
        }
      }
    }
  }
`;

export const updateExamProgress = /* GraphQL */ `
  mutation updateExamProgress(
    $organizationID: ID!
    $studentID: ID!
    $examProgress: [ExamProgressInput]
  ) {
    updateStudent(
      input: {organizationID: $organizationID, id: $studentID, examProgress: $examProgress}
    ) {
      examProgress {
        studentID
        examID
        examQuestionsIDs
        timeElapsed
        started
        remainingAttempts
        available
        lastQuestionAnswered
        studentAnswers {
          examID
          studentID
          questionID
          orderTypeAnswer {
            order
            title
            correctOption
          }
          multipleChoiceTextAnswer {
            choice
            correct
            locked
            order
          }
          multipleChoiceImageAnswer {
            choiceId
            correct
            order
            link
          }
          matchingAnswer {
            match
            order
            title
            correctOrder
            correct
          }
          freeTypeAnswer {
            answer
            explanation
          }
          flashcardAnswer {
            answer
          }
          fillInTheBlankAnswer {
            explanation
            answer
          }
        }
      }
    }
  }
`;

export const deleteExamProgress = /* GraphQL */ `
  mutation updateExamProgress(
    $organizationID: ID!
    $studentID: ID!
    $examProgress: [ExamProgressInput]
  ) {
    updateStudent(
      input: {organizationID: $organizationID, id: $studentID, examProgress: $examProgress}
    ) {
      examProgress {
        studentID
        examID
        examQuestionsIDs
        timeElapsed
        started
        remainingAttempts
        available
        lastQuestionAnswered
        studentAnswers {
          examID
          studentID
          questionID
          orderTypeAnswer {
            order
            title
            correctOption
          }
          multipleChoiceTextAnswer {
            choice
            correct
            locked
            order
          }
          multipleChoiceImageAnswer {
            choiceId
            correct
            order
            link
          }
          matchingAnswer {
            match
            order
            title
            correctOrder
            correct
          }
          freeTypeAnswer {
            answer
            explanation
          }
          flashcardAnswer {
            answer
          }
          fillInTheBlankAnswer {
            explanation
            answer
          }
        }
      }
    }
  }
`;
