const supportEmailTemplate = ({title, body, footer, backgroundOrg, orgLogo}) => {
  const backgroundColor = backgroundOrg ? backgroundOrg : '#052d30';
  const organizationLogo = orgLogo
    ? orgLogo
    : 'https://evcreatorsdev-20210729123707-hostingbucket-production.s3.us-west-2.amazonaws.com/header.png';
  const template = `
  <html>
    <head>
      ${
        title
          ? `
        <title>${title}</title>
      `
          : ''
      }
      <style>
        * {
            font-size: 1em;
        }
        h1 {
          color: #f00;
        }
        .header {
          background-color: ${backgroundColor};
          display: flex;
          justify-content: center !important;
          padding-top: 10px;
          padding-bottom: 10px;
          margin-bottom: 50px;
        }
        .text {
          font-family: "Open Sans", "Helvetica Neue", sans-serif;
          display: table;
        }
        .text-body {
          margin-left: 20%;
          margin-right: 20%;
          font-family: "Open Sans", "Helvetica Neue", sans-serif;
          margin-bottom: 50px;
        }
        .text-bold {
          font-weight: bold;
        }
        .footer{
          font-size: smaller;
          text-align: center;
        }
        .organization-logo{
          display: table;
          margin: 0 auto;
          max-height: 75px
        }
        @media screen and (max-width: 530px) {
          .text-body {
            margin-left: 5%;
            margin-right: 5%;
            margin-bottom: 20px;
          }
          .header{
            padding: 5%;
          }
        }
      </style>
    </head>
    <body>
      <div class="header" style="justify-content: center;">
        <img class="organization-logo"
          src='${organizationLogo}'
        />
      </div>
      ${
        body
          ? `
        <div class="text-body">
          ${body}
        </div>
      `
          : ''
      }
      ${
        footer
          ? `
        <div class="footer">
          ${footer}
        </div>
      `
          : ''
      }
    </body>
  </html> 
  `;
  return template;
};

export default supportEmailTemplate;
