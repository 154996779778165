import {Map} from 'immutable';
import * as t from '../actions/actionTypes/appActionTypes';
import * as loginActions from '../actions/actionTypes/loginActionTypes';

const init = Map({
  appTheme: null,
  loadingTheme: false,
  openDisabledCourseModal: false,
  submitedOpenDisabledCourseModal: false,
});

const App = (state = init, action) => {
  switch (action.type) {
    case t.SET_LOADING_APP_THEME:
      return state.set('loadingTheme', action.value);
    case t.SET_APP_THEME:
      return state.set('appTheme', action.value);
    case loginActions.SIGN_OUT_USER:
      return init;
    case t.SET_OPEN_DISABLED_COURSE_MODAL:
      return state.set('openDisabledCourseModal', action.value);
    case t.SET_SUBMIT_OPEN_DISABLED_COURSE_MODAL:
      return state.set('submitedOpenDisabledCourseModal', action.value);
    default:
      return state;
  }
};

export default App;
