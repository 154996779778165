import { Map } from 'immutable';
import * as t from '../actions/actionTypes/userActionTypes';
import * as loginActions from '../actions/actionTypes/loginActionTypes';
import * as examActions from '../actions/actionTypes/examActionTypes';

const initialState = Map({
  userData: null,
  organizationId: null,
  userLogo: null,
  courseId: null,
  loadingUserLogo: false,
  selectedTopicId: null,
  selectedSectionId: null,
  practiceExamId: null,
  studyProgress: null,
  studentData: null,
  previewUser: false,
  mylaunchDarklyAccess: null,
  stripeConnect: false,
  userAnswers: null,
  lessonsRead: []
});

const user = function (state = initialState, action) {
  switch (action.type) {
    case t.SET_MY_LAUNCH_DARKLY_ACCESS:
      return state.set('mylaunchDarklyAccess', action.value);
    case t.SET_STRIPE_CONNECT:
      return state.set('stripeConnect', action.value);
    case t.SET_USER:
      return state.set('userData', action.value);
    case t.SET_SELECTED_COURSE_ID:
      return state.set('courseId', action.value);
    case t.SET_ORGANIZATION_ID:
      return state.set('organizationId', action.value);
    case t.SET_USER_LOGO:
      return state.set('userLogo', action.value);
    case t.LOADING_USER_LOGO:
      return state.set('loadingUserLogo', action.value);
    case t.SET_SELECTED_TOPIC_ID:
      return state.set('selectedTopicId', action.value);
    case t.SET_SELECTED_SECTION_ID:
      return state.set('selectedSectionId', action.value);
    case examActions.SET_EXAM_ID:
      return state.set('practiceExamId', action.value);
    case t.SET_STUDY_PROGRESS:
      return state.set('studyProgress', action.value);
    case t.SET_STUDENT_DATA:
      return state.set('studentData', action.value);
    case t.PREVIEW_USER:
      return state.set('previewUser', action.value);
    case t.USER_ANSWERS:
      return state.set('userAnswers', action.value);
    case t.LESSONS_READ:
      return state.set('lessonsRead', action.value);
    case loginActions.SIGN_OUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default user;
