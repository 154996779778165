import * as type from './actionTypes/userActionTypes';

export const setUser = value => ({
  type: type.SET_USER,
  value
});

export const setSelectedCourse = value => ({
  type: type.SET_SELECTED_COURSE_ID,
  value
});

export const setOrganizationId = value => ({
  type: type.SET_ORGANIZATION_ID,
  value
});

export const setUserLogo = value => ({
  type: type.SET_USER_LOGO,
  value
});

export const getUserLogo = payload => ({
  type: type.GET_USER_LOGO,
  payload
});

export const uploadUSerLogo = value => ({
  type: type.UPLOAD_USER_LOGO,
  value
});

export const loadingUserLogo = value => ({
  type: type.LOADING_USER_LOGO,
  value
});

export const setSelectedTopicId = value => ({
  type: type.SET_SELECTED_TOPIC_ID,
  value
});

export const setSelectedSectionId = value => ({
  type: type.SET_SELECTED_SECTION_ID,
  value
});

export const studyProgress = value => ({
  type: type.SET_STUDY_PROGRESS,
  value
});

export const getStudentData = value => ({
  type: type.GET_STUDENT_DATA,
  value
});

export const setStudentData = value => ({
  type: type.SET_STUDENT_DATA,
  value
});

export const updateStudentEmail = value => ({
  type: type.UPDATE_STUDENT_EMAIL,
  value
});

export const updateStudentName = value => ({
  type: type.UPDATE_STUDENT_NAME,
  value
});

export const updateUserEmail = newEmail => ({
  type: type.UPDATE_USER_EMAIL,
  newEmail
});

export const createFreeAccessStudent = payload => ({
  type: type.CREATE_FREE_ACCESS_STUDENT, //course home signup
  payload
});

export const deleteStudent = payload => ({
  type: type.DELETE_STUDENT,
  payload
});

export const checkEmailRegistered = payload => ({
  type: type.CHECK_EMAIL_REGISTERED,
  payload
});

export const previewUser = value => ({
  //Sets in redux the variable that handles the previewCourse state
  type: type.PREVIEW_USER,
  value
});

export const handlePreviewUserQuestions = () => ({
  //Edits database to reduce the question of the user in preview state
  type: type.HANDLE_PREVIEW_USER_QUESITONS
});

export const findMyLaunchDatklyAccess = value => ({
  type: type.FIND_MY_LAUNCH_DARKLY_ACCESS,
  value
});

export const setMyLaunchDatklyAccess = value => ({
  type: type.SET_MY_LAUNCH_DARKLY_ACCESS,
  value
});

export const setStripeConnect = value => ({
  type: type.SET_STRIPE_CONNECT,
  value
});

export const sendEmail = payload => ({
  type: type.SEND_EMAIL,
  payload
});

export const userAnswers = value => ({
  type: type.USER_ANSWERS,
  value
});

export const lessonsRead = value => ({
  type: type.LESSONS_READ,
  value
});

export const login = payload => ({
  type: type.LOGIN,
  payload
});

export const changePassword = payload => ({
  type: type.CHANGE_PASSWORD,
  payload
});

export const getPasswordPolicies = payload => ({
  type: type.GET_PASSWORD_POLICIES,
  payload
});