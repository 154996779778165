import * as type from './actionTypes/courseActionTypes';

export const getCourseData = value => ({
  type: type.GET_COURSE_DATA,
  value
});

export const setCourseData = value => ({
  type: type.SET_COURSE_DATA,
  value
});

export const sections = value => ({
  type: type.SECTIONS,
  value
});

export const getStudyPerformance = value => ({
  type: type.GET_STUDY_PERFORMANCE,
  value
});

export const setStudyPerformance = value => ({
  type: type.SET_STUDY_PERFORMANCE,
  value
});

export const getLoginStreak = value => ({
  type: type.GET_LOGIN_STREAK,
  value
});

export const setLoginStreak = value => ({
  type: type.SET_LOGIN_STREAK,
  value
});

export const getStudyProgress = value => ({
  type: type.GET_STUDY_PROGRESS,
  value
});

export const setQuestionsAnswered = value => ({
  type: type.SET_QUESTIONS_ANSWERED,
  value
});

export const setTimeStudied = value => ({
  type: type.SET_TIME_STUDIED,
  value
});

export const getAvailableCourses = payload => ({
  type: type.GET_AVAILABLE_COURSES,
  payload
});

export const getStudentCourses = payload => ({
  type: type.GET_STUDENT_COURSES,
  payload
});

export const setAvailableCourses = value => ({
  type: type.SET_AVAILABLE_COURSES,
  value
});

export const loadingAvailableCourses = value => ({
  type: type.LOADING_AVAILABLE_COURSES,
  value
});

export const errorAvailableCourses = value => ({
  type: type.ERROR_AVAILABLE_COURSES,
  value
});

export const getTopicData = value => ({
  type: type.GET_TOPIC_DATA,
  value
});

export const setTopicData = value => ({
  type: type.SET_TOPIC_DATA,
  value
});

export const loadingTopicData = value => ({
  type: type.LOADING_TOPIC_DATA,
  value
});

export const switchCourse = () => ({
  type: type.SWITCH_COURSE
});

export const verifyCourse = value => ({
  type: type.VERIFY_COURSE,
  value
});

export const getStripeCourseProduct = payload => ({
  type: type.GET_STRIPE_COURSE_PRODUCT,
  payload
});

export const setStripeCourseProduct = payload => ({
  type: type.SET_STRIPE_COURSE_PRODUCT,
  payload
});

export const setSelectedTab = payload => ({
  type: type.SET_SELECTED_TAB,
  payload
});

export const setCourseFreeTrial = payload => ({
  type: type.SET_COURSE_FREE_TRIAL,
  payload
});

export const updateCoursePerformance = payload => ({
  type: type.UPDATE_COURSE_PERFORMANCE,
  payload
});

export const createStripeCheckoutSession = payload => ({
  type: type.CREATE_CHECKOUT_SESSION,
  payload
});

export const courseName = value => ({
  type: type.COURSE_NAME,
  value
});

export const setPercentageLoadingScreen = value => ({
  type: type.SET_PRECENTAGE_LOADING_SCREEN,
  value
});

export const setLoadingScreen = value => ({
  type: type.SET_LOADING_SCREEN,
  value
});

export const loadingStudyModes = value => ({
  type: type.LOADING_STUDY_MODES,
  value
});

export const courseQuestions = value => ({
  type: type.COURSE_QUESTIONS,
  value
});

export const courseContents = value => ({
  type: type.COURSE_CONTENTS,
  value
});

export const generateStripePayment = payload => ({
  type: type.GENERATE_STRIPE_PAYMENT,
  payload
});

export const generateStripePaymentError = value => ({
  type: type.GENERATE_STRIPE_PAYMENT_ERROR,
  value
});

export const getCreateAccountInformation = payload => ({
  type: type.GET_CREATE_ACCOUNT_INFORMATION,
  payload
});

export const validateEmailAndGenerateCheckout = payload => ({
  type: type.VALIDATE_EMAIL_AND_GENERATE_CHECKOUT,
  payload
});

export const coursesAvailableForPurchase = value => ({
  type: type.COURSES_AVAILABLE_FOR_PURCHASE,
  value
});
