import { createTheme, lighten } from '@mui/material/styles';

//This function parse hexcolor to RGB to confirm the color is light or dark and return the correct color to contrast
function getContrastYIQ(hexcolor) {
  hexcolor = hexcolor.replace('#', '');
  let r = parseInt(hexcolor.substr(0, 2), 16);
  let g = parseInt(hexcolor.substr(2, 2), 16);
  let b = parseInt(hexcolor.substr(4, 2), 16);
  let yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? '#000000' : '#FFFFFF';
}

const theme = props => {
  // BACKGROUND_AND_TEXT was deleted from props, if you want to reactivate only add it to the destructuring
  const { ACCENT } = props;
  return createTheme({
    palette: {
      background: '#F8F8F8',
      border: '#E2E2E2',
      common: {
        adminPrimary: '#000000',
        adminSecondary: '#6A6A6A',
        flashCard: '#F9EAC8',
        flashCardLight: '#fbf4e5',
        flashCardGray: '#B6C1CC',
        flashCardHard: '#FAD4D8',
        flashCardNormal: '#F9EAC8',
        flashCardEasy: '#E6F2CD',
        inputBackground: '#F5F7FA',
        subTitle: '#7C9099',
        lightBackground: '#294B54',
        white: '#FFFFFF',
        grayColor: '#D9E0E7',
        shadow: '#7C909940',
        lavander: '#E9E9F0',
        darkGray: '#0000004D',
        lightBlue: '#0DB4FF',
        lightGreen: '#85B420',
        lightGrey: '#D6D3D3',
        fill: '#00000061',
        border: '#BDBDBD',
        borderColor: ACCENT,
        disabled: "#B2B2B2"
      },
      primary: {
        main: ACCENT || '#DCDCDC',
        hover: '#153B40',
        light: '#294B54',
        aqua: '#CCF4EF'
      },
      secondary: {
        main: lighten(ACCENT, 0.4),
        light: '#F8E9CB'
      },
      error: {
        main: '#EA2F41',
        light: 'rgba(234,47,65,0.3)'
      },
      warning: {
        main: '#F5F7FA'
      },
      info: {
        main: '#00acc1'
      },
      success: {
        main: '#A0CE3C'
      },
      text: {
        primary: '#000000',
        light: '#FFFFFF',
        button: getContrastYIQ(ACCENT) || '#000000',
        hover: '#7C9099'
      }
    },
    typography: {
      fontFamily: '"Open Sans", sans-serif',
      htmlFontSize: 16,
      fontSize: 12,
      fontWeightRegular: '400',
      pxToRem: size => `${size / 16}rem`,
      body1: {
        fontSize: '0.875rem'
      }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1366,
        xl: 1680
      }
    }
  });
};

export default theme;
