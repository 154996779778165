import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import { API, graphqlOperation } from 'aws-amplify';
// Actions
import * as types from '../actions/actionTypes/courseActionTypes';
import * as notificationsActions from '../actions/errorHandlerActions';
import * as courseActions from '../actions/courseActions';
import * as studySessionAction from '../actions/studySessionActions';
import { getPayment, getPaymentDate } from '../actions/myAccountActions';
import {
  studyProgress,
  setSelectedCourse,
  previewUser,
  userAnswers,
  lessonsRead,
  setStudentData
} from '../actions/userActions';
import { loadingOrganizationLogo, getOrganizationLogo } from '../actions/organizationActions';
import { startLoading, stopLoading } from '../actions/loaderHandlerActions';
import { setContainer } from '../actions/contentActions';
import { setCertificate } from '../actions/examActions';
// Selectors
import {
  getCourseDataReducer,
  getCourseQuestionsSelector,
  getSectionsReducer,
  getCourseContentsSelector
} from '../selectors/Course/courseSelector';
import { getAppTheme } from '../selectors/appSelectors';
import { getOrganizationDataReducer } from '../selectors/organizationSelector';
import {
  getCourseIdReducer,
  getUserOrganizationId,
  getUserData,
  getStudentDataReducer,
  getStudyProgressReducer,
  getUserAnswersSelector,
  getLessonsReadSelector
} from '../selectors/userSelectors';
// Queries
import * as query from '../graphql/queries/courseQueries';
import * as queryStudents from '../graphql/queries/studentQueries';
import { stdGetOrganizationDataQuerie } from '../graphql/queries/organizationQueries';
// Mutations
import * as mutation from '../graphql/mutationsStudent';
//External files
import GraphOp from '../sagas/common/GraphOp';
import GetNumberOfWeek from '../utils/getNumberOfWeek';
import determineOpenStudyMode from '../utils/determineOpenStudyMode';
import sendEmailProcess from '../utils/sendEmailProcess';
import verifyStripeConnect from '../utils/verifyStripeConnect';
// Study algorithm calls
import {
  scanQuestionsCLI,
  scanStudentAnswersCLI,
  stdGetStripeCheckoutSessionQuerie,
  stdGetProductFromStripeQuerie,
  stdBringStripeCheckoutSessionQuerie,
  stdValidateStripeCustomerQuerie,
  sendEmailSES
} from '../graphql/queries/callsToLambdaFunctions';
// See commented function on sortingAlgorithmCLI lambda
import analyzeArray from '../utils/sortingAlgorithm.min';
import { envName } from '../components/Common/const';
// This boolean is used to test in production without having an active subscription.
const testMode = false;

function* getCourseDataSagas(action) {
  /* 
    This function is very complex as it handles several things:
    - Loaders
    - Get course information, sections and question sets
    - Get course questions and user answers
    - Form arrays of each question set
    - Get course payment data

    This sagas is divided into 
    1) Obtain information about the course, sections and contents.
    2) First course, sections and contents mapping
    3) Obtaining the student's answers
    4) Obtaining the questions of the course
    5) Mapping questions and answers to question sets and forming study arrays
    6) Obtaining subscription information

    Handle percentage for loading screen:
    Case 1: fromCreateAccount || fromPurchaseCourse
    If we are registering or subscribing to a course we will have a longer duration. So the loading screen will be divided into:
    createStudentAccountAndSubscriptionSagas:
    0% -> Home
    25% -> Return from lambda
    getCourseSagas:
    50% -> Get course information, content and sections.
    75% -> Get information about questions and answers
    100% -> Finish loading

    Case 2: fromChooseCourse or fromLoginView:
    25% -> Obtain information on course content and sections
    50% -> Obtain information of questions
    75% -> Obtain information of answers
    100% -> Finish loading

    Case 3: fromCourseHome || fromStudySession || reloadAlgorithm || fromMyAccount
    Use the start and stop loading
  */
  try {
    // Restarting the view
    yield all([
      put(courseActions.loadingStudyModes(true)),
      put(studySessionAction.studyModes([])),
      put(setCertificate(null)),
      put(setContainer([]))
    ]);
    // Payload data
    let {
      courseId,
      organizationId,
      studentId,
      // Context
      fromCreateAccount,
      fromPurchaseCourse,
      fromChooseCourse,
      fromLoginView,
      fromCourseHome,
      fromStudySession,
      fromMyAccount,
      // Handle information
      reloadAlgorithm,
      // Redirect function
      redirect
    } = action.value;
    // Handle loader
    const showPercentageScreen =
      fromChooseCourse || fromCreateAccount || fromPurchaseCourse || fromLoginView;
    const dontReload = fromCourseHome || fromStudySession;
    if (showPercentageScreen) {
      let percentage = 50;
      if (fromChooseCourse || fromLoginView) percentage = 0;
      yield all([
        put(courseActions.setSelectedTab(0)),
        put(courseActions.setLoadingScreen(true)),
        put(courseActions.setPercentageLoadingScreen(percentage))
      ]);
    } else {
      yield put(startLoading());
    }
    // Get information from reducer
    let [
      studentData,
      organizationData,
      userData,
      courseIdReducer,
      userAnswersReducer,
      courseQuestionsReducer,
      lessonsReadSelector
    ] = yield all([
      select(getStudentDataReducer),
      select(getOrganizationDataReducer),
      select(getUserData),
      select(getCourseIdReducer),
      select(getUserAnswersSelector),
      select(getCourseQuestionsSelector),
      select(getLessonsReadSelector)
    ]);
    // Constants asignaton
    if (!courseId) courseId = courseIdReducer;
    if (!organizationId) {
      organizationId = organizationData && organizationData.id ? organizationData.id : null;
    }
    if (!studentId) studentId = userData && userData.username ? userData.username : null;
    if (courseId) yield put(setSelectedCourse(courseId));
    if ((fromLoginView || fromPurchaseCourse) && studentId && organizationId) {
      yield put(courseActions.getAvailableCourses({ studentId }));
    }
    let courseData = null;
    let contents = [];
    let sections = [];
    let questionSets = [];
    let arrayOfQueries = [];
    // Part 1: Course, sections and contents
    if (!dontReload) {
      // Get course information
      arrayOfQueries.push(
        call(
          [API, 'graphql'],
          graphqlOperation(query.stdGetCourseDataQuerie, {
            courseId,
            organizationID: organizationId
          })
        )
      );
      // Get contents
      arrayOfQueries.push(
        call(
          [API, 'graphql'],
          graphqlOperation(query.stdGetCourseContentsQuerie, {
            courseId
          })
        )
      );
      // Get sections
      arrayOfQueries.push(
        call(
          [API, 'graphql'],
          graphqlOperation(query.stdGetCourseSectionsQuerie, {
            courseId,
            organizationId
          })
        )
      );
      // Get student data
      if (!studentData && studentId && organizationId) {
        arrayOfQueries.push(
          call(
            [API, 'graphql'],
            graphqlOperation(queryStudents.stdGetStudentDataQuerie, {
              studentId,
              organizationId
            })
          )
        );
      }
      const [responseCourse, responseContents, responseSections, responseStudent] = yield all(
        arrayOfQueries
      );
      // Store query responses
      courseData =
        responseCourse && responseCourse.data && responseCourse.data.getCourse
          ? responseCourse.data.getCourse
          : null;
      // Store course name for the course overview
      const courseName = courseData && courseData.name ? courseData.name : '';
      // This query goes with nexToken because can appear a lot of topics on this querie.
      if (responseContents && responseContents.data && responseContents.data.listTopics) {
        if (
          responseContents.data.listTopics.items &&
          responseContents.data.listTopics.items.length > 0
        ) {
          contents = [...responseContents.data.listTopics.items];
        }
        let nextToken = responseContents.data.listTopics.nextToken
          ? responseContents.data.listTopics.nextToken
          : null;
        while (nextToken) {
          const response = yield GraphOp(query.stdGetCourseContentsQuerie, { courseId, nextToken });
          if (
            response &&
            response.data &&
            response.data.listTopics &&
            response.data.listTopics.items
          ) {
            contents = contents.concat(response.data.listTopics.items);
          }
          nextToken = response.data.listTopics.nextToken
            ? response.data.listTopics.nextToken
            : null;
        }
      }
      sections =
        responseSections &&
        responseSections.data &&
        responseSections.data.getCourse &&
        responseSections.data.getCourse.sections &&
        responseSections.data.getCourse.sections.length > 0
          ? responseSections.data.getCourse.sections
          : [];
      if (
        !studentData &&
        responseStudent &&
        responseStudent.data &&
        responseStudent.data.getStudent
      ) {
        studentData = responseStudent.data.getStudent;
        yield put(setStudentData(studentData));
      }
      yield all([
        put(courseActions.courseName(courseName)),
        put(courseActions.courseContents(contents))
      ]);
    } else {
      courseData = yield select(getCourseDataReducer);
      sections = yield select(getSectionsReducer);
      contents = yield select(getCourseContentsSelector);
    }
    if (showPercentageScreen && (fromChooseCourse || fromLoginView)) {
      yield all([put(courseActions.setPercentageLoadingScreen(25))]);
    }
    // Part 2: First mapping
    // We map the sections and the unprocessed question sets.
    let cleanedSections = [];
    if (sections && sections.length > 0 && contents && contents.length > 0) {
      // Sort the sections
      sections.sort((a, b) => (a.order > b.order ? 1 : -1));
      // Mapping of each section
      sections.forEach(section => {
        if (section) {
          const thisSectionContent = [];
          if (section.topicsIds && section.topicsIds.length > 0) {
            // Mapping of each topicId belonging to this section
            section.topicsIds.forEach(id => {
              const thisContent = contents.find(qs => qs && qs.id && qs.id === id);
              if (thisContent && thisContent.status && thisContent.status === 'ACTIVE') {
                thisSectionContent.push(thisContent);
              }
            });
          }
          // Sort
          if (thisSectionContent.length > 0) {
            thisSectionContent.sort((a, b) => (a.order > b.order ? 1 : -1));
          }
          section.topics = thisSectionContent;
          if (section.topics && section.topics.length > 0) cleanedSections.push(section);
        }
      });
    }
    // Lessons read assignation
    const readed =
      lessonsReadSelector && lessonsReadSelector.length > 0
        ? lessonsReadSelector
        : studentData && studentData.lessonsRead && studentData.lessonsRead.length > 0
        ? studentData.lessonsRead
        : [];
    yield all([put(courseActions.sections(cleanedSections)), put(lessonsRead(readed))]);
    /***** STUDY ALGORITHM FUNCTIONS *****/
    if (courseId && studentId) {
      // Assignment from the reducer
      let answers =
        userAnswersReducer && userAnswersReducer.length > 0 ? [...userAnswersReducer] : [];
      let questions =
        courseQuestionsReducer && courseQuestionsReducer.length > 0
          ? [...courseQuestionsReducer]
          : [];
      // Handle simultaneous calls
      const algorithmCalls = [];
      const scanAnswers = userAnswersReducer === null || reloadAlgorithm;
      const scanQuestions = courseQuestionsReducer === null || reloadAlgorithm;
      // Part 3: Obtaining student responses
      if (scanAnswers && courseId && studentId) {
        algorithmCalls.push(
          call(
            [API, 'graphql'],
            graphqlOperation(scanStudentAnswersCLI, {
              courseId,
              studentId,
              envName
            })
          )
        );
      }
      // Part 4: Get questions
      if (scanQuestions && courseId) {
        algorithmCalls.push(
          call(
            [API, 'graphql'],
            graphqlOperation(scanQuestionsCLI, {
              courseId,
              envName
            })
          )
        );
      }
      // Call to the lambas and process response
      if (algorithmCalls && algorithmCalls.length > 0) {
        const [responseAnswers, responseQuestions] = yield all(algorithmCalls);
        // We need to parse because the lambda returns a string to maintain the structure
        if (
          scanAnswers &&
          responseAnswers &&
          responseAnswers.data &&
          responseAnswers.data.scanStudentAnswersCLI
        ) {
          answers = JSON.parse(responseAnswers.data.scanStudentAnswersCLI);
        }
        if (
          scanQuestions &&
          responseQuestions &&
          responseQuestions.data &&
          responseQuestions.data.scanQuestionsCLI
        ) {
          questions = JSON.parse(responseQuestions.data.scanQuestionsCLI);
        }
      }
      // Update screen
      if (showPercentageScreen) {
        let percentage = 75;
        if (fromChooseCourse || fromLoginView) percentage = 50;
        yield all([put(courseActions.setPercentageLoadingScreen(percentage))]);
      }
      if (!answers || answers.length === 0) answers = [];
      if (!questions || questions.length === 0) questions = [];
      yield all([put(userAnswers(answers)), put(courseActions.courseQuestions(questions))]);
      ////////////////////////
      let stackedQuestions = [];
      let notElapsed = [];
      // Scan questions, answers and analyze the array
      const algorithmResponse = analyzeArray(questions, answers, studentId);
      if (algorithmResponse) {
        stackedQuestions = algorithmResponse.stackedQuestions
          ? algorithmResponse.stackedQuestions
          : [];
        notElapsed = algorithmResponse.notElapsed ? algorithmResponse.notElapsed : [];
      }
      // *** Delete items for questions set not published https://tracker.serfe.com/view.php?id=113062 ***//
      stackedQuestions = stackedQuestions.filter(question => {
        // Get all topic IDs from all sections
        const allTopicIds = sections.flatMap(section => section.topics.map(topic => topic.id));

        // Check if any topic ID from the question is in allTopicIds
        return question.topicIDs.some(topicId => allTopicIds.includes(topicId));
      });
      // *** End delete items for questions set not published ***//
      // Relationship questions and course
      if (courseData) {
        courseData.questionsToStudy =
          stackedQuestions && stackedQuestions.length ? stackedQuestions.length : 0;
      }
      // Open study mode
      if (stackedQuestions.length === 0 && courseData) {
        const { openStudyText, countdown } = determineOpenStudyMode(notElapsed);
        courseData.openStudyMode = true;
        courseData.notElapsed = notElapsed;
        courseData.openStudyText = openStudyText;
        courseData.countdown = countdown;
      }
      // Part 5: Relationship between questions and question sets
      questionSets = mapQuestionSetsAndQuestions(contents, stackedQuestions, notElapsed);
      // Switch question sets to open study mode
      if (questionSets && questionSets.length > 0) {
        questionSets.forEach(qs => {
          if (qs && qs.questionsToStudy === 0) qs.openStudyMode = true;
        });
      }
      cleanedSections = [];
      if (sections && sections.length > 0 && questionSets && questionSets.length > 0) {
        // Sort the sections
        sections.sort((a, b) => (a.order > b.order ? 1 : -1));
        // Mapping of each section
        sections.forEach(section => {
          if (section) {
            const thisSectionContent = [];
            if (section.topicsIds && section.topicsIds.length > 0) {
              // Mapping of each topicId belonging to this section
              section.topicsIds.forEach(id => {
                const thisContent = questionSets.find(qs => qs && qs.id === id);
                if (thisContent) {
                  thisContent.processed = true;
                  thisSectionContent.push(thisContent);
                }
              });
            }
            // Sort
            if (thisSectionContent.length > 0) {
              thisSectionContent.sort((a, b) => (a.order > b.order ? 1 : -1));
            }
            section.topics = thisSectionContent;
            if (section.topics && section.topics.length > 0) cleanedSections.push(section);
          }
        });
      }
      yield all([
        put(courseActions.sections(cleanedSections)),
        put(studySessionAction.preloadFirstQuestions({ sections: cleanedSections, courseId })),
        put(studySessionAction.getStudyModes({ stackedQuestions, courseData }))
      ]);
    }
    // Part 6: Get course subscrition
    // Get course price
    if (courseData) {
      courseData.activeCoursePricing =
        courseData.pricing && courseData.pricing.active ? courseData.pricing.active : false;
    }
    // Here we get if the student has an active sub on this course
    // CoursePreview -> if the students has an active coursePreview session we are going to analyze it here.
    let thisOrganizationCustomer = null;
    let customerId = null;
    let subscriptionId = null;
    if (
      organizationId &&
      studentData &&
      studentData.organizationCustomers &&
      studentData.organizationCustomers.length > 0
    ) {
      thisOrganizationCustomer = studentData.organizationCustomers.find(
        item => item && item.organizationID && item.organizationID === organizationId
      );
    }
    if (
      courseId &&
      thisOrganizationCustomer &&
      thisOrganizationCustomer.customerID &&
      thisOrganizationCustomer.subscriptions &&
      thisOrganizationCustomer.subscriptions.length > 0
    ) {
      customerId = thisOrganizationCustomer.customerID;
      const subs = thisOrganizationCustomer.subscriptions.find(
        sub => sub && sub.courseID === courseId
      );
      let auxPreviewUser = false;
      if (subs && subs.coursePreview) auxPreviewUser = true;
      yield put(previewUser(auxPreviewUser));
      thisOrganizationCustomer.subscriptions = thisOrganizationCustomer.subscriptions.filter(
        sub => sub.dateSubscribed
      );
      thisOrganizationCustomer.subscriptions = thisOrganizationCustomer.subscriptions.sort(
        function (a, b) {
          return new Date(b.dateSubscribed) - new Date(a.dateSubscribed);
        }
      );
      // LastSub is the last subscription from the student
      // It can be a stripe subscription (the user has paid) or free access (the user did not pay)
      const lastSub = thisOrganizationCustomer.subscriptions.find(
        sub => sub && sub.courseID === courseId
      );
      if (lastSub && lastSub.subscriptionID) subscriptionId = lastSub.subscriptionID;
      if (courseData) {
        courseData.activeSubscription = lastSub && lastSub.active ? lastSub.active : false;
        if (testMode) courseData.activeSubscription = true;
      }
    }
    yield all([put(courseActions.setCourseData(courseData))]);
    if (showPercentageScreen) {
      yield all([
        put(courseActions.setPercentageLoadingScreen(100)),
        put(courseActions.setLoadingScreen(false))
      ]);
    } else yield put(stopLoading());
    if (redirect) redirect();
    // Si viene desde alguna de estas vistas recargamos la información de Stripe
    const stripeId =
      organizationData && organizationData.stripeId ? organizationData.stripeId : null;
    if (
      fromChooseCourse ||
      fromCreateAccount ||
      fromPurchaseCourse ||
      fromMyAccount ||
      fromLoginView
    ) {
      // Load stripe product information
      if (courseId) yield put(courseActions.getStripeCourseProduct({ courseId }));
      // Load card information
      if (customerId) yield put(getPayment({ customerId }));
      // Load payment date
      if (stripeId && subscriptionId) {
        yield put(getPaymentDate({ fromStripe: false, stripeId, subscriptionId }));
      }
    }
    // Part 7: Get student certificate (IF THE STUDENT HAS A CERTIFICATE FOR THIS COURSE)
    if (courseId && studentId) {
      const response = yield GraphOp(query.getCertificatesByStudent, { studentID: studentId });
      // certificatesByStudent is the name of query index
      if (response && response.data && response.data.certificatesByStudent) {
        if (
          response.data.certificatesByStudent.items &&
          response.data.certificatesByStudent.items.length > 0
        ) {
          // here we check if the student has a certificate for this course
          const certificateCourseID = response.data.certificatesByStudent.items.find(
            item => item.courseID === courseId
          );
          if (certificateCourseID) yield put(setCertificate(certificateCourseID));
        }
      }
    }
    // Part 8: Update last login course
    if (studentData && courseId && organizationId && studentId) {
      if (!studentData.lastCourseId || studentData.lastCourseId !== courseId) {
        // Update Student on Dynamo
        yield GraphOp(mutation.stdUpdateLastCourseStudent, {
          organizationId,
          studentId,
          lastCourseId: courseId
        });
        // Update Student on Reducer
        studentData.lastCourseId = courseId;
        yield put(setStudentData(studentData));
      }
    }
  } catch (err) {
    yield all([
      put(courseActions.setLoadingScreen(false)),
      put(courseActions.loadingStudyModes(false)),
      put(stopLoading()),
      put(notificationsActions.handleCatchError(err, 'getCourseDataSagas')),
      put(
        notificationsActions.setNotification({
          message: 'Unable to complete request. Please contact us for support.',
          severity: 'error'
        })
      )
    ]);
  }
}

const mapQuestionSetsAndQuestions = (questionSets, stackedQuestions, notElapsed) => {
  let filteredQuestionSets = [];
  if (questionSets && questionSets.length > 0) {
    questionSets.forEach(qs => {
      if (qs && qs.id) {
        const thisQuestionSet = {};
        thisQuestionSet.id = qs.id;
        thisQuestionSet.name = qs.name;
        thisQuestionSet.type = qs.type;
        thisQuestionSet.order = qs.order;
        // This question set stacked questions
        const thisQSStackedQts = stackedQuestions.filter(
          qt => qt && qt.topicIDs && qt.topicIDs.length > 0 && qt.topicIDs.includes(qs.id)
        );
        // This question set not elapsed questions
        const thisQSNotElapsedQts = notElapsed.filter(
          qt => qt && qt.topicIDs && qt.topicIDs.length > 0 && qt.topicIDs.includes(qs.id)
        );
        const numberOfQuestions = thisQSStackedQts ? thisQSStackedQts.length : 0;
        thisQuestionSet.questionsToStudy = numberOfQuestions;
        thisQuestionSet.stackedQuestions = thisQSStackedQts;
        if (numberOfQuestions === 0) {
          const { openStudyText, countdown } = determineOpenStudyMode(thisQSNotElapsedQts);
          thisQuestionSet.openStudyText = openStudyText;
          thisQuestionSet.countdown = countdown;
        }
        thisQuestionSet.notElapsed = thisQSNotElapsedQts;
        filteredQuestionSets.push(thisQuestionSet);
      }
    });
  }
  return filteredQuestionSets;
};

function* getTopicDataSagas(action) {
  try {
    const [courseData] = yield all([select(getCourseDataReducer), all([put(startLoading())])]);
    let { courseId, selectedTopicId } = action.value;
    if (!courseId && courseData && courseData.id) courseId = courseData.id;
    if (courseId && selectedTopicId) {
      const response = yield GraphOp(query.stdGetContentDataQuerie, {
        courseId,
        topicId: selectedTopicId
      });
      const topicData =
        response && response.data && response.data.getTopic ? response.data.getTopic : null;
      if (topicData) yield all([put(courseActions.setTopicData(topicData))]);
    }
    yield all([put(stopLoading())]);
  } catch (err) {
    yield put(stopLoading());
    yield put(notificationsActions.setNotification(err));
    yield put(notificationsActions.handleCatchError(err, 'getTopicDataSagas'));
  }
}

const getPercentaje = (total, correct) => {
  let percentage = Math.round((correct / total) * 100);
  if (!percentage) percentage = 0;
  return percentage;
};

function* getStudyPerformanceDataSagas(action) {
  const {
    totalToday,
    correctToday,
    totalThisWeek,
    correctThisWeek,
    totalThisMonth,
    correctThisMonth,
    totalThisYear,
    correctThisYear,
    totalAllTime,
    correctAllTime
  } = action.value;
  try {
    const studyPerformanceToday = {
      correct: correctToday,
      incorrect: totalToday - correctToday,
      percentage: getPercentaje(totalToday, correctToday),
      period: 'Today'
    };
    const studyPerformanceWeek = {
      correct: correctThisWeek,
      incorrect: totalThisWeek - correctThisWeek,
      percentage: getPercentaje(totalThisWeek, correctThisWeek),
      period: 'This Week'
    };
    const studyPerformanceMonth = {
      correct: correctThisMonth,
      incorrect: totalThisMonth - correctThisMonth,
      percentage: getPercentaje(totalThisMonth, correctThisMonth),
      period: 'This Month'
    };
    const studyPerformanceYear = {
      correct: correctThisYear,
      incorrect: totalThisYear - correctThisYear,
      percentage: getPercentaje(totalThisYear, correctThisYear),
      period: 'This Year'
    };
    const studyPerformanceAllTime = {
      correct: correctAllTime,
      incorrect: totalAllTime - correctAllTime,
      percentage: getPercentaje(totalAllTime, correctAllTime),
      period: 'All Time'
    };
    const studyPerformance = [
      studyPerformanceToday,
      studyPerformanceWeek,
      studyPerformanceMonth,
      studyPerformanceYear,
      studyPerformanceAllTime
    ];
    yield put(courseActions.setStudyPerformance(studyPerformance));
  } catch (err) {
    yield put(notificationsActions.handleCatchError(err, 'getStudyPerformanceDataSagas'));
  }
}

function* getLoginStreakDataSagas(action) {
  try {
    const { studentId } = action.value;
    const [courseId, organizationId] = yield all([
      select(getCourseIdReducer),
      select(getUserOrganizationId)
    ]);
    const arrayOfQueries = [];
    if (organizationId && studentId && courseId) {
      arrayOfQueries.push(
        call(
          [API, 'graphql'],
          graphqlOperation(query.stdGetLoginStreakQuerie, {
            organizationID: organizationId,
            studentID: studentId
          })
        )
      );
      arrayOfQueries.push(
        call(
          [API, 'graphql'],
          graphqlOperation(queryStudents.stdGetCourseLoginQuerie, {
            studentId,
            organizationId
          })
        )
      );
      let [loginStreak, courseLogin] = yield all(arrayOfQueries);
      const lastLogin = new Date();
      if (courseLogin && courseLogin.data && courseLogin.data.getStudent) {
        courseLogin = courseLogin.data.getStudent.lastLoginCourses;
      } else {
        courseLogin = null;
      }
      // HANDLE COURSE LOGIN
      if (!courseLogin) {
        // If not exist a last login in a course, then i create it
        const lastLoginCourses = [];
        lastLoginCourses.push({ courseID: courseId, lastLogin });
        yield GraphOp(mutation.updateLastLogin, {
          studentId,
          organizationId,
          lastLogin,
          lastLoginCourses
        });
      } else {
        // If a record exists, then a mapping is made. If there is something stored in the course then it is updated, otherwise it is added.
        let exists = false;
        if (courseLogin && courseLogin.length > 0) {
          courseLogin.forEach(item => {
            if (item && item.courseID === courseId) {
              exists = true;
              item.lastLogin = lastLogin;
            }
          });
          if (!exists) courseLogin.push({ courseID: courseId, lastLogin });
          yield GraphOp(mutation.updateLastLogin, {
            studentId,
            organizationId,
            lastLogin,
            lastLoginCourses: courseLogin
          });
        }
      }
      // HANDLE LOGIN STREAK
      let courseIndex;
      if (
        loginStreak &&
        loginStreak.data &&
        loginStreak.data.getStudent &&
        loginStreak.data.getStudent.loginStreaks &&
        loginStreak.data.getStudent.loginStreaks.length > 0
      ) {
        courseIndex = loginStreak.data.getStudent.loginStreaks.findIndex(
          i => i && i.courseID === courseId
        );
        if (courseIndex !== -1) {
          //if loginStreak exists and if the last login date was yesterday, the loginstreak increments in one
          const loginStreakMapped = {
            id: 1,
            days: loginStreak.data.getStudent.loginStreaks[courseIndex].days,
            loginDate: loginStreak.data.getStudent.loginStreaks[courseIndex].loginDate,
            courseID: courseId,
            longest: loginStreak.data.getStudent.loginStreaks[courseIndex].longest
          };
          //handle login streak date
          validateLoginDate(loginStreakMapped);
          loginStreak.data.getStudent.loginStreaks[courseIndex] = loginStreakMapped;
          const newLoginStreakArray = loginStreak.data.getStudent.loginStreaks;
          // Mutation
          yield GraphOp(mutation.updateStudent, {
            organizationID: organizationId,
            studentID: studentId,
            loginStreak: newLoginStreakArray
          });
          yield put(courseActions.setLoginStreak(loginStreakMapped));
        } else {
          //If the login streak doesn't exists for the current course (but exists for other)
          let newLoginStreak = {
            id: Math.ceil(Math.random()),
            days: 1,
            longest: 1,
            loginDate: new Date().toISOString(),
            courseID: courseId
          };
          let newLoginStreakArray = loginStreak.data.getStudent.loginStreaks;
          newLoginStreakArray.push(newLoginStreak);
          yield GraphOp(mutation.updateStudent, {
            organizationID: organizationId,
            studentID: studentId,
            loginStreak: newLoginStreakArray
          });
          yield put(courseActions.setLoginStreak(newLoginStreak));
        }
      } else {
        //since there is no login streak created, we make a new one
        let newLoginStreak = {
          id: Math.ceil(Math.random()),
          days: 1,
          longest: 1,
          loginDate: new Date().toISOString(),
          courseID: courseId
        };
        const newArray = [];
        newArray.push(newLoginStreak);
        yield GraphOp(mutation.updateStudent, {
          organizationID: organizationId,
          studentID: studentId,
          loginStreak: newArray
        });
        yield put(courseActions.setLoginStreak(newLoginStreak));
      }
    }
  } catch (err) {
    yield all([put(notificationsActions.setNotification(err))]);
    yield put(notificationsActions.handleCatchError(err, 'getLoginStreakDataSagas'));
  }
}

function validateLoginDate(loginStreak) {
  //if the last login streak has yesterday's date, then it increments in one
  //if it's been more than one day, then it will reset in one
  const actualDateInt = parseInt(Date.now());
  const dateAux = Date.parse(loginStreak.loginDate);
  const difInMs = actualDateInt - dateAux;
  const msPerDay = 24 * 60 * 60 * 1000; //86400000
  const difInDays = Math.round(difInMs / msPerDay);

  const currentDay = new Date().getDate();
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getYear();

  const loginStreakDate = new Date(loginStreak.loginDate);

  let isToday = false;

  //we check if the student as already logged in today
  if (
    currentDay === loginStreakDate.getDate() &&
    currentMonth === loginStreakDate.getMonth() &&
    currentYear === loginStreakDate.getYear()
  ) {
    isToday = true;
  }
  if (difInDays === 1) {
    //if the last login was yesterday: add 1 to current loginStreak and 1 to current longest if corresponds
    loginStreak.days += 1;
    loginStreak.loginDate = new Date().toISOString();
    if (loginStreak.longest < loginStreak.days || !loginStreak.longest) {
      loginStreak.longest = loginStreak.days;
    }
    return;
  } else if (!isToday) {
    //set the loginStreak to 1 and dont increment the longest
    loginStreak.days = 1;
    loginStreak.loginDate = new Date().toISOString();
    return;
  }
}

function* getStudyProgressSagas(action) {
  const { courseId, studentId } = action.value;
  try {
    const [organization] = yield all([select(getOrganizationDataReducer)]);
    const organizationId = organization && organization.id ? organization.id : null;
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const numberOfWeek = GetNumberOfWeek(today);
    const formatedToday = `${month}-${today.getDate()}`;
    let todayPerformance;
    let weekPerformance;
    let monthPerformance;
    let yearPerformance;
    let allTimePerformance;
    if (studentId && organizationId) {
      const response = yield GraphOp(query.stdGetStudentPerfomanceQuerie, {
        studentId,
        organizationId
      });
      if (response && response.data && response.data.getStudent) {
        todayPerformance = response.data.getStudent.todayPerformance;
        weekPerformance = response.data.getStudent.weekPerformance;
        monthPerformance = response.data.getStudent.monthPerformance;
        yearPerformance = response.data.getStudent.yearPerformance;
        allTimePerformance = response.data.getStudent.allTimePerformance;
      }
    }
    // These arrays will be passed to the reducer for progress, they will be used when executing the algorithm.
    let arrayToday = [];
    let arrayWeek = [];
    let arrayMonth = [];
    let arrayYear = [];
    let arrayAllTime = [];
    // Numbers to pass to the reducer for each card
    // When the mapping is done, it will be replaced by the corresponding values.
    let performanceAllTimeData = { msStudied: 0, questionsAnswered: 0, correctAnswers: 0 };
    let performanceYearData = { msStudied: 0, questionsAnswered: 0, correctAnswers: 0 };
    let performanceMonthData = { msStudied: 0, questionsAnswered: 0, correctAnswers: 0 };
    let performanceWeekData = { msStudied: 0, questionsAnswered: 0, correctAnswers: 0 };
    let performanceTodayData = { msStudied: 0, questionsAnswered: 0, correctAnswers: 0 };
    let performance = {};
    if (studentId && courseId && organizationId) {
      // Today performance
      if (!todayPerformance) {
        // If it does not exist, I create it
        arrayToday.push({
          courseID: courseId,
          day: formatedToday,
          msStudied: 0,
          questionsAnswered: 0,
          correctAnswers: 0
        });
        yield GraphOp(mutation.manageStudentPerformance, {
          studentId,
          organizationId,
          todayPerformance: arrayToday
        });
      } else {
        arrayToday = [...todayPerformance];
        let existDataCourse = false;
        let existThisDayData = false;
        if (arrayToday && arrayToday.length > 0) {
          arrayToday.forEach(item => {
            if (item && item.courseID === courseId) {
              // We will save only one performance per course
              existDataCourse = true;
              if (item.day === formatedToday) {
                //I verify that the saved information is for this day.
                existThisDayData = true;
                performanceTodayData.msStudied = item.msStudied;
                performanceTodayData.correctAnswers = item.correctAnswers;
                performanceTodayData.questionsAnswered = item.questionsAnswered;
              } else {
                // If the saved information is not from this day, then I overwrite the saved information.
                item.msStudied = 0;
                item.questionsAnswered = 0;
                item.correctAnswers = 0;
                item.day = formatedToday;
              }
            }
          });
        }
        // If the mapping is finished and we have no course information, then we create it.
        if (!existDataCourse) {
          arrayToday.push({
            courseID: courseId,
            day: formatedToday,
            msStudied: 0,
            correctAnswers: 0,
            questionsAnswered: 0
          });
        }
        // If any of the 2 conditionals was false, that means that we will have to update the student's values.
        if (!existDataCourse || !existThisDayData) {
          yield GraphOp(mutation.manageStudentPerformance, {
            studentId,
            organizationId,
            todayPerformance: arrayToday
          });
        }
      }
      // Week performance
      let lastWeek = null;
      if (!weekPerformance) {
        // If it does not exist, I create it
        arrayWeek.push({
          courseID: courseId,
          numberOfWeek: numberOfWeek,
          msStudied: 0,
          questionsAnswered: 0,
          correctAnswers: 0
        });
        yield GraphOp(mutation.manageStudentPerformance, {
          studentId,
          organizationId,
          weekPerformance: arrayWeek
        });
      } else {
        arrayWeek = [...weekPerformance];
        let existDataCourse = false;
        let existThisWeekData = false;
        if (arrayWeek && arrayWeek.length > 0) {
          arrayWeek.forEach(item => {
            if (item && item.courseID === courseId) {
              // We will save only one performance per course
              existDataCourse = true;
              if (item.numberOfWeek === numberOfWeek) {
                //I verify that the saved information is for this week.
                existThisWeekData = true;
                performanceWeekData.msStudied = item.msStudied;
                performanceWeekData.correctAnswers = item.correctAnswers;
                performanceWeekData.questionsAnswered = item.questionsAnswered;
              } else {
                // If the saved information is not from this week, then I overwrite the saved information.
                lastWeek = {};
                lastWeek.msStudied = item.msStudied;
                lastWeek.correctAnswers = item.correctAnswers;
                lastWeek.questionsAnswered = item.questionsAnswered;
                item.msStudied = 0;
                item.questionsAnswered = 0;
                item.correctAnswers = 0;
                item.numberOfWeek = numberOfWeek;
              }
            }
          });
        }
        // If the mapping is finished and we have no course information, then we create it.
        if (!existDataCourse) {
          arrayWeek.push({
            courseID: courseId,
            numberOfWeek: numberOfWeek,
            msStudied: 0,
            correctAnswers: 0,
            questionsAnswered: 0
          });
        }
        // If any of the 2 conditionals was false, that means that we will have to update the student's values.
        if (!existDataCourse || !existThisWeekData) {
          yield GraphOp(mutation.manageStudentPerformance, {
            studentId,
            organizationId,
            weekPerformance: arrayWeek
          });
        }
      }
      // Month performance
      if (!monthPerformance) {
        // If it does not exist, I create it
        arrayMonth.push({
          courseID: courseId,
          numberOfMonth: month,
          msStudied: 0,
          questionsAnswered: 0,
          correctAnswers: 0
        });
        yield GraphOp(mutation.manageStudentPerformance, {
          studentId,
          organizationId,
          monthPerformance: arrayMonth
        });
      } else {
        arrayMonth = [...monthPerformance];
        let existDataCourse = false;
        let existThisMonthData = false;
        if (arrayMonth && arrayMonth.length > 0) {
          arrayMonth.forEach(item => {
            if (item && item.courseID === courseId) {
              // We will save only one performance per course
              existDataCourse = true;
              if (item.numberOfMonth === month) {
                //I verify that the saved information is for this week.
                existThisMonthData = true;
                performanceMonthData.msStudied = item.msStudied;
                performanceMonthData.correctAnswers = item.correctAnswers;
                performanceMonthData.questionsAnswered = item.questionsAnswered;
              } else {
                // If the saved information is not from this month, then I overwrite the saved information.
                item.msStudied = 0;
                item.questionsAnswered = 0;
                item.correctAnswers = 0;
                item.numberOfMonth = month;
              }
            }
          });
        }
        // If the mapping is finished and we have no course information, then we create it.
        if (!existDataCourse) {
          arrayMonth.push({
            courseID: courseId,
            numberOfMonth: month,
            msStudied: 0,
            correctAnswers: 0,
            questionsAnswered: 0
          });
        }
        // If any of the 2 conditionals was false, that means that we will have to update the student's values.
        if (!existDataCourse || !existThisMonthData) {
          yield GraphOp(mutation.manageStudentPerformance, {
            studentId,
            organizationId,
            monthPerformance: arrayMonth
          });
        }
      }
      // Year performance
      if (!yearPerformance) {
        // If it does not exist, I create it
        arrayYear.push({
          courseID: courseId,
          year,
          msStudied: '0',
          questionsAnswered: 0,
          correctAnswers: 0
        });
        yield GraphOp(mutation.manageStudentPerformance, {
          studentId,
          organizationId,
          yearPerformance: arrayYear
        });
      } else {
        arrayYear = [...yearPerformance];
        let existDataCourse = false;
        let existThisYearData = false;
        if (arrayYear && arrayYear.length > 0) {
          arrayYear.map(item => {
            if (item && item.courseID === courseId) {
              // We will save only one performance per course
              existDataCourse = true;
              if (item.year === year) {
                //I verify that the saved information is for this year.
                existThisYearData = true;
                performanceYearData.msStudied = item.msStudied;
                performanceYearData.correctAnswers = item.correctAnswers;
                performanceYearData.questionsAnswered = item.questionsAnswered;
              } else {
                // If the saved information is not from this year, then I overwrite the saved information.
                item.msStudied = '0';
                item.questionsAnswered = 0;
                item.correctAnswers = 0;
                item.year = year;
              }
            }
            return null;
          });
        }
        // If the mapping is finished and we have no course information, then we create it.
        if (!existDataCourse) {
          arrayYear.push({
            courseID: courseId,
            year,
            msStudied: '0',
            correctAnswers: 0,
            questionsAnswered: 0
          });
        }
        // If any of the 2 conditionals was false, that means that we will have to update the student's values.
        if (!existDataCourse || !existThisYearData) {
          yield GraphOp(mutation.manageStudentPerformance, {
            studentId,
            organizationId,
            yearPerformance: arrayYear
          });
        }
      }
      // All time performance
      if (!allTimePerformance) {
        // If it does not exist, I create it
        arrayAllTime.push({
          courseID: courseId,
          msStudied: '0',
          questionsAnswered: 0,
          correctAnswers: 0
        });
        yield GraphOp(mutation.manageStudentPerformance, {
          studentId,
          organizationId,
          allTimePerformance: arrayAllTime
        });
      } else {
        arrayAllTime = [...allTimePerformance];
        let existDataCourse = false;
        if (arrayAllTime && arrayAllTime.length > 0) {
          arrayAllTime.forEach(item => {
            if (item && item.courseID === courseId) {
              // We will save only one performance per course
              existDataCourse = true;
              performanceAllTimeData.msStudied = item.msStudied;
              performanceAllTimeData.correctAnswers = item.correctAnswers;
              performanceAllTimeData.questionsAnswered = item.questionsAnswered;
            }
          });
        }
        // If the mapping is finished and we have no course information, then we create it.
        if (!existDataCourse) {
          arrayAllTime.push({
            courseID: courseId,
            msStudied: '0',
            correctAnswers: 0,
            questionsAnswered: 0
          });
          yield GraphOp(mutation.manageStudentPerformance, {
            studentId,
            organizationId,
            allTimePerformance: arrayAllTime
          });
        }
      }
      performance = {
        totalToday: performanceTodayData.questionsAnswered,
        correctToday: performanceTodayData.correctAnswers,
        totalThisWeek: performanceWeekData.questionsAnswered,
        correctThisWeek: performanceWeekData.correctAnswers,
        totalThisMonth: performanceMonthData.questionsAnswered,
        correctThisMonth: performanceMonthData.correctAnswers,
        totalThisYear: performanceYearData.questionsAnswered,
        correctThisYear: performanceYearData.correctAnswers,
        totalAllTime: performanceAllTimeData.questionsAnswered,
        correctAllTime: performanceAllTimeData.correctAnswers
      };
    }
    yield all([
      put(courseActions.getStudyPerformance(performance)),
      put(
        courseActions.setTimeStudied({
          today: performanceTodayData.msStudied,
          thisWeek: performanceWeekData.msStudied,
          thisMonth: performanceMonthData.msStudied,
          thisYear: parseInt(performanceYearData.msStudied),
          allTime: parseInt(performanceAllTimeData.msStudied)
        })
      ),
      put(
        courseActions.setQuestionsAnswered({
          today: performanceTodayData.questionsAnswered,
          thisWeek: performanceWeekData.questionsAnswered,
          thisMonth: performanceMonthData.questionsAnswered,
          thisYear: performanceYearData.questionsAnswered,
          allTime: performanceAllTimeData.questionsAnswered
        })
      ),
      put(
        studyProgress({
          todayPerformance: arrayToday,
          weekPerformance: arrayWeek,
          monthPerformance: arrayMonth,
          yearPerformance: arrayYear,
          allTimePerformance: arrayAllTime
        })
      )
    ]);
  } catch (err) {
    yield put(notificationsActions.handleCatchError(err, 'getStudyProgressSagas'));
  }
}

function* verifyCourseSagas(action) {
  try {
    yield all([put(startLoading())]);
    let userData = yield select(getUserData);
    if (userData) {
      let { courseId, organizationId, redirectOnSuccess, redirectOnError } = action.value;

      const courseData = yield call(
        [API, 'graphql'],
        graphqlOperation(query.stdGetCourseDataQuerie, {
          courseId,
          organizationID: organizationId
        })
      );
      if (courseData.data.getCourse) {
        //the course exist so courseId is Correct

        const studentCoursesIds = yield call(
          [API, 'graphql'],
          graphqlOperation(queryStudents.stdGetStudentCoursesIdsQuerie, {
            studentId: userData.username,
            organizationId: organizationId
          })
        );

        if (
          studentCoursesIds &&
          studentCoursesIds.data.getStudent.courseIDs.indexOf(courseId) > -1
        ) {
          //the student belong to the course
          yield put(setSelectedCourse(courseId));
          if (redirectOnSuccess) redirectOnSuccess();
        } else {
          //the student does not belong to the course
          yield put(
            notificationsActions.setNotification({
              message:
                'You do not belong to the course you were invited, please select another one',
              severity: 'error'
            })
          );
          if (redirectOnError) redirectOnError();
        }
      } else {
        //the url is incorrect
        yield put(
          notificationsActions.setNotification({
            message: 'Invalid course selection, please try again.',
            severity: 'error'
          })
        );
        if (redirectOnError) redirectOnError();
      }
    }
    yield put(stopLoading());
  } catch (err) {
    yield all([put(stopLoading()), put(notificationsActions.setNotification(err))]);
    yield put(notificationsActions.handleCatchError(err, 'verifyCourseSagas'));
  }
}

function* updateCoursePerformanceSagas(action) {
  const { timestamp, studySession, respondedCorrectly, redirect } = action.payload;
  try {
    const [student, courseData, organization, progressPerformance] = yield all([
      select(getStudentDataReducer),
      select(getCourseDataReducer),
      select(getOrganizationDataReducer),
      select(getStudyProgressReducer)
    ]);
    const studentId = student && student.id ? student.id : null;
    const courseId = courseData && courseData.id ? courseData.id : '';
    const organizationId = organization.id ? organization.id : null;
    if (courseId) {
      if (progressPerformance) {
        const {
          todayPerformance,
          weekPerformance,
          monthPerformance,
          yearPerformance,
          allTimePerformance
        } = progressPerformance;
        // Map the arrays and increment the progress
        let todayExists;
        let weekExists;
        let monthExists;
        let yearExists;
        let allExists;
        if (todayPerformance && todayPerformance.length > 0) {
          todayExists = todayPerformance.find(item => item && item.courseID === courseId);
        }
        if (weekPerformance && weekPerformance.length > 0) {
          weekExists = weekPerformance.find(item => item && item.courseID === courseId);
        }
        if (monthPerformance && monthPerformance.length > 0) {
          monthExists = monthPerformance.find(item => item && item.courseID === courseId);
        }
        if (yearPerformance && yearPerformance.length > 0) {
          yearExists = yearPerformance.find(item => item && item.courseID === courseId);
        }
        if (allTimePerformance && allTimePerformance.length > 0) {
          allExists = allTimePerformance.find(item => item && item.courseID === courseId);
        }
        if (todayExists) {
          todayPerformance.forEach(item => {
            if (item && item.courseID === courseId) {
              if (studySession) {
                item.questionsAnswered = item.questionsAnswered + 1;
                if (respondedCorrectly) {
                  item.correctAnswers = item.correctAnswers + 1;
                }
              }
              item.msStudied = item.msStudied ? item.msStudied + timestamp : timestamp;
            }
          });
        } else {
          todayPerformance.push({
            courseID: courseId,
            questionsAnswered: studySession ? 1 : 0,
            msStudied: timestamp,
            correctAnswers: respondedCorrectly ? 1 : 0
          });
        }
        if (weekExists) {
          weekPerformance.forEach(item => {
            if (item && item.courseID === courseId) {
              if (studySession) {
                item.questionsAnswered = item.questionsAnswered + 1;
                if (respondedCorrectly) {
                  item.correctAnswers = item.correctAnswers + 1;
                }
              }
              item.msStudied = item.msStudied ? item.msStudied + timestamp : timestamp;
            }
          });
        } else {
          weekPerformance.push({
            courseID: courseId,
            questionsAnswered: studySession ? 1 : 0,
            msStudied: timestamp,
            correctAnswers: respondedCorrectly ? 1 : 0
          });
        }
        if (monthExists) {
          monthPerformance.forEach(item => {
            if (item && item.courseID === courseId) {
              if (studySession) {
                item.questionsAnswered = item.questionsAnswered + 1;
                if (respondedCorrectly) {
                  item.correctAnswers = item.correctAnswers + 1;
                }
              }
              item.msStudied = item.msStudied ? item.msStudied + timestamp : timestamp;
            }
          });
        } else {
          monthPerformance.push({
            courseID: courseId,
            questionsAnswered: studySession ? 1 : 0,
            msStudied: timestamp,
            correctAnswers: respondedCorrectly ? 1 : 0
          });
        }
        if (yearExists) {
          yearPerformance.forEach(item => {
            if (item && item.courseID === courseId) {
              if (studySession) {
                item.questionsAnswered = item.questionsAnswered + 1;
                if (respondedCorrectly) {
                  item.correctAnswers = item.correctAnswers + 1;
                }
              }
              if (item.msStudied === 'NaN') item.msStudied = timestamp.toString();
              else {
                item.msStudied = parseInt(item.msStudied) + timestamp;
                item.msStudied = item.msStudied.toString();
              }
            }
          });
        } else {
          yearPerformance.push({
            courseID: courseId,
            questionsAnswered: studySession ? 1 : 0,
            msStudied: timestamp.toString(),
            correctAnswers: respondedCorrectly ? 1 : 0
          });
        }
        if (allExists) {
          allTimePerformance.forEach(item => {
            if (item && item.courseID === courseId) {
              if (studySession) {
                item.questionsAnswered = item.questionsAnswered + 1;
                if (respondedCorrectly) {
                  item.correctAnswers = item.correctAnswers + 1;
                }
              }
              if (item.msStudied === 'NaN') item.msStudied = timestamp.toString();
              else {
                item.msStudied = parseInt(item.msStudied) + timestamp;
                item.msStudied = item.msStudied.toString();
              }
            }
          });
        } else {
          allTimePerformance.push({
            courseID: courseId,
            questionsAnswered: studySession ? 1 : 0,
            msStudied: timestamp.toString(),
            correctAnswers: respondedCorrectly ? 1 : 0
          });
        }
        // Execute the mutation
        yield GraphOp(mutation.manageStudentPerformance, {
          studentId,
          organizationId,
          todayPerformance,
          weekPerformance,
          monthPerformance,
          yearPerformance,
          allTimePerformance
        });
        // Save the changes in the reducer
        yield put(
          studyProgress({
            todayPerformance,
            weekPerformance,
            monthPerformance,
            yearPerformance,
            allTimePerformance
          })
        );
      } else {
        const todayPerformance = [];
        const weekPerformance = [];
        const monthPerformance = [];
        const yearPerformance = [];
        const allTimePerformance = [];
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth() + 1;
        const numberOfWeek = GetNumberOfWeek(today);
        const formatedToday = `${month}-${today.getDate()}`;
        todayPerformance.push({
          courseID: courseId,
          day: formatedToday,
          questionsAnswered: studySession ? 1 : 0,
          msStudied: timestamp,
          correctAnswers: respondedCorrectly ? 1 : 0
        });
        weekPerformance.push({
          courseID: courseId,
          numberOfWeek,
          questionsAnswered: studySession ? 1 : 0,
          msStudied: timestamp,
          correctAnswers: respondedCorrectly ? 1 : 0
        });
        monthPerformance.push({
          courseID: courseId,
          numberOfMonth: month,
          questionsAnswered: studySession ? 1 : 0,
          msStudied: timestamp,
          correctAnswers: respondedCorrectly ? 1 : 0
        });
        yearPerformance.push({
          courseID: courseId,
          year,
          questionsAnswered: studySession ? 1 : 0,
          msStudied: timestamp.toString(),
          correctAnswers: respondedCorrectly ? 1 : 0
        });
        allTimePerformance.push({
          courseID: courseId,
          questionsAnswered: studySession ? 1 : 0,
          msStudied: timestamp.toString(),
          correctAnswers: respondedCorrectly ? 1 : 0
        });
        // Execute the mutation
        yield GraphOp(mutation.manageStudentPerformance, {
          studentId,
          organizationId,
          todayPerformance,
          weekPerformance,
          monthPerformance,
          yearPerformance,
          allTimePerformance
        });
        // Save the changes in the reducer
        yield put(
          studyProgress({
            todayPerformance,
            weekPerformance,
            monthPerformance,
            yearPerformance,
            allTimePerformance
          })
        );
      }
    }
    if (redirect) redirect();
  } catch (err) {
    if (redirect) redirect();
    yield put(notificationsActions.handleCatchError(err, 'updateCoursePerformanceSagas'));
  }
}

function* generateStripePaymentSagas(action) {
  // The payload has only basic information
  const {
    organizationId,
    courseId,
    priceId,
    period,
    setLoading,
    setMetadata,
    setPrice,
    setStripeAccount,
    setOrgName
  } = action.payload;
  let customErrorMsg = null;
  try {
    yield all([put(courseActions.generateStripePaymentError(null)), put(getOrganizationLogo())]);
    const arrayOfQueries = [];
    // Information about the organization
    arrayOfQueries.push(
      call(
        [API, 'graphql'],
        graphqlOperation(stdGetOrganizationDataQuerie, {
          id: organizationId
        })
      )
    );
    // Information about the course
    arrayOfQueries.push(
      call(
        [API, 'graphql'],
        graphqlOperation(query.stdGetCourseDataQuerie, {
          courseId,
          organizationID: organizationId
        })
      )
    );
    yield put(courseActions.setPercentageLoadingScreen(40));
    // Breakdown of queries and allocation of information
    const [orgResponse, courseResponse] = yield all(arrayOfQueries);
    const orgData =
      orgResponse && orgResponse.data && orgResponse.data.getOrganization
        ? orgResponse.data.getOrganization
        : null;
    const courseData =
      courseResponse && courseResponse.data && courseResponse.data.getCourse
        ? courseResponse.data.getCourse
        : null;
    // Handle error
    if (!orgData) customErrorMsg = 'No information on the organization could be obtained';
    if (!courseData) customErrorMsg = 'No information on the course could be obtained';
    if (courseData && courseData.status && courseData.status !== 'ACTIVE') {
      customErrorMsg = 'The course is not active';
    }
    if (customErrorMsg) throw new Error(customErrorMsg);
    if (orgData && courseData) {
      if (orgData.name) setOrgName(orgData.name);
      else setOrgName('');
      // Course and organization stripe data
      const stripeId = courseData.stripeProductID ? courseData.stripeProductID : null;
      const stripeAccount = orgData.stripeId ? orgData.stripeId : null;
      // Error handling
      if (!stripeId) customErrorMsg = 'Missing product id related to the course';
      if (!stripeAccount) customErrorMsg = `The organization's stripe id is missing.`;
      if (customErrorMsg) throw new Error(customErrorMsg);
      if (stripeAccount && stripeId) {
        yield put(courseActions.setPercentageLoadingScreen(80));
        const response = yield GraphOp(stdGetProductFromStripeQuerie, {
          productId: stripeId,
          stripeAccount: stripeAccount,
          envName
        });
        // We get the product of stripe
        const stripeProduct =
          response && response.data && response.data.getproductfromstripe
            ? response.data.getproductfromstripe
            : null;
        if (!stripeProduct) {
          customErrorMsg = 'The course stripe product is missing';
          throw new Error(customErrorMsg);
        } else {
          // Validate that the priceId exists and is still active.
          let currentPrice = null;
          if (stripeProduct.prices && stripeProduct.prices.length > 0) {
            currentPrice = stripeProduct.prices.find(
              i => i && i.priceId && i.priceId === priceId && i.active && i.price && i.interval
            );
          }
          // If we do not find anything, it means that the original price may have changed or is no longer active.
          // So let's search by period and see if it is still active.
          if (!currentPrice && period) {
            if (stripeProduct.prices && stripeProduct.prices.length > 0) {
              currentPrice = stripeProduct.prices.find(
                i => i && i.interval && i.active && i.interval === period
              );
            }
          }
          // If not found, it means that we no longer have active prices for the period.
          if (!currentPrice) {
            if (period) {
              customErrorMsg = 'There are no active prices in this period for this course.';
            } else customErrorMsg = 'The indicated price has changed or is no longer active.';
            throw new Error(customErrorMsg);
          }
          // Metadata to pass to Stripe
          const metadata = {};
          metadata.organizationId = organizationId;
          metadata.courseId = courseId;
          metadata.stripeId = stripeAccount;
          metadata.fromSignUp = 'account';
          if (courseData && courseData.name) metadata.courseName = courseData.name;
          if (orgData && orgData.name) metadata.organizationName = orgData.name;
          if (currentPrice && currentPrice.price) metadata.originalCoursePrice = currentPrice.price;
          if (period === 'month') metadata.purchaseDuration = 'Monthly subscription';
          if (period === 'year') metadata.purchaseDuration = 'Yearly subscription';
          if (period === 'week') metadata.purchaseDuration = 'Weekly subscription';
          // Check if the course has the free trial activated
          let timeEnd;
          const freeTrial =
            courseData &&
            courseData.pricing &&
            courseData.pricing.freeTrial &&
            courseData.pricing.freeTrial.active
              ? courseData.pricing.freeTrial
              : false;
          if (freeTrial && freeTrial.period && freeTrial.duration) {
            const period = freeTrial.period;
            const duration = freeTrial.duration;
            timeEnd = new Date();
            switch (period) {
              case 'DAY':
                timeEnd.setDate(timeEnd.getDate() + parseInt(duration));
                metadata.duration = duration;
                break;
              case 'MONTH':
                timeEnd.setMonth(timeEnd.getMonth() + parseInt(duration));
                break;
              default:
                break;
            }
            timeEnd = timeEnd.getTime() / 1000;
          }
          metadata.timeEnd = timeEnd;
          yield put(courseActions.setPercentageLoadingScreen(75));
          if (metadata && currentPrice && stripeAccount) {
            setMetadata(metadata);
            setPrice(currentPrice);
            setStripeAccount(stripeAccount);
          } else {
            customErrorMsg = 'Some information is missing';
            throw new Error(customErrorMsg);
          }
        }
      }
    }
    yield all([put(courseActions.setPercentageLoadingScreen(100))]);
    if (setLoading) setLoading(false);
  } catch (err) {
    if (customErrorMsg) {
      yield all([put(courseActions.generateStripePaymentError(customErrorMsg))]);
    } else {
      if (setLoading) setLoading(false);
      yield all([put(notificationsActions.handleCatchError(err, 'generateStripePaymentSagas'))]);
    }
    yield all([
      put(courseActions.setPercentageLoadingScreen(0)),
      put(
        notificationsActions.setNotification({
          message: 'Unable to complete request. Please contact us for support.',
          severity: 'error'
        })
      )
    ]);
  }
}

function* validateEmailAndGenerateCheckoutSagas(action) {
  const {
    studentEmail,
    metadata,
    price,
    stripeAccount,
    organizationId,
    setLoaderButton,
    redirectToLogin
  } = action.payload;
  try {
    let existingUser = false;
    if (studentEmail && organizationId) {
      let response = yield GraphOp(queryStudents.stdListStudentsWithThisEmailQuerie, {
        studentEmail,
        organizationId
      });
      if (response && response.data && response.data.listStudents) {
        if (response.data.listStudents.items && response.data.listStudents.items.length > 0) {
          existingUser = true;
        }
        let nextToken = response.data.listStudents.nextToken
          ? response.data.listStudents.nextToken
          : null;
        while (nextToken) {
          if (existingUser) nextToken = null;
          else {
            // If has nextToken it means that are more users to get
            response = yield GraphOp(queryStudents.stdListStudentsWithThisEmailQuerie, {
              studentEmail,
              organizationId,
              nextToken
            });
            if (response && response.data && response.data.listStudents) {
              if (response.data.listStudents.items && response.data.listStudents.items.length > 0) {
                existingUser = true;
              }
              nextToken = response.data.listStudents.nextToken
                ? response.data.listStudents.nextToken
                : null;
            }
          }
        }
      }
    }
    if (existingUser) {
      localStorage.setItem('prefilledEmail', studentEmail);
      yield all([
        put(
          notificationsActions.setNotification({
            message: `The email ${studentEmail} is already registered on our platform!`,
            severity: 'error'
          })
        )
      ]);
      if (redirectToLogin) redirectToLogin();
    } else {
      // Validation in Stripe
      const stripeValidation = yield GraphOp(stdValidateStripeCustomerQuerie, {
        email: studentEmail,
        stripeAccount
      });
      const stripeResponse =
        stripeValidation && stripeValidation.data && stripeValidation.data.validateStripeCustomer
          ? JSON.parse(stripeValidation.data.validateStripeCustomer)
          : null;
      const customerId =
        stripeResponse && stripeResponse.customerId ? stripeResponse.customerId : null;
      // If the cursomerId exists then the account is already registred on Stripe and has an active subscription
      if (stripeResponse && stripeResponse.customerId && stripeResponse.hasActiveSubscriptions) {
        localStorage.setItem('prefilledEmail', studentEmail);
        yield all([
          put(
            notificationsActions.setNotification({
              message: `A payment has already been registered with ${studentEmail}. Check your mailbox.`,
              severity: 'error'
            })
          )
        ]);
        if (redirectToLogin) redirectToLogin();
      } else {
        // Get stripe connect information
        // Get the organization IDs that use stripe oAuth
        const everprepOrganizations = yield call(verifyStripeConnect);
        let stripeConnect = true;
        if (everprepOrganizations && everprepOrganizations.length > 0) {
          const isStripeoAuth = everprepOrganizations.find(id => id === organizationId);
          if (isStripeoAuth) stripeConnect = false;
        }
        const myDomain = `${window.location.origin}`;
        metadata.autoRenewal = price ? price.autoRenewal : true;
        metadata.studentEmail = studentEmail;
        metadata.organizationId = organizationId;
        let response = yield GraphOp(stdGetStripeCheckoutSessionQuerie, {
          metadata: JSON.stringify(metadata),
          customerId,
          price: JSON.stringify(price),
          stripeAccount,
          myDomain,
          type: 'subscription',
          stripeConnect,
          registerAtTheEnd: true
        });
        const session =
          response &&
          response.data &&
          response.data.getStripeCheckoutSession &&
          response.data.getStripeCheckoutSession !== '{}'
            ? response.data.getStripeCheckoutSession
            : null;
        if (session) window.open(session, '_self');
        else {
          yield all([
            put(
              notificationsActions.setNotification({
                message: 'An error has occurred connecting to Stripe. Please contact support!',
                severity: 'error'
              })
            ),
            put(
              notificationsActions.handleCatchError(
                'Object returned by the lambda is null',
                'getCreateAccountInformationSagas'
              )
            )
          ]);
        }
      }
    }
    if (setLoaderButton) setLoaderButton(false);
  } catch (err) {
    if (setLoaderButton) setLoaderButton(false);
    yield all([
      put(notificationsActions.handleCatchError(err, 'validateEmailAndGenerateCheckoutSagas'))
    ]);
  }
}

function* getCreateAccountInformationSagas(action) {
  let customErrorMsg = null;
  try {
    const [organizationId] = yield all([
      select(getUserOrganizationId),
      put(loadingOrganizationLogo(true)),
      put(getOrganizationLogo()),
      put(startLoading())
    ]);
    const {
      courseId,
      stripeSession,
      setPrefilledEmail,
      setOrgName,
      setOrgSupportEmail,
      setCourseName,
      setAutoRenewal,
      setStripeAccount,
      setCurstomerId,
      setStudentName,
      redirectToLogin
    } = action.payload;
    let studentEmail = '';
    let organizationName = '';
    let creatorSupportEmail = '';
    let courseName = '';
    if (!organizationId) {
      customErrorMsg = 'Missing organization id';
      throw new Error(customErrorMsg);
    }
    const arrayOfQueries = [];
    // Get organization info
    arrayOfQueries.push(
      call(
        [API, 'graphql'],
        graphqlOperation(stdGetOrganizationDataQuerie, {
          id: organizationId
        })
      )
    );
    // Get course info
    arrayOfQueries.push(
      call(
        [API, 'graphql'],
        graphqlOperation(query.stdGetCourseDataQuerie, {
          courseId,
          organizationID: organizationId
        })
      )
    );
    // Breakdown of queries and allocation of information
    const [orgResponse, courseResponse] = yield all(arrayOfQueries);
    const orgInfo =
      orgResponse && orgResponse.data && orgResponse.data.getOrganization
        ? orgResponse.data.getOrganization
        : null;
    const courseInfo =
      courseResponse && courseResponse.data && courseResponse.data.getCourse
        ? courseResponse.data.getCourse
        : null;
    let stripeAccount = null;
    if (orgInfo) {
      if (orgInfo.name) {
        organizationName = orgInfo.name;
        setOrgName(organizationName);
      }
      if (orgInfo.supportEmail) {
        creatorSupportEmail = orgInfo.supportEmail;
        setOrgSupportEmail(creatorSupportEmail);
      }
      if (orgInfo.stripeId) {
        stripeAccount = orgInfo.stripeId;
        setStripeAccount(orgInfo.stripeId);
      }
    }
    if (courseInfo) {
      if (courseInfo.name) {
        courseName = courseInfo.name;
        setCourseName(courseName);
      }
    }
    if (!stripeAccount) {
      customErrorMsg = `The organization's stripe id is missing.`;
      throw new Error(customErrorMsg);
    }
    // Stripe information
    if (stripeAccount && stripeSession) {
      let response = yield GraphOp(stdBringStripeCheckoutSessionQuerie, {
        stripeSession,
        stripeAccount
      });
      if (response && response.data && response.data.bringStripeCheckoutSession) {
        response = JSON.parse(response.data.bringStripeCheckoutSession);
        if (response) {
          if (response.email) {
            studentEmail = response.email.trim().toLowerCase();
            setPrefilledEmail(studentEmail);
          }
          if (response.customerId) setCurstomerId(response.customerId);
          if (response.name) setStudentName(response.name);
          if (response.autoRenewal && response.autoRenewal === 'false') setAutoRenewal(false);
        }
      } else {
        customErrorMsg = 'Error obtaining subscription information';
        throw new Error(customErrorMsg);
      }
    }
    // Validate that the student is not already registered in the organization.
    if (studentEmail && organizationId) {
      let existingUser = false;
      let response = yield GraphOp(queryStudents.stdListStudentsWithThisEmailQuerie, {
        studentEmail,
        organizationId
      });
      if (response && response.data && response.data.listStudents) {
        if (response.data.listStudents.items && response.data.listStudents.items.length > 0) {
          existingUser = true;
        }
        let nextToken = response.data.listStudents.nextToken
          ? response.data.listStudents.nextToken
          : null;
        while (nextToken) {
          if (existingUser) nextToken = null;
          else {
            // If has nextToken it means that are more users to get
            response = yield GraphOp(queryStudents.stdListStudentsWithThisEmailQuerie, {
              studentEmail,
              organizationId,
              nextToken
            });
            if (response && response.data && response.data.listStudents) {
              if (response.data.listStudents.items && response.data.listStudents.items.length > 0) {
                existingUser = true;
              }
              nextToken = response.data.listStudents.nextToken
                ? response.data.listStudents.nextToken
                : null;
            }
          }
        }
        if (existingUser) {
          redirectToLogin();
          customErrorMsg = 'This user is already registered! Please log in.';
          throw new Error(customErrorMsg);
        }
      }
    }
    const sendWelcomeEmail =
      localStorage &&
      localStorage.getItem('sendWelcomeEmail') &&
      localStorage.getItem('sendWelcomeEmail') === 'true';
    if (sendWelcomeEmail) {
      const [appTheme] = yield all([select(getAppTheme)]);
      let backgroundEmail = '';
      if (appTheme && appTheme.length > 0) {
        let accentTheme = appTheme.find(t => t && t.section === 'ACCENT');
        if (accentTheme) {
          if (accentTheme.presentActive) backgroundEmail = accentTheme.presentColor;
          else if (accentTheme.customColor) backgroundEmail = accentTheme.customColor;
        }
      }
      const payload = {
        type: 'studentSignsUp',
        email: studentEmail,
        organizationSupportEmail: creatorSupportEmail,
        organizationName,
        organizationId,
        registrationLink: window.location.href,
        courseName,
        backgroundEmail
      };
      const mailParams = sendEmailProcess(payload);
      // Send welcome email
      yield GraphOp(sendEmailSES, { mailParams });
      localStorage.removeItem('sendWelcomeEmail');
    }
    yield all([put(stopLoading())]);
  } catch (err) {
    yield all([
      put(stopLoading()),
      put(
        notificationsActions.setNotification({
          message: customErrorMsg
            ? customErrorMsg
            : 'Unable to complete request. Please contact us for support.',
          severity: 'error'
        })
      )
    ]);
    if (!customErrorMsg) {
      yield all([
        put(notificationsActions.handleCatchError(err, 'getCreateAccountInformationSagas'))
      ]);
    }
  }
}

// Watchers
function* getCourseDataWatcher() {
  yield takeLatest(types.GET_COURSE_DATA, getCourseDataSagas);
}
function* getTopicDataWatcher() {
  yield takeLatest(types.GET_TOPIC_DATA, getTopicDataSagas);
}
function* getStudyPerformanceDataWatcher() {
  yield takeLatest(types.GET_STUDY_PERFORMANCE, getStudyPerformanceDataSagas);
}
function* getLoginStreakDataWatcher() {
  yield takeLatest(types.GET_LOGIN_STREAK, getLoginStreakDataSagas);
}
function* getStudyProgressWatcher() {
  yield takeLatest(types.GET_STUDY_PROGRESS, getStudyProgressSagas);
}
function* verifyCourseWatcher() {
  yield takeLatest(types.VERIFY_COURSE, verifyCourseSagas);
}
function* updateCoursePerformanceWatcher() {
  yield takeLatest(types.UPDATE_COURSE_PERFORMANCE, updateCoursePerformanceSagas);
}
function* generateStripePaymentWatcher() {
  yield takeLatest(types.GENERATE_STRIPE_PAYMENT, generateStripePaymentSagas);
}
function* getCreateAccountInformationWatcher() {
  yield takeLatest(types.GET_CREATE_ACCOUNT_INFORMATION, getCreateAccountInformationSagas);
}
function* validateEmailAndGenerateCheckoutWatcher() {
  yield takeLatest(
    types.VALIDATE_EMAIL_AND_GENERATE_CHECKOUT,
    validateEmailAndGenerateCheckoutSagas
  );
}

// Exports the sagas
export default function* sagas() {
  yield all([
    getCourseDataWatcher(),
    getStudyPerformanceDataWatcher(),
    getLoginStreakDataWatcher(),
    getTopicDataWatcher(),
    getStudyProgressWatcher(),
    verifyCourseWatcher(),
    updateCoursePerformanceWatcher(),
    generateStripePaymentWatcher(),
    getCreateAccountInformationWatcher(),
    validateEmailAndGenerateCheckoutWatcher()
  ]);
}
