import React, { Suspense, lazy } from 'react';
import LoadingScreen from '../components/LoadingScreen/loadingScreenView';
import { Route, Routes, Navigate } from 'react-router-dom';

// Parents containers
const Mainlayout = lazy(() => import('../containers/mainContainer'));

export default (
  <Suspense fallback={<LoadingScreen />}>
    <Routes>
      <Route path="*" element={<Mainlayout />} />
      <Route render={() => <Navigate replace to="*" />} />
    </Routes>
  </Suspense>
);
