import { connect } from 'react-redux';
// View
import View from '../../components/Modal/GetHelpModal/getHelpModalView';
// States
import { getUserData, getStudentDataReducer, } from '../../selectors/userSelectors';
import { getCourseDataReducer } from '../../selectors/Course/courseSelector';
import {
  getActualQuestionReducer as examActualQuestion,
  getExamDataReducer
} from '../../selectors/Course/examSelector';
import { getActualQuestionReducer as studySessionActualQuestion } from '../../selectors/studySessionSelectors';
import { getOrganizationDataReducer } from '../../selectors/organizationSelector';
// Functions
import { sendIntercomMessage } from '../../actions/appActions';
import { sendStudentFeedback } from '../../actions/myAccountActions';
import { sendExamFeedback } from '../../actions/examActions';
import { sendQuestionStudySessionFeedback } from '../../actions/studySessionActions';
import { deleteNotification, handleCatchError } from '../../actions/errorHandlerActions';

const mapStateToProps = state => ({
  actualQuestionExam: examActualQuestion(state),
  actualQuestionStudySession: studySessionActualQuestion(state),
  courseData: getCourseDataReducer(state),
  examData: getExamDataReducer(state),
  organizationData: getOrganizationDataReducer(state),
  studentData: getStudentDataReducer(state),
  userData: getUserData(state),
});

const mapDispatchToProps = dispatch => {
  return {
    deleteNotification: payload => dispatch(deleteNotification(payload)),
    handleCatchError: (error, message) => dispatch(handleCatchError(error, message)),
    sendExamFeedback: payload => dispatch(sendExamFeedback(payload)),
    sendIntercomMessage: payload => dispatch(sendIntercomMessage(payload)),
    sendQuestionFeedback: payload => dispatch(sendQuestionStudySessionFeedback(payload)),
    sendStudentFeedback: payload => dispatch(sendStudentFeedback(payload)),
  };
};

const Container = connect(mapStateToProps, mapDispatchToProps)(View);

export default Container;
