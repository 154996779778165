export const stdGetProductFromStripeQuerie = /* GraphQL */ `
  query Getproductfromstripe($productId: ID, $stripeAccount: ID, $envName: String) {
    getproductfromstripe(productId: $productId, stripeAccount: $stripeAccount, envName: $envName) {
      id
      object
      active
      name
      description
      prices {
        price
        interval
        interval_count
        currency
        priceId
        active
        autoRenewal
      }
    }
  }
`;

export const createStripeCustomer = /* GraphQL */ `
  query createStripeCustomer(
    $source: String
    $billingInfo: String
    $metadata: String
    $envName: String
  ) {
    createStripeCustomer(
      source: $source
      billingInfo: $billingInfo
      metadata: $metadata
      envName: $envName
    )
  }
`;

export const stdRetriveStripeSubscriptionsQuerie = /* GraphQL */ `
  query retriveStripeSubscriptions(
    $subscriptionId: String
    $stripeAccount: ID
    $envName: String
    $fromWebhook: Boolean
  ) {
    retriveStripeSubscriptions(
      subscriptionId: $subscriptionId
      stripeAccount: $stripeAccount
      envName: $envName
      fromWebhook: $fromWebhook
    )
  }
`;

export const stdRetrievePaymentInformationQuerie = /* GraphQL */ `
  query retrievePaymentInformation($customerId: String, $stripeAccount: ID, $envName: String) {
    retrievePaymentInformation(
      customerId: $customerId
      stripeAccount: $stripeAccount
      envName: $envName
    )
  }
`;

export const updateCustomer = /* GraphQL */ `
  query updateCustomer(
    $sourceId: String
    $customerId: String
    $stripeAccount: ID
    $envName: String
    $billingInfo: String
  ) {
    updateCustomer(
      customerId: $customerId
      sourceId: $sourceId
      stripeAccount: $stripeAccount
      envName: $envName
      billingInfo: $billingInfo
    )
  }
`;

export const cancelStripeSubscription = /* GraphQL */ `
  query cancelStripeSubscription(
    $subscriptionId: String
    $stripeAccount: ID
    $envName: String
    $autoRenewal: Boolean
    $changeAccount: Boolean
  ) {
    cancelStripeSubscription(
      subscriptionId: $subscriptionId
      stripeAccount: $stripeAccount
      envName: $envName
      autoRenewal: $autoRenewal
      changeAccount: $changeAccount
    )
  }
`;

export const sendIntercomAutomaticMessageMutation = /* GraphQL */ `
  query sendIntercomAutomaticMessage(
    $email: String
    $message: String
    $accessToken: String
    $customAttributes: String
  ) {
    sendIntercomAutomaticMessage(
      email: $email
      message: $message
      accessToken: $accessToken
      customAttributes: $customAttributes
    )
  }
`;

export const removePaymentMethod = /* GraphQL */ `
  query removePaymentMethod(
    $sourceId: String
    $customerId: String
    $stripeAccount: ID
    $envName: String
  ) {
    removePaymentMethod(
      customerId: $customerId
      sourceId: $sourceId
      stripeAccount: $stripeAccount
      envName: $envName
    )
  }
`;

export const stdGetStripeCheckoutSessionQuerie = /* GraphQL */ `
  query getStripeCheckoutSession(
    $metadata: String
    $customerId: String
    $price: String
    $stripeAccount: ID
    $myDomain: String
    $type: String
    $stripeConnect: Boolean
    $registerAtTheEnd: Boolean
  ) {
    getStripeCheckoutSession(
      metadata: $metadata
      customerId: $customerId
      price: $price
      stripeAccount: $stripeAccount
      myDomain: $myDomain
      type: $type
      stripeConnect: $stripeConnect
      registerAtTheEnd: $registerAtTheEnd
    )
  }
`;

export const stdGetStripeBillingQuerie = /* GraphQL */ `
  query getStripeBilling(
    $customerId: String
    $stripeAccount: ID
    $envName: String
    $myDomain: String
  ) {
    getStripeBilling(
      customerId: $customerId
      stripeAccount: $stripeAccount
      envName: $envName
      myDomain: $myDomain
    )
  }
`;

export const stdRetrieveStripePaymentMethodQuerie = /* GraphQL */ `
  query retrieveStripePaymentMethod(
    $customerId: String
    $stripeAccount: ID
    $envName: String
    $paymentMethod: String
  ) {
    retrieveStripePaymentMethod(
      customerId: $customerId
      stripeAccount: $stripeAccount
      envName: $envName
      paymentMethod: $paymentMethod
    )
  }
`;

export const stdGetStripeSubscriptionListQuerie = /* GraphQL */ `
  query getStripeSubscriptionList(
    $customerId: String
    $stripeAccount: ID
    $envName: String
    $courseId: String
    $type: String
  ) {
    getStripeSubscriptionList(
      customerId: $customerId
      stripeAccount: $stripeAccount
      envName: $envName
      courseId: $courseId
      type: $type
    )
  }
`;

export const stdGetOrganizationDomainQuerie = /* GraphQL */ `
  query stdGetOrganizationDomainQuerie($organizationID: String, $envName: String) {
    getOrganizationDomain(organizationID: $organizationID, envName: $envName)
  }
`;

export const sendEmailSES = /* GraphQL */ `
  query sendEmailSES($mailParams: String) {
    sendEmailSES(mailParams: $mailParams)
  }
`;

export const scanStudentAnswersCLI = /* GraphQL */ `
  query scanStudentAnswersCLI($courseId: String, $studentId: String, $envName: String) {
    scanStudentAnswersCLI(courseId: $courseId, studentId: $studentId, envName: $envName)
  }
`;
export const scanQuestionsCLI = /* GraphQL */ `
  query ScanQuestionsCLI($courseId: String, $envName: String) {
    scanQuestionsCLI(courseId: $courseId, envName: $envName)
  }
`;

export const handleCatchErrorStudents = /* GraphQL */ `
  query HandleCatchErrorStudents($catchedError: String, $envName: String) {
    handleCatchErrorStudents(catchedError: $catchedError, envName: $envName)
  }
`;

export const stdCreateStudentAccountAndSubscriptionQuerie = /* GraphQL */ `
  query stdCreateStudentAccountAndSubscriptionQuerie(
    $metadata: String
    $poolData: String
    $mainUserPoolId: String
  ) {
    createStudentAccountAndSubscription(
      metadata: $metadata
      poolData: $poolData
      mainUserPoolId: $mainUserPoolId
    )
  }
`;

export const stdBringStripeCheckoutSessionQuerie = /* GraphQL */ `
  query bringStripeCheckoutSession($stripeSession: String, $stripeAccount: String) {
    bringStripeCheckoutSession(stripeSession: $stripeSession, stripeAccount: $stripeAccount)
  }
`;

export const stdValidateStripeCustomerQuerie = /* GraphQL */ `
  query validateStripeCustomer($email: String, $stripeAccount: String, $organizationId: String) {
    validateStripeCustomer(
      email: $email
      stripeAccount: $stripeAccount
      organizationId: $organizationId
    )
  }
`;

export const stdRetrieveStudentAnswerQuerie = /* GraphQL */ `
  query retrieveStudentAnswer($questionId: String, $studentId: String) {
    retrieveStudentAnswer(questionId: $questionId, studentId: $studentId)
  }
`;

export const loginCognitoService = /* GraphQL */ `
  query LoginCognitoService($params: LoginParams!, $poolData: PoolData) {
    loginCognitoService(params: $params, poolData: $poolData)
  }
`;
export const changePasswordCognitoService = /* GraphQL */ `
  query ChangePasswordCognitoService($userParams: UserParams!) {
    changePasswordCognitoService(userParams: $userParams)
  }
`;

export const getPasswordPoliciesCognitoService = /* GraphQL */ `
  query GetPasswordPoliciesCognitoService($params: getPasswordPoliciesCognitoService!) {
    getPasswordPoliciesCognitoService(params: $params)
  }
`;

export const generateCertificateS3Link = /* GraphQL */ `
  query GenerateCertificateS3Link($certificateID: String!, $blobBase64: String!) {
    generateCertificateS3Link(certificateID: $certificateID, blobBase64: $blobBase64)
  }
`;
