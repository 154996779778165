export const stdGetTopicContainerMediaURLQuerie = /* GraphQL */ `
  query stdGetTopicContainerMediaURLQuerie($courseId: ID!, $topicId: ID!, $containerIndex: Int!) {
    getTopic(courseID: $courseId, id: $topicId) {
      contentContainers(filter: { order: { eq: $containerIndex } }) {
        items {
          id
          actualVersion
          contents {
            id
            published
            fileID
            order
            type
          }
          versions {
            id
            contents {
              id
              fileID
              order
              type
              published
            }
          }
        }
      }
    }
  }
`;

export const stdGetFileDataQuerie = /* GraphQL */ `
  query stdGetFileDataQuerie($courseId: ID!, $fileId: ID!) {
    getCourseFile(courseID: $courseId, id: $fileId) {
      id
      name
      label
      type
      format
      size
      fileKey
      isOnlineVideo
      onlinePreview
      onlineUrl
      status
      duration
    }
  }
`;

export const stdGetContentStatsQuerie = /* GraphQL */ `
  query stdGetContentStatsQuerie($courseId: ID!, $contentId: ID!) {
    getTopic(courseID: $courseId, id: $contentId) {
      id
      name
      type
      studentStats {
        studentID
        sessionInfo {
          contentId
          day
          markedDone
          timeSpent
          timeStarted
          totalQuestions
          totalQuestionsOk
        }
      }
    }
  }
`;
