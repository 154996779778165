export const updateCourseActionRequired = /* GraphQL */ `
  mutation updateCourseActionRequired(
    $courseId: ID!
    $organizationId: ID!
    $actionRequired: [String]
  ) {
    updateCourse(
      input: {id: $courseId, organizationID: $organizationId, actionRequired: $actionRequired}
    ) {
      id
    }
  }
`;
