import { Map } from 'immutable';
import * as t from '../actions/actionTypes/loginActionTypes';

const initialState = Map({
  errorSignin: null,
  successPasswordChange: false,
  poolData: null,
  setSignInUser: false,
  forgotPasswordEmail: ''
});

const login = function (state = initialState, action) {
  switch (action.type) {
    case t.ERROR_SIGNIN_USER:
      return state.set('errorSignin', action.value);
    case t.SUCCESS_PASSWORD_CHANGE:
      return state.set('successPasswordChange', action.value);
    case t.POOL_DATA:
      return state.set('poolData', action.value);
    case t.SET_SIGN_IN_USER:
      return state.set('setSignInUser', action.value);
    case t.SIGN_OUT_USER:
      return initialState;
    case t.SET_FORGOT_PASSWORD_EMAIL:
      return state.set('forgotPasswordEmail', action.value);
    default:
      return state;
  }
};

export default login;
