import React from 'react';
import { Amplify } from 'aws-amplify';
import { Auth } from '@aws-amplify/auth';
import awsconfig from '../../aws-exports';
import App from '../../containers/appContainer';
import { store } from '../../store/ConfigureStore';

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

const AppWithAuth = () => (
  <div>
    <App store={store} />
  </div>
);

export default AppWithAuth;
