export const markLessonAsReadMutation = /* GraphQL */ `
  mutation markLessonAsReadMutation($organizationId: ID!, $studentId: ID!, $lessonsRead: [String]) {
    updateStudent(
      input: { organizationID: $organizationId, id: $studentId, lessonsRead: $lessonsRead }
    ) {
      id
    }
  }
`;

export const stdUpdateStudentsStatsMutation = /* GraphQL */ `
  mutation UpdateStudentsStats(
    $contentId: ID!
    $courseId: ID!
    $studentStats: [StudentStatsInput]
  ) {
    updateTopic(input: { id: $contentId, courseID: $courseId, studentStats: $studentStats }) {
      id
    }
  }
`;
