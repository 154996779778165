const getTimeZoneAbbr = date => {
  const timezoneOffset = -date.getTimezoneOffset();
  const timezoneAbbrMap = {
    '-720': 'GMT-12',
    '-660': 'GMT-11',
    '-600': 'HST', // Hawaii Standard Time
    '-570': 'ACST', // Australian Central Standard Time
    '-540': 'AKST', // Alaska Standard Time
    '-480': 'PST', // Pacific Standard Time
    '-420': 'MST', // Mountain Standard Time
    '-360': 'CST', // Central Standard Time
    '-300': 'EST', // Eastern Standard Time
    '-240': 'AST', // Atlantic Standard Time
    '-210': 'NST', // Newfoundland Standard Time
    '-180': 'ART', // Argentina Time
    '-120': 'GMT-2',
    '-60': 'GMT-1',
    0: 'GMT', // Greenwich Mean Time
    60: 'CET', // Central European Time
    120: 'EET', // Eastern European Time
    180: 'MSK', // Moscow Standard Time
    210: 'IRST', // Iran Standard Time
    240: 'GST', // Gulf Standard Time
    270: 'PKT', // Pakistan Standard Time
    300: 'IST', // India Standard Time
    330: 'NPT', // Nepal Time
    345: 'MMT', // Myanmar Time
    360: 'CST', // China Standard Time
    420: 'JST', // Japan Standard Time
    480: 'KST', // Korea Standard Time
    540: 'AEST', // Australian Eastern Standard Time
    570: 'ACDT', // Australian Central Daylight Time
    600: 'AEDT', // Australian Eastern Daylight Time
    660: 'GMT+11',
    720: 'GMT+12'
  };

  return timezoneAbbrMap[timezoneOffset] || 'UTC';
};

export default getTimeZoneAbbr;
