import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  FormControl,
  MenuItem,
  Select,
  Fade,
  Modal,
  TextareaAutosize
} from '@mui/material';
// Icons
import { X, ChevronDown } from 'react-feather';
// Components
import PillButton from '../../Common/Components/pillButtonView';
// Page Styles
import { useStyles } from '../../../assets/jss/components/Modal/GetHelpModal/getHelpModalStyles';

export default function GetHelpModal(props) {
  const classes = useStyles();
  const {
    // Props
    open,
    handleOnClose,
    source,
    showExam,
    userEmail,
    preselectedIssue,
    // States
    actualQuestionExam,
    actualQuestionStudySession,
    courseData,
    examData,
    organizationData,
    studentData,
    userData,
    // Functions
    deleteNotification,
    handleCatchError,
    sendExamFeedback,
    sendIntercomMessage,
    sendQuestionFeedback,
    sendStudentFeedback
  } = props;
  const [issues, setIssues] = useState([]);
  const [issueSelected, setIssueSelected] = useState(-1);
  const [textValue, setTextValue] = useState('');
  const [loading, setLoading] = useState(false);

  /* 
  Source variable indicates where the getHelpModal was called in order to send the correct feedback
    1: Choose course, Content view, Signup -> Avalaible options: My Account, Technical issue, Other
    2: Exam Session, Exam finished, Exam review -> Avalaible options: Question, Exam, My Account, Technical issue, Other
    3: Study Session, Study Session Finished -> Avalaible options: Question, My Account, Technical issue, Other
    4: Create account -> Avalaible options: Other
  */
  useEffect(() => {
    if (open) {
      window.analytics.page({
        url: window.location.href,
        path: window.location.pathname + '/modal/Get Help'
      });
      window.analytics.track('Click get help');
      window.analytics.track('Pageview: ' + window.location.pathname + '/modal/Get Help');
      setIssues(getTypes(source));
      if (preselectedIssue) setIssueSelected(preselectedIssue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleChange = event => {
    const value = event.target.value;
    if (value && issues && issues.length > 0) {
      const thisIssue = issues.find(i => i && i.type === value);
      if (thisIssue) setIssueSelected(thisIssue);
    }
  };

  const handleTextChange = e => {
    const { value } = e.target;
    if (value.length <= 1000) setTextValue(value);
  };

  const handleSubmitIssue = () => {
    // Student data
    const studentId = userData && userData.username ? userData.username : null;
    let studentEmail = '';
    if (userEmail) studentEmail = userEmail;
    else {
      if (userData && userData.attributes && userData.attributes.email) {
        studentEmail = userData.attributes.email;
      } else {
        if (studentData && studentData.email) studentEmail = studentData.email;
      }
    }
    // Course data
    let courseId = courseData && courseData.id ? courseData.id : '';
    // Get course from URL
    if (courseId === '') {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams) {
        for (let value of urlParams.keys()) {
          const inLower = value.toLowerCase();
          if (inLower === 'courseid') courseId = urlParams.get(value);
        }
      }
    }
    const courseName = courseData && courseData.name ? courseData.name : '';
    // Exam data
    const examName = examData && examData.title ? examData.title : '';
    const examId = examData && examData.id ? examData.id : '';
    // Question data
    const questionId =
      showExam && examData && examData.id && actualQuestionExam && actualQuestionExam.id
        ? actualQuestionExam.id
        : actualQuestionStudySession && actualQuestionStudySession.id
        ? actualQuestionStudySession.id
        : '';
    const questionName =
      showExam && examData && examData.id && actualQuestionExam && actualQuestionExam.name
        ? actualQuestionExam.name
        : actualQuestionStudySession && actualQuestionStudySession.name
        ? actualQuestionStudySession.name
        : '';
    const payload = {};
    // In case you do not have intercom an email is sent to the support account.
    const emailPayload = {};
    let catchedIssue = '';
    if (issueSelected) {
      emailPayload.feedback = textValue;
      emailPayload.backgroundOrg = '#FFFFFF';
      emailPayload.type = issueSelected.type;
      catchedIssue = `Type: ${issueSelected.type}. Report: ${textValue}`;
      switch (issueSelected.type) {
        case 'QUESTION':
          emailPayload.data = `Support - Question Feedback for ${courseName} - ${questionName}`;
          emailPayload.body = `<p>A new support request was opened for question <b>${questionName}</b> in course <b>${courseName}</b></p>`;
          emailPayload.questionId = questionId;
          emailPayload.courseId = courseId;
          break;
        case 'EXAM':
          emailPayload.data = `Support - Question Feedback for ${courseName} - ${examName}`;
          emailPayload.body = `<p>A new support request was opened for exam <b>${examName}</b> in course ${courseName}</p>`;
          emailPayload.examId = examId;
          emailPayload.courseId = courseId;
          break;
        case 'MY_ACCOUNT':
          emailPayload.data = `Support - Student Feedback for ${courseName}`;
          emailPayload.body = `<p>A new support request was opened from <b>${
            studentEmail ? studentEmail : studentId
          }</b></p>`;
          break;
        default:
          if (source === 4 && courseId) {
            // Handle checkout problems
            emailPayload.data = `Checkout problems on course: ${courseId}`;
            emailPayload.body = `<p>A user is not being able to register for the course with the id <b>${courseId}</b> possibly there is a problem with the link that has been sent to him. Link: ${window.location.href}</p>`;
          } else {
            emailPayload.data = `Support - Student 'Other' Feedback for ${
              studentEmail ? studentEmail : studentId
            }`;
            emailPayload.body = `<p>A new support request was opened from <b>${
              studentEmail ? studentEmail : studentId
            }</b></p>`;
          }
          break;
      }
      payload.emailPayload = emailPayload;
    }
    payload.feedback = textValue;
    payload.type = issueSelected.type;
    payload.setLoading = setLoading;
    payload.closeModal = closeModal;
    payload.userEmail = studentEmail;
    switch (issueSelected.type) {
      case 'QUESTION':
        payload.questionId = questionId;
        payload.courseId = courseId;
        sendQuestionFeedback(payload);
        break;
      case 'EXAM':
        payload.examId = examId;
        payload.courseId = courseId;
        sendExamFeedback(payload);
        break;
      default:
        payload.organizationId = organizationData && organizationData.id ? organizationData.id : '';
        payload.studentId = studentId;
        sendStudentFeedback(payload);
        break;
    }
    let hasIntercom = false;
    if (
      organizationData &&
      organizationData.intercom &&
      organizationData.intercom.appId &&
      organizationData.intercom.accessToken
    ) {
      hasIntercom = true;
    }
    if (hasIntercom && issueSelected && issueSelected.type === 'TECHNICAL_ISSUE') {
      sendIntercomMessage({
        type: 'TECHNICAL_ISSUE',
        feedback: textValue,
        email: studentEmail
      });
    }
    deleteNotification();
    handleCatchError(catchedIssue, 'Get help modal');
  };

  const closeModal = () => {
    setTextValue('');
    setIssueSelected(-1);
    window.analytics.page({ url: window.location.href, path: window.location.pathname });
    window.analytics.track('Pageview: ' + window.location.pathname);
    handleOnClose();
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={closeModal}
      closeAfterTransition={false}
    >
      <Fade in={open}>
        <div className={classes.container}>
          <Grid container className={classes.gridContainer}>
            <Grid item xs={6} className={classes.gridItemTitle}>
              Get Help
            </Grid>
            <Grid item xs={6} className={classes.gridItemClose}>
              <X className={classes.closeIcon} onClick={closeModal} />
            </Grid>
          </Grid>

          <Grid className={classes.bodyContainer}>
            <Typography className={classes.textModal}>Issue Submission</Typography>
            <Grid className={classes.inputsGrid}>
              <FormControl variant="standard" className={classes.formControl}>
                <Select
                  variant="standard"
                  onChange={e => handleChange(e)}
                  value={issueSelected.type ? issueSelected.type : -1}
                  className={classes.selectStyle}
                  disableUnderline
                  inputProps={{ className: classes.inputSelect }}
                  IconComponent={ChevronDown}
                  MenuProps={menuProps}
                >
                  <MenuItem
                    dense
                    className={classes.menuItem}
                    value={-1}
                    key={-1}
                    style={{ background: '#FFFFFF', borderBottom: '1px solid #B6C1CC' }}
                  >
                    Choose Type of Issue
                  </MenuItem>
                  {issues &&
                    issues.map((issue, index) => {
                      return (
                        <MenuItem
                          dense
                          className={classes.menuItem}
                          key={index}
                          value={issue.type}
                          style={{ background: '#FFFFFF' }}
                        >
                          {issue.label}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
              <Grid container direction="column">
                <TextareaAutosize
                  className={classes.textArea}
                  value={textValue}
                  onChange={e => handleTextChange(e)}
                  minRows={3}
                  placeholder="Describe the issue"
                  maxLength="1000"
                />
                <Typography className={classes.lengthLabel}>
                  {`${textValue.length}/1000`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid container direction="row" alignItems="center" className={classes.buttonsSave}>
            <Grid item className={classes.cancelButton}>
              <PillButton
                smallRadius
                disabled={loading}
                color="white"
                label="Cancel"
                handleClick={closeModal}
              />
            </Grid>
            <Grid item>
              <PillButton
                smallRadius
                loading={loading}
                label="Submit"
                handleClick={handleSubmitIssue}
                disabled={!textValue || textValue.length === 0 || issueSelected === -1}
              />
            </Grid>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
}

const menuProps = {
  elevation: 2,
  anchorOrigin: { vertical: 50, horizontal: 'left' },
  transformOrigin: { vertical: 'top', horizontal: 'left' },
  MenuListProps: { disablePadding: true }
};

const getTypes = source => {
  if (source === 1) {
    return [
      { type: 'MY_ACCOUNT', label: 'My Account' },
      { type: 'TECHNICAL_ISSUE', label: 'Technical issue' },
      { type: 'OTHER', label: 'Other' }
    ];
  }
  if (source === 2) {
    return [
      { type: 'QUESTION', label: 'Question' },
      { type: 'EXAM', label: 'Exam' },
      { type: 'MY_ACCOUNT', label: 'My Account' },
      { type: 'TECHNICAL_ISSUE', label: 'Technical issue' },
      { type: 'OTHER', label: 'Other' }
    ];
  }
  if (source === 3) {
    return [
      { type: 'QUESTION', label: 'Question' },
      { type: 'MY_ACCOUNT', label: 'My Account' },
      { type: 'TECHNICAL_ISSUE', label: 'Technical issue' },
      { type: 'OTHER', label: 'Other' }
    ];
  }
  if (source === 4) {
    return [{ type: 'OTHER', label: 'Other' }];
  }
};
