import * as type from './actionTypes/examActionTypes';

export const getOpenExams = value => ({
  type: type.GET_OPEN_EXAMS,
  value
});

export const setOpenExams = value => ({
  type: type.SET_OPEN_EXAMS,
  value
});

export const getHistoryExams = payload => ({
  type: type.GET_HISTORY_EXAMS,
  payload
});

export const setHistoryExams = value => ({
  type: type.SET_HISTORY_EXAMS,
  value
});

export const updateHistoryExams = value => ({
  type: type.UPDATE_HISTORY_EXAMS,
  value
});

export const setCurrentExamHistory = value => ({
  type: type.SET_CURRENT_EXAM_HISTORY,
  value
});

export const setLatestExamPerformance = value => ({
  type: type.SET_LATEST_EXAM_PERFORMANCE,
  value
});

export const getExamData = payload => ({
  type: type.GET_EXAM_DATA,
  payload
});

export const setExamData = value => ({
  type: type.SET_EXAM_DATA,
  value
});

export const setExamId = value => ({
  type: type.SET_EXAM_ID,
  value
});

export const actualQuestion = value => ({
  type: type.ACTUAL_QUESTION,
  value
});

export const setActualQuestion = (value, resetFunction, firstQuestion) => ({
  type: type.SET_ACTUAL_QUESTION,
  value,
  resetFunction,
  firstQuestion
});

export const setStackedQuestions = value => ({
  type: type.SET_STACKED_QUESTIONS,
  value
});

export const setExamProgress = value => ({
  type: type.SET_EXAM_PROGRESS,
  value
});

export const getStackedAnswers = value => ({
  type: type.GET_STACKED_ANSWERS,
  value
});

export const setStackedAnswers = value => ({
  type: type.SET_STACKED_ANSWERS,
  value
});

export const examProgress = value => ({
  type: type.EXAM_PROGRESS,
  value
});

export const updateExamProgress = value => ({
  type: type.UPDATE_EXAM_PROGRESS,
  value
});

export const sendExamFeedback = value => ({
  type: type.SEND_EXAM_FEEDBACK,
  value
});

export const loadingOpenExam = value => ({
  type: type.LOADING_OPEN_EXAM,
  value
});

export const loadingHistoryExam = value => ({
  type: type.LOADING_HISTORY_EXAM,
  value
});

export const finished = value => ({
  type: type.FINISHED,
  value
});

export const started = value => ({
  type: type.STARTED,
  value
});

export const examResults = value => ({
  type: type.EXAM_RESULTS,
  value
});

export const getExamResults = value => ({
  type: type.GET_EXAM_RESULTS,
  value
});

export const questionsAndAnswers = value => ({
  type: type.QUESTIONS_AND_ANSWERS,
  value
});

export const preloadedQuestions = value => ({
  type: type.PRELOADED_QUESTIONS,
  value
});

export const previousQuestions = value => ({
  type: type.PREVIOUS_QUESTIONS,
  value
});

export const preloadExamFirstQuestions = payload => ({
  type: type.PRELOAD_EXAM_FIRST_QUESTIONS,
  payload
});

export const preloadedExamFirstQuestions = value => ({
  type: type.PRELOADED_EXAM_FIRST_QUESTIONS,
  value
});

export const generateCertificate = payload => ({
  type: type.GENERATE_CERTIFICATE,
  payload
});

export const setCertificate = value => ({
  type: type.SET_CERTIFICATE,
  value
});

export const preloadAllQuestions = payload => ({
  type: type.PRELOAD_ALL_QUESTIONS,
  payload
});
