export const GET_COURSE_DATA = 'course/GET_COURSE_DATA';
export const SET_COURSE_DATA = 'course/SET_COURSE_DATA';
export const SECTIONS = 'course/SECTIONS';
export const GET_STUDY_PERFORMANCE = 'course/GET_STUDY_PERFORMANCE';
export const SET_STUDY_PERFORMANCE = 'course/SET_STUDY_PERFORMANCE';
export const SET_SELECTED_TAB = 'course/SET_SELECTED_TAB';
export const GET_LOGIN_STREAK = 'course/GET_LOGIN_STREAK';
export const SET_LOGIN_STREAK = 'course/SET_LOGIN_STREAK';
export const GET_STUDY_PROGRESS = 'course/GET_STUDY_PROGRESS';
export const SET_QUESTIONS_ANSWERED = 'course/SET_QUESTIONS_ANSWERED';
export const SET_TIME_STUDIED = 'course/SET_TIME_STUDIED';
export const SET_AVAILABLE_COURSES = 'course/SET_AVAILABLE_COURSES';
export const GET_AVAILABLE_COURSES = 'course/GET_AVAILABLE_COURSES';
export const GET_STUDENT_COURSES = 'course/GET_STUDENT_COURSES';
export const LOADING_AVAILABLE_COURSES = 'course/LOADING_AVAILABLE_COURSES';
export const ERROR_AVAILABLE_COURSES = 'course/ERROR_AVAILABLE_COURSES';
export const GET_TOPIC_DATA = 'course/GET_TOPIC_DATA';
export const SET_TOPIC_DATA = 'course/SET_TOPIC_DATA';
export const LOADING_TOPIC_DATA = 'course/LOADING_TOPIC_DATA';
export const SWITCH_COURSE = 'COURSE/SWITCH_COURSE';
export const VERIFY_COURSE = 'courseHome/VERIFY_COURSE';
export const GET_STRIPE_COURSE_PRODUCT = 'courseHome/GET_STRIPE_COURSE_PRODUCT';
export const SET_STRIPE_COURSE_PRODUCT = 'courseHome/SET_STRIPE_COURSE_PRODUCT';
export const SET_COURSE_FREE_TRIAL = 'SET_COURSE_FREE_TRIAL';
export const UPDATE_COURSE_PERFORMANCE = 'UPDATE_COURSE_PERFORMANCE';
export const CREATE_CHECKOUT_SESSION = 'CREATE_CHECKOUT_SESSION';
export const COURSE_NAME = 'COURSE_NAME';
export const SET_PRECENTAGE_LOADING_SCREEN = 'course/SET_PRECENTAGE_LOADING_SCREEN';
export const SET_LOADING_SCREEN = 'course/SET_LOADING_SCREEN';
export const LOADING_STUDY_MODES = 'LOADING_STUDY_MODES';
export const COURSE_QUESTIONS = 'COURSE_QUESTIONS';
export const COURSE_CONTENTS = 'COURSE_CONTENTS';
export const GENERATE_STRIPE_PAYMENT = 'GENERATE_STRIPE_PAYMENT';
export const GENERATE_STRIPE_PAYMENT_ERROR = 'GENERATE_STRIPE_PAYMENT_ERROR';
export const GET_CREATE_ACCOUNT_INFORMATION = 'GET_CREATE_ACCOUNT_INFORMATION';
export const VALIDATE_EMAIL_AND_GENERATE_CHECKOUT = 'VALIDATE_EMAIL_AND_GENERATE_CHECKOUT';
export const COURSES_AVAILABLE_FOR_PURCHASE = 'COURSES_AVAILABLE_FOR_PURCHASE';