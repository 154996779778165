import { Map } from 'immutable';
import * as t from '../actions/actionTypes/organizationActionTypes';
import * as loginActions from '../actions/actionTypes/loginActionTypes';
import { SET_ORGANIZATION_ID } from '../actions/actionTypes/userActionTypes';

const initialState = Map({
  organizationData: null,
  organizationLogo: null,
  organizationLogoBlobUrl: null,
  organizationFavicon: null,
  loadingOrganizationData: false,
  loadingOrganizationLogo: false,
  organizationId: "",
});

const Organization = function (state = initialState, action) {
  switch (action.type) {
    case t.SET_ORGANIZATION_DATA:
      return state.set('organizationData', action.value);
    case t.LOADING_ORGANIZATION_DATA:
      return state.set('loadingOrganizationData', action.value);
    case t.SET_ORGANIZATION_LOGO:
      return state.set('organizationLogo', action.value);
    case t.SET_ORGANIZATION_LOGO_BLOB_URL:
      return state.set('organizationLogoBlobUrl', action.value);
    case t.SET_ORGANIZATION_FAVICON:
      return state.set('organizationFavicon', action.value);
    case t.LOADING_ORGANIZATION_LOGO:
      return state.set('loadingOrganizationLogo', action.value);
    case SET_ORGANIZATION_ID:
      return state.set('organizationId', action.value);
    case loginActions.SIGN_OUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default Organization;
