const determineOpenStudyMode = notElapsed => {
  // Here are all the questions that are not yet ready to be answered
  let openStudyText;
  let countdown = null;
  if (notElapsed.length > 0) {
    // I ask if there are questions that need to start a countdown.
    const withCountdown = notElapsed.filter(question => question.enableCountdown);
    if (withCountdown && withCountdown.length > 0) {
      withCountdown.sort((a, b) =>
        a.countdownMs > b.countdownMs ? 1 : b.countdownMs > a.countdownMs ? -1 : 0
      );
      const nextQuestion = withCountdown.shift();
      openStudyText = nextQuestion.openStudyText;
      countdown = Date.now() + nextQuestion.countdownMs;
    } else {
      // If they do not have a counter then the next study session is hours away.
      //I order them to know which one has the lowest nextAppearance.
      notElapsed.sort((a, b) =>
        a.nextAppearance > b.nextAppearance ? 1 : b.nextAppearance > a.nextAppearance ? -1 : 0
      );
      // It is possible that there are several questions with the same next appareance
      const nextQuestions = notElapsed.filter(
        question => question.nextAppearance === notElapsed[0].nextAppearance
      );
      // I sort them by text difference
      nextQuestions.sort((a, b) =>
        a.openStudyText > b.openStudyText ? 1 : b.openStudyText > a.openStudyText ? -1 : 0
      );
      const nextQuestion = nextQuestions.shift();
      openStudyText = nextQuestion.openStudyText;
    }
  } else {
    openStudyText = 'No questions';
  }
  const openStudyModeResults = {openStudyText, countdown};
  return openStudyModeResults;
};

export default determineOpenStudyMode;
