import getOrdinalNumber from './getOrdinalSuffix';

const dateMonthDayYearString = date => {
  // Example format "April 4th, 2024"
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const month = new Date(date).getMonth();
  const day = new Date(date).getDate();
  const year = new Date(date).getFullYear();
  const ordinalSuffix = getOrdinalNumber(day);
  return `${months[month]} ${ordinalSuffix}, ${year}`;
};

export default dateMonthDayYearString;
