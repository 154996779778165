import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { PersistGate } from 'redux-persist/integration/react';
import { history, persistor } from './store/ConfigureStore';
import routes from './routes';
import theme from './assets/jss/studentAppTheme';
import './App.css';

const App = props => {
  const {
    // Props
    store,
    // States
    appTheme,
    organizationFavicon,
    userData,
    userOrganizationId,
    // Functions
    loadAppTheme
  } = props;
  const [themeProps, setThemeProps] = useState({
    ACCENT:
      localStorage && localStorage.getItem('accentColor')
        ? localStorage.getItem('accentColor')
        : '#E5AA25',
    BACKGROUND_AND_TEXT: '#DCDCDC'
  });
  let favicon = organizationFavicon
    ? organizationFavicon
    : localStorage && localStorage.getItem('Favicon')
    ? localStorage.getItem('Favicon')
    : null;

  useEffect(() => {
    if (userData && userOrganizationId) loadAppTheme(userOrganizationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userOrganizationId, userData]);

  useEffect(() => {
    if (appTheme) {
      const themePropsObject = {};
      appTheme.forEach(colorSetting => {
        const section =
          colorSetting && colorSetting.section ? colorSetting.section : 'BACKGROUND_AND_TEXT';
        const customDefault = section === 'ACCENT' ? '#E5AA25' : '#052D30';
        themePropsObject[`${section}`] = colorSetting.presetActive
          ? colorSetting.presetColor
          : colorSetting.customColor || customDefault;
      });
      setThemeProps(themePropsObject);
    }
  }, [appTheme, userOrganizationId]);

  return (
    <Provider store={store}>
      {favicon && (
        <Helmet>
          <link id="favicon" rel="icon" type="image/png" href={favicon} sizes="16x16" />
        </Helmet>
      )}
      <Router history={history}>
        <PersistGate persistor={persistor}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme(themeProps)}>
              <div className="wrap">{routes}</div>
            </ThemeProvider>
          </StyledEngineProvider>
        </PersistGate>
      </Router>
    </Provider>
  );
};

export default App;
