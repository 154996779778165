import React from 'react';
import { Map } from 'immutable';
import * as t from '../actions/actionTypes/errorHandlerActionTypes';
import { ERROR_TYPES } from '../utils/const';
import * as loginActions from '../actions/actionTypes/loginActionTypes';
import ContactUsView from '../components/Common/Components/contactUsView';

const initialState = Map({
  localNotifications: [],
  totalNotifications: 0,
  duration: 4500
});

const ErrorHandler = function (state = initialState, action) {
  switch (action.type) {
    case t.SET_NOTIFICATION:
      const aux = state.get('localNotifications');
      if (
        action.value &&
        action.value.message &&
        action.value.message.toLowerCase().includes('contact us')
      ) {
        aux.push({
          message: <ContactUsView />,
          severity: 'error'
        });
      } else {
        if (action.value.severity && action.value.message) {
          aux.push(action.value); // custom notification
        } else {
          const error = ERROR_TYPES.find(error => error.code === action.value.message);
          if (error) aux.push({ message: error.name, severity: 'error' });
        }
      }
      return state.set('localNotifications', aux).set('totalNotifications', aux.length);
    case t.DELETE_NOTIFCATION:
      return state.set('localNotifications', []).set('totalNotifications', 0);
    case loginActions.SIGN_OUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default ErrorHandler;
