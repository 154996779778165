export const createQuestionFeedback = /* GraphQL */ `
  mutation createQuestionFeedback(
    $questionId: ID!
    $courseId: ID!
    $feedback: [QuestionFeedbackInput]
  ) {
    updateQuestion(input: { id: $questionId, courseID: $courseId, feedbacks: $feedback }) {
      id
      courseID
      feedbacks {
        status
      }
    }
  }
`;

export const deleteStudentAnswer = /* GraphQL */ `
  mutation deleteStudentAnswer($answerId: ID!, $studentId: ID!) {
    deleteStudentAnswer(input: { id: $answerId, studentID: $studentId }) {
      id
    }
  }
`;
