import * as type from './actionTypes/errorHandlerActionTypes';

export const setNotification = value => ({
  type: type.SET_NOTIFICATION,
  value
});

export const deleteNotification = () => ({
  type: type.DELETE_NOTIFCATION
});

export const handleCatchError = (error, message) => ({
  type: type.HANDLE_CATCH_ERROR,
  payload: { error, message }
});
