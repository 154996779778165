import { connect } from 'react-redux';
import View from '../App';
// States
import { getAppTheme } from '../selectors/appSelectors';
import { getOrganizationFavicon } from '../selectors/organizationSelector';
import { getUserData, getUserOrganizationId } from '../selectors/userSelectors';
// Function
import { loadAppTheme } from '../actions/appActions';

const mapStateToProps = (state) => ({
  userData: getUserData(state),
  userOrganizationId: getUserOrganizationId(state),
  appTheme: getAppTheme(state),
  organizationFavicon: getOrganizationFavicon(state)
});

const mapDispatchToProps = dispatch => {
  return {
    loadAppTheme: value => dispatch(loadAppTheme(value)),
  };
};

const Container = connect(mapStateToProps, mapDispatchToProps)(View);

export default Container;
