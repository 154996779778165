export default function getAWSConfigObject() {
  return {
    credentials: {
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY_ID,
      region: process.env.REACT_APP_AWS_REGION
    },
    region: process.env.REACT_APP_AWS_REGION
  };
}
