import React from 'react';
import Responsive from 'react-responsive';
import awsmobile from '../../aws-exports';
const REGEX = /.*-(\w+)/;

export const region = awsmobile.aws_project_region;

export const UserPoolId = awsmobile.aws_user_pools_id;
export const ClientId = awsmobile.aws_user_pools_web_client_id;
// envName serfe - stage - production
export const envName = awsmobile.aws_content_delivery_bucket.match(REGEX)[1];

export const Desktop = props => <Responsive {...props} minWidth={600} />;
export const Tablet = props => <Responsive {...props} minWidth={768} maxWidth={991} />;
export const Mobile = props => <Responsive {...props} maxWidth={599} />;

// ROLES
export const roles = ['ADMIN', 'CREATOR', 'STUDENT', 'AUTHOR'];
export const managePeopleRoles = ['ADMIN', 'CREATOR', 'OWNER', 'SYSTEM'];
export const ORGANIZATION_SETTINGS_ROLES = ['OWNER', 'ADMIN'];

export const ownerRole = 'OWNER';
export const administratorRole = 'ADMIN';
export const authorRole = 'AUTHOR';
export const peopleRole = 'PEOPLE';
export const studentRole = 'STUDENT';

export const defaultTheme = [
  { customColor: '#052d30', presetActive: true, presetColor: '#052d30', section: null },
  { customColor: '#E5AA25', presetActive: false, presetColor: '#E5AA25', section: 'ACCENT' }
];
