export const GET_OPEN_EXAMS = 'course/GET_OPEN_EXAMS';
export const SET_OPEN_EXAMS = 'course/SET_OPEN_EXAMS';
export const GET_HISTORY_EXAMS = 'course/GET_HISTORY_EXAMS';
export const SET_HISTORY_EXAMS = 'course/SET_HISTORY_EXAMS';
export const UPDATE_HISTORY_EXAMS = 'course/UPDATE_HISTORY_EXAMS';
export const SET_CURRENT_EXAM_HISTORY = 'course/SET_CURRENT_EXAM_HISTORY';
export const SET_LATEST_EXAM_PERFORMANCE = 'course/SET_LATEST_EXAM_PERFORMANCE';
export const GET_EXAM_DATA = 'examSession/GET_EXAM_DATA';
export const SET_EXAM_DATA = 'examSession/SET_EXAM_DATA';
export const SET_EXAM_ID = 'examSession/SET_EXAM_ID';
export const SET_STACKED_QUESTIONS = 'examSession/SET_STACKED_QUESTIONS';
export const SET_ACTUAL_QUESTION = 'examSession/SET_ACTUAL_QUESTION';
export const ACTUAL_QUESTION = 'examSession/ACTUAL_QUESTION';
export const GET_STACKED_ANSWERS = 'examSession/GET_STACKED_ANSWERS';
export const SET_STACKED_ANSWERS = 'examSession/SET_STACKED_ANSWERS';
export const GET_EXAM_TIME = 'examSession/GET_EXAM_TIME';
export const SET_EXAM_TIME = 'examSession/SET_EXAM_TIME';
export const LOADING_ACTUAL_QUESTION = 'studySessionLeftView/LOADING_ACTUAL_QUESTION';
export const SET_EXAM_PROGRESS = 'examSession/SET_EXAM_PROGRESS';
export const EXAM_PROGRESS = 'examSession/EXAM_PROGRESS';
export const UPDATE_EXAM_PROGRESS = 'examSession/UPDATE_EXAM_PROGRESS';
export const SEND_EXAM_FEEDBACK = 'getHelp/SEND_EXAM_FEEDBACK';
export const SEND_QUESTION_EXAM_FEEDBACK = 'getHelp/SEND_QUESTION_EXAM_FEEDBACK';
export const LOADING_OPEN_EXAM = 'course/LOADING_OPEN_EXAM';
export const LOADING_HISTORY_EXAM = 'course/LOADING_HISTORY_EXAM';
export const FINISHED = 'examSession/FINISHED';
export const STARTED = 'examSession/STARTED';
export const EXAM_RESULTS = 'examSession/EXAM_RESULTS';
export const GET_EXAM_RESULTS = 'examSession/GET_EXAM_RESULTS';
export const QUESTIONS_AND_ANSWERS = 'examSession/QUESTIONS_AND_ANSWERS';
export const PRELOADED_QUESTIONS = 'examSession/PRELOADED_QUESTIONS';
export const PREVIOUS_QUESTIONS = 'examSession/PREVIOUS_QUESTIONS';
export const PRELOAD_EXAM_FIRST_QUESTIONS = 'examSession/PRELOAD_EXAM_FIRST_QUESTIONS';
export const PRELOADED_EXAM_FIRST_QUESTIONS = 'examSession/PRELOADED_EXAM_FIRST_QUESTIONS';
export const GENERATE_CERTIFICATE = 'examSession/GENERATE_CERTIFICATE';
export const SET_CERTIFICATE = 'examSession/SET_CERTIFICATE';
export const PRELOAD_ALL_QUESTIONS = 'examSession/PRELOAD_ALL_QUESTIONS';
