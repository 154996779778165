import React, { useState } from 'react';
// Libraries
import { Grid } from '@mui/material';
import GetHelpModal from '../../../containers/Modal/getHelpModalContainer';

function ContactUsView() {
  const [openGetHelpModal, setOpenGetHelpModal] = useState(false);

  return (
    <>
      <Grid>
        Unable to complete request. Please{' '}
        <span
          role="button"
          style={{ cursor: 'pointer', textDecoration: 'underline' }}
          onClick={() => {
            setOpenGetHelpModal(true);
          }}
        >
          contact us
        </span>{' '}
        for support.
      </Grid>
      {/*GET HELP MODAL*/}
      {openGetHelpModal && (
        <GetHelpModal
          open={openGetHelpModal}
          handleOnClose={() => setOpenGetHelpModal(false)}
          source={1}
          preselectedIssue={{ type: 'TECHNICAL_ISSUE', label: 'Technical issue' }}
        />
      )}
    </>
  );
}

export default ContactUsView;
