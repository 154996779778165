import { call, takeLatest, all, put, select } from 'redux-saga/effects';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import * as types from '../actions/actionTypes/contentActionTypes';
// Actions
import * as contentActions from '../actions/contentActions';
import * as notificationsActions from '../actions/errorHandlerActions';
import * as userActions from '../actions/userActions';
import { updateCoursePerformance } from '../actions/courseActions';
import { startLoading, stopLoading } from '../actions/loaderHandlerActions';
// Selectors
import {
  getSelectedTopicId,
  getSelectedSectionId,
  getLessonsReadSelector,
  getStudentDataReducer
} from '../selectors/userSelectors';
import {
  getCourseDataReducer,
  getSectionsReducer,
  getTopicDataReducer
} from '../selectors/Course/courseSelector';
import { getLessonSessionReducer } from '../selectors/contentSelectors';
import { getOrganizationDataReducer } from '../selectors/organizationSelector';
// Queries
import * as contentQueries from '../graphql/queries/contentQueries';
// Mutations
import {
  markLessonAsReadMutation,
  stdUpdateStudentsStatsMutation
} from '../graphql/mutationsContent';
// External files
import GraphOp from '../sagas/common/GraphOp';
import convertHourToLocal from '../utils/convertHourToLocal';
import dateMonthDayYearString from '../utils/dateMonthDayYearString';

// SAGA FUNCTIONS
function* startLessonSessionSagas(action) {
  try {
    const { contentId, redirect, name } = action.payload;
    const lessonInfo = {};
    lessonInfo.id = contentId;
    lessonInfo.name = name;
    lessonInfo.timestamp = new Date();
    yield all([put(contentActions.lessonSession(lessonInfo))]);
    if (redirect) redirect();
  } catch (err) {
    yield put(notificationsActions.setNotification(err));
    yield put(notificationsActions.handleCatchError(err, 'startLessonSessionSagas'));
  }
}

function* getContainerDataSagas(action) {
  try {
    const [courseData] = yield all([
      select(getCourseDataReducer),
      put(contentActions.loadingContainer(true)),
      put(startLoading())
    ]);
    let { courseId, selectedTopicId, containerIndex } = action.value;
    if (!courseId && courseData && courseData.id) courseId = courseData.id;
    // Info about content container
    if (courseId && selectedTopicId) {
      const response = yield GraphOp(contentQueries.stdGetTopicContainerMediaURLQuerie, {
        courseId,
        topicId: selectedTopicId,
        containerIndex
      });
      let containersMediaURL =
        response &&
        response.data &&
        response.data.getTopic &&
        response.data.getTopic.contentContainers &&
        response.data.getTopic.contentContainers.items
          ? response.data.getTopic.contentContainers.items
          : [];
      if (containersMediaURL && containersMediaURL.length > 0) {
        const containerInfo = containersMediaURL.shift();
        const containerId = containerInfo && containerInfo.id ? containerInfo.id : null;
        let currentContent = [];
        let hasVersions = false;
        let currentVersion = null;
        if (
          containerInfo &&
          containerInfo.actualVersion &&
          containerInfo.versions &&
          containerInfo.versions.length > 0
        ) {
          currentVersion = containerInfo.actualVersion;
          hasVersions = true;
        }
        // If no version exists, I show the current published content.
        if (
          !currentVersion &&
          containerInfo &&
          containerInfo.contents &&
          containerInfo.contents.length > 0
        ) {
          let aux = containerInfo.contents;
          if (aux && aux.length > 0) {
            aux = aux.sort((a, b) => (a.order > b.order ? 1 : -1));
            currentContent = [...aux];
          }
        } else {
          // There is a published version
          if (containerInfo && containerInfo.versions && containerInfo.versions.length > 0) {
            const thisVersion = containerInfo.versions.find(
              item =>
                item && item.id === currentVersion && item.contents && item.contents.length > 0
            );
            if (thisVersion && thisVersion.contents) {
              let aux = thisVersion.contents;
              aux = aux.sort((a, b) => (a.order > b.order ? 1 : -1));
              currentContent = [...aux];
            }
          }
        }
        let container = { id: containerId, contents: currentContent };
        let fileId;
        let fileName;
        let fileKey;
        const arrayOfCalls = [];
        //Get files data
        if (container && container.contents && container.contents.length > 0) {
          container.contents.forEach(content => {
            if (content && content.fileID) {
              fileId = content.fileID;
              // If there are versions, we should check if the content is visible.
              if (hasVersions) {
                if (content.published) {
                  arrayOfCalls.push(
                    call(
                      [API, 'graphql'],
                      graphqlOperation(contentQueries.stdGetFileDataQuerie, {
                        courseId,
                        fileId
                      })
                    )
                  );
                }
              } else {
                arrayOfCalls.push(
                  call(
                    [API, 'graphql'],
                    graphqlOperation(contentQueries.stdGetFileDataQuerie, {
                      courseId,
                      fileId
                    })
                  )
                );
              }
            }
          });
        }
        let arrayOfFilesData = [];
        if (arrayOfCalls && arrayOfCalls.length > 0) arrayOfFilesData = yield all(arrayOfCalls);
        const arrayOfAwsCalls = [];
        // Get aws link fot each file
        if (
          container &&
          container.contents &&
          container.contents.length > 0 &&
          arrayOfFilesData.length > 0
        ) {
          container.contents.forEach(content => {
            let fileData = arrayOfFilesData.find(
              item =>
                item &&
                item.data &&
                item.data.getCourseFile &&
                item.data.getCourseFile.id === content.fileID
            );
            if (fileData && fileData.data && fileData.data.getCourseFile) {
              content.file = fileData.data.getCourseFile;
              fileId = fileData.data.getCourseFile.id;
              fileName = fileData.data.getCourseFile.name;
              fileKey = fileData.data.getCourseFile.fileKey;
              let awsLink = `${fileKey}/${fileId}_${fileName}`;
              arrayOfAwsCalls.push(call([Storage, 'get'], awsLink));
            }
          });
        }
        // Remove contents with no files (this happend when the db is corrupted)
        container.contents = container.contents.filter(content => content && content.file != null);
        let arrayOfLinks = [];
        if (arrayOfAwsCalls && arrayOfAwsCalls.length > 0) {
          arrayOfLinks = yield all(arrayOfAwsCalls);
        }
        if (
          arrayOfLinks &&
          arrayOfLinks.length > 0 &&
          container &&
          container.contents &&
          container.contents.length > 0
        ) {
          const mappedFiles = [];
          container.contents.forEach(f => {
            if (f && f.file && f.file.id) {
              const thisFileLik = arrayOfLinks.find(link => link && link.includes(f.file.id));
              if (thisFileLik) {
                f.file.awsLink = thisFileLik;
                mappedFiles.push(f);
              }
            }
          });
          if (mappedFiles.length > 0) container.contents = mappedFiles;
        }
        if (container && container.contents && container.contents.length > 0) {
          yield put(contentActions.setContainer(container));
        } else {
          yield put(notificationsActions.handleCatchError('Empty lesson'));
        }
      }
    }
    yield all([put(contentActions.loadingContainer(false)), put(stopLoading())]);
  } catch (err) {
    yield all([
      put(contentActions.loadingContainer(false)),
      put(notificationsActions.setNotification(err)),
      put(stopLoading()),
      put(notificationsActions.handleCatchError(err, 'getContainerDataSagas'))
    ]);
  }
}

function* getNextTopicContentDataSagas() {
  try {
    const [sections, selectedTopicId, selecteSectionId] = yield all([
      select(getSectionsReducer),
      select(getSelectedTopicId),
      select(getSelectedSectionId)
    ]);
    if (sections && sections.length > 0 && selectedTopicId && selecteSectionId) {
      const actualSection = sections.find(section => section.id === selecteSectionId);
      if (actualSection) {
        const actualTopicIndex = actualSection.topics.findIndex(
          topic => topic && topic.id === selectedTopicId
        );
        //if the topic index is less than topics length it means that is a next topic
        if (actualTopicIndex < actualSection.topics.length) {
          yield put(contentActions.setNextTopicContent(actualSection.topics[actualTopicIndex + 1]));
        } else yield put(contentActions.setNextTopicContent(null));
      }
    }
  } catch (err) {
    yield put(notificationsActions.setNotification(err));
    yield put(notificationsActions.handleCatchError(err, 'getNextTopicContentDataSagas'));
  }
}

function* markReadSagas(action) {
  try {
    const [lessonsReadSelector, studentData, org] = yield all([
      select(getLessonsReadSelector),
      select(getStudentDataReducer),
      select(getOrganizationDataReducer)
    ]);
    const { lessonId, markAsRead } = action.value;
    const organizationId = org && org.id ? org.id : null;
    const studentId = studentData && studentData.id ? studentData.id : null;
    let updatedArray =
      lessonsReadSelector && lessonsReadSelector.length > 0 ? [...lessonsReadSelector] : [];
    // See if it is already marked as read
    const isInclued = updatedArray && updatedArray.length > 0 && updatedArray.includes(lessonId);
    if (markAsRead) {
      // If it is not found, we add it
      if (!isInclued) updatedArray.push(lessonId);
    } else {
      // If found we map again without this element
      if (isInclued) updatedArray = updatedArray.filter(item => item !== lessonId);
    }
    if (!updatedArray || updatedArray.length === 0) updatedArray = [];
    if (organizationId && studentId) {
      // Perform mutation
      yield GraphOp(markLessonAsReadMutation, {
        organizationId,
        studentId,
        lessonsRead: updatedArray
      });
    }
    // Update reducer
    yield all([put(userActions.lessonsRead(updatedArray))]);
    if (markAsRead) window.analytics.track('Click to mark lesson as read');
    else window.analytics.track('Click to mark lesson as unread');
  } catch (err) {
    yield all([
      put(notificationsActions.setNotification(err)),
      put(notificationsActions.handleCatchError(err, 'markReadSagas'))
    ]);
  }
}

function* exitLessonSessionSagas(action) {
  try {
    const [lessonInfo] = yield all([select(getLessonSessionReducer), put(startLoading())]);
    const { redirect, lessonReaded } = action.payload;
    const timestamp = lessonInfo && lessonInfo.timestamp ? lessonInfo.timestamp : null;
    if (timestamp) {
      // Calculate the difference in milliseconds from the time the question was loaded to the time it was answered.
      let now = new Date();
      const diffBetweenTimestamps = now - timestamp;
      // This is the time you spent studying this question, so I am adding it to the time studied for both this month and this week.
      // I get the array with the information about the number of questions answered during this month and this week.
      const payload = { timestamp: diffBetweenTimestamps, redirect };
      yield all([put(updateCoursePerformance(payload))]);
    } else {
      if (redirect) redirect();
    }
    yield all([
      put(contentActions.setContainer([])),
      put(contentActions.trackStudentStats({ lessonReaded })),
      put(contentActions.lessonSession(null)),
      put(stopLoading())
    ]);
  } catch (err) {
    yield all([
      put(notificationsActions.setNotification(err)),
      put(notificationsActions.handleCatchError(err, 'exitLessonSessionSagas')),
      put(stopLoading())
    ]);
  }
}

function* trackStudentStatsSagas(action) {
  try {
    // Data of the reducer and payload
    const [courseData, studentData, contentData] = yield all([
      select(getCourseDataReducer),
      select(getStudentDataReducer),
      select(getTopicDataReducer)
    ]);
    const {
      // Lessons handle
      lessonReaded,
      // Question set handle
      questionSetTotalQuestionsOk,
      questionSetTotalQuestions
    } = action.payload;
    // Constant assignation
    const courseId = courseData && courseData.id ? courseData.id : null;
    const studentID = studentData && studentData.id ? studentData.id : null;
    let now = new Date();
    // Session info
    const totalQuestionsOk = questionSetTotalQuestionsOk ? questionSetTotalQuestionsOk : 0;
    const totalQuestions = questionSetTotalQuestions ? questionSetTotalQuestions : 0;
    const contentId = contentData && contentData.id ? contentData.id : null;
    // Get local storage information
    const storedTime = localStorage.getItem('trackingTimeStartedInMs');
    const day = localStorage.getItem('trackingToday');
    const markedDone = lessonReaded ? true : false;
    // Time consumed during this session
    let timeSpent;
    if (storedTime) {
      // Convert the retrieved date and time to number
      const startTime = parseInt(storedTime, 10);
      // Get current date and time in milliseconds
      const currentTime = now.getTime();
      // Get the difference in milliseconds
      timeSpent = currentTime - startTime;
    }
    // In case we need to start a new data we have to know what time we are saving it
    const timeStarted = convertHourToLocal(now);
    // Get the information of this topic from dynamo
    let contentType;
    let studentStats = [];
    if (contentId && courseId) {
      const response = yield GraphOp(contentQueries.stdGetContentStatsQuerie, {
        courseId,
        contentId
      });
      if (response && response.data && response.data.getTopic) {
        studentStats =
          response.data.getTopic.studentStats && response.data.getTopic.studentStats.length > 0
            ? response.data.getTopic.studentStats
            : [];
        contentType = response.data.getTopic.type ? response.data.getTopic.type : '';
      }
      if (studentStats && studentStats.length > 0) {
        // Handling to update
        let statsAux = [];
        const studentStatsExists = studentStats.find(
          item => item && item.studentID && item.studentID === studentID
        );
        if (studentStatsExists) {
          // We need to update this student's information.
          studentStats.forEach(item => {
            if (item && item.studentID) {
              if (item.studentID === studentID) {
                // See if there is a tracking for this content id during this day.
                const updatedInfo = [];
                let thisContentAndDay = -1;
                if (item.sessionInfo && item.sessionInfo.length > 0) {
                  thisContentAndDay = item.sessionInfo.findIndex(
                    i => i && i.contentId === contentId && i.day === day
                  );
                }
                // If an index was found it means that we will have to update today's array.
                if (thisContentAndDay !== -1) {
                  item.sessionInfo.forEach((session, index) => {
                    if (index === thisContentAndDay) {
                      // We increase the time studied
                      session.timeSpent = session.timeSpent + timeSpent;
                      if (contentType === 'LESSON') {
                        session.markedDone = markedDone;
                      }
                      if (contentType === 'QUESTION_SET') {
                        session.totalQuestions = session.totalQuestions + totalQuestions;
                        session.totalQuestionsOk = session.totalQuestionsOk + totalQuestionsOk;
                      }
                    }
                    // Store on the array
                    updatedInfo.push(session);
                  });
                } else {
                  // Add a new element to the array
                  const sessionInfo = {
                    contentId,
                    day,
                    markedDone,
                    timeSpent,
                    timeStarted,
                    totalQuestions,
                    totalQuestionsOk
                  };
                  // Copy the existing ones
                  if (item.sessionInfo && item.sessionInfo.length > 0) {
                    item.sessionInfo.forEach(i => {
                      if (i && i.contentId) updatedInfo.push(i);
                    });
                  }
                  updatedInfo.push(sessionInfo);
                }
                // Final assignment of the student with modified sessions
                statsAux.push({ studentID, sessionInfo: updatedInfo });
              } else {
                // If it is another student, we leave it unmodified
                statsAux.push(item);
              }
            }
          });
        } else {
          // We have no tracked info yet for this student.
          statsAux = [...studentStats];
          const sessionInfo = [
            {
              contentId,
              day,
              markedDone,
              timeSpent,
              timeStarted,
              totalQuestions,
              totalQuestionsOk
            }
          ];
          statsAux.push({ studentID, sessionInfo });
        }
        // Handle mutation
        yield GraphOp(stdUpdateStudentsStatsMutation, {
          contentId,
          courseId,
          studentStats: statsAux
        });
      } else {
        // If there are no stats it means that this is the first student to consume this content.
        const sessionInfo = [
          {
            contentId,
            day,
            markedDone,
            timeSpent,
            timeStarted,
            totalQuestions,
            totalQuestionsOk
          }
        ];
        studentStats.push({ studentID, sessionInfo });
        // Handle mutation
        yield GraphOp(stdUpdateStudentsStatsMutation, {
          contentId,
          courseId,
          studentStats
        });
      }
    }
    // Clean storage
    localStorage.removeItem('trackingToday');
    localStorage.removeItem('trackingTimeStartedInMs');
    // We track the data again since we are in new content
    now = new Date();
    const today = dateMonthDayYearString(now);
    localStorage.setItem('trackingToday', today);
    localStorage.setItem('trackingTimeStartedInMs', now.getTime());
  } catch (err) {
    yield all([put(notificationsActions.handleCatchError(err, 'trackStudentStatsSagas'))]);
  }
}

// WATCHERS
function* getContainerDataWatcher() {
  yield takeLatest(types.GET_CONTAINER, getContainerDataSagas);
}

function* getNextTopicContentDataWatcher() {
  yield takeLatest(types.GET_NEXT_TOPIC_CONTENT, getNextTopicContentDataSagas);
}

function* markReadWatcher() {
  yield takeLatest(types.MARK_READ, markReadSagas);
}

function* startLessonSessionWatcher() {
  yield takeLatest(types.START_LESSON_SESSION, startLessonSessionSagas);
}

function* exitLessonSessionWatcher() {
  yield takeLatest(types.EXIT_LESSON_SESSION, exitLessonSessionSagas);
}

function* trackStudentStatsWatcher() {
  yield takeLatest(types.TRACK_STUDENT_STATS, trackStudentStatsSagas);
}

export default function* sagas() {
  yield all([
    getContainerDataWatcher(),
    getNextTopicContentDataWatcher(),
    markReadWatcher(),
    startLessonSessionWatcher(),
    exitLessonSessionWatcher(),
    trackStudentStatsWatcher()
  ]);
}
