import getOrdinalNumber from './getOrdinalSuffix';
import convertHourToLocal from './convertHourToLocal';

const dateCompleteString = date => {
  // Example format "March 25th at 10:30 AM ART"
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const month = new Date(date).getMonth();
  const day = new Date(date).getDate();
  const ordinalSuffix = getOrdinalNumber(day);
  const hour = convertHourToLocal(date);
  return `${months[month]} ${ordinalSuffix} at ${hour}`;
};

export default dateCompleteString;
