export const stdGetAppThemeSettingsQuerie = /* GraphQL */ `
  query stdGetAppThemeSettingsQuerie($organizationId: ID!) {
    getOrganization(id: $organizationId) {
      appSetting {
        customColor
        presetActive
        presetColor
        section
      }
    }
  }
`;
