const emailTemplate = ({ title, body, footer, backgroundOrg, orgLogo }) => {
  const backgroundColor = backgroundOrg ? backgroundOrg : '#000000';
  const template = /*html*/ `
  <html>
    <head>
     
      <style>
        * {
            font-size: 1em;
        }
        .organization-logo{
          height: 70px;
          width: 70px;
        }
        .header {
        }
        .text-body {
        }
        .footer{
        }

        .text {
          font-family: "Open Sans", "Helvetica Neue", sans-serif;
          font-size: 1em;
        }
        .text-header {
          font-size: 2em;
          font-weight: bold;
          color: ${backgroundColor};
        }
        .text-color{
          color: ${backgroundColor};
        }
        .text-course{
          font-weight: bold;
        }
        .text-password{
          font-weight: bold;
        }
        a{
          color: ${backgroundColor};
        }   

      </style>
    </head>
   
    <body>
      <div class="header">
        ${orgLogo ? `<img class="organization-logo" src='${orgLogo}'/>` : ''}
        <br/><br/>
        ${title ? `<div class='text text-header'>${title}</div>` : ''}
      </div>
      ${body ? `<div class="text-body text">${body}</div>` : ''}
      ${footer ? `<div class="footer text">${footer}</div>` : ''}
    </body>
  </html> 
  `;
  return template;
};

export default emailTemplate;
