import { fork, all } from 'redux-saga/effects';
import appSagas from './appSagas';
import courseSagas from './courseSagas';
import organizationSagas from './organizationSagas';
import examSagas from './examSagas';
import UserSagas from './userSagas';
import loginSagas from './loginSagas';
import myAccountSagas from './myAccountSagas';
import studySession from './studySessionSagas';
import contentSagas from './contentSagas';
import handleCatchErrorSagas from './handleCatchErrorSagas';

export default function* startForman() {
  yield all([
    fork(handleCatchErrorSagas),
    fork(appSagas),
    fork(courseSagas),
    fork(organizationSagas),
    fork(examSagas),
    fork(loginSagas),
    fork(UserSagas),
    fork(myAccountSagas),
    fork(studySession),
    fork(contentSagas)
  ]);
}
