// AWS services
import { API, graphqlOperation, Storage, Cache } from 'aws-amplify';
import { call, put, takeLatest, all, select } from 'redux-saga/effects';
// Actions
import * as types from '../actions/actionTypes/organizationActionTypes';
import * as organizationActions from '../actions/organizationActions';
import * as notificationsActions from '../actions/errorHandlerActions';
import { setAppTheme } from '../actions/appActions';
// Selectors
import {
  getUserData,
  getUserOrganizationId,
  getStudentDataReducer
} from '../selectors/userSelectors';
import { getOrganizationDataReducer } from '../selectors/organizationSelector';
import { getCourseDataReducer } from '../selectors/Course/courseSelector';
// Queries
import * as query from '../graphql/queries/organizationQueries';
import {
  stdGetOrganizationDomainQuerie,
  sendEmailSES
} from '../graphql/queries/callsToLambdaFunctions';
// External files
import supportEmailTemplate from '../utils/supportEmailTemplate';
import dateCompleteString from '../utils/dateAndHourCompleteString';
import GraphOp from '../sagas/common/GraphOp';
import { envName, defaultTheme } from '../../src/components/Common/const';

function* getOrganizationDataSagas(action) {
  try {
    let { organizationId, minimunData } = action.payload;
    if (!organizationId) organizationId = yield select(getUserOrganizationId);
    if (organizationId) {
      let response;
      if (minimunData) {
        response = yield GraphOp(query.stdGetOrganizationMinimunDataQuerie, {
          id: organizationId
        });
      } else {
        response = yield GraphOp(query.stdGetOrganizationDataQuerie, {
          id: organizationId
        });
      }
      const organizationData =
        response && response.data && response.data.getOrganization
          ? response.data.getOrganization
          : null;
      if (minimunData && organizationData) {
        const studentAppTheme = organizationData.appSetting
          ? organizationData.appSetting
          : defaultTheme;
        delete organizationData.appSetting;
        yield put(setAppTheme(studentAppTheme));
      } else {
        if (organizationData && organizationData.termsAndConditions) {
          if (organizationData.termsAndConditions.isFile) {
            const filePath = `media/${organizationId}/terms_and_conditions/TermsAndConditions`;
            const fileUrl = yield call([Storage, 'get'], filePath);
            if (fileUrl) organizationData.termsAndConditions.url = fileUrl;
          } else {
            if (organizationData.termsAndConditions.url) {
              const url = organizationData.termsAndConditions.url;
              if (!url.startsWith('http://') && !url.startsWith('https://')) {
                organizationData.termsAndConditions.url = `//${url}`;
              }
            }
          }
        }
        if (organizationData && organizationData.privacyPolicy) {
          if (organizationData.privacyPolicy.isFile) {
            const filePath = `media/${organizationId}/privacy_policy/PrivacyPolicy`;
            const fileUrl = yield call([Storage, 'get'], filePath);
            if (fileUrl) organizationData.privacyPolicy.url = fileUrl;
          } else {
            if (organizationData.privacyPolicy.url) {
              const url = organizationData.privacyPolicy.url;
              if (!url.startsWith('http://') && !url.startsWith('https://')) {
                organizationData.privacyPolicy.url = `//${url}`;
              }
            }
          }
        }
      }
      yield put(organizationActions.setOrganizationData(organizationData));
    }
  } catch (err) {
    yield put(notificationsActions.handleCatchError(err, 'getOrganizationDataSagas'));
  }
}

function* getOrganizationLogoSagas(action) {
  try {
    let [organizationId] = yield all([
      select(getUserOrganizationId),
      put(organizationActions.loadingOrganizationLogo(true))
    ]);
    let orgId = organizationId;
    if (!orgId && action && action.value && action.value.organizationId) {
      orgId = action.value.organizationId;
    }
    if (orgId) {
      // orgLogo is the name of the organization logo image
      // can be found in S3 management in media/${organizationId}/profile_images/orgLogo
      // expiration time 70 minutes
      let logoKey = yield call([Storage, 'get'], `media/${orgId}/profile_images/orgLogo`, {
        expires: 4200
      });
      //url logoKey is the url of the image, i will set it with expired of 1 hour with Cache Amplify
      if (logoKey) {
        const actualDate = new Date();
        localStorage.setItem(
          'TTL-logo',
          actualDate.setMinutes(actualDate.getMinutes() + 60) / 1000
        );
        yield Cache.setItem('organizationLogo', logoKey, {
          expires: actualDate.setMinutes(actualDate.getMinutes() + 60) / 1000
        });

        // Fetch the image and convert it to a blob
        const response = yield fetch(logoKey);
        const blob = yield response.blob();

        // Convert the blob to an object URL
        const blobUrl = URL.createObjectURL(blob);

        // Store the blob URL in Redux
        yield put(organizationActions.setOrganizationLogo(logoKey));
        // blob url for certificate
        yield put(organizationActions.setOrganizationLogoBlobUrl(blobUrl));
      }
      let faviconKey = yield call([Storage, 'get'], `media/${orgId}/profile_images/favicon`);
      const faviconResponse = yield fetch(faviconKey, { method: 'GET' });
      if (faviconResponse && faviconResponse.status && faviconResponse.status === 404) {
        faviconKey = null;
      } else {
        localStorage.setItem('Favicon', faviconKey);
      }
      yield all([put(organizationActions.setOrganizationFavicon(faviconKey))]);
    }
    yield all([put(organizationActions.loadingOrganizationLogo(false))]);
  } catch (err) {
    yield all([
      put(organizationActions.setOrganizationLogo(null)),
      put(organizationActions.loadingOrganizationLogo(false))
    ]);
    yield put(notificationsActions.handleCatchError(err, 'getOrganizationLogoSagas'));
  }
}

function* getOrganizationBundlesSagas(action) {
  try {
    const [userData] = yield all([select(getUserData)]);
    //We get the bundles that can be offered in the organization
    if (action.value.organizationData && action.value.organizationData.stripeOnboardingComplete) {
      let bundles = yield call(
        [API, 'graphql'],
        graphqlOperation(query.stdGetBundlesQuerie, {
          organizationId: action.value.organizationData.id
        })
      );

      let studentCourses = yield call(
        [API, 'graphql'],
        graphqlOperation(query.stdGetCoursesStudentQuerie, {
          organizationID: action.value.organizationData.id,
          id: userData?.username ? userData.username : action.value.studentData.id
        })
      );

      bundles = bundles.data.getOrganization.subscriptionBundles;
      studentCourses = studentCourses.data.getStudent.courseIDs;

      const currentCourse = action.value.courseData;

      //We filter the active courses in each bundle
      //If the current course and all the other course are active in a bundle
      //And the student is invited in all of them we can show the bundle
      let arrayOfBundlesWithCourse = [];
      if (bundles) {
        bundles.forEach(item => {
          if (
            item.activeCourses &&
            item.activeCourses.length > 0 &&
            item.activeCourses.find(course => course === currentCourse.id)
          ) {
            let allCoursesInvited = true;
            item.activeCourses.forEach(course => {
              if (
                allCoursesInvited &&
                studentCourses &&
                studentCourses.length > 0 &&
                !studentCourses.find(c => c === course)
              ) {
                allCoursesInvited = false;
              }
            });
            if (allCoursesInvited) {
              arrayOfBundlesWithCourse.push(item);
            }
          }
        });
      }

      //yield put(organizationActions.setOrganizationBundles(arrayOfBundlesWithCourse));
    }
  } catch (err) {
    yield put(notificationsActions.handleCatchError(err, 'getOrganizationBundlesSagas'));
  }
}

function* sendReportEmailToOrganizationSagas(action) {
  try {
    const [organization, orgId, userData, studentData, courseData] = yield all([
      select(getOrganizationDataReducer),
      select(getUserOrganizationId),
      select(getUserData),
      select(getStudentDataReducer),
      select(getCourseDataReducer)
    ]);
    const {
      type,
      data,
      body,
      feedback,
      backgroundOrg,
      questionText,
      questionId,
      examId,
      courseId,
      feedbackId
    } = action.payload;
    const organizationId = organization && organization.id ? organization.id : orgId ? orgId : '';
    // Email of the logged in user. Double validation in case the user data is not loaded.
    let replyTo =
      userData && userData.attributes && userData.attributes.email
        ? userData.attributes.email
        : null;
    if (!replyTo) {
      replyTo = studentData && studentData.email ? studentData.email : '';
    }
    let supportEmail = organization && organization.supportEmail ? organization.supportEmail : null;
    const arrayOfQueries = [];
    // Get organization domain
    arrayOfQueries.push(
      call(
        [API, 'graphql'],
        graphqlOperation(stdGetOrganizationDomainQuerie, {
          organizationID: organizationId,
          envName
        })
      )
    );
    // Get suport email from a querie
    if (!supportEmail) {
      arrayOfQueries.push(
        call(
          [API, 'graphql'],
          graphqlOperation(query.stdGetOrganizationDataQuerie, {
            id: organizationId
          })
        )
      );
    }
    const [domainResponse, organizationResponse] = yield all(arrayOfQueries);
    if (organizationResponse && !supportEmail) {
      supportEmail =
        organizationResponse &&
        organizationResponse.data &&
        organizationResponse.data.getOrganization &&
        organizationResponse.data.getOrganization.supportEmail
          ? organizationResponse.data.getOrganization.supportEmail
          : null;
    }
    let domain =
      domainResponse && domainResponse.data && domainResponse.data.getOrganizationDomain
        ? domainResponse.data.getOrganizationDomain
        : null;
    // Change to localhost in case of developer
    if (window.location.hostname === 'localhost') domain = `http://localhost:3000`;
    // Get organization logo
    let logoKey = yield call([Storage, 'get'], `media/${organizationId}/profile_images/orgLogo`);
    if (!logoKey) logoKey = null;
    if (supportEmail && domain) {
      // Add the secure connection so the link can be opened from the emal
      if (!domain.startsWith('http://') && !domain.startsWith('https://')) {
        domain = `http://${domain}`;
      }
      // Add the .creator. to the domain
      if (domain !== `http://localhost:3000` && !domain.includes('.creator.')) {
        const init = domain.indexOf('.everprep.com');
        let subdomain = '';
        if (init !== -1) subdomain = domain.substring(0, init);
        domain = `${subdomain}.creator.everprep.com`;
      }
      // Form link to manage sagas
      // The "&" sign is used to form a pair.
      // The "=" sign separates between the key and the value.
      let chain = '';
      if (type === 'QUESTION') {
        chain = `orgId=${organizationId}&cId=${courseId}&qId=${questionId}&feedback=${feedbackId}`;
      }
      if (type === 'EXAM') {
        chain = `orgId=${organizationId}&cId=${courseId}&eId=${examId}&feedback=${feedbackId}`;
      }
      const resolveLink = `${domain}/resolveFeedback?${chain}`;
      const viewLink = `${domain}/viewFeedback?${chain}`;
      const userFeedback =
        feedback && type !== 'OTHERS'
          ? `<p class="text-bold">Feedback:</p>
              <p>${feedback}</p>
              <p></p>
              <p><a href="${viewLink}">View Feedback</a> | <a href="${resolveLink}">Resolve Feedback</a></p>
              <p></p>
              <p>View feedback takes you to the questions feedback, resolve marks the question resolved.</p>
            `
          : feedback && type === 'OTHERS'
          ? `<p class="text-bold">Feedback:</p>
          <p>${feedback}</p>
        `
          : '';
      let mailParams;
      // For questions these email has changed -> https://everprep.atlassian.net/browse/EPP-748
      if (type === 'QUESTION') {
        const courseName = courseData && courseData.name ? courseData.name : '';
        const studentName = studentData && studentData.name ? studentData.name : '';
        const dateReported = dateCompleteString(new Date());
        mailParams = {
          Destination: { ToAddresses: [supportEmail] },
          ReplyToAddresses: [replyTo],
          Source: `noreply@everprep.com`,
          Message: {
            Subject: { Data: `Question Reported in ${courseName}`, Charset: 'UTF-8' },
            Body: {
              Html: {
                Charset: 'UTF-8',
                Data: supportEmailTemplate({
                  header: null,
                  body: /*html*/ `
                  <div class="text">
                    <p>A question has been reported in your course. Please review the details below:</p>
                    <br />
                    <p><strong>Student:</strong> ${studentName}</p>
                    <p><strong>Email:</strong> ${replyTo}</p>
                    <p><strong>Course:</strong> ${courseName}</p>
                    <p><strong>Reported On:</strong> ${dateReported}</p>
                    <p><strong>Student Feedback:</strong> ${feedback}</p>
                    <br />
                    <p><strong>Question ID:</strong> ${questionId}</p>
                    <p><strong>Question Text:</strong> ${questionText}</p>
                    <p><strong>Link to question:</strong> <a href="${viewLink}">Click here</a></p>
                  </div>
                  `,
                  footer: null,
                  backgroundOrg: backgroundOrg,
                  orgLogo: logoKey
                })
              }
            }
          }
        };
      } else {
        mailParams = {
          Source: 'noreply@everprep.com',
          Destination: { ToAddresses: [supportEmail] },
          ReplyToAddresses: [replyTo],
          Message: {
            Subject: { Charset: 'UTF-8', Data: data },
            Body: {
              Html: {
                Charset: 'UTF-8',
                Data: supportEmailTemplate({
                  header: null,
                  body: `<div class="text">
                          ${body}
                          <br/><br/>
                          ${userFeedback}
                        </div>
                  `,
                  footer: null,
                  orgLogo: logoKey,
                  backgroundOrg: backgroundOrg
                })
              }
            }
          }
        };
      }
      const response = yield GraphOp(sendEmailSES, {
        mailParams: JSON.stringify(mailParams)
      });
      if (
        !(
          response &&
          response.data &&
          response.data.sendEmailSES &&
          response.data.sendEmailSES.includes('httpStatusCode=200')
        )
      ) {
        throw new Error('Email was not sent');
      }
    }
  } catch (err) {
    yield put(
      notificationsActions.setNotification({
        message: 'Unable to complete request. Please contact us for support.',
        severity: 'error'
      })
    );
    yield put(notificationsActions.handleCatchError(err, 'sendReportEmailToOrganizationSagas'));
  }
}

// Watchers
function* getOrganizationDataWatcher() {
  yield takeLatest(types.GET_ORGANIZATION_DATA, getOrganizationDataSagas);
}
function* getOrganizationLogoWatcher() {
  yield takeLatest(types.GET_ORGANIZATION_LOGO, getOrganizationLogoSagas);
}
function* getOrganizationBundlesWatcher() {
  yield takeLatest(types.GET_ORGANIZATION_BUNDLES, getOrganizationBundlesSagas);
}
function* sendReportEmailToOrganizationWatcher() {
  yield takeLatest(types.SEND_REPORT_EMAIL_TO_ORGANIZATION, sendReportEmailToOrganizationSagas);
}
// Exports the sagas
export default function* sagas() {
  yield all([
    getOrganizationDataWatcher(),
    getOrganizationLogoWatcher(),
    getOrganizationBundlesWatcher(),
    sendReportEmailToOrganizationWatcher()
  ]);
}
