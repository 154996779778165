import { lighten } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

const PillButton = makeStyles(theme => {
  const { palette, typography, breakpoints } = theme;
  const { pxToRem } = typography;

  return {
    rootButton: {
      width: '100%',
      height: '100%',
      borderRadius: props => (props.submit ? '0px' : props.smallRadius ? '16px' : `30px`),
      textTransform: 'none',
      padding: `${pxToRem(0)} ${pxToRem(18)}`,
      minHeight: `${pxToRem(43)}`,
      marginBottom: props => (props.marginBottom ? pxToRem(10) : 0),
      border: props => (props.bordered ? `solid 1px` : `0px`),
      color: palette.text.button,
      [breakpoints.down('md')]: {
        minWidth: pxToRem(96),
        minHeight: pxToRem(30)
      },
      backgroundColor: palette.primary.main,
      '&:hover': {
        backgroundColor: lighten(palette.primary.main, 0.2)
      },
      '&.white': {
        backgroundColor: palette.common.white,
        color: palette.text.primary,
        border: `1px solid ${palette.primary.main}`,
        '&:hover': {
          backgroundColor: lighten(palette.primary.main, 0.7)
        }
      },
      '&.gray': {
        color: 'lightgray'
      },
      '&.MuiButton-root.Mui-disabled': {
        color: palette.common.black,
        backgroundColor: palette.common.disabled
      },
      /*
      '&.yellow': {
        backgroundColor: palette.primary.main,
        color: palette.common.inputBackground,
        '&:hover': {
          backgroundColor: lighten(palette.primary.main, 0.4),
        },
      },
      '&.white': {
        border: '1px solid',
        borderColor: props => (props.borderColor ? props.borderColor : palette.primary.main),
        boxShadow: props =>
          props.boxShadow ? `1px 1px 2px 0px ${palette.common.grayColor}` : 'none',
        color: palette.primary.main,
        '&:hover': {
          backgroundColor: palette.primary.main,
          color: palette.common.inputBackground,
        },
      },
      '&.green': {
        backgroundColor: palette.primary.main,
        color: palette.common.inputBackground,
        '&:hover': {
          backgroundColor: palette.primary.hover,
        },
      },
      },
      '&.dark': {
        minWidth: pxToRem(110),
        height: pxToRem(43),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.inputBackground,
        '&:hover': {
          backgroundColor: theme.palette.primary.hover,
        },
      },
      */
    },
    flatBtn: {
      paddingLeft: pxToRem(35),
      paddingRight: pxToRem(35),
      minHeight: pxToRem(42)
    },
    textButtons: {
      width: 'max-content',
      fontSize: pxToRem(16),
      textTransform: 'inherit',
      letterSpacing: '0px',
      lineHeight: pxToRem(12),
      fontFamily: props => (props.bold ? 'circe-bold' : 'circe-regular'),
      [breakpoints.down('md')]: {
        fontSize: pxToRem(12)
      }
    },
    carouselTextButton: {
      width: 'max-content',
      fontSize: pxToRem(16),
      textTransform: 'inherit',
      letterSpacing: '0px',
      lineHeight: pxToRem(12),
      fontFamily: props => (props.bold ? 'circe-bold' : 'circe-regular'),
      [breakpoints.down('lg')]: {
        fontSize: pxToRem(14)
      },
      [breakpoints.down('md')]: {
        fontSize: pxToRem(18)
      }
    },
    countdownTextButton: {
      width: 'max-content',
      fontSize: pxToRem(16),
      textTransform: 'inherit',
      letterSpacing: '0px',
      lineHeight: pxToRem(12),
      display: 'flex',
      flexWrap: 'noWrap',
      fontFamily: props => (props.bold ? 'circe-bold' : 'circe-regular'),
      [breakpoints.down('md')]: {
        fontSize: pxToRem(12)
      }
    },
    lockedGrid: { display: 'flex', alignItems: 'center' },
    lockIcon: { width: pxToRem(15), paddingRight: pxToRem(5) }
  };
});
export default PillButton;
