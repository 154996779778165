export const START_STUDY_SESSION = 'studySession/START_STUDY_SESSION';
export const STUDY_SESSION_DATA = 'studySession/STUDY_SESSION_DATA';
export const SET_STACKED_QUESTIONS = 'studySession/SET_STACKED_QUESTION';
export const SET_STACKED_ANSWERS = 'studySession/SET_STACKED_ANSWERS';
export const SET_NOT_ELAPSED_QUESTIONS = 'studySession/SET_NOT_ELAPSED_QUESTIONS';
export const SET_LOADING_QUESTIONS = 'studySession/SET_LOADING_QUESTIONS';
export const ACTUAL_QUESTION = 'studySession/ACTUAL_QUESTION';
export const SET_ACTUAL_QUESTION = 'studySessionLeftView/SET_ACTUAL_QUESTION';
export const DIFFICULT_FEEDBACK = 'studySession/DIFFICULT_FEEDBACK';
export const STUDY_SESSION_RESULTS = 'studySession/STUDY_SESSION_RESULTS';
export const EXIT_STUDY_SESSION = 'studySession/EXIT_STUDY_SESSION';
export const START_OPEN_STUDY_MODE = 'studySession/START_OPEN_STUDY_MODE';
export const SEND_QUESTION_STUDY_SESSION_FEEDBACK = 'getHelp/SEND_QUESTION_STUDY_SESSION_FEEDBACK';
export const OPEN_STUDY_MODE_RESULTS = 'studySession/OPEN_STUDY_MODE_RESULTS';
export const ENABLE_NOTIFICATIONS = 'studySession/ENABLE_NOTIFICATIONS';
export const GET_STUDY_MODES = 'studySession/GET_STUDY_MODES';
export const STUDY_MODES = 'studySession/STUDY_MODES';
export const ACTUAL_ANSWER = 'studySession/ACTUAL_ANSWER';
export const RESPONDED_CORRECTLY = 'studySession/RESPONDED_CORRECTLY';
export const SHOW_SUBMIT = 'studySession/SHOW_SUBMIT';
export const SHOW_RESULTS = 'studySession/SHOW_RESULTS';
export const SHOW_FINAL_RESULTS = 'studySession/SHOW_FINAL_RESULTS';
export const STUDENT_ANSWER = 'studySession/STUDENT_ANSWER';
export const EXPIRED_PREVIEW = 'studySession/EXPIRED_PREVIEW';
export const PRELOADED_QUESTIONS = 'studySession/PRELOADED_QUESTIONS';
export const SET_BTN_SUBMIT = 'studySession/SET_BTN_SUBMIT';
export const SAVE_QUESTION_ANSWER = 'studySession/SAVE_QUESTION_ANSWER';
export const STORED_SELF_GRADED = 'studySession/STORED_SELF_GRADED';
export const VALIDATE_UPDATED_QUESTION = 'VALIDATE_UPDATED_QUESTION';
export const PRELOAD_FIRST_QUESTIONS = 'PRELOAD_FIRST_QUESTIONS';
export const PRELOADED_FIRST_QUESTIONS = 'PRELOADED_FIRST_QUESTIONS';
export const LOADED_QUESTIONS = 'LOADED_QUESTIONS';
export const ANCHORED_NUMBER = 'ANCHORED_NUMBER';
export const ANCHORED_ANSWERS = 'ANCHORED_ANSWERS';
