import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => {
  const { palette, typography, breakpoints } = theme;
  const { pxToRem } = typography;
  return {
    container: {
      border: 'none',
      width: pxToRem(654),
      height: pxToRem(422),
      background: `${palette.common.white} 0% 0% no-repeat padding-box`,
      opacity: '1',
      outline: 'none',
      maxHeight: '100vh',
      overflowY: 'scroll',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none'
      },
      [breakpoints.up('xs')]: {
        width: pxToRem(500)
      }
    },
    gridContainer: {
      background: `${palette.common.white} 0% 0% no-repeat padding-box`,
      borderRadius: `${pxToRem(10)}, ${pxToRem(10)}, 0px, 0px`,
      opacity: '1',
      paddingTop: pxToRem(13),
      paddingBottom: pxToRem(16),
      borderBottom: `${pxToRem(1)} solid ${palette.border}`
    },
    bodyContainer: {
      marginRight: pxToRem(16),
      marginLeft: pxToRem(16),
      padding: `${pxToRem(40)} ${pxToRem(24)} ${pxToRem(0)} ${pxToRem(24)}`
    },
    gridItemTitle: {
      paddingLeft: pxToRem(18),
      textAlign: 'left',
      fontSize: pxToRem(22),
      color: palette.text.primary,
      fontFamily: 'circe-regular'
    },
    gridItemClose: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingRight: pxToRem(16)
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: 'none'
    },
    closeIcon: {
      color: palette.common.subTitle,
      cursor: 'pointer'
    },
    textModal: {
      textAlign: 'left',
      fontSize: pxToRem(18),
      color: palette.text.primary,
      fontFamily: 'circe-regular'
    },
    organizationDataBox: {
      padding: 0,
      background: '0% 0% no-repeat padding-box',
      backgroundColor: palette.common.inputBackground,
      border: `${pxToRem(1)} solid`,
      borderColor: palette.common.grayColor,
      borderRadius: pxToRem(6),
      width: '100%',
      fontSize: pxToRem(14),
      letterSpacing: pxToRem(0),
      color: palette.common.inputBackground,
      opacity: '1'
    },
    buttonsSave: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingRight: pxToRem(40),
      paddingTop: pxToRem(15),
      marginBottom: pxToRem(22)
    },
    cancelButton: { marginRight: pxToRem(10) },
    helpLabel: {
      fontSize: pxToRem(14),
      fontFamily: 'circe-regular',
      color: palette.primary.main,
      marginTop: pxToRem(10),
      width: 'max-content'
    },
    helpLink: {
      color: `${palette.primary.main} !important`,
      cursor: 'pointer'
    },
    inputsGrid: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: pxToRem(13)
    },
    formControl: { width: '100%' },
    selectStyle: {
      border: `${pxToRem(1)} solid ${palette.common.flashCardGray}`,
      borderRadius: pxToRem(16),
      lineHeight: `${pxToRem(20)} !important`,
      background: palette.common.white,
      height: pxToRem(50),
      fontFamily: 'circe-regular',
      fontSize: pxToRem(14),
      color: palette.text.primary,
      '& .MuiSelect-icon': {
        top: 'inherit',
        color: palette.primary.main,
        marginRight: pxToRem(7)
      },
      '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent'
      }
    },
    inputSelect: {
      padding: `${pxToRem(6)} ${pxToRem(24)} ${pxToRem(4)} ${pxToRem(15)}`
    },
    menuItem: {
      fontFamily: 'circe-regular',
      fontSize: pxToRem(14),
      color: palette.text.primary,
      background: palette.common.white
    },
    textArea: {
      height: pxToRem(70),
      borderRadius: pxToRem(16),
      width: 'fill-available',
      minWidth: 'fill-available',
      maxWidth: 'fill-available',
      minHeight: pxToRem(20),
      maxHeight: pxToRem(300),
      borderColor: palette.common.flashCardGray,
      marginTop: pxToRem(15),
      fontFamily: 'circe-regular',
      fontSize: pxToRem(16),
      color: palette.text.primary,
      paddingTop: pxToRem(12),
      paddingLeft: pxToRem(17),
      '&::placeholder': {
        color: palette.text.primary,
        fontFamily: 'circe-light'
      },
      outline: 'none'
    },
    lengthLabel: {
      fontFamily: 'circe-regular',
      fontSize: pxToRem(12),
      textAlign: 'end',
      color: palette.common.subTitle,
      marginRight: pxToRem(6),
      marginTop: pxToRem(6)
    }
  };
});

export { useStyles };
