import * as t from './actionTypes/appActionTypes';

export const loadAppTheme = value => ({
  type: t.LOAD_APP_THEME,
  value,
});

export const setAppTheme = value => ({
  type: t.SET_APP_THEME,
  value,
});

export const setLoadingAppTheme = value => ({
  type: t.SET_LOADING_APP_THEME,
  value,
});

export const sendIntercomMessage = payload => ({
  type: t.SEND_INTERCOM_MESSAGE,
  payload,
});

export const setOpenDisabledCourseModal = value => ({
  type: t.SET_OPEN_DISABLED_COURSE_MODAL,
  value,
});

export const setSubmitOpenDisableCourseModal = value => ({
  type: t.SET_SUBMIT_OPEN_DISABLED_COURSE_MODAL,
  value,
});
