/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const stripeOnboard = /* GraphQL */ `
  query StripeOnboard(
    $orgId: ID!
    $stripeId: String
    $redirection: String
    $envName: String
  ) {
    stripeOnboard(
      orgId: $orgId
      stripeId: $stripeId
      redirection: $redirection
      envName: $envName
    )
  }
`;

export const getproductfromstripe = /* GraphQL */ `
  query Getproductfromstripe(
    $productId: ID
    $stripeAccount: ID
    $envName: String
  ) {
    getproductfromstripe(
      productId: $productId
      stripeAccount: $stripeAccount
      envName: $envName
    ) {
      id
      object
      active
      name
      description
      prices {
        price
        interval
        interval_count
        currency
        priceId
        active
        autoRenewal
      }
    }
  }
`;
export const getOrganizationDomain = /* GraphQL */ `
  query GetOrganizationDomain($organizationID: String, $envName: String) {
    getOrganizationDomain(organizationID: $organizationID, envName: $envName)
  }
`;
export const retrieveOnBoardComplete = /* GraphQL */ `
  query RetrieveOnBoardComplete($stripeId: String, $envName: String) {
    retrieveOnBoardComplete(stripeId: $stripeId, envName: $envName)
  }
`;
export const createStripeCustomer = /* GraphQL */ `
  query CreateStripeCustomer(
    $source: String
    $billingInfo: String
    $metadata: String
    $envName: String
  ) {
    createStripeCustomer(
      source: $source
      billingInfo: $billingInfo
      metadata: $metadata
      envName: $envName
    )
  }
`;
export const createStripePaymentIntent = /* GraphQL */ `
  query CreateStripePaymentIntent(
    $metadata: String
    $customerId: String
    $price: String
    $stripeAccount: ID
    $feeType: String
    $envName: String
  ) {
    createStripePaymentIntent(
      metadata: $metadata
      customerId: $customerId
      price: $price
      stripeAccount: $stripeAccount
      feeType: $feeType
      envName: $envName
    )
  }
`;
export const createStripeProduct = /* GraphQL */ `
  query CreateStripeProduct(
    $metadata: String
    $product: String
    $stripeAccount: ID
    $envName: String
  ) {
    createStripeProduct(
      metadata: $metadata
      product: $product
      stripeAccount: $stripeAccount
      envName: $envName
    )
  }
`;
export const createSubscriptionInvoice = /* GraphQL */ `
  query CreateSubscriptionInvoice(
    $metadata: String
    $customerId: String
    $subscriptionId: String
    $stripeAccount: ID
    $envName: String
  ) {
    createSubscriptionInvoice(
      metadata: $metadata
      customerId: $customerId
      subscriptionId: $subscriptionId
      stripeAccount: $stripeAccount
      envName: $envName
    )
  }
`;
export const retriveStripeSubscriptions = /* GraphQL */ `
  query RetriveStripeSubscriptions(
    $subscriptionId: String
    $stripeAccount: ID
    $envName: String
    $fromWebhook: Boolean
  ) {
    retriveStripeSubscriptions(
      subscriptionId: $subscriptionId
      stripeAccount: $stripeAccount
      envName: $envName
      fromWebhook: $fromWebhook
    )
  }
`;
export const retrievePaymentInformation = /* GraphQL */ `
  query RetrievePaymentInformation(
    $metadata: String
    $customerId: String
    $stripeAccount: ID
    $envName: String
    $paymentMethod: String
  ) {
    retrievePaymentInformation(
      metadata: $metadata
      customerId: $customerId
      stripeAccount: $stripeAccount
      envName: $envName
      paymentMethod: $paymentMethod
    )
  }
`;
export const updateCustomer = /* GraphQL */ `
  query UpdateCustomer(
    $customerId: String
    $sourceId: String
    $stripeAccount: ID
    $envName: String
    $billingInfo: String
  ) {
    updateCustomer(
      customerId: $customerId
      sourceId: $sourceId
      stripeAccount: $stripeAccount
      envName: $envName
      billingInfo: $billingInfo
    )
  }
`;
export const updateStripeProduct = /* GraphQL */ `
  query UpdateStripeProduct(
    $productId: String
    $productName: String
    $productDescription: String
    $stripeAccount: ID
    $envName: String
  ) {
    updateStripeProduct(
      productId: $productId
      productName: $productName
      productDescription: $productDescription
      stripeAccount: $stripeAccount
      envName: $envName
    )
  }
`;
export const cancelStripeSubscription = /* GraphQL */ `
  query CancelStripeSubscription(
    $subscriptionId: String
    $stripeAccount: ID
    $envName: String
    $autoRenewal: Boolean
    $changeAccount: Boolean
  ) {
    cancelStripeSubscription(
      subscriptionId: $subscriptionId
      stripeAccount: $stripeAccount
      envName: $envName
      autoRenewal: $autoRenewal
      changeAccount: $changeAccount
    )
  }
`;
export const updateStripePrices = /* GraphQL */ `
  query UpdateStripePrices(
    $pricing: String
    $stripeAccount: ID
    $envName: String
  ) {
    updateStripePrices(
      pricing: $pricing
      stripeAccount: $stripeAccount
      envName: $envName
    )
  }
`;
export const sendIntercomAutomaticMessage = /* GraphQL */ `
  query SendIntercomAutomaticMessage(
    $email: String
    $message: String
    $accessToken: String
    $customAttributes: String
  ) {
    sendIntercomAutomaticMessage(
      email: $email
      message: $message
      accessToken: $accessToken
      customAttributes: $customAttributes
    )
  }
`;
export const removePaymentMethod = /* GraphQL */ `
  query RemovePaymentMethod(
    $customerId: String
    $sourceId: String
    $stripeAccount: ID
    $envName: String
  ) {
    removePaymentMethod(
      customerId: $customerId
      sourceId: $sourceId
      stripeAccount: $stripeAccount
      envName: $envName
    )
  }
`;
export const stripeoAuth = /* GraphQL */ `
  query StripeoAuth($accessToken: String, $envName: String) {
    stripeoAuth(accessToken: $accessToken, envName: $envName)
  }
`;
export const getLaunchDarklyAccess = /* GraphQL */ `
  query GetLaunchDarklyAccess($envName: String) {
    getLaunchDarklyAccess(envName: $envName)
  }
`;
export const getStripeCheckoutSession = /* GraphQL */ `
  query GetStripeCheckoutSession(
    $metadata: String
    $customerId: String
    $price: String
    $stripeAccount: ID
    $feeType: String
    $envName: String
    $myDomain: String
    $type: String
    $stripeConnect: Boolean
  ) {
    getStripeCheckoutSession(
      metadata: $metadata
      customerId: $customerId
      price: $price
      stripeAccount: $stripeAccount
      feeType: $feeType
      envName: $envName
      myDomain: $myDomain
      type: $type
      stripeConnect: $stripeConnect
    )
  }
`;
export const getStripeBilling = /* GraphQL */ `
  query GetStripeBilling(
    $customerId: String
    $stripeAccount: ID
    $envName: String
    $myDomain: String
  ) {
    getStripeBilling(
      customerId: $customerId
      stripeAccount: $stripeAccount
      envName: $envName
      myDomain: $myDomain
    )
  }
`;
export const retrieveStripePaymentMethod = /* GraphQL */ `
  query RetrieveStripePaymentMethod(
    $customerId: String
    $stripeAccount: ID
    $envName: String
    $paymentMethod: String
  ) {
    retrieveStripePaymentMethod(
      customerId: $customerId
      stripeAccount: $stripeAccount
      envName: $envName
      paymentMethod: $paymentMethod
    )
  }
`;
export const getStripeSubscriptionList = /* GraphQL */ `
  query GetStripeSubscriptionList(
    $customerId: String
    $stripeAccount: ID
    $envName: String
    $courseId: String
    $type: String
  ) {
    getStripeSubscriptionList(
      customerId: $customerId
      stripeAccount: $stripeAccount
      envName: $envName
      courseId: $courseId
      type: $type
    )
  }
`;
export const retrieveCreatorInvoices = /* GraphQL */ `
  query RetrieveCreatorInvoices($customerId: String, $envName: String) {
    retrieveCreatorInvoices(customerId: $customerId, envName: $envName)
  }
`;
export const getAllActiveStudents = /* GraphQL */ `
  query GetAllActiveStudents(
    $customerId: String
    $envName: String
    $organizationId: ID
  ) {
    getAllActiveStudents(
      customerId: $customerId
      envName: $envName
      organizationId: $organizationId
    )
  }
`;
export const convertCreatorInvoicesToZipFile = /* GraphQL */ `
  query ConvertCreatorInvoicesToZipFile(
    $customerId: String
    $envName: String
    $invoicesList: String
  ) {
    convertCreatorInvoicesToZipFile(
      customerId: $customerId
      envName: $envName
      invoicesList: $invoicesList
    )
  }
`;
export const sendEmailSES = /* GraphQL */ `
  query SendEmailSES($mailParams: String, $envName: String) {
    sendEmailSES(mailParams: $mailParams, envName: $envName)
  }
`;
export const scanStudentAnswersCLI = /* GraphQL */ `
  query ScanStudentAnswersCLI(
    $courseId: String
    $studentId: String
    $answersTable: String
  ) {
    scanStudentAnswersCLI(
      courseId: $courseId
      studentId: $studentId
      answersTable: $answersTable
    )
  }
`;
export const analizeQuestionsCLI = /* GraphQL */ `
  query AnalizeQuestionsCLI(
    $courseId: String
    $studentId: String
    $envName: String
    $questionsTable: String
    $answersTable: String
  ) {
    analizeQuestionsCLI(
      courseId: $courseId
      studentId: $studentId
      envName: $envName
      questionsTable: $questionsTable
      answersTable: $answersTable
    )
  }
`;
export const scanQuestionsCLI = /* GraphQL */ `
  query ScanQuestionsCLI($courseId: String, $questionsTable: String) {
    scanQuestionsCLI(courseId: $courseId, questionsTable: $questionsTable)
  }
`;
export const handleCatchErrorLambda = /* GraphQL */ `
  query HandleCatchErrorLambda($catchedError: String, $envName: String) {
    handleCatchErrorLambda(catchedError: $catchedError, envName: $envName)
  }
`;
export const handleCatchErrorStudents = /* GraphQL */ `
  query HandleCatchErrorStudents($catchedError: String, $envName: String) {
    handleCatchErrorStudents(catchedError: $catchedError, envName: $envName)
  }
`;
export const createCopyQuestionSet = /* GraphQL */ `
  query CreateCopyQuestionSet(
    $contentsSelected: String
    $filesAndStatus: String
    $courseIdDest: String
    $courseQuestionsToCopy: String
    $organizationId: String
    $createUserID: String
    $envName: String
  ) {
    createCopyQuestionSet(
      contentsSelected: $contentsSelected
      filesAndStatus: $filesAndStatus
      courseIdDest: $courseIdDest
      courseQuestionsToCopy: $courseQuestionsToCopy
      organizationId: $organizationId
      createUserID: $createUserID
      envName: $envName
    )
  }
`;
export const createStudentAccountAndSubscription = /* GraphQL */ `
  query CreateStudentAccountAndSubscription(
    $metadata: String
    $poolData: String
    $mainUserPoolId: String
    $envName: String
  ) {
    createStudentAccountAndSubscription(
      metadata: $metadata
      poolData: $poolData
      mainUserPoolId: $mainUserPoolId
      envName: $envName
    )
  }
`;
export const getUserPoolIdFromApp = /* GraphQL */ `
  query GetUserPoolIdFromApp($domain: String) {
    getUserPoolIdFromApp(domain: $domain)
  }
`;
export const getContentContainer = /* GraphQL */ `
  query GetContentContainer($topicID: ID!, $id: ID!) {
    getContentContainer(topicID: $topicID, id: $id) {
      id
      topicID
      courseID
      status
      order
      createUserID
      updateUserID
      contents {
        id
        order
        fileID
        type
        published
        createdFrom
      }
      read {
        studentID
        read
      }
      actionRequired
      actualVersion
      versions {
        id
        name
        publishedBy
        publishedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listContentContainers = /* GraphQL */ `
  query ListContentContainers(
    $topicID: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelContentContainerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listContentContainers(
      topicID: $topicID
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        topicID
        courseID
        status
        order
        createUserID
        updateUserID
        actionRequired
        actualVersion
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStudent = /* GraphQL */ `
  query GetStudent($organizationID: ID!, $id: ID!) {
    getStudent(organizationID: $organizationID, id: $id) {
      organizationID
      id
      name
      email
      courseIDs
      lastLogin
      firstLogin
      lastLoginCourses {
        courseID
        lastLogin
      }
      notifications {
        id
        text
        type
        read
        createdAt
        organizationName
        questionName
        courseName
        courseId
        questionId
      }
      loginStreaks {
        id
        courseID
        days
        loginDate
        longest
      }
      examHistory {
        examID
        courseID
        title
        date
        score
        totalQuestions
        accuracy
        time
      }
      studentAnswers {
        nextToken
      }
      examProgress {
        studentID
        examID
        examQuestionsIDs
        timeElapsed
        started
        remainingAttempts
        available
        lastQuestionAnswered
      }
      notificationPreferences {
        courseID
        newQuestionEmail
        sendNQE
        newQuestionPush
        weeklyPerformanceEmail
        weeklyPerformancePush
      }
      feedbacks {
        feedbackId
        feedback
        createdAt
        updatedAt
        receptorUserId
        reporterUserId
        status
        type
        reporterUserName
        reporterUserEmail
        reporterVersionId
        page
      }
      allTimePerformance {
        courseID
        questionsAnswered
        correctAnswers
        msStudied
      }
      yearPerformance {
        courseID
        year
        questionsAnswered
        correctAnswers
        msStudied
      }
      monthPerformance {
        courseID
        numberOfMonth
        questionsAnswered
        correctAnswers
        msStudied
      }
      weekPerformance {
        courseID
        numberOfWeek
        questionsAnswered
        correctAnswers
        msStudied
      }
      todayPerformance {
        courseID
        day
        questionsAnswered
        correctAnswers
        msStudied
      }
      questionNotifications {
        courseID
        topicId
        enabled
        sendMail
      }
      organizationCustomers {
        organizationID
        customerID
      }
      lastStudySessions {
        courseID
        questionStack
      }
      activeCoursesByPeriod {
        courseID
        addedByCreator
      }
      ipAddress
      createdAt
      updatedAt
    }
  }
`;
export const listStudents = /* GraphQL */ `
  query ListStudents(
    $organizationID: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelStudentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStudents(
      organizationID: $organizationID
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        organizationID
        id
        name
        email
        courseIDs
        lastLogin
        firstLogin
        ipAddress
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStudentAnswer = /* GraphQL */ `
  query GetStudentAnswer($studentID: ID!, $id: ID!) {
    getStudentAnswer(studentID: $studentID, id: $id) {
      id
      studentID
      questionID
      questionVersion
      courseID
      correctAnswer
      nextAppearance
      eFactor
      hard
      normal
      easy
      answerHistory
      answerHistoryVersion {
        questionID
        answerHistory
      }
      createdAt
      updatedAt
    }
  }
`;
export const listStudentAnswers = /* GraphQL */ `
  query ListStudentAnswers(
    $studentID: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelStudentAnswerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStudentAnswers(
      studentID: $studentID
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        studentID
        questionID
        questionVersion
        courseID
        correctAnswer
        nextAppearance
        eFactor
        hard
        normal
        easy
        answerHistory
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserEmailNotification = /* GraphQL */ `
  query GetUserEmailNotification($id: ID!) {
    getUserEmailNotification(id: $id) {
      id
      sendTo
      sendHour
      subject
      message
      courseID
      topicID
      studentID
      createdAt
      updatedAt
    }
  }
`;
export const listUserEmailNotifications = /* GraphQL */ `
  query ListUserEmailNotifications(
    $filter: ModelUserEmailNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserEmailNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sendTo
        sendHour
        subject
        message
        courseID
        topicID
        studentID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getExam = /* GraphQL */ `
  query GetExam($courseID: ID!, $id: ID!) {
    getExam(courseID: $courseID, id: $id) {
      courseID
      id
      questionSource
      title
      attemptType
      maxAttempts
      timeLimit
      time
      revisable
      status
      totalQuestions
      questionsIDs
      examTopics {
        id
        percentage
        questions
      }
      feedbacks {
        feedbackId
        feedback
        createdAt
        updatedAt
        receptorUserId
        reporterUserId
        status
        type
        reporterUserName
        reporterUserEmail
        reporterVersionId
        page
      }
      resetMetricDate
      actionRequired {
        activated
        message
      }
      repeatedQuestions
      showAccuracyByQs
      unpublishedVersion
      createdAt
      updatedAt
    }
  }
`;
export const listExams = /* GraphQL */ `
  query ListExams(
    $courseID: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelExamFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listExams(
      courseID: $courseID
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        courseID
        id
        questionSource
        title
        attemptType
        maxAttempts
        timeLimit
        time
        revisable
        status
        totalQuestions
        questionsIDs
        resetMetricDate
        repeatedQuestions
        showAccuracyByQs
        unpublishedVersion
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuestion = /* GraphQL */ `
  query GetQuestion($courseID: ID!, $id: ID!) {
    getQuestion(courseID: $courseID, id: $id) {
      id
      courseID
      createUserID
      updatedUserID
      name
      type
      topicIDs
      multipleChoiceTextAnswer {
        order
        choice
        correct
        locked
      }
      multipleChoiceImageAnswer {
        order
        choiceId
        correct
        link
      }
      matchingAnswer {
        order
        title
        match
        correctOrder
        correct
      }
      fillInTheBlankAnswer {
        answer
        explanation
      }
      flashcardAnswer {
        answer
      }
      freeTypeAnswer {
        answer
        explanation
      }
      orderTypeAnswer {
        order
        title
        correctOption
      }
      anchored {
        anchoredText
        questionID
        questionsIDs
      }
      furtherReadingText
      questionFileId
      furtherReadingFileId
      feedbacks {
        feedbackId
        feedback
        createdAt
        updatedAt
        receptorUserId
        reporterUserId
        status
        type
        reporterUserName
        reporterUserEmail
        reporterVersionId
        page
      }
      status
      uploadedAt
      actualVersion
      versions {
        id
        name
        publishedBy
        publishedAt
      }
      questionStatus
      readyToPublish
      questionsRelatedID {
        id
        courseID
        isOriginalQuestion
      }
      createdAt
      updatedAt
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $courseID: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listQuestions(
      courseID: $courseID
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        courseID
        createUserID
        updatedUserID
        name
        type
        topicIDs
        furtherReadingText
        questionFileId
        furtherReadingFileId
        status
        uploadedAt
        actualVersion
        questionStatus
        readyToPublish
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTopic = /* GraphQL */ `
  query GetTopic($courseID: ID!, $id: ID!) {
    getTopic(courseID: $courseID, id: $id) {
      id
      courseID
      name
      createUserID
      updateUserID
      type
      order
      status
      contentStatus
      read {
        studentID
        read
      }
      contentContainers {
        nextToken
      }
      dontShowRestore
      dontShowPublish
      dontShowRepublish
      dontShowDeleteExam
      createdAt
      updatedAt
    }
  }
`;
export const listTopics = /* GraphQL */ `
  query ListTopics(
    $courseID: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelTopicFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTopics(
      courseID: $courseID
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        courseID
        name
        createUserID
        updateUserID
        type
        order
        status
        contentStatus
        dontShowRestore
        dontShowPublish
        dontShowRepublish
        dontShowDeleteExam
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCourseFile = /* GraphQL */ `
  query GetCourseFile($courseID: ID!, $id: ID!) {
    getCourseFile(courseID: $courseID, id: $id) {
      courseID
      id
      name
      label
      fileKey
      type
      format
      size
      createUserID
      updateUserID
      uploadedBy
      version
      topicIDs
      isOnlineVideo
      onlineUrl
      onlinePreview
      duration
      status
      createdFrom
      createdAt
      updatedAt
    }
  }
`;
export const listCourseFiles = /* GraphQL */ `
  query ListCourseFiles(
    $courseID: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelCourseFileFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCourseFiles(
      courseID: $courseID
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        courseID
        id
        name
        label
        fileKey
        type
        format
        size
        createUserID
        updateUserID
        uploadedBy
        version
        topicIDs
        isOnlineVideo
        onlineUrl
        onlinePreview
        duration
        status
        createdFrom
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCourse = /* GraphQL */ `
  query GetCourse($organizationID: ID!, $id: ID!) {
    getCourse(organizationID: $organizationID, id: $id) {
      organizationID
      id
      name
      welcomeMessage
      type
      createUserID
      updateUserID
      topics {
        nextToken
      }
      exams {
        nextToken
      }
      files {
        nextToken
      }
      questions {
        nextToken
      }
      status
      stripeProductID
      stripeBundleProductIDs
      examDate
      url
      pricingPlanUrl {
        type
        url
      }
      pricing {
        active
      }
      sections {
        id
        name
        order
        topicsIds
      }
      expandedContent
      actionRequired
      dontShowDeleteExam
      createdAt
      updatedAt
    }
  }
`;
export const listCourses = /* GraphQL */ `
  query ListCourses(
    $organizationID: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCourses(
      organizationID: $organizationID
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        organizationID
        id
        name
        welcomeMessage
        type
        createUserID
        updateUserID
        status
        stripeProductID
        stripeBundleProductIDs
        examDate
        url
        expandedContent
        actionRequired
        dontShowDeleteExam
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTeamMember = /* GraphQL */ `
  query GetTeamMember($organizationID: ID!, $id: ID!) {
    getTeamMember(organizationID: $organizationID, id: $id) {
      organizationID
      id
      name
      email
      role
      lastLogin
      avatar
      courseIDs
      notifications {
        id
        text
        type
        read
        createdAt
        organizationName
        questionName
        courseName
        courseId
        questionId
      }
      timezone {
        label
        altName
        value
        offset
      }
      ipAddress
      firstTimeTour
      createdAt
      updatedAt
    }
  }
`;
export const listTeamMembers = /* GraphQL */ `
  query ListTeamMembers(
    $organizationID: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelTeamMemberFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTeamMembers(
      organizationID: $organizationID
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        organizationID
        id
        name
        email
        role
        lastLogin
        avatar
        courseIDs
        ipAddress
        firstTimeTour
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrganizationOwnerConfig = /* GraphQL */ `
  query GetOrganizationOwnerConfig($id: ID!) {
    getOrganizationOwnerConfig(id: $id) {
      id
      organizationID
      status
      SystemStatus
      RegisterStatus
      VerificationStatus
      ProvisionStatus
      CustomDomain
      PersonalDomain
      VerificationCode
      Domain
      AuthID
      EmailCode
      UserPoolID
      AWSProjectRegion
      AWSCognitoIdentityPoolID
      AWSCognitoRegion
      AWSUserPoolsID
      AWSUserPoolsWebClientID
      createdAt
      updatedAt
    }
  }
`;
export const listOrganizationOwnerConfigs = /* GraphQL */ `
  query ListOrganizationOwnerConfigs(
    $filter: ModelOrganizationOwnerConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizationOwnerConfigs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        status
        SystemStatus
        RegisterStatus
        VerificationStatus
        ProvisionStatus
        CustomDomain
        PersonalDomain
        VerificationCode
        Domain
        AuthID
        EmailCode
        UserPoolID
        AWSProjectRegion
        AWSCognitoIdentityPoolID
        AWSCognitoRegion
        AWSUserPoolsID
        AWSUserPoolsWebClientID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPayments = /* GraphQL */ `
  query GetPayments($id: ID!) {
    getPayments(id: $id) {
      id
      PaymentIntentID
      OrganizationID
      StripeID
      StudentID
      CourseID
      PurchaseDate
      CompleteDate
      ClaimType
      ClaimStart
      ClaimEnd
      ClaimStatus
      amount
      ApplicationFeeAmount
      PurchaseStatus
      currency
      metadata {
        name
        value
      }
      description
      status
      Events {
        EventDate
        EventType
        EventSource
        data
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPaymentss = /* GraphQL */ `
  query ListPaymentss(
    $filter: ModelPaymentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaymentss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        PaymentIntentID
        OrganizationID
        StripeID
        StudentID
        CourseID
        PurchaseDate
        CompleteDate
        ClaimType
        ClaimStart
        ClaimEnd
        ClaimStatus
        amount
        ApplicationFeeAmount
        PurchaseStatus
        currency
        description
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const studentAnswersByQuestion = /* GraphQL */ `
  query StudentAnswersByQuestion(
    $questionID: ID
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studentAnswersByQuestion(
      questionID: $questionID
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studentID
        questionID
        questionVersion
        courseID
        correctAnswer
        nextAppearance
        eFactor
        hard
        normal
        easy
        answerHistory
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const questionsByStatus = /* GraphQL */ `
  query QuestionsByStatus(
    $status: ConstantsStatus
    $courseID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionsByStatus(
      status: $status
      courseID: $courseID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        courseID
        createUserID
        updatedUserID
        name
        type
        topicIDs
        furtherReadingText
        questionFileId
        furtherReadingFileId
        status
        uploadedAt
        actualVersion
        questionStatus
        readyToPublish
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const ownershipItems = /* GraphQL */ `
  query OwnershipItems(
    $EmailCode: String
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrganizationOwnerConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ownershipItems(
      EmailCode: $EmailCode
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        status
        SystemStatus
        RegisterStatus
        VerificationStatus
        ProvisionStatus
        CustomDomain
        PersonalDomain
        VerificationCode
        Domain
        AuthID
        EmailCode
        UserPoolID
        AWSProjectRegion
        AWSCognitoIdentityPoolID
        AWSCognitoRegion
        AWSUserPoolsID
        AWSUserPoolsWebClientID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const studentOwnership = /* GraphQL */ `
  query StudentOwnership(
    $StudentID: String
    $OrganizationID: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPaymentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studentOwnership(
      StudentID: $StudentID
      OrganizationID: $OrganizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        PaymentIntentID
        OrganizationID
        StripeID
        StudentID
        CourseID
        PurchaseDate
        CompleteDate
        ClaimType
        ClaimStart
        ClaimEnd
        ClaimStatus
        amount
        ApplicationFeeAmount
        PurchaseStatus
        currency
        description
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const studentClaims = /* GraphQL */ `
  query StudentClaims(
    $StudentID: String
    $ClaimStatus: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPaymentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studentClaims(
      StudentID: $StudentID
      ClaimStatus: $ClaimStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        PaymentIntentID
        OrganizationID
        StripeID
        StudentID
        CourseID
        PurchaseDate
        CompleteDate
        ClaimType
        ClaimStart
        ClaimEnd
        ClaimStatus
        amount
        ApplicationFeeAmount
        PurchaseStatus
        currency
        description
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const organizationClaims = /* GraphQL */ `
  query OrganizationClaims(
    $OrganizationID: String
    $ClaimStatus: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPaymentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    organizationClaims(
      OrganizationID: $OrganizationID
      ClaimStatus: $ClaimStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        PaymentIntentID
        OrganizationID
        StripeID
        StudentID
        CourseID
        PurchaseDate
        CompleteDate
        ClaimType
        ClaimStart
        ClaimEnd
        ClaimStatus
        amount
        ApplicationFeeAmount
        PurchaseStatus
        currency
        description
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const organizationPurchases = /* GraphQL */ `
  query OrganizationPurchases(
    $OrganizationID: String
    $PurchaseStatus: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPaymentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    organizationPurchases(
      OrganizationID: $OrganizationID
      PurchaseStatus: $PurchaseStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        PaymentIntentID
        OrganizationID
        StripeID
        StudentID
        CourseID
        PurchaseDate
        CompleteDate
        ClaimType
        ClaimStart
        ClaimEnd
        ClaimStatus
        amount
        ApplicationFeeAmount
        PurchaseStatus
        currency
        description
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      description
      status
      logo
      appSetting {
        presetActive
        presetColor
        customColor
        section
      }
      generalSettings {
        integrationType
        name
        activated
        description
        image
      }
      site {
        webLink
        status
        name
        courseList
      }
      checkList {
        name
        checked
        permissions
      }
      teamMembers {
        nextToken
      }
      students {
        nextToken
      }
      courses {
        nextToken
      }
      hiddenChecklist
      hiddenChecklistDate
      stripeId
      customerID
      subscriptions {
        subscriptionID
        active
        dateSubscribed
        autoRenewal
        interval
        monthlyPeriods
      }
      supportEmail
      stripeOnboardingComplete
      intercom {
        appId
        secretCode
        accessToken
      }
      termsAndConditions {
        url
        isFile
        fileName
      }
      privacyPolicy {
        url
        isFile
        fileName
      }
      subscriptionBundles {
        name
        subtitle
        available
        activeCourses
        lastEdited
        stripeProductID
      }
      createdAt
      updatedAt
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        status
        logo
        hiddenChecklist
        hiddenChecklistDate
        stripeId
        customerID
        supportEmail
        stripeOnboardingComplete
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
