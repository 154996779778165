import { getStateWithOnlyOneLevel } from '../../utils/StateSelectorHelper';

const getState = state => getStateWithOnlyOneLevel(state, 'Exam');

export const getOpenExamsReducer = state => getState(state).get('openExams');
export const getHistoryExamsReducer = state => getState(state).get('historyExams');
export const getCurrentExamHistoryReducer = state => getState(state).get('currentHistoryExam');
export const getLatestExamPerformanceReducer = state =>
  getState(state).get('latestExamPerformance');
export const getExamDataReducer = state => getState(state).get('examData');
export const getStackedAnswersReducer = state => getState(state).get('stackedAnswers');
export const getActualQuestionReducer = state => getState(state).get('actualQuestion');
export const getStackedQuestionsReducer = state => getState(state).get('stackedQuestions');
export const getExamProgressReducer = state => getState(state).get('examProgress');
export const getUserDataReducer = state => getState(state).get('userData');
export const getLoadingOpenExam = state => getState(state).get('loadingOpenExam');
export const getLoadingExamHistory = state => getState(state).get('loadingHistoryExams');
export const getFinished = state => getState(state).get('finished');
export const getStarted = state => getState(state).get('started');
export const getExamResultsReducer = state => getState(state).get('examResults');
export const getQuestionsAndAnswers = state => getState(state).get('questionsAndAnswers');
export const getPreloadedQuestions = state => getState(state).get('preloadedQuestions');
export const getPreviousQuestions = state => getState(state).get('previousQuestions');
export const getPreloadedExamFirstQuestions = state =>
  getState(state).get('preloadedExamFirstQuestions');
export const getCertificate = state => getState(state).get('certificate');
