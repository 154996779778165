export const stdGetQuestionQuerie = /* GraphQL */ `
  query stdGetQuestionQuerie($questionId: ID!, $courseId: ID!) {
    getQuestion(id: $questionId, courseID: $courseId) {
      id
      actualVersion
      name
      type
      furtherReadingText
      questionFileId
      furtherReadingFileId
      fillInTheBlankAnswer {
        answer
        explanation
      }
      flashcardAnswer {
        answer
      }
      freeTypeAnswer {
        answer
        explanation
      }
      matchingAnswer {
        order
        title
        match
      }
      multipleChoiceTextAnswer {
        order
        correct
        choice
        locked
      }
      multipleChoiceImageAnswer {
        choiceId
        correct
        order
      }
      orderTypeAnswer {
        order
        title
      }
      anchored {
        anchoredText
        questionID
        questionsIDs
      }
    }
  }
`;

export const stdGetQuestionFeedbacksQuerie = /* GraphQL */ `
  query stdGetQuestionFeedbacksQuerie($courseId: ID!, $questionId: ID!) {
    getQuestion(courseID: $courseId, id: $questionId) {
      createUserID
      actualVersion
      feedbacks {
        feedbackId
        createdAt
        feedback
        receptorUserId
        reporterUserId
        status
        type
        updatedAt
        reporterUserName
        reporterUserEmail
        page
      }
    }
  }
`;

export const stdGetStudentLastSessionsQuerie = /* GraphQL */ `
  query stdGetStudentLastSessionsQuerie($studentId: ID!, $organizationId: ID!) {
    getStudent(id: $studentId, organizationID: $organizationId) {
      lastStudySessions {
        courseID
        questionStack
      }
    }
  }
`;

export const stdGetStudentsAnswersQuerie = /* GraphQL */ `
  query stdGetStudentsAnswersQuerie($studentId: ID!) {
    listStudentAnswers(filter: { studentID: { eq: $studentId } }, limit: 20) {
      items {
        id
      }
    }
  }
`;

export const stdGetQuestionStatusQuerie = /* GraphQL */ `
  query stdGetQuestionStatusQuerie($questionId: ID!, $courseId: ID!) {
    getQuestion(id: $questionId, courseID: $courseId) {
      id
      courseID
      status
    }
  }
`;
