// import email template
import emailTemplate from './emailTemplate';
// import validate email
import validateEmail from './validateEmail';

// Here we have a function only to choose the correct template for each email type, the case name is the name of function that invokes this function
// you can found that function using the name of case
// every case use different props, you can see the props that each case use in the function that invokes this function
// all props come from a payload that is passed to the function that invokes this function

function maskEmail(email) {
  if (email) return `${email.slice(0, 2)}******${email.slice(email.search('@'))}`;
  else return 'un*****@mail.com';
}

export const sendEmailProcess = props => {
  // with this function we will set mail mailParams for each email type
  switch (props.type) {
    case 'sendConfirmationPasswordEmail': //Reset Password Notification
      {
        const {
          email,
          backgroundEmail,
          organizationLogo,
          organizationSupportEmail,
          organizationName,
          organizationId,
          setErrorPasswordChange
        } = props;
        let validEmail = validateEmail(email);
        if (validEmail) {
          const mailParams = {
            Destination: { ToAddresses: [email] },
            ReplyToAddresses: [organizationSupportEmail],
            Message: {
              Subject: { Charset: 'UTF-8', Data: `Password updated successfully` },
              Body: {
                Html: {
                  Charset: 'UTF-8',
                  Data: emailTemplate({
                    title: `Password updated`,
                    body: /*html*/ `
                          <br />
                          The password for the ${organizationName} account 
                          <a class='text-color'>${maskEmail(email)}</a>
                          has been updated successfully.`,
                    footer: null,
                    backgroundOrg: backgroundEmail,
                    orgLogo: organizationLogo
                  })
                }
              }
            },
            Source: `${organizationName} <notifications+${organizationId}@everprep.com>`
          };
          return JSON.stringify(mailParams);
        } else {
          setErrorPasswordChange('Not a valid email, please try again.');
        }
      }
      break;
    case 'forgetPasswordClick': //Reset Password Request
      {
        const {
          email,
          verificationCode,
          backgroundEmail,
          organizationLogo,
          organizationSupportEmail,
          organizationName,
          organizationId,
          setErrorPasswordChange
        } = props;

        let validEmail = validateEmail(email);
        if (validEmail) {
          const mailParams = {
            Destination: { ToAddresses: [email] },
            ReplyToAddresses: [organizationSupportEmail],
            Message: {
              Subject: { Charset: 'UTF-8', Data: 'Password reset code' },
              Body: {
                Html: {
                  Charset: 'UTF-8',
                  Data: emailTemplate({
                    title: `Password reset code`,
                    body: /*html*/ `
                          <br />
                          Please use this code to reset the password for the ${organizationName} account 
                          <a class='text-color'>${maskEmail(email)}</a>.
                          <br /><br />
                          Here is your code: <strong>${verificationCode} </strong>
                          <br /><br />`,
                    footer: /*html*/ `
                          If you don't recognize the ${organizationName} account 
                          <a class='text-color'>${maskEmail(email)}</a>,
                          please disregard this email.`,
                    backgroundOrg: backgroundEmail,
                    orgLogo: organizationLogo
                  })
                }
              }
            },
            Source: `${organizationName} <notifications+${organizationId}@everprep.com>`
          };
          return JSON.stringify(mailParams);
        } else {
          setErrorPasswordChange('Not a valid email, please try again.');
        }
      }
      break;
    case 'sendConfirmationEmail': {
      //Updated Email Address Notification
      const {
        emailValue,
        newMail,
        backgroundEmail,
        organizationLogo,
        organizationSupportEmail,
        organizationName,
        organizationId
      } = props;
      const mailParams = {
        Destination: { ToAddresses: [emailValue, newMail] },
        ReplyToAddresses: [organizationSupportEmail],
        Message: {
          Subject: { Charset: 'UTF-8', Data: `Email changed successfully` },
          Body: {
            Html: {
              Charset: 'UTF-8',
              Data: emailTemplate({
                title: `Email changed successfully`,
                body: /*html*/ `
                      <br />
                        The email address for ${organizationName} account 
                      <a class='text-color'>${maskEmail(emailValue)}</a>
                        has successfully been changed to 
                      <a class='text-color'>${maskEmail(newMail)}</a>.
                      <br /><br />`,
                footer: /*html*/ `
                      If you did not make this request, please contact support by replying to this email.`,
                orgLogo: organizationLogo,
                backgroundOrg: backgroundEmail
              })
            }
          }
        },
        Source: `${organizationName} <notifications+${organizationId}@everprep.com>`
      };
      return JSON.stringify(mailParams);
    }
    case 'sendCodeByEmail': {
      //Update Email Address Request
      const {
        newMail,
        code,
        backgroundEmail,
        organizationLogo,
        organizationSupportEmail,
        organizationName,
        organizationId
      } = props;
      const mailParams = {
        Destination: { ToAddresses: [newMail] },
        ReplyToAddresses: [organizationSupportEmail],
        Message: {
          Subject: { Charset: 'UTF-8', Data: `Email change confirmation code` },
          Body: {
            Html: {
              Charset: 'UTF-8',
              Data: emailTemplate({
                title: `Email change confirmation code
                `,
                body: /*html*/ `
                      <br />
                      Please use this code to change the email address for ${organizationName} account 
                      <a class='text-color'>${maskEmail(newMail)}</a>.
                      <br /><br />
                      Here is your code: <strong>${code} </strong>
                      <br /><br />`,
                footer: /*html*/ `
                      If you did not request this account be set up, please ignore this message.`,
                orgLogo: organizationLogo,
                backgroundOrg: backgroundEmail
              })
            }
          }
        },
        Source: `${organizationName} <notifications+${organizationId}@everprep.com>`
      };
      return JSON.stringify(mailParams);
    }
    case 'sendConfirmationPasswordEmailLogInfo': {
      //Updated Password Notification
      const {
        emailValue,
        backgroundEmail,
        organizationLogo,
        organizationSupportEmail,
        organizationName,
        organizationId
      } = props;
      const mailParams = {
        Destination: { ToAddresses: [emailValue] },
        ReplyToAddresses: [organizationSupportEmail],
        Message: {
          Subject: { Charset: 'UTF-8', Data: `Password updated successfully` },
          Body: {
            Html: {
              Charset: 'UTF-8',
              Data: emailTemplate({
                title: `Password updated`,
                body: /*html*/ `
                      <br />
                      The password for the ${organizationName} account 
                      <a class='text-color'>${maskEmail(emailValue)}</a>
                      has been updated successfully.`,
                footer: null,
                backgroundOrg: backgroundEmail ? backgroundEmail : '#ffffff',
                orgLogo: organizationLogo
              })
            }
          }
        },
        Source: `${organizationName} <notifications+${organizationId}@everprep.com>`
      };
      return JSON.stringify(mailParams);
    }
    case 'studentSignsUp': {
      const {
        email,
        courseName,
        backgroundEmail,
        organizationLogo,
        organizationSupportEmail,
        organizationName,
        organizationId,
        registrationLink
      } = props;
      const mailParams = {
        Destination: { ToAddresses: [email] },
        ReplyToAddresses: [organizationSupportEmail],
        Message: {
          Subject: {
            Charset: 'UTF-8',
            Data: `Welcome to ${organizationName}!`
          },
          Body: {
            Html: {
              Charset: 'UTF-8',
              Data: emailTemplate({
                title: `<h1>Welcome to ${organizationName}!</h1>`,
                body: /*html*/ `
                <br />
                You have successfully signed up for our <strong>${courseName}</strong> course.
                ${
                  registrationLink
                    ? `
                      <br />
                      <br />
                      <a href='${registrationLink}'><u>Click here</u></a> to login and begin studying.`
                    : ''
                }
                `,
                footer: null,
                backgroundOrg: backgroundEmail,
                orgLogo: organizationLogo
              })
            }
          }
        },
        Source: `${organizationName} <notifications+${organizationId}@everprep.com>`
      };
      return JSON.stringify(mailParams);
    }
    case 'studentGetCertificate': {
      const {
        email,
        courseName,
        backgroundEmail,
        organizationLogo,
        organizationSupportEmail,
        organizationName,
        organizationId,
        certificateLink
      } = props;
      const mailParams = {
        Destination: { ToAddresses: [email] },
        ReplyToAddresses: [organizationSupportEmail],
        Message: {
          Subject: {
            Charset: 'UTF-8',
            Data: `Certificate of Completion`
          },
          Body: {
            Html: {
              Charset: 'UTF-8',
              Data: emailTemplate({
                title: `<h1>Congratulations!</h1>`,
                body: /*html*/ `
                <br />
                You have earned your certificate of completion for the <strong>${courseName}</strong> course. To download your certificate,
                <a href='${certificateLink}'><u>click here.</u></a>
                <br />
                This link expires in 24 hours. You can also download your certificate from your course dashboard.
                `,
                footer: null,
                backgroundOrg: backgroundEmail,
                orgLogo: organizationLogo
              })
            }
          }
        },
        Source: `${organizationName} <notifications+${organizationId}@everprep.com>`
      };
      return JSON.stringify(mailParams);
    }
    case 'studentGetCertificateToCreator': {
      const {
        accuracy,
        backgroundEmail,
        nAttempt,
        organizationId,
        organizationLogo,
        organizationName,
        organizationSupportEmail,
        questionsAnswered,
        score,
        studentCreationDate,
        studentEmail,
        studentName,
        timeStudied
      } = props;
      const mailParams = {
        Destination: { ToAddresses: [organizationSupportEmail] },
        Message: {
          Subject: { Charset: 'UTF-8', Data: `${studentName} earned their certificate` },
          Body: {
            Html: {
              Charset: 'UTF-8',
              Data: emailTemplate({
                title: `${studentName} earned their certificate`,
                body: /*html*/ `
                <br />
                <strong>${studentName}</strong> has passed the exam with a score of ${score}% and has been awarded a certificate. This was the ${nAttempt} attempt.
                <br />
                <br />
                User details:
                <br />
                <ul>
                  <li>Email: ${studentEmail}</li>
                  <li>Account created on ${studentCreationDate}</li>
                  <li>Total number of practice questions answered: ${questionsAnswered}</li>
                  <li>Overall answer accuracy: ${accuracy}%</li>
                  <li>Total time studied: ${timeStudied}</li>
                </ul>
                `,
                footer: null,
                backgroundOrg: backgroundEmail,
                orgLogo: organizationLogo
              })
            }
          }
        },
        Source: `${organizationName} <notifications+${organizationId}@everprep.com>`
      };
      return JSON.stringify(mailParams);
    }
    default:
      return null;
  }
};

export default sendEmailProcess;
