import { all, select, call, takeEvery } from 'redux-saga/effects';
import { API, graphqlOperation } from 'aws-amplify';
// Selectors
import { getUserData } from '../selectors/userSelectors';
import { getOrganizationDataReducer } from '../selectors/organizationSelector';
// Lambda
import { handleCatchErrorStudents } from '../graphql/queries/callsToLambdaFunctions';
// Constants
import { envName, ClientId } from '../components/Common/const';
import projectVersioned from '../utils/projectVersioned';

function* handleCatchErrorSagas(action) {
  try {
    const [userData, organizationData] = yield all([
      select(getUserData),
      select(getOrganizationDataReducer)
    ]);
    const { error, message } = action.payload;
    let errorParsed = "";
    if (error instanceof Error) {
      errorParsed = JSON.stringify(error && error.message);
    } else {
      if (typeof error === 'object') {
        if (error && error.errors) {
          error.errors.forEach(e => {
            if (e && e.message) {
              errorParsed = errorParsed.concat(e.message);
            }
          })
        } else {
          if (error && error.message) {
            errorParsed = JSON.stringify(error && error.message);
          }
        }
      } else {
        errorParsed = error;
      }
    }
    const errorCatched = [`${errorParsed} - ${message}`];
    let showConsoleLog = process.env.REACT_APP_IN_DEVELOPMENT === 'true';
    let sendToLambda = envName === 'stage' || envName === 'production';
    // Prevent send the error of resfresh token to the lambda
    if (
      sendToLambda &&
      errorCatched &&
      errorCatched[0] &&
      errorCatched[0].includes('Refresh Token')
    ) {
      sendToLambda = false;
    }
    if (window.location.href.includes('localhost')) {
      sendToLambda = false;
      showConsoleLog = true;
    }
    if (sendToLambda) {
      const date = new Date();
      // here we store all data to send to the lambda, we will stringify this object
      // you can put here all data you want to send to the lambda
      // then the lambda will log this data in the cloudwatch logs
      let userEmail =
        userData && userData.attributes && userData.attributes.email
          ? userData.attributes.email
          : 'no userEmail';
      if (userEmail === 'no userEmail' && localStorage && localStorage.getItem('storageEmail')) {
        userEmail = localStorage.getItem('storageEmail');
        localStorage.removeItem('storageEmail');
      }
      const userId = userData ? userData.id : 'no userId';
      const organizationId = organizationData ? organizationData.id : 'no organizationId';
      const dateFormatted = date
        ? `${date.getFullYear()}-${date.getMonth() + 1
        }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
        : 'no date';
      const errorPayload = {
        organizationId,
        ClientId,
        userId,
        userEmail,
        envName,
        currentUrl: window.location.href,
        systemVersion: projectVersioned(),
        dateFormatted,
        errorCatched
      };
      // execute lambda handleCatchErrorStudentss
      yield call(
        [API, 'graphql'],
        graphqlOperation(handleCatchErrorStudents, {
          catchedError: JSON.stringify(errorPayload)
        })
      );
    }
    if (showConsoleLog) {
      // eslint-disable-next-line
      console.log('catchedError: ', action.payload);
    }
  } catch (error) {
    // eslint-disable-next-line
    console.log('error in handleCatchError.js: ', error);
  }
  return null;
}

function* handleCatchErrorSagasWatcher() {
  yield takeEvery('HANDLE_CATCH_ERROR', handleCatchErrorSagas);
}

export default function* sagas() {
  yield all([handleCatchErrorSagasWatcher()]);
}
