export const GET_BILLING_PORTAL = 'myAccount/GET_BILLING_PORTAL';

export const SET_PAYMENT = 'myAccount/SET_PAYMENT';
export const GET_PAYMENT = 'myAccount/GET_PAYMENT';
export const UPDATE_PAYMENT = 'myAccount/UPDATE_PAYMENT';
export const CANCEL_SUBSCRIPTION = 'myAccount/CANCEL_SUBSCRIPTION';

export const SEND_STUDENT_FEEDBACK = 'getHelp/SEND_STUDENT_FEEDBACK';
export const CREATE_STRIPE_CUSTOMER_AND_PAYMENT = 'myAccount/CREATE_STRIPE_CUSTOMER_AND_PAYMENT';
export const PAYMENT_DATE = 'myAccount/PAYMENT_DATE';
export const GET_PAYMENT_DATE = 'myAccount/GET_PAYMENT_DATE';
export const PAYMENT_PRICE = 'myAccount/PAYMENT_PRICE';
export const PAYMENT_AMOUNT = 'myAccount/PAYMENT_AMOUNT';
export const PAYMENT_TYPE = 'myAccount/PAYMENT_TYPE';
export const REMOVE_PAYMENT_METHOD = 'myAccount/REMOVE_PAYMENT_METHOD';
export const SET_BILLING_INFO = 'myAccount/SET_BILLING_INFO';
export const SET_OPEN_SUBSCRIPTION = 'myAccount/SET_OPEN_SUBSCRIPTION';

export const CHECK_SUBSCRIPTION = 'courseHome/CHECK_SUBSCRIPTION';
export const HANDLE_COURSE_PREVIEW_SUBSCRIPTION_ACTIVE =
  'myAccount/HANDLE_COURSE_PREVIEW_SUBSCRIPTION_ACTIVE';
export const HANDLE_STUDENT_SUBSCRIPTION = 'HANDLE_STUDENT_SUBSCRIPTION';
