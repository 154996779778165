import getTimeZoneAbbr from './getTimezone';

const convertHourToLocal = date => {
  // Example: 12:48 AM ATM
  const timeZone = getTimeZoneAbbr(date);
  let hours = new Date(date).getHours();
  let mins = new Date(date).getMinutes();
  let meridiem = 'AM';
  if (hours > 12) {
    hours -= 12;
    meridiem = 'PM';
  }
  if (mins < 10) mins = `0${mins}`;
  return `${hours}:${mins} ${meridiem} ${timeZone}`;
};

export default convertHourToLocal;
