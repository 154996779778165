import { takeLatest, all, put, select, call } from 'redux-saga/effects';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import uuid4 from 'uuid4';
// Actions
import * as studySessionActionTypes from '../actions/actionTypes/studySessionActionTypes';
import * as studySessionAction from '../actions/studySessionActions';
import * as courseActions from '../actions/courseActions';
import * as notificationsActions from '../actions/errorHandlerActions';
import { userAnswers } from '../actions/userActions';
import { trackStudentStats } from '../actions/contentActions';
import { sendReportEmailToOrganization } from '../actions/organizationActions';
import { startLoading, stopLoading } from '../actions/loaderHandlerActions';
// Selectors
import {
  getStudySessionResultsReducer,
  getStackedQuestions,
  getStudySessionData,
  getStackedAnswers,
  getStudentAnswerReducer,
  getActualQuestionReducer,
  getPreloadedQuestions,
  getPreloadedFirstQuestionsReducer,
  getLoadedQuestionsReducer,
  getNotElapsedQuestions
} from '../selectors/studySessionSelectors';
import {
  getCourseDataReducer,
  getCourseQuestionsSelector,
  getCourseNameReducer
} from '../selectors/Course/courseSelector';
import {
  getUserData,
  getStudentDataReducer,
  getUserAnswersSelector
} from '../selectors/userSelectors';
import { getOrganizationDataReducer } from '../selectors/organizationSelector';
// Queries
import * as studySessionQueries from '../graphql/queries/studySessionQueries';
import { stdGetFileDataQuerie } from '../graphql/queries/contentQueries';
import { stdGetCourseNotificationsQuerie } from '../graphql/queries/studentQueries';
import { stdGetCourseActionRequiredQuerie } from '../graphql/queries/courseQueries';
import * as orgQueries from '../graphql/queries/organizationQueries';
import { stdGetOpenExamDataQuerie } from '../graphql/queries/examQueries';
import { stdRetrieveStudentAnswerQuerie } from '../graphql/queries/callsToLambdaFunctions';
// Mutations
import * as studySessionMutations from '../graphql/mutationsStudySession';
import * as questionMutations from '../graphql/mutationsQuestion';
import * as studentMutations from '../graphql/mutationsStudent';
import { updateCourseActionRequired } from '../graphql/mutationsCourse';
//External files
import GraphOp from '../sagas/common/GraphOp';
import StorageGet from '../sagas/common/StorageGet';
const oneMin = 0.00069444444;
const tenMins = 0.00694444444;
const numberOfPreloadedQts = 15;

// SAGAS FUNCTIONS

function* getStudyModesSagas(action) {
  try {
    const { stackedQuestions, courseData } = action.payload;
    const [student, organization] = yield all([
      select(getUserData),
      select(getOrganizationDataReducer)
    ]);
    const studyModes = [];
    const courseId = courseData && courseData.id ? courseData.id : null;
    const organizationId = organization && organization.id ? organization.id : null;
    const studentId = student && student.username ? student.username : null;
    if (courseId && organizationId && studentId) {
      const arrayOfQueries = [];
      // Get exams
      arrayOfQueries.push(
        call(
          [API, 'graphql'],
          graphqlOperation(stdGetOpenExamDataQuerie, {
            courseID: courseId
          })
        )
      );
      // Get last session executed
      arrayOfQueries.push(
        call(
          [API, 'graphql'],
          graphqlOperation(studySessionQueries.stdGetStudentLastSessionsQuerie, {
            studentId,
            organizationId
          })
        )
      );
      let [openExamsArray, sessions] = yield all(arrayOfQueries);
      // #1 mode: Study all
      if (stackedQuestions && stackedQuestions.length > 0) {
        const studyAll = {};
        studyAll.mode = 'StudyAll';
        studyAll.name = 'Study all questions at once';
        studyAll.number = stackedQuestions.length;
        studyAll.buttonLabel = `${stackedQuestions.length} ${
          stackedQuestions.length === 1 ? 'question' : 'questions'
        }`;
        studyAll.disabled =
          courseData &&
          courseData.openStudyText === 'No questions' &&
          courseData.questionsToStudy === 0;
        studyModes.push(studyAll);
      } else {
        // #2 mode: Open Study
        if (courseData && courseData.openStudyMode) {
          const openStudyMode = {};
          openStudyMode.mode = 'OpenStudyMode';
          openStudyMode.name = 'Questions on Open Study Mode';
          openStudyMode.number = courseData.notElapsed ? courseData.notElapsed.length : 0;
          openStudyMode.buttonLabel = 'Open Study';
          if (openStudyMode.number === 0) {
            openStudyMode.buttonLabel = 'No questions';
            openStudyMode.disabled = true;
          }
          studyModes.push(openStudyMode);
        }
      }
      // #3 mode: X exams avalaibles
      openExamsArray =
        openExamsArray &&
        openExamsArray.data &&
        openExamsArray.data.listExams &&
        openExamsArray.data.listExams.items &&
        openExamsArray.data.listExams.items.length > 0
          ? openExamsArray.data.listExams.items
          : [];
      let numberOfExams = 0;
      if (openExamsArray && openExamsArray.length > 0) {
        numberOfExams = openExamsArray.filter(item => item && item.status === 'ACTIVE').length;
        if (numberOfExams > 0) {
          const practiceExams = {};
          practiceExams.mode = 'PracticeExams';
          practiceExams.name = 'Take a practice exam';
          practiceExams.number = numberOfExams;
          practiceExams.buttonLabel = 'Go to practice exams';
          studyModes.push(practiceExams);
        }
      }
      // #4 and #5 mode: New and Study again questions
      if (stackedQuestions && stackedQuestions.length > 0) {
        // Filter by classification
        let newQt = stackedQuestions.filter(qt => qt && qt.studyStatus === 'NEW');
        let learningQt = stackedQuestions.filter(qt => qt && qt.studyStatus === 'LEARNING');
        let reviewQt = stackedQuestions.filter(qt => qt && qt.studyStatus === 'REVIEW');
        // Empty cases
        if (!newQt || newQt.length === 0) newQt = [];
        if (!learningQt || learningQt.length === 0) learningQt = [];
        if (!reviewQt || reviewQt.length === 0) reviewQt = [];
        // #4 mode: New questions
        if (newQt.length > 0) {
          const newQuestions = {};
          newQuestions.mode = 'NewQuestions';
          newQuestions.name = `Only study questions you haven't seen before`;
          newQuestions.number = newQt.length;
          newQuestions.buttonLabel = `${newQt.length} ${
            newQt.length === 1 ? 'question' : 'questions'
          }`;
          newQuestions.stack = newQt;
          studyModes.push(newQuestions);
        }
        // #5 mode: Study again
        // Calculation of total
        let totalOfQt = 0;
        if (learningQt && learningQt.length) totalOfQt = totalOfQt + learningQt.length;
        if (reviewQt && reviewQt.length) totalOfQt = totalOfQt + reviewQt.length;
        if (totalOfQt > 0) {
          const studyAgain = {};
          studyAgain.mode = 'StudyAgain';
          studyAgain.name = 'Only study questions you recently answered incorrectly';
          studyAgain.number = totalOfQt;
          studyAgain.buttonLabel = `${totalOfQt} ${totalOfQt === 1 ? 'question' : 'questions'}`;
          studyAgain.stack = reviewQt.concat(learningQt);
          /*
          I leave this commented as this way we can reactivate the popover if necessary.
          studyAgain.hasPopover = true;
          studyAgain.popoverInfo = {
            learningText:
              "Questions that you indicated your are having trouble with",
            reviewText: "Questions that you are confident in, but should review",
            learningTotal: learningQt.length,
            reviewTotal: reviewQt.length,
          };
          */
          studyModes.push(studyAgain);
        }
      }
      // #6 mode: Study the questions you reviewed last visit
      sessions =
        sessions &&
        sessions.data &&
        sessions.data.getStudent &&
        sessions.data.getStudent.lastStudySessions
          ? sessions.data.getStudent.lastStudySessions
          : [];
      if (sessions && sessions.length > 0) {
        sessions.forEach(item => {
          if (
            item &&
            item.courseID &&
            item.courseID === courseId &&
            item.questionStack &&
            item.questionStack.length > 0
          ) {
            const stack = [];
            item.questionStack.forEach(qt => stack.push({ id: qt }));
            if (stack.length > 0) {
              const lastSession = {};
              lastSession.mode = 'LastSession';
              lastSession.name = 'Repeat the last session you did';
              lastSession.number = stack.length;
              lastSession.buttonLabel = 'Begin';
              lastSession.stack = stack;
              studyModes.push(lastSession);
            }
          }
        });
      }
    }
    yield all([
      put(studySessionAction.setStackedQuestions(stackedQuestions)),
      put(studySessionAction.studyModes(studyModes)),
      put(studySessionAction.loadedQuestions([])),
      put(courseActions.loadingStudyModes(false)),
      put(courseActions.setPercentageLoadingScreen(0)),
      put(courseActions.setLoadingScreen(false))
    ]);
  } catch (err) {
    yield put(courseActions.loadingStudyModes(false));
    yield put(notificationsActions.handleCatchError(err, 'getStudyModesSagas'));
  }
}

function* preloadFirstQuestionsSagas(action) {
  try {
    const [student, preloaded, answers] = yield all([
      select(getUserData),
      select(getPreloadedFirstQuestionsReducer),
      select(getUserAnswersSelector)
    ]);
    const { sections, courseId } = action.payload;
    const studentId = student && student.username ? student.username : null;
    const arrayOfQueries = [];
    const cleanedArray = [];
    if (courseId && studentId && sections && sections.length > 0) {
      // Map sections
      sections.forEach(section => {
        if (section && section.topics && section.topics.length > 0) {
          // Map question sets
          section.topics.forEach(questionSet => {
            // Get the first question of each question set
            if (
              questionSet &&
              questionSet.type &&
              questionSet.type === 'QUESTION_SET' &&
              questionSet.stackedQuestions &&
              questionSet.stackedQuestions[0] &&
              questionSet.stackedQuestions[0].id
            ) {
              const firtQtId = questionSet.stackedQuestions[0].id;
              if (firtQtId) {
                let thisQuestion = null;
                // We search if it exists in the array of the preloaded qts.
                if (preloaded && preloaded.length > 0) {
                  thisQuestion = preloaded.find(qt => qt && qt.id && qt.id === firtQtId);
                  // Search for the user answer related to this question
                  if (thisQuestion) {
                    let thisAnswer = [];
                    if (answers && answers.length > 0) {
                      thisAnswer = answers.find(
                        a => a && a.questionID && a.questionID === firtQtId
                      );
                    }
                    if (thisAnswer) thisQuestion.userAnswer = thisAnswer;
                    else thisQuestion.userAnswer = [];
                  }
                }
                // If it exists, there is no need to load it again
                if (thisQuestion) cleanedArray.push(thisQuestion);
                else {
                  arrayOfQueries.push(
                    call(
                      [API, 'graphql'],
                      graphqlOperation(studySessionQueries.stdGetQuestionQuerie, {
                        questionId: firtQtId,
                        courseId
                      })
                    )
                  );
                  // Validate if the question anchored
                  if (
                    questionSet.stackedQuestions[0] &&
                    questionSet.stackedQuestions[0].anchored &&
                    questionSet.stackedQuestions[0].anchored.questionsIDs &&
                    questionSet.stackedQuestions[0].anchored.questionsIDs.length > 0
                  ) {
                    // If it is anchored we scan the children also
                    questionSet.stackedQuestions[0].anchored.questionsIDs.forEach(id => {
                      arrayOfQueries.push(
                        call(
                          [API, 'graphql'],
                          graphqlOperation(studySessionQueries.stdGetQuestionQuerie, {
                            questionId: id,
                            courseId
                          })
                        )
                      );
                    });
                  }
                  // Get answer associated with the user and the question
                  arrayOfQueries.push(
                    call(
                      [API, 'graphql'],
                      graphqlOperation(stdRetrieveStudentAnswerQuerie, {
                        questionId: firtQtId,
                        studentId
                      })
                    )
                  );
                }
              }
            }
          });
        }
      });
    }
    if (arrayOfQueries && arrayOfQueries.length < 30) {
      const response = yield all(arrayOfQueries);
      const questions = [];
      const answers = [];
      if (response && response.length > 0) {
        response.forEach(item => {
          if (item && item.data && item.data.getQuestion) {
            const question = item.data.getQuestion;
            if (question.type === 'ANCHORED') {
              // Analyze whether parent or child
              const isFather =
                question &&
                question.id &&
                question.anchored &&
                question.anchored.questionID &&
                question.id === question.anchored.questionID;
              if (
                isFather &&
                question.anchored &&
                question.anchored.questionsIDs &&
                question.anchored.questionsIDs.length > 0
              ) {
                question.childrenQuestions = [];
                // We are looking for children
                question.anchored.questionsIDs.forEach(child => {
                  let childrenQt = response.find(
                    aux =>
                      aux && aux.data && aux.data.getQuestion && aux.data.getQuestion.id === child
                  );
                  if (childrenQt && childrenQt.data && childrenQt.data.getQuestion) {
                    childrenQt = childrenQt.data.getQuestion;
                    // See if is multiple choice
                    if (childrenQt.type === 'MULTI_CHOICE') {
                      childrenQt = processMultipleChoiceQuestion(childrenQt);
                    }
                    question.childrenQuestions.push(childrenQt);
                  }
                });
                if (question.childrenQuestions.length > 0) questions.push(question);
              }
            } else {
              // We make sure it is not anchored
              if (question && !question.anchored) questions.push(question);
            }
          } else {
            // If it's not a question it's an answer
            if (item && item.data && item.data.retrieveStudentAnswer) {
              answers.push(JSON.parse(item.data.retrieveStudentAnswer));
            }
          }
        });
      }
      if (questions && questions.length > 0) {
        questions.forEach((qt, index) => {
          if (qt) {
            qt.userAnswer = answers[index];
            cleanedArray.push(qt);
          }
        });
      }
    }
    yield all([put(studySessionAction.preloadedFirstQuestions(cleanedArray))]);
  } catch (err) {
    yield put(notificationsActions.handleCatchError(err, 'preloadFirstQuestionsSagas'));
  }
}

function* startStudySessionSagas(action) {
  // The study session will now have codes to differentiate it from the rest.
  // SS-StudyAll -> Study session - Study all
  // OS-StudyAll -> Open study mode - Study all
  // SS-Topic -> Study session - Question set
  // OS-Topic -> Open study - Question set
  const { allQuestions, redirect, sessionInfo, studyMode, resetStudySession, questionsStack } =
    action.payload;
  try {
    const [courseData, courseName, stackedQuestions] = yield all([
      select(getCourseDataReducer),
      select(getCourseNameReducer),
      select(getStackedQuestions),
      put(studySessionAction.actualAnswer(null)),
      put(studySessionAction.actualQuestion(null)),
      put(studySessionAction.respondedCorrectly(false)),
      put(studySessionAction.showSubmit(false)),
      put(studySessionAction.showResults(false)),
      put(studySessionAction.showFinalResults(false)),
      put(studySessionAction.setStackedAnswers([])),
      put(studySessionAction.preloadedQuestions([])),
      put(studySessionAction.studySessionData(null)),
      put(studySessionAction.studySessionResults(null))
    ]);
    let data = {};
    if (courseData && courseData.activeSubscription) data.stopSession = false;
    else data.stopSession = true;
    let questionsInThisSession;
    if (allQuestions) {
      // This case is made for the study all case.
      questionsInThisSession =
        stackedQuestions && stackedQuestions.length > 0 ? stackedQuestions : [];
      data.id = courseData && courseData.id ? courseData.id : '';
      data.name = courseName;
      data.studyMode = 'SS-StudyAll';
      data.enableNotifications = false;
    } else {
      if (studyMode === 'SS-StudyAgain' || studyMode === 'SS-NewQuestions') {
        questionsInThisSession = questionsStack && questionsStack.length > 0 ? questionsStack : [];
        if (studyMode === 'SS-StudyAgain') data.name = `Study again - ${courseName}`;
        else data.name = `New questions - ${courseName}`;
        data.id = null;
      } else {
        if (sessionInfo) {
          questionsInThisSession = sessionInfo.stackedQuestions ? sessionInfo.stackedQuestions : [];
          data.name = sessionInfo.name;
          data.id = sessionInfo.id;
        }
      }
      const notElapsedQuestions = sessionInfo && sessionInfo.notElapsed;
      // I change the queue of questions to the queue of this topic.
      yield all([
        put(studySessionAction.setStackedQuestions(questionsInThisSession)),
        put(studySessionAction.setNotElapsedQuestions(notElapsedQuestions))
      ]);
      data.studyMode = studyMode;
      data.notificationsArray = [];
      data.enableNotifications = false;
    }
    data.totalOfQuestions = questionsInThisSession.length;
    data.thisSession = [...questionsInThisSession];
    // I get the first question to be displayed,
    // Send it to the getActualQuestion function and leave the queue ready to display.
    const firstQuestion =
      questionsInThisSession && questionsInThisSession.length > 0
        ? questionsInThisSession.shift()
        : null;
    // Empty study session results
    const emptyResults = {
      questionsAnswered: 0,
      questionsAnsweredOk: 0,
      accuracy: 0,
      finishSession: false
    };
    if (firstQuestion && firstQuestion.id) {
      yield all([
        put(studySessionAction.studySessionResults(emptyResults)),
        put(
          studySessionAction.setActualQuestion(
            firstQuestion.id,
            redirect,
            resetStudySession,
            null,
            true
          )
        ),
        put(studySessionAction.studySessionData(data)),
        put(courseActions.setTopicData(data))
      ]);
    } else {
      yield put(
        notificationsActions.handleCatchError(
          'Question not available. Please try again.',
          'startStudySessionSagas'
        )
      );
    }
    window.analytics.track('Click to launch question set');
  } catch (err) {
    yield put(notificationsActions.handleCatchError(err, 'startStudySessionSagas'));
  }
}

function* setActualQuestionSagas(action) {
  try {
    const [
      courseData,
      student,
      preloadedQuestions,
      stackedQuestions,
      sessionInfo,
      preloadedFirstQuestions,
      loadedQuestions,
      userAnswers
    ] = yield all([
      select(getCourseDataReducer),
      select(getUserData),
      select(getPreloadedQuestions),
      select(getStackedQuestions),
      select(getStudySessionData),
      select(getPreloadedFirstQuestionsReducer),
      select(getLoadedQuestionsReducer),
      select(getUserAnswersSelector),
      put(startLoading()),
      put(studySessionAction.actualAnswer(null)),
      put(studySessionAction.respondedCorrectly(false)),
      put(studySessionAction.showSubmit(false)),
      put(studySessionAction.showResults(false)),
      put(studySessionAction.showFinalResults(false))
    ]);
    // Payload data
    const { questionId, redirect, resetStudySession, status, firstQuestion } = action;
    // Constants assignment
    const courseId = courseData && courseData.id ? courseData.id : null;
    const studentId = student && student.username ? student.username : null;
    const isOpenStudy = sessionInfo && sessionInfo.openStudyMode;
    const deleteStudentAnswerMutations = [];
    let question = null;
    let userAnswer = null;
    let preloadedQts = [];
    let loadedQts = loadedQuestions && loadedQuestions.length > 0 ? [...loadedQuestions] : [];
    let fillPreloadedArray = false;
    /*
     CASE 0: Fist question of each question set
    - It is indicated at the time of starting the study session to search in a particular array for the questions already preloaded.
    - If not found, continue with case 1.
    CASE 1: The array of preloaded questions is empty: 
    - Ask for the current question and the next 5.
    - The query is made and separated between the one we are looking for now and the following questions to be looked for later.
    - The current question and the array of preloaded questions are saved in redux.

    CASE 2: The array of questions contains elements:
    - The question is searched in the preloaded array
      - If found: Assigned to the current question, removed from the preloaded array and updated.
      - If it is not found: The load is forced by performing querie (This case not must happen)
    */
    if (!preloadedQuestions || preloadedQuestions.length === 0) {
      fillPreloadedArray = true;
      if (
        questionId &&
        firstQuestion &&
        preloadedFirstQuestions &&
        preloadedFirstQuestions.length > 0
      ) {
        const belongs = preloadedFirstQuestions.find(qt => qt && qt.id && qt.id === questionId);
        if (belongs) {
          question = belongs;
          userAnswer = belongs && belongs.userAnswer ? belongs.userAnswer : [];
        }
      }
      if (!question) {
        const queries = [];
        // The question that we are looking
        queries.push(
          call(
            [API, 'graphql'],
            graphqlOperation(studySessionQueries.stdGetQuestionQuerie, {
              questionId,
              courseId
            })
          )
        );
        // Answer for this question
        if (!isOpenStudy) {
          queries.push(
            call(
              [API, 'graphql'],
              graphqlOperation(stdRetrieveStudentAnswerQuerie, {
                questionId,
                studentId
              })
            )
          );
        }
        const [qtResponse, answerResponse] = yield all(queries);
        question =
          qtResponse && qtResponse.data && qtResponse.data.getQuestion
            ? qtResponse.data.getQuestion
            : null;
        userAnswer = answerResponse;
        if (userAnswer && userAnswer.data && userAnswer.data.retrieveStudentAnswer) {
          userAnswer = JSON.parse(userAnswer.data.retrieveStudentAnswer);
        }
      }
    } else {
      const thisQtIndex = preloadedQuestions.findIndex(qt => qt && qt.id === questionId);
      if (thisQtIndex !== -1) {
        // It is preloaded, so we save ourselves the query
        // Save the question and delete from the array
        const thisQt = preloadedQuestions.splice(thisQtIndex, 1);
        if (thisQt && thisQt.length > 0) {
          question = thisQt.pop();
          userAnswer = question && question.userAnswer ? question.userAnswer : [];
        }
      } else {
        // Case in which the question is not preloaded
        // We see if we have already loaded it previously
        if (loadedQuestions && loadedQuestions.length > 0) {
          const index = loadedQuestions.findIndex(qt => qt && qt.id === questionId);
          if (index !== -1) {
            let answer = [];
            if (userAnswers && userAnswers.length > 0) {
              const thisAnswer = userAnswers.find(
                a => a && a.questionID && a.questionID === questionId
              );
              if (thisAnswer) answer.push(thisAnswer);
            }
            question = loadedQuestions[index];
            userAnswer = answer;
          }
        }
        if (!question) {
          // Force the query and get the question
          const forceResponse = yield GraphOp(studySessionQueries.stdGetQuestionQuerie, {
            questionId,
            courseId
          });
          // Force the user answer
          userAnswer = yield GraphOp(stdRetrieveStudentAnswerQuerie, {
            questionId,
            studentId
          });
          if (userAnswer && userAnswer.data && userAnswer.data.retrieveStudentAnswer) {
            userAnswer = JSON.parse(userAnswer.data.retrieveStudentAnswer);
          }
          question =
            forceResponse && forceResponse.data && forceResponse.data.getQuestion
              ? forceResponse.data.getQuestion
              : null;
        }
      }
      preloadedQts = [...preloadedQuestions];
    }
    if (question) {
      // Handle for anchored questions
      if (question.type === 'ANCHORED') {
        // If the children are not loaded, we have to load them in a new query.
        if (!question.childrenQuestions && question.anchored && question.anchored.questionsIDs) {
          question.childrenQuestions = [];
          const arrayOfQueries = [];
          question.anchored.questionsIDs.forEach(id => {
            arrayOfQueries.push(
              call(
                [API, 'graphql'],
                graphqlOperation(studySessionQueries.stdGetQuestionQuerie, {
                  questionId: id,
                  courseId
                })
              )
            );
          });
          if (arrayOfQueries && arrayOfQueries.length > 0) {
            const response = yield all(arrayOfQueries);
            if (response && response.length > 0) {
              response.forEach(children => {
                if (children && children.data && children.data.getQuestion) {
                  let cQt = children.data.getQuestion;
                  if (cQt.type === 'MULTI_CHOICE') cQt = processMultipleChoiceQuestion(cQt);
                  question.childrenQuestions.push(cQt);
                }
              });
            }
          }
        }
        // Handle files on children questions
        if (question.childrenQuestions && question.childrenQuestions.length > 0) {
          // In this array we will store the calls to obtain information about the file.
          const filesForChildren = [];
          // In this array we will store how to map the children and process the children if necessary.
          const mappingChildrens = [];
          question.childrenQuestions.forEach((item, index) => {
            if (item && item.id) {
              // The question daughter has a file but it has not been processed yet.
              if (item.questionFileId) {
                filesForChildren.push(
                  call(
                    [API, 'graphql'],
                    graphqlOperation(stdGetFileDataQuerie, {
                      courseId,
                      fileId: item.questionFileId
                    })
                  )
                );
                // We indicate the type in order to be able to separate later on
                mappingChildrens.push({ type: 'QUESTION_FILE', childIndex: index });
              }
              // Same procedure as above
              if (item.furtherReadingFileId) {
                filesForChildren.push(
                  call(
                    [API, 'graphql'],
                    graphqlOperation(stdGetFileDataQuerie, {
                      courseId,
                      fileId: item.furtherReadingFileId
                    })
                  )
                );
                mappingChildrens.push({ type: 'FURTHER_READING_FILE', childIndex: index });
              }
            }
          });
          // We validate that they have the same number of mappings as queries.
          if (filesForChildren.length > 0 && mappingChildrens.length === filesForChildren.length) {
            // Calls to storage to obtain the URL of each file
            const storageCalls = [];
            // The processed file that will later be saved in the child
            const processedFiles = [];
            const filesResponse = yield all(filesForChildren);
            if (filesResponse && filesResponse.length > 0) {
              filesResponse.forEach(file => {
                if (file && file.data && file.data.getCourseFile && file.data.getCourseFile.id) {
                  const id = file.data.getCourseFile.id;
                  const key = file.data.getCourseFile.fileKey
                    ? file.data.getCourseFile.fileKey
                    : null;
                  const name = file.data.getCourseFile.name ? file.data.getCourseFile.name : null;
                  if (key && name) {
                    // File ready, just get the link from aws
                    processedFiles.push(file.data.getCourseFile);
                    // Call to storage for get the processed link
                    const path = `${key}/${id}_${name}`;
                    storageCalls.push(call([Storage, 'get'], path));
                  }
                }
              });
            }
            if (storageCalls && storageCalls.length > 0) {
              const storageResponse = yield all(storageCalls);
              // We map the files with their corresponding link
              if (
                storageResponse &&
                storageResponse.length > 0 &&
                processedFiles &&
                processedFiles.length > 0
              ) {
                processedFiles.forEach((file, index) => {
                  file.awsLink = storageResponse[index];
                });
              }
              // Last step: Map the files and match as appropriate to the question
              mappingChildrens.forEach((mappedCase, index) => {
                if (mappedCase && mappedCase.type) {
                  const questionIndex = mappedCase.childIndex;
                  if (mappedCase.type === 'QUESTION_FILE') {
                    // Question file
                    question.childrenQuestions[questionIndex].questionFile = processedFiles[index];
                  } else {
                    // Further reading file
                    question.childrenQuestions[questionIndex].furtherReadingFile =
                      processedFiles[index];
                  }
                }
              });
            }
          }
        }
        // Handle multiple choice images
        if (question.childrenQuestions && question.childrenQuestions.length > 0) {
          // We search if there is a multiple choice image among the children of the question
          const someMCImg = question.childrenQuestions.some(
            child => child && child.type && child.type === 'MULTI_IMAGE_CHOICE'
          );
          // If there is one, we will have to process
          if (someMCImg) {
            // Array of calls
            const filesQuerie = [];
            let filesUrls = [];
            // We process the children and obtain information from the files we need.
            question.childrenQuestions.forEach(children => {
              if (
                children &&
                children.type &&
                children.type === 'MULTI_IMAGE_CHOICE' &&
                children.multipleChoiceImageAnswer &&
                children.multipleChoiceImageAnswer.length > 0
              ) {
                children.multipleChoiceImageAnswer.forEach(option => {
                  if (option && option.choiceId) {
                    filesQuerie.push(
                      call(
                        [API, 'graphql'],
                        graphqlOperation(stdGetFileDataQuerie, {
                          courseId,
                          fileId: option.choiceId
                        })
                      )
                    );
                  }
                });
              }
            });
            if (filesQuerie && filesQuerie.length > 0) {
              const filesResponse = yield all(filesQuerie);
              const storageCalls = [];
              // Process files and form the storage location of the files
              if (filesResponse && filesResponse.length > 0) {
                filesResponse.forEach(file => {
                  if (file && file.data && file.data.getCourseFile) {
                    const info = file.data.getCourseFile;
                    const id = info && info.id ? info.id : null;
                    const name = info && info.name ? info.name : null;
                    const fileKey = info && info.fileKey ? info.fileKey : null;
                    if (id && name && fileKey) {
                      const filePath = `${fileKey}/${id}_${name}`;
                      storageCalls.push(call([Storage, 'get'], filePath));
                    }
                  }
                });
              }
              // Call to storage to obtain file urls
              if (storageCalls && storageCalls.length > 0) filesUrls = yield all(storageCalls);
            }
            // At this point we already have the URLs of all the images in the question
            // All that remains is to process them
            if (filesUrls && filesUrls.length > 0) {
              question.childrenQuestions.forEach(children => {
                const imageAnswer = [];
                let isMCimg = false;
                if (
                  children &&
                  children.type &&
                  children.type === 'MULTI_IMAGE_CHOICE' &&
                  children.multipleChoiceImageAnswer &&
                  children.multipleChoiceImageAnswer.length > 0
                ) {
                  isMCimg = true;
                  const correctOptions = children.multipleChoiceImageAnswer.filter(
                    option => option && option.correct
                  );
                  const numberOfCorrectAnswers = correctOptions.length;
                  if (numberOfCorrectAnswers === 1) {
                    children.choiceText = 'Select the correct image';
                    children.oneOption = true;
                  } else {
                    children.choiceText = 'Select all images that apply';
                    children.oneOption = false;
                  }
                  children.multipleChoiceImageAnswer.forEach(option => {
                    if (option && option.choiceId) {
                      const image = {};
                      image.choiceId = option.choiceId;
                      image.order = option.order ? option.order : null;
                      image.correct = option.correct ? option.correct : false;
                      // Finally, we look for the image link
                      let thisFile = filesUrls.find(item => item && item.includes(option.choiceId));
                      if (thisFile) {
                        image.link = thisFile;
                        imageAnswer.push(image);
                      }
                    }
                  });
                }
                if (imageAnswer.length > 0 && isMCimg) {
                  delete children.multipleChoiceImageAnswer;
                  children.multipleChoiceImageAnswer = imageAnswer.sort(() => Math.random() - 0.5);
                }
              });
            }
          }
        }
      }
      // Handle for files
      if (question.questionFileId || question.furtherReadingFileId) {
        if (question.questionFileId) {
          let fileData = yield GraphOp(stdGetFileDataQuerie, {
            courseId,
            fileId: question.questionFileId
          });
          fileData = fileData.data.getCourseFile;
          if (fileData && fileData.fileKey && fileData.name && fileData.status !== 'DELETED') {
            const { fileKey, name } = fileData;
            const filePath = `${fileKey}/${question.questionFileId}_${name}`;
            fileData.awsLink = yield StorageGet(filePath);
            question.questionFile = fileData;
          }
          delete question.questionFileId;
        }
        if (question.furtherReadingFileId) {
          let fileData = yield GraphOp(stdGetFileDataQuerie, {
            courseId,
            fileId: question.furtherReadingFileId
          });
          fileData = fileData.data.getCourseFile;
          if (fileData && fileData.fileKey && fileData.name && fileData.status !== 'DELETED') {
            const { fileKey, name } = fileData;
            const filePath = `${fileKey}/${question.furtherReadingFileId}_${name}`;
            fileData.awsLink = yield StorageGet(filePath);
            question.furtherReadingFile = fileData;
          }
          delete question.furtherReadingFileId;
        }
      }
      // In case the question is of the type Multiple choice the questions should be in random order except those that are blocked
      if (question.type === 'MULTI_CHOICE') question = processMultipleChoiceQuestion(question);
      // Here you have to get the images for the multiple choice answers.
      if (
        question.type === 'MULTI_IMAGE_CHOICE' &&
        question.multipleChoiceImageAnswer &&
        question.multipleChoiceImageAnswer.length > 0
      ) {
        const correctOptions = question.multipleChoiceImageAnswer.filter(option => option.correct);
        const numberOfCorrectAnswers = correctOptions.length;
        if (numberOfCorrectAnswers === 1) {
          question.choiceText = 'Select the correct image';
          question.oneOption = true;
        } else {
          question.choiceText = 'Select all images that apply';
          question.oneOption = false;
        }
        const imageAnswer = [];
        const queriesArray = [];
        const previewsURLS = [];
        // Map the ids to get the file information.
        question.multipleChoiceImageAnswer.forEach(item => {
          // This object will form the response that will be sent to the component.
          if (item) {
            const image = {};
            const fileId = item.choiceId ? item.choiceId : null;
            image.choiceId = fileId;
            image.order = item.order ? item.order : null;
            image.correct = item.correct ? item.correct : false;
            imageAnswer.push(image);
            // In this array we will store the queries to obtain the file information.
            queriesArray.push(
              call(
                [API, 'graphql'],
                graphqlOperation(stdGetFileDataQuerie, {
                  courseId,
                  fileId
                })
              )
            );
          }
        });
        // Get the files
        let files = [];
        if (queriesArray.length > 0) files = yield all(queriesArray);
        // Map the files to obtain the preview urls
        if (files && files.length > 0) {
          files.forEach(item => {
            if (item && item.data.getCourseFile) {
              const fileInfo = item.data.getCourseFile;
              if (fileInfo && fileInfo.id && fileInfo.name && fileInfo.fileKey) {
                const { id, name, fileKey } = fileInfo;
                if (id && name && fileKey) {
                  const filePath = `${fileKey}/${id}_${name}`;
                  previewsURLS.push(call([Storage, 'get'], filePath));
                }
              }
            }
          });
        }
        let urls = [];
        if (previewsURLS.length > 0) urls = yield all(previewsURLS);
        if (urls.length > 0 && imageAnswer.length > 0) {
          // Finally, I map the options and match with the url
          imageAnswer.forEach((item, index) => {
            // They should come ordered, I'm going to add one more control to make sure.
            const link = urls[index];
            let match = link.includes(item.choiceId);
            if (match) item.link = link;
            else {
              urls.forEach(url => {
                match = url.includes(item.choiceId);
                if (match) item.link = link;
              });
            }
            // If the link was not found it is because the file has been deleted, so I remove the option from the available ones.
            if (!item.link) imageAnswer.splice(index, 1);
          });
        }
        delete question.multipleChoiceImageAnswer;
        // And finally, I randomly mix the images to avoid memory association.
        question.multipleChoiceImageAnswer = imageAnswer.sort(() => Math.random() - 0.5);
      }
      if (userAnswer && userAnswer.length > 1) {
        // It means that there is more than one answer assigned, so we will take the last one and delete the obsolete answers.
        // This error occurs in case there has been a crash or error in the algorithm during a previous process.
        // First we will determine which was the last response.
        // Ordering by updatedAt property
        userAnswer.sort((a, b) =>
          b.updatedAt > a.updatedAt ? 1 : a.updatedAt > b.updatedAt ? -1 : 0
        );
        // Once ordered I get what will be the answer to use
        const answer = userAnswer.shift();
        // This will be the new answer. Now the unnecessary answers will be deleted.
        // Form an array of mutations and map the remaining responses.
        userAnswer.forEach(item => {
          if (item && item.id && item.studentID) {
            deleteStudentAnswerMutations.push(
              call(
                [API, 'graphql'],
                graphqlOperation(questionMutations.deleteStudentAnswer, {
                  answerId: item.id,
                  studentId: item.studentID
                })
              )
            );
          }
        });
        //Lastly, I reassemble the array of answers with the one that was not deleted.
        userAnswer = [];
        userAnswer.push(answer);
      }
      let previousAnswered;
      if (!userAnswer || userAnswer.length === 0) {
        // If it was not answered, then it will have to be hardcoded depending on how the user answers the question.
        previousAnswered = false;
        userAnswer = null;
      } else {
        // If it has already been answered previously, then we save the values of hard, normal and easy.
        previousAnswered = true;
        if (userAnswer && userAnswer.length > 0) userAnswer = userAnswer.pop();
        question.hardValue = userAnswer.hard;
        question.normalValue = userAnswer.normal;
        question.easyValue = userAnswer.easy;
        question.answeredTimestamp = userAnswer.updatedAt;
      }
      // Here I keep the conditional to know whether to hardcode or if there are already assigned values.
      question.previousAnswered = previousAnswered;
      yield all([put(studySessionAction.studentAnswer(userAnswer))]);
      // We save the time when the question starts, when you answer the question, we will add the ms it took you to answer it to add it to the study time.
      question.timestamp = new Date();
      if (status) question.status = status;
    }
    loadedQts.push(question);
    yield all([
      put(stopLoading()),
      put(studySessionAction.actualQuestion(question)),
      put(studySessionAction.loadedQuestions(loadedQts)),
      put(studySessionAction.storedSelfGraded(false)),
      put(studySessionAction.setBtnSubmit(false))
    ]);
    if (redirect) redirect();
    if (resetStudySession) resetStudySession();
    if (fillPreloadedArray) {
      const arrayOfQueries = [];
      let responses = null;
      let answerResponses = [];
      let nextQts = [];
      // Get the following questions, map and create an array of queries with the information of these questions.
      // Questions and Answers queries will be executed at the same time (from the same array) for optimization.
      // Later, responses will be separated.
      if (stackedQuestions && stackedQuestions.length > 0) {
        nextQts = stackedQuestions.slice(0, numberOfPreloadedQts);
        if (nextQts && nextQts.length > 0) {
          nextQts.forEach(qt => {
            if (qt && qt.id) {
              // Question information
              arrayOfQueries.push(
                call(
                  [API, 'graphql'],
                  graphqlOperation(studySessionQueries.stdGetQuestionQuerie, {
                    questionId: qt.id,
                    courseId
                  })
                )
              );
              // Answer information
              // The array of responses from answers must be executed in case the session is not open study.
              if (!isOpenStudy) {
                arrayOfQueries.push(
                  call(
                    [API, 'graphql'],
                    graphqlOperation(stdRetrieveStudentAnswerQuerie, {
                      questionId: qt.id,
                      studentId
                    })
                  )
                );
              }
            }
          });
        }
      }

      // Execute queries
      if (arrayOfQueries && arrayOfQueries.length > 0) {
        responses = yield all(arrayOfQueries);
        if (responses && responses.length > 0) {
          // If not 'open to study', responses array will contain results from
          // questions and answers queries, thus, separation is needed.
          // The pattern will be given as follows: qt, answer, qt, answer, qt, answer
          if (!isOpenStudy) {
            responses.forEach((item, index) => {
              if (index % 2 === 0) {
                if (item && item.data && item.data.getQuestion) {
                  preloadedQts.push(item.data.getQuestion);
                }
              } else if (item && item.data && item.data.retrieveStudentAnswer) {
                answerResponses.push(JSON.parse(item.data.retrieveStudentAnswer));
              }
            });
            // If 'open to study', answers queries won't execute, only question queries will
          } else {
            preloadedQts = responses;
          }
        }
      }
      // Process answers queries
      if (answerResponses && answerResponses.length > 0 && question) {
        if (preloadedQts && preloadedQts.length > 0) {
          for (let i = 0; i < answerResponses.length; i++) {
            let answerAux = answerResponses[i];
            // Processing and deletion in case more than one answer has been saved
            // These cases are rare as there should have been some error beforehand.
            if (answerAux && answerAux.length > 1) {
              answerAux.sort((a, b) =>
                b.updatedAt > a.updatedAt ? 1 : a.updatedAt > b.updatedAt ? -1 : 0
              );
              const aux = answerAux.shift();
              answerAux.forEach(item => {
                if (item && item.id && item.studentID) {
                  deleteStudentAnswerMutations.push(
                    call(
                      [API, 'graphql'],
                      graphqlOperation(questionMutations.deleteStudentAnswer, {
                        answerId: item.id,
                        studentId: item.studentID
                      })
                    )
                  );
                }
              });
              answerAux = [];
              answerAux.push(aux);
            }
            if (preloadedQts[i]) preloadedQts[i].userAnswer = answerAux;
          }
        }
      }
    }
    yield put(studySessionAction.preloadedQuestions(preloadedQts));
    if (deleteStudentAnswerMutations.length > 0) yield all(deleteStudentAnswerMutations);
  } catch (err) {
    yield all([
      put(
        notificationsActions.setNotification({
          message: 'Unable to complete request. Please contact us for support.',
          severity: 'error'
        })
      ),
      put(stopLoading())
    ]);
    yield put(notificationsActions.handleCatchError(err, 'setActualQuestionSagas > studySession'));
  }
}

function* saveQuestionAnswerSagas(action) {
  // Data for payload connected with the component
  const { respondedCorrectly, timestamp, setButtonLoader, moveToTheNextScreen } = action.payload;
  try {
    let [student, courseData, userAnswer, question] = yield all([
      select(getUserData),
      select(getCourseDataReducer),
      select(getStudentAnswerReducer),
      select(getActualQuestionReducer),
      put(studySessionAction.storedSelfGraded(false))
    ]);
    // Constants
    const studentId = student && student.username ? student.username : '';
    const courseId = courseData && courseData.id ? courseData.id : '';
    const questionId = question && question.id ? question.id : '';
    const questionVersion = question && question.actualVersion ? question.actualVersion : null;
    const answerNumericalValue = respondedCorrectly ? 1 : 0;
    /**** VERSIONING HANDLE ****/
    /*
    Versioning of questions:
    There will be two answer history:
    - The general one we are working with from the beginning.
    - That of the version
    It is necessary to take into account the case in which the question was created before the versioning.
    In these cases we will ignore the version history until there is a versioning.
    */
    let answerHistory = [];
    let answerHistoryVersion = [];
    if (questionVersion) {
      // Case in which a version has been created
      if (
        userAnswer &&
        userAnswer.answerHistoryVersion &&
        userAnswer.answerHistoryVersion.length > 0
      ) {
        // If the question has been previously answered
        answerHistoryVersion = userAnswer.answerHistoryVersion;
      }
      // Let's see if among the previous answers there is a history saved on this question.
      let thisVersion;
      if (answerHistoryVersion && answerHistoryVersion.length > 0) {
        thisVersion = answerHistoryVersion.find(
          item =>
            item &&
            item.questionID &&
            item.answerHistory &&
            item.questionID === questionVersion &&
            item.answerHistory.length > 0
        );
      }
      if (thisVersion) {
        // The array of responses contains a history of this version
        answerHistoryVersion.forEach(item => {
          if (item && item.questionID && item.questionID === questionVersion) {
            item.answerHistory.push(answerNumericalValue);
          }
        });
      } else {
        // If it was not found then I add it to this version
        answerHistoryVersion.push({
          questionID: questionVersion,
          answerHistory: [answerNumericalValue]
        });
      }
    }
    /**** HANDLING ANSWERS ****/
    if (!userAnswer) {
      // Empty object
      userAnswer = {};
      // FIRST ITERATION
      // Add to the general story
      answerHistory.push(answerNumericalValue);
      // Create userAnswer
      if (questionId && courseId && studentId) {
        const response = yield GraphOp(studySessionMutations.storeFirstAnswer, {
          questionId,
          courseId,
          studentId,
          answerHistory,
          answerHistoryVersion
        });
        const userAnswerId = response.data.createStudentAnswer.id;
        userAnswer = { id: userAnswerId, firstIt: true };
      }
    } else {
      // SECOND IT AND NEXT
      const answerId = userAnswer && userAnswer.id ? userAnswer.id : '';
      // Assign the previous history (if available)
      if (userAnswer.answerHistory) answerHistory = userAnswer.answerHistory;
      // Add to the general story
      answerHistory.push(answerNumericalValue);
      // Update the answer
      if (answerId && courseId && studentId) {
        yield GraphOp(studySessionMutations.updateStudentAnswerMutation, {
          answerId,
          courseId,
          studentId,
          answerHistory,
          answerHistoryVersion
        });
      }
    }
    /**** HANDLE COURSE STADISTICS ****/
    const now = new Date();
    const diffBetweenTimestamps = now - timestamp;
    const payload = {
      timestamp: diffBetweenTimestamps,
      respondedCorrectly,
      studySession: true
    };
    // Finally, we update the reducer with the processed response here and update the course statistics.
    yield all([
      put(courseActions.updateCoursePerformance(payload)),
      put(studySessionAction.studentAnswer(userAnswer)),
      put(studySessionAction.storedSelfGraded(true))
    ]);
    if (moveToTheNextScreen) moveToTheNextScreen();
  } catch (err) {
    if (setButtonLoader) setButtonLoader(false);
    yield all([
      put(
        notificationsActions.setNotification({
          message: 'Error storing response!',
          severity: 'error'
        })
      ),
      put(studySessionAction.storedSelfGraded(false))
    ]);
    yield put(notificationsActions.handleCatchError(err, 'saveQuestionAnswerSagas'));
  }
}

////////////////////////////// STUDY ALGORITHM FUNCTIONS //////////////////////////////

// This function is to set the eFactor following the lineaments developed in the algorithm.
const setEfactor = (E, difficult, coeficientValue, respondedCorrectly) => {
  let eFactor = E;
  switch (difficult) {
    case 'EASY':
      // If a user answered a question INCORRECTLY and is given the options of 1m | 10m | 1d and selects Easy at 1d,
      // then E should NOT be increased by 0.03. It should remain as 1.33 or below.
      if (!respondedCorrectly && coeficientValue === 1) break;
      // If the user selects Easy, then E should increase by 0.03.
      eFactor = eFactor + 0.03;
      break;
    case 'HARD':
      // Also, if the user selects Hard at 1m, then do not decrease E by 0.03. Leave it as is at 1.33 or below.
      if (coeficientValue === oneMin) break;
      // If the user selects Hard, then E should be be reduced by 0.03.
      eFactor = eFactor - 0.03;
      break;
    default:
      // If the user selects Normal, then E should remain it’s current value.
      break;
  }
  // The value of E must never be lower than 1.01.
  if (eFactor < 1.01) eFactor = 1.01;
  // If a user answers a question incorrectly and E is greater than the default of 1.33, reset it to 1.33.
  // However, if it is less than 1.33, leave it as is.
  if (!respondedCorrectly && eFactor > 1.33) eFactor = 1.33;
  return eFactor;
};

// Function to determine the next values to be displayed on the card
const getNextValues = (eFactor, coeficientValue) => {
  const newValues = {};
  // To determine the next value of Normal, multiple the value of the user’s option selected by E and round up.
  // All results for Normal should be rounded up. For example, if the result is 4.01d, then it should round up to 5d.
  let nextNormal = coeficientValue * eFactor;
  nextNormal = Math.ceil(nextNormal);
  // For Hard, just multiply the resultant Normal by 50% and always round down.
  let nextHard = nextNormal * 0.5;
  nextHard = Math.floor(nextHard);
  // To determine Easy, multiply the resultant Normal by 150%, and always round up.
  let nextEasy = nextNormal * 1.5;
  nextEasy = Math.ceil(nextEasy);
  /* 
  Note in the algorithm PDF:
      On the first iteration after a question is answered correct, it will be shown 10m | 1d | 3d.
      If the user selects 10m, the algorithm should see it as being answered incorrect.
      The next time it is shown after being answered correctly, it should provide again 10m | 1d | 3d
      as the options until the user selects 1d or 3d.
  Implementation:
  if (respondedCorrectly && coeficientValue === tenMins) {
    nextNormal = tenMins;
    nextHard = 1;
    nextEasy = 3;
  }
  Developer proposed change:
  In this case I will change the implementation by adding an indistinct condition on how he/she answers: if the student chooses an option in minutes (can be 1 or 10).
  Then the values will be kept until the difficulty changes.

  I don't know if this was omitted in the pdf, but if these cases occur when rounding down the next hard value, then they will be zero.
  In these cases, regardless of whether he/she answered right or wrong, the values |10 min|1d|3d will be assigned.
  And they will continue to be displayed until the student selects an option in days.
  */
  if (coeficientValue < 1) {
    nextHard = tenMins;
    nextNormal = 1;
    nextEasy = 3;
  }
  newValues.nextHard = nextHard;
  newValues.nextNormal = nextNormal;
  newValues.nextEasy = nextEasy;
  return newValues;
};

// --------------------------- Flashcard ---------------------------
const setEfactorFlashcard = (E, difficult) => {
  // Default E for Flashcard mode should be 1.25.
  // It is a little more difficult to recall information from the top of your head
  // so the intervals should be a little shorter by default.
  let eFactor = E;
  switch (difficult) {
    case 'WRONG':
      // When a user selects Wrong and E is greater than the default E, reset E to default.
      if (E > 1.25) {
        eFactor = 1.25;
        break;
      }
      // However, if E is less than or equal to default E, then subtract 0.02 from E.
      if (eFactor <= 1.25) eFactor = eFactor - 0.02;
      break;
    case 'EASY':
      // If the user selects Easy, then add 0.03 to E.
      eFactor = eFactor + 0.03;
      break;
    default:
      //CORRECT
      // If the user selects Correct, then leave E as is.
      break;
  }
  // E must never be below 1.01.
  if (eFactor < 1.01) eFactor = 1.01;
  return eFactor;
};

const getNextValuesFlashcard = (eFactor, coeficientValue) => {
  const newValues = {};
  // To determine the next Correct, multiple the user’s selection by E and round up.
  let nextCorrect = coeficientValue * eFactor;
  nextCorrect = Math.ceil(nextCorrect);
  // To determine the interval for Easy, multiple the current Correct by 1.33
  // and add 1 and round the result up.
  let nextEasy = nextCorrect * 1.33;
  nextEasy = Math.ceil(nextEasy + 1);
  newValues.nextHard = tenMins;
  newValues.nextNormal = nextCorrect;
  newValues.nextEasy = nextEasy;
  return newValues;
};

function* difficultFeedbackSagas(action) {
  let {
    questionId,
    stackedQuestions,
    difficult,
    upcomingAppearance,
    respondedCorrectly,
    setShowFinalResults,
    flashcardTreatment,
    resetCard,
    setDisabled,
    skipIteration,
    isAnchored
  } = action.payload;
  try {
    let [
      student,
      results,
      courseData,
      stackedAnswers,
      userAnswer,
      answersReducer,
      notElapsedQuestions
    ] = yield all([
      select(getUserData),
      select(getStudySessionResultsReducer),
      select(getCourseDataReducer),
      select(getStackedAnswers),
      select(getStudentAnswerReducer),
      select(getUserAnswersSelector),
      select(getNotElapsedQuestions),
      put(startLoading())
    ]);
    // Constants asignation
    const studentId = student && student.username ? student.username : '';
    const courseId = courseData && courseData.id ? courseData.id : '';
    const answerId = userAnswer && userAnswer.id ? userAnswer.id : '';
    if (!results) {
      results = {
        questionsAnswered: 0,
        questionsAnsweredOk: 0,
        accuracy: 0,
        finishSession: false
      };
    }
    // This variable will be used to avoid reloading the course information and keep the answers stored without the need to scan again.
    let updatedAnswer;
    let now = new Date();
    // FIRST ITERATION
    const anchoredFistIt = !userAnswer && isAnchored;
    const firstIt = userAnswer && userAnswer.firstIt;
    if (firstIt || anchoredFistIt) {
      let eFactor;
      let newValues;
      if (flashcardTreatment) {
        // If the user selects Wrong, then the next iteration Wrong should be 1m. Wrong should remain 1m until they select Correct or Easy.
        if (difficult === 'WRONG') {
          eFactor = 1.25; // Default value
          newValues = { nextHard: oneMin, nextNormal: 1, nextEasy: 3 };
        } else {
          eFactor = setEfactorFlashcard(1.25, difficult);
          newValues = getNextValuesFlashcard(eFactor, upcomingAppearance);
        }
      } else {
        // E = the difficulty of the question. The default value of E should be 1.33.
        eFactor = setEfactor(1.33, difficult, upcomingAppearance, respondedCorrectly);
        newValues = getNextValues(eFactor, upcomingAppearance);
      }
      const { nextHard, nextNormal, nextEasy } = newValues;
      // Next appareance calculation
      let nextAppearance;
      if (upcomingAppearance < 1) {
        //1 min = 60000 ms, 10 min = 600000 ms
        if (upcomingAppearance === oneMin) nextAppearance = new Date(now.getTime() + 60000);
        if (upcomingAppearance === tenMins) nextAppearance = new Date(now.getTime() + 600000);
      } else {
        // If it is one day or more, you will have to proceed as follows:
        // Note that it does not actually take a whole day
        // For example: if we select 1 day it is actually 16 hours.
        // Therefore, 8 hours must be subtracted from the whole number of days we wish to have the open study mode activated.
        const hoursToTheNextAppearance = upcomingAppearance * 24 - 8;
        const msToTheNextAppearance = hoursToTheNextAppearance * 3600000; // 1 hour = 3600000 ms
        nextAppearance = new Date(now.getTime() + msToTheNextAppearance);
      }
      if (anchoredFistIt) {
        const history = [];
        if (respondedCorrectly) history.push(1);
        else history.push(0);
        if (studentId && courseId && questionId) {
          updatedAnswer = yield GraphOp(studySessionMutations.createStudentAnswerMutation, {
            studentId,
            courseId,
            questionId,
            eFactor,
            nextNormal,
            nextHard,
            nextEasy,
            nextAppearance,
            correctAnswer: respondedCorrectly,
            answerHistory: history
          });
        }
      } else {
        if (studentId && courseId && answerId) {
          updatedAnswer = yield GraphOp(studySessionMutations.updateStudentAnswerMutation, {
            answerId,
            courseId,
            studentId,
            eFactor,
            nextAppearance,
            nextHard,
            nextNormal,
            nextEasy,
            correctAnswer: respondedCorrectly
          });
        }
      }
    } else {
      // Second iteration and so on
      const previousEfactor = userAnswer && userAnswer.eFactor ? userAnswer.eFactor : 1.33;
      let eFactor;
      let newValues;
      if (skipIteration) {
        // If we skip the iteration then we keep the same values of eFactor and the values suggested by the algorithm.
        eFactor = previousEfactor;
        newValues = {
          nextHard: userAnswer && userAnswer.hard ? userAnswer.hard : tenMins,
          nextNormal: userAnswer && userAnswer.normal ? userAnswer.normal : 1,
          nextEasy: userAnswer && userAnswer.easy ? userAnswer.easy : 3
        };
      } else {
        if (flashcardTreatment) {
          // Wrong should remain 1m until they select Correct or Easy. Then, the Wrong should be 10m again.
          if (difficult === 'WRONG' && upcomingAppearance === oneMin) {
            eFactor = setEfactorFlashcard(previousEfactor, difficult);
            newValues = { nextHard: oneMin, nextNormal: 1, nextEasy: 3 };
          } else {
            eFactor = setEfactorFlashcard(previousEfactor, difficult);
            newValues = getNextValuesFlashcard(eFactor, upcomingAppearance);
          }
        } else {
          eFactor = setEfactor(previousEfactor, difficult, upcomingAppearance, respondedCorrectly);
          newValues = getNextValues(eFactor, upcomingAppearance);
        }
      }
      const { nextHard, nextNormal, nextEasy } = newValues;
      // Next appareance calculation
      let nextAppearance;
      const now = new Date();
      if (upcomingAppearance < 1) {
        //1 min = 60000 ms
        if (upcomingAppearance === oneMin) nextAppearance = new Date(now.getTime() + 60000);
        // 10 min = 600000 ms
        if (upcomingAppearance === tenMins) nextAppearance = new Date(now.getTime() + 600000);
      } else {
        // If it is one day or more, you will have to proceed as follows:
        // Note that it does not actually take a whole day
        // For example: if we select 1 day it is actually 16 hours.
        // Therefore, 8 hours must be subtracted from the whole number of days we wish to have the  open study mode activated.
        const hoursToTheNextAppearance = upcomingAppearance * 24 - 8;
        const msToTheNextAppearance = hoursToTheNextAppearance * 3600000; // 1 hour = 3600000 ms
        nextAppearance = new Date(now.getTime() + msToTheNextAppearance);
      }
      if (answerId && courseId && studentId) {
        updatedAnswer = yield GraphOp(studySessionMutations.updateStudentAnswerMutation, {
          answerId,
          courseId,
          studentId,
          eFactor,
          nextAppearance,
          nextHard,
          nextNormal,
          nextEasy,
          correctAnswer: respondedCorrectly
        });
      }
    }
    // Process the response
    if (updatedAnswer && updatedAnswer.data) {
      if (updatedAnswer.data.createStudentAnswer) {
        updatedAnswer = updatedAnswer.data.createStudentAnswer;
      } else if (updatedAnswer.data.updateStudentAnswer) {
        updatedAnswer = updatedAnswer.data.updateStudentAnswer;
      }
    } else updatedAnswer = null;
    // Update answers reducer
    if (updatedAnswer && updatedAnswer.id) {
      let belongs = false;
      if (answersReducer && answersReducer.length > 0) {
        let answerIndex = answersReducer.findIndex(
          item => item && item.questionID && item.questionID === questionId
        );
        if (answerIndex !== -1) {
          belongs = true;
          answersReducer[answerIndex] = updatedAnswer;
        }
      } else answersReducer = [];
      if (!belongs) answersReducer.push(updatedAnswer);
      // Upgrade reducer
      yield put(userAnswers(answersReducer));
    }
    // Lastly, I update the results of this session
    results.questionsAnswered =
      results && results.questionsAnswered ? results.questionsAnswered + 1 : 1;
    if (respondedCorrectly) {
      results.questionsAnsweredOk =
        results && results.questionsAnsweredOk ? results.questionsAnsweredOk + 1 : 1;
    }
    // In case it is a flash card I reset the component status before displaying another card.
    if (flashcardTreatment && resetCard) resetCard();
    // To determine the minimum value it is necessary to know which will be the next of the whole batch of questions.
    // This value will be taken into account to calculate the mail that will be scheduled at the end of the study session and determines when the open study mode will be deactivated.
    // This e-mail will be sent in cases where the study session will be deactivated after one day.
    if (!results.disableOpenStudyMode) {
      results.disableOpenStudyMode = upcomingAppearance;
      results.nextOpenStudyModeTimestamp = new Date();
    } else {
      if (upcomingAppearance < results.disableOpenStudyMode) {
        results.disableOpenStudyMode = upcomingAppearance;
        results.nextOpenStudyModeTimestamp = new Date();
      }
    }
    now = new Date();
    const notElapsedReady = [];
    // Let's see which of the questions not elapsed are already ready
    if (notElapsedQuestions && notElapsedQuestions.length > 0) {
      notElapsedQuestions.forEach(qt => {
        if (qt && qt.timestamp) {
          // Calculate the difference in ms between the next occurrence and the current time
          const difInMs = qt.timestamp.getTime() - now.getTime();
          if (difInMs < 0) notElapsedReady.push(qt);
        }
      });
    }
    // If there are no more questions then I show the final results.
    if (stackedQuestions.length === 0 && notElapsedReady.length === 0) {
      // Accuracy calculation
      let accuracy = 0;
      if (results && results.questionsAnsweredOk > 0 && results.questionsAnswered > 0) {
        accuracy = (results.questionsAnsweredOk / results.questionsAnswered) * 100;
        accuracy = accuracy.toFixed(2);
      }
      results.accuracy = accuracy;
      // This attribute is used to determine if the session has ended in case an email needs to be sent to the student.
      results.finishSession = true;
      yield all([put(studySessionAction.studySessionResults(results))]);
      setShowFinalResults(true);
    } else {
      let nextQuestion;
      let qtStatus = null;
      const questionsReady = [];
      const questionsNotReady = [];
      if (stackedQuestions.length === 0 && notElapsedQuestions && notElapsedQuestions.length > 0) {
        nextQuestion = notElapsedQuestions.shift();
        qtStatus = 'LEARNING';
        yield put(studySessionAction.setNotElapsedQuestions(notElapsedQuestions));
      } else {
        // First let's check if one of the questions we already answered in this session is ready to be answered again.
        // In questionsReady we will store the questions that can be answered again.
        // In questionsNotReady we will store the ones that are not ready.
        if (stackedAnswers && stackedAnswers.length > 0) {
          // Store the current time on milliseconds
          const nowInMs = now.getTime();
          stackedAnswers.forEach(item => {
            if (item && item.timestamp) {
              // Pass the question timestamp to milliseconds
              const timestampInMs = item.timestamp.getTime();
              const difInMs = timestampInMs - nowInMs;
              // Compare if the timestamp is less than the current time
              // With this if we divide the stack in two parts:
              // - Those that are already there to answer again
              // - Those not yet ready to respond
              if (difInMs < 0) questionsReady.push(item);
              else questionsNotReady.push(item);
            }
          });
        }
        // If any of the questions already answered are ready, then it will be the next one to appear.
        if (questionsReady.length > 0) {
          // I rearrange the stack in order of appearance
          questionsReady.sort((a, b) =>
            b.timestamp.getTime() > a.timestamp.getTime()
              ? -1
              : a.timestamp.getTime() > b.timestamp.getTime()
              ? 1
              : 0
          );
          // I take the first value
          nextQuestion = questionsReady.shift();
          qtStatus = 'LEARNING';
          // If there is more than one question ready to answer I add them to the top of the stack.
          if (questionsReady.length > 0) stackedQuestions = questionsReady.concat(stackedQuestions);
          if (notElapsedQuestions && notElapsedQuestions.length > 0) {
            const notElapsedQuestionsToRemove = notElapsedQuestions.filter(
              item => item.id !== nextQuestion.id
            );
            if (notElapsedQuestionsToRemove && notElapsedQuestionsToRemove.length > 0) {
              yield put(studySessionAction.setNotElapsedQuestions(notElapsedQuestionsToRemove));
            } else {
              yield put(studySessionAction.setNotElapsedQuestions([]));
            }
          }
        } else {
          nextQuestion = stackedQuestions.shift();
          // If the question has a timestamp it means that it was answered in this session
          if (nextQuestion.timestamp) qtStatus = 'LEARNING';
        }
      }
      // Calculate next occurrence of the question
      let nextAppInMs;
      let studyStatusAux;
      if (upcomingAppearance < 1) {
        // Next app in minutes
        if (upcomingAppearance === tenMins) nextAppInMs = 600000; // 10 min in ms
        if (upcomingAppearance === oneMin) nextAppInMs = 60000; // 1 min in ms
        studyStatusAux = 'LEARNING';
      } else {
        // Next app in days
        const hoursToTheNextApp = upcomingAppearance * 24 - 8;
        const oneHourInMs = 1000 * 60 * 60;
        nextAppInMs = hoursToTheNextApp * oneHourInMs;
      }
      // Calculate the next date when the question should be available again.
      const nextApp = new Date(now.getTime() + nextAppInMs);
      const answerObject = { id: questionId, timestamp: nextApp, studyStatus: studyStatusAux };
      if (studyStatusAux === 'LEARNING') {
        if (notElapsedQuestions && notElapsedQuestions.length > 0) {
          notElapsedQuestions.push(answerObject);
        } else {
          notElapsedQuestions = [];
          notElapsedQuestions.push(answerObject);
        }
        yield put(studySessionAction.setNotElapsedQuestions(notElapsedQuestions));
      }
      questionsNotReady.push(answerObject);
      yield all([
        put(studySessionAction.studySessionResults(results)),
        put(studySessionAction.setActualQuestion(nextQuestion.id, null, null, qtStatus)),
        put(studySessionAction.setStackedQuestions(stackedQuestions)),
        put(studySessionAction.setStackedAnswers(questionsNotReady)),
        put(studySessionAction.actualAnswer(null)),
        put(studySessionAction.respondedCorrectly(false)),
        put(studySessionAction.showSubmit(false)),
        put(studySessionAction.showResults(false)),
        put(studySessionAction.showFinalResults(false))
      ]);
    }
    yield all([put(stopLoading())]);
    // Track student stats in the background
    const stats = {};
    stats.questionSetTotalQuestions = results ? results.questionsAnswered : 0;
    stats.questionSetTotalQuestionsOk = results ? results.questionsAnsweredOk : 0;
    yield all([put(trackStudentStats(stats))]);
  } catch (err) {
    if (setDisabled) setDisabled(false);
    yield all([
      put(
        notificationsActions.setNotification({
          message: 'Unable to complete request. Please contact us for support.',
          severity: 'error'
        })
      ),
      put(stopLoading())
    ]);
    yield put(notificationsActions.handleCatchError(err, 'difficultFeedbackSagas'));
  }
}

function* exitStudySessionSagas(action) {
  const { redirect } = action.payload;
  try {
    const [results, studySessionData, student, courseData, organization, question] = yield all([
      select(getStudySessionResultsReducer),
      select(getStudySessionData),
      select(getStudentDataReducer),
      select(getCourseDataReducer),
      select(getOrganizationDataReducer),
      select(getActualQuestionReducer),
      put(startLoading())
    ]);
    const studentId = student && student.id ? student.id : null;
    const organizationId = organization && organization.id ? organization.id : null;
    const courseId = courseData && courseData.id ? courseData.id : null;
    if (
      courseId &&
      studentId &&
      results &&
      results.finishSession &&
      studySessionData.studyMode &&
      studySessionData.studyMode.includes('SS')
    ) {
      // If the session is ended then we save the array of this session in case if the student want repeat again
      const questionStack = [];
      if (
        studySessionData &&
        studySessionData.thisSession &&
        studySessionData.thisSession.length > 0
      ) {
        studySessionData.thisSession.forEach(qt => {
          if (qt && qt.id) questionStack.push(qt.id);
        });
        const newLastSession = { courseID: courseId, questionStack };
        const lastSessionsArray = [];
        let sessions = yield GraphOp(studySessionQueries.stdGetStudentLastSessionsQuerie, {
          studentId,
          organizationId
        });
        sessions =
          sessions &&
          sessions.data &&
          sessions.data.getStudent &&
          sessions.data.getStudent.lastStudySessions
            ? sessions.data.getStudent.lastStudySessions
            : [];
        if (sessions && sessions.length > 0) {
          sessions.forEach(item => {
            if (item && item.courseID === courseId) lastSessionsArray.push(newLastSession);
            else lastSessionsArray.push(item);
          });
        } else {
          lastSessionsArray.push(newLastSession);
        }
        yield GraphOp(studySessionMutations.updateLastStudySession, {
          studentId,
          organizationId,
          studySessions: lastSessionsArray
        });
      }
    }
    // If the session is not finished
    // If the question has a stored timestamp
    // This elapsed time will then be tracked as studied time.
    if (results && question && results.finishSession === false) {
      const timestamp = question.timestamp ? question.timestamp : null;
      if (timestamp) {
        let now = new Date();
        const diffBetweenTimestamps = now - timestamp;
        const payload = { timestamp: diffBetweenTimestamps, redirect };
        yield all([put(courseActions.updateCoursePerformance(payload))]);
      }
    } else {
      if (redirect) redirect();
    }
    const payload = { courseId, organizationId, studentId, fromStudySession: true };
    yield all([
      put(courseActions.getCourseData(payload)),
      put(studySessionAction.setStackedQuestions([])),
      put(studySessionAction.setStackedAnswers([])),
      put(studySessionAction.studentAnswer(null)),
      put(studySessionAction.actualAnswer(null)),
      put(studySessionAction.respondedCorrectly(false)),
      put(studySessionAction.showSubmit(false)),
      put(studySessionAction.showResults(false)),
      put(studySessionAction.showFinalResults(false)),
      put(studySessionAction.studySessionResults(null)),
      put(stopLoading())
    ]);
  } catch (err) {
    yield put(stopLoading());
    if (redirect) redirect();
    yield put(notificationsActions.handleCatchError(err, 'exitStudySessionSagas'));
  }
}

function* startOpenStudyModeSagas(action) {
  const {
    redirect,
    closeModal,
    allQuestions,
    isTopic,
    notElapsed,
    sessionInfo,
    resetStudySession,
    stack
  } = action.payload;
  try {
    const [courseData, studySessionData] = yield all([
      select(getCourseDataReducer),
      select(getStudySessionData),
      put(studySessionAction.actualAnswer(null)),
      put(studySessionAction.respondedCorrectly(false)),
      put(studySessionAction.showSubmit(false)),
      put(studySessionAction.showResults(false)),
      put(studySessionAction.showFinalResults(false)),
      put(studySessionAction.preloadedQuestions([])),
      put(studySessionAction.studySessionData(null))
    ]);
    let data = {};
    data.openStudyMode = true;
    data.id = sessionInfo && sessionInfo.id ? sessionInfo.id : null;
    let questionsInThisSession = [];
    // Case 1: Study All: I grab all the questions in the course, shuffle them in random order and start studying.
    if (allQuestions) {
      if (sessionInfo && sessionInfo.name && sessionInfo.name.includes('OPEN STUDY')) {
        data.name = sessionInfo.name;
      } else {
        if (courseData && courseData.name) data.name = `OPEN STUDY - ${courseData.name}`;
      }
      data.studyMode = 'OS-StudyAll';
      if (courseData && courseData.notElapsed && courseData.notElapsed.length > 0) {
        questionsInThisSession = courseData.notElapsed.sort(() => Math.random() - 0.5);
      } else {
        questionsInThisSession =
          studySessionData && studySessionData.thisSession ? studySessionData.thisSession : [];
      }
      // Handle for switch of Alert me when more questions are available
      data.enableNotifications = false;
    } else {
      if (
        sessionInfo &&
        (sessionInfo.name === 'ReviewLastSession' ||
          sessionInfo.name === 'A review of your last completed session')
      ) {
        data.name = 'A review of your last completed session';
        if (stack) questionsInThisSession = stack;
        else questionsInThisSession = studySessionData && studySessionData.thisSession;
      } else {
        if (sessionInfo && sessionInfo.name && sessionInfo.name.includes('OPEN STUDY')) {
          data.name = sessionInfo.name;
          questionsInThisSession = stack;
        } else {
          if (sessionInfo && sessionInfo.name) data.name = `OPEN STUDY - ${sessionInfo.name}`;
        }
        if (isTopic) {
          data.studyMode = 'OS-Topic';
          // Case 2: Topic: I map all the questions in the topic, mix them in random order and start studying.
          if (notElapsed && notElapsed.length > 0) {
            questionsInThisSession = notElapsed.sort(() => Math.random() - 0.5);
          } else {
            questionsInThisSession =
              studySessionData && studySessionData.thisSession ? studySessionData.thisSession : [];
          }
          // Handle for switch of Alert me when more questions are available
          data.enableNotifications = false;
          data.notificationsArray = [];
        }
      }
    }
    data.totalOfQuestions =
      questionsInThisSession && questionsInThisSession.length ? questionsInThisSession.length : 0;
    data.thisSession = [...questionsInThisSession];
    const firstQuestion = questionsInThisSession.shift();
    if (closeModal) closeModal();
    const emptyResults = { questionsAnswered: 0, questionsAnsweredOk: 0, accuracy: 0 };
    if (firstQuestion && firstQuestion.id) {
      yield all([
        put(studySessionAction.studySessionResults(emptyResults)),
        put(studySessionAction.setStackedQuestions(questionsInThisSession)),
        put(studySessionAction.setActualQuestion(firstQuestion.id, redirect, resetStudySession)),
        put(studySessionAction.studySessionData(data))
      ]);
    } else {
      yield put(
        notificationsActions.setNotification({
          message: 'Unable to complete request. Please contact us for support.',
          severity: 'error'
        })
      );
    }
  } catch (err) {
    yield put(notificationsActions.handleCatchError(err, 'startOpenStudyModeSagas'));
  }
}

function* sendQuestionStudySessionFeedbackSagas(action) {
  const { courseId, questionId, feedback, type, setLoading, closeModal, emailPayload } =
    action.value;
  try {
    const [selectedCourse, organization, studentData] = yield all([
      select(getCourseDataReducer),
      select(getOrganizationDataReducer),
      select(getStudentDataReducer)
    ]);
    if (setLoading) setLoading(true);
    const organizationId = organization && organization.id ? organization.id : '';
    const arrayOfQueries = [];
    // Feedbacks of the reported question are retrieved
    arrayOfQueries.push(
      call(
        [API, 'graphql'],
        graphqlOperation(studySessionQueries.stdGetQuestionFeedbacksQuerie, {
          courseId,
          questionId
        })
      )
    );
    // Get the course action required state
    arrayOfQueries.push(
      call(
        [API, 'graphql'],
        graphqlOperation(stdGetCourseActionRequiredQuerie, {
          courseId,
          organizationId
        })
      )
    );
    //Get organization notifications
    arrayOfQueries.push(
      call(
        [API, 'graphql'],
        graphqlOperation(orgQueries.stdGetOrganizationNotificationsQuerie, {
          organizationID: organizationId
        })
      )
    );
    const [questionResponse, actionRequiredResponse, orgResponse] = yield all(arrayOfQueries);
    const actualVersion = questionResponse.data.getQuestion.actualVersion;
    let questionFeedbacks = questionResponse.data.getQuestion.feedbacks;
    let feedbacksArray = [];
    if (questionFeedbacks && questionFeedbacks.length > 0) feedbacksArray = questionFeedbacks;
    // Push the new feedback
    const feedbackId = uuid4();
    feedbacksArray.push({
      feedbackId,
      feedback,
      type,
      status: 'ACTIVE',
      createdAt: new Date().toISOString(),
      reporterUserId: studentData?.id,
      reporterUserName: studentData?.name,
      reporterUserEmail: studentData?.email,
      reporterVersionId: actualVersion,
      page: window.location.href
    });
    yield GraphOp(questionMutations.createQuestionFeedback, {
      questionId,
      courseId,
      feedback: feedbacksArray
    });
    // Handle action required
    const actionRequired = actionRequiredResponse.data.getCourse.actionRequired;
    let courseAR = [];
    let executeMutation = false;
    if (!actionRequired || actionRequired.length === 0) {
      courseAR.push('QUESTION');
      executeMutation = true;
    } else {
      if (actionRequired && actionRequired.length > 0 && !actionRequired.includes('QUESTION')) {
        courseAR = actionRequired;
        courseAR.push('QUESTION');
        executeMutation = true;
      }
    }
    if (executeMutation) {
      yield GraphOp(updateCourseActionRequired, {
        courseId,
        organizationId,
        actionRequired: courseAR
      });
    }
    //Get list of organization notifications
    const notificationsArray =
      orgResponse &&
      orgResponse.data &&
      orgResponse.data.getOrganization &&
      orgResponse.data.getOrganization.notifications &&
      orgResponse.data.getOrganization.notifications.length > 0
        ? orgResponse.data.getOrganization.notifications
        : [];
    //Create new notification
    const newNotification = {
      id: uuid4(),
      text: 'Question issue has been reported',
      type: 'QUESTION_REPORTED',
      createdAt: new Date().toISOString(),
      organizationName: organization && organization.name ? organization.name : '',
      courseName: selectedCourse && selectedCourse.name ? selectedCourse.name : '',
      courseId: selectedCourse && selectedCourse.id ? selectedCourse.id : '',
      questionId,
      readedBy: []
    };
    // Add to the array
    notificationsArray.push(newNotification);
    // Perform mutation
    yield GraphOp(studySessionMutations.addNotificationMutation, {
      organizationId,
      notifications: notificationsArray
    });
    window.analytics.track('Question submitted');
    if (setLoading) setLoading(false);
    if (closeModal) closeModal();
    // Call the sagas that sends emails to support
    if (emailPayload) {
      emailPayload.feedbackId = feedbackId;
      yield all([put(sendReportEmailToOrganization(emailPayload))]);
    }

    yield all([
      put(
        notificationsActions.setNotification({
          message: 'Thank you. This question has been reported successfully.',
          severity: 'success'
        })
      )
    ]);
  } catch (err) {
    if (setLoading) setLoading(false);
    yield all([
      put(
        notificationsActions.setNotification({
          message: 'Unable to complete request. Please contact us for support.',
          severity: 'error'
        })
      )
    ]);
    yield put(notificationsActions.handleCatchError(err, 'sendQuestionStudySessionFeedbackSagas'));
  }
}

function* openStudyModeResultsSagas(action) {
  const {
    stackedQuestions,
    respondedCorrectly,
    setShowFinalResults,
    flashcardTreatment,
    resetCard,
    timestamp
  } = action.payload;
  try {
    let [results] = yield all([select(getStudySessionResultsReducer)]);
    if (!results) {
      results = { questionsAnswered: 0, questionsAnsweredOk: 0, accuracy: 0 };
    }
    if (flashcardTreatment && resetCard) resetCard();
    results.questionsAnswered = results.questionsAnswered + 1;
    if (respondedCorrectly) results.questionsAnsweredOk = results.questionsAnsweredOk + 1;
    // Calculate the difference in milliseconds from the time the question was loaded to the time it was answered.
    const now = new Date();
    const diffBetweenTimestamps = now - timestamp;
    // This is the time you spent studying this question, so I am adding it to the time studied for both this month and this week.
    // In the open study mode, the questions answered and the time studied are counted, so only these data are modified.
    const payload = { timestamp: diffBetweenTimestamps };
    yield all([put(courseActions.updateCoursePerformance(payload))]);
    if (stackedQuestions && stackedQuestions.length === 0) {
      // Accuracy calculation
      let accuracy = 0;
      if (
        results &&
        results.questionsAnsweredOk &&
        results.questionsAnswered &&
        results.questionsAnsweredOk > 0 &&
        results.questionsAnswered > 0
      ) {
        const ok = results.questionsAnsweredOk;
        const total = results.questionsAnswered;
        accuracy = (ok / total) * 100;
        accuracy = accuracy.toFixed(2);
      }
      results.accuracy = accuracy;
      yield all([put(studySessionAction.studySessionResults(results))]);
      setShowFinalResults(true);
    } else {
      const nextQuestion = stackedQuestions.shift();
      yield all([
        put(studySessionAction.studySessionResults(results)),
        put(studySessionAction.setActualQuestion(nextQuestion.id)),
        put(studySessionAction.setStackedQuestions(stackedQuestions)),
        put(studySessionAction.actualAnswer(null)),
        put(studySessionAction.respondedCorrectly(false)),
        put(studySessionAction.showSubmit(false)),
        put(studySessionAction.showResults(false)),
        put(studySessionAction.showFinalResults(false))
      ]);
    }
  } catch (err) {
    yield all([
      put(
        notificationsActions.setNotification({
          message: 'Unable to complete request. Please contact us for support.',
          severity: 'error'
        })
      ),
      put(notificationsActions.handleCatchError(err, 'openStudyModeResultsSagas'))
    ]);
  }
}

function* enableNotificationsSagas(action) {
  try {
    const { id, forAll, forTopic, notificationsArray, newValue, setEnableSwitch } = action.value;
    const [student, organization, courseData, studySessionDataReducer] = yield all([
      select(getUserData),
      select(getOrganizationDataReducer),
      select(getCourseDataReducer),
      select(getStudySessionData)
    ]);
    if (studySessionDataReducer) {
      studySessionDataReducer.enableNotifications = newValue;
      yield all([put(studySessionAction.studySessionData(studySessionDataReducer))]);
    }
    const studentId = student.username;
    const organizationId = organization.id;
    const courseId = courseData.id;
    let courseNotifications = yield GraphOp(stdGetCourseNotificationsQuerie, {
      studentId,
      organizationId
    });
    courseNotifications = courseNotifications.data.getStudent.notificationPreferences;
    if (forAll) {
      if (courseNotifications && courseNotifications.length > 0) {
        // If there are course notifications then I have to ask if they exist for this course.
        const thisCourse = courseNotifications.filter(item => item.courseID === id);
        if (thisCourse && thisCourse.length > 0) {
          // if the course exists then I change the value
          courseNotifications.forEach(item => {
            if (item.courseID === id) item.newQuestionEmail = newValue;
          });
        } else {
          // if it does not exist I add it to the existing array
          courseNotifications.push({
            courseID: id,
            newQuestionEmail: newValue,
            sendNQE: true
          });
        }
        yield GraphOp(studentMutations.updateNotificationPreferences, {
          studentId,
          organizationId,
          notificationPreferences: courseNotifications
        });
      } else {
        // If there is nothing created yet then I start the array from 0
        const notificationPreferences = [
          { courseID: id, newQuestionEmail: newValue, sendNQE: true }
        ];
        yield GraphOp(studentMutations.updateNotificationPreferences, {
          studentId,
          organizationId,
          notificationPreferences
        });
      }
    }
    if (forTopic) {
      if (notificationsArray && notificationsArray.length > 0) {
        const thisTopic = notificationsArray.filter(item => item.topicId === id);
        if (thisTopic && thisTopic.length > 0) {
          notificationsArray.forEach(item => {
            if (item.topicId === id) item.enabled = newValue;
          });
        } else {
          notificationsArray.push({
            courseID: courseId,
            enabled: newValue,
            topicId: id,
            sendMail: true
          });
        }
      } else {
        notificationsArray.push({
          courseID: courseId,
          enabled: newValue,
          topicId: id,
          sendMail: true
        });
      }
    }
    if (!forAll) {
      yield GraphOp(studentMutations.updateQuestionsNotifications, {
        studentId,
        organizationId,
        questionNotifications: notificationsArray
      });
      // // If the new value is false, then I have to disable global notifications for this course
      if (!newValue && courseNotifications && courseNotifications.length > 0) {
        const thisCourse = courseNotifications.filter(item => item.courseID === id);
        if (thisCourse && thisCourse.length > 0) {
          courseNotifications.forEach(item => {
            if (item.courseID === courseData.id) item.newQuestionEmail = false;
          });
          yield GraphOp(studentMutations.updateNotificationPreferences, {
            studentId,
            organizationId,
            notificationPreferences: courseNotifications
          });
        }
      }
    }
    if (setEnableSwitch) setEnableSwitch(newValue);
  } catch (err) {
    yield put(notificationsActions.handleCatchError(err, 'enableNotificationsSagas'));
  }
}

function* validateUpdatedQuestionSagas(action) {
  try {
    const { questionId } = action.payload;
    const [selectedCourse, questionsReducer] = yield all([
      select(getCourseDataReducer),
      select(getCourseQuestionsSelector)
    ]);
    const courseId = selectedCourse && selectedCourse.id ? selectedCourse.id : null;
    let reloads = false;
    if (courseId && questionId) {
      // I get the course id and status of the question that was changed
      let qt = yield GraphOp(studySessionQueries.stdGetQuestionStatusQuerie, {
        questionId,
        courseId
      });
      qt = qt && qt.data && qt.data.getQuestion ? qt.data.getQuestion : null;
      // The question exists and is related to this course.
      if (qt && qt.id && qt.courseID && qt.courseID === courseId) {
        // The question became active
        if (qt.status === 'ACTIVE') {
          /* 
          We check the question reducer, here we have 3 options:
          1) If the reducer is loaded and the question is already there, then there is no need to reload.
          2) If the reducer is loaded and the question is not there, then we have to reload it.
          3) If the reducer is empty, then we have to reload.
          */
          if (questionsReducer && questionsReducer.length > 0) {
            const belongs = questionsReducer.find(item => item && item.id && item.id === qt.id);
            if (!belongs) reloads = true;
          } else reloads = true;
        } else {
          // If the question is no longer active, then we have to see if it belongs to our reducer.
          // If it is in our reducer we have to reload again
          if (questionsReducer && questionsReducer.length > 0) {
            const belongs = questionsReducer.find(item => item && item.id && item.id === qt.id);
            if (belongs) reloads = true;
          }
        }
      }
    }
    if (reloads && courseId) {
      yield all([put(courseActions.getCourseData({ courseId, reloadAlgorithm: true }))]);
    }
  } catch (err) {
    yield put(notificationsActions.handleCatchError(err, 'validateUpdatedQuestionWatcher'));
  }
}

// WATCHERS
function* getStudyModesWatcher() {
  yield takeLatest(studySessionActionTypes.GET_STUDY_MODES, getStudyModesSagas);
}
function* preloadFirstQuestionsWatcher() {
  yield takeLatest(studySessionActionTypes.PRELOAD_FIRST_QUESTIONS, preloadFirstQuestionsSagas);
}
function* startStudySessionWatcher() {
  yield takeLatest(studySessionActionTypes.START_STUDY_SESSION, startStudySessionSagas);
}
function* setActualQuestionWatcher() {
  yield takeLatest(studySessionActionTypes.SET_ACTUAL_QUESTION, setActualQuestionSagas);
}
function* saveQuestionAnswerWatcher() {
  yield takeLatest(studySessionActionTypes.SAVE_QUESTION_ANSWER, saveQuestionAnswerSagas);
}
function* difficultFeedbackWatcher() {
  yield takeLatest(studySessionActionTypes.DIFFICULT_FEEDBACK, difficultFeedbackSagas);
}
function* exitStudySessionWatcher() {
  yield takeLatest(studySessionActionTypes.EXIT_STUDY_SESSION, exitStudySessionSagas);
}
function* startOpenStudyModeWatcher() {
  yield takeLatest(studySessionActionTypes.START_OPEN_STUDY_MODE, startOpenStudyModeSagas);
}
function* openStudyModeResultsWatcher() {
  yield takeLatest(studySessionActionTypes.OPEN_STUDY_MODE_RESULTS, openStudyModeResultsSagas);
}
function* sendQuestionStudySessionFeedbackWatcher() {
  yield takeLatest(
    studySessionActionTypes.SEND_QUESTION_STUDY_SESSION_FEEDBACK,
    sendQuestionStudySessionFeedbackSagas
  );
}
function* enableNotificationsWatcher() {
  yield takeLatest(studySessionActionTypes.ENABLE_NOTIFICATIONS, enableNotificationsSagas);
}
function* validateUpdatedQuestionWatcher() {
  yield takeLatest(studySessionActionTypes.VALIDATE_UPDATED_QUESTION, validateUpdatedQuestionSagas);
}

// EXPORT SAGAS
export default function* sagas() {
  yield all([
    getStudyModesWatcher(),
    preloadFirstQuestionsWatcher(),
    setActualQuestionWatcher(),
    startStudySessionWatcher(),
    saveQuestionAnswerWatcher(),
    difficultFeedbackWatcher(),
    exitStudySessionWatcher(),
    startOpenStudyModeWatcher(),
    sendQuestionStudySessionFeedbackWatcher(),
    openStudyModeResultsWatcher(),
    enableNotificationsWatcher(),
    validateUpdatedQuestionWatcher()
  ]);
}

const processMultipleChoiceQuestion = question => {
  if (
    question &&
    question.multipleChoiceTextAnswer &&
    question.multipleChoiceTextAnswer.length > 0
  ) {
    const numberOfCorrectAnswers = question.multipleChoiceTextAnswer.filter(
      option => option.correct
    ).length;
    if (numberOfCorrectAnswers === 1) {
      question.choiceText = 'Select the correct answer';
      question.oneOption = true;
    } else {
      question.choiceText = 'Select all answers that apply';
      question.oneOption = false;
    }
    const answersArray = [];
    const lockedQuestions = question.multipleChoiceTextAnswer.filter(option => option.locked);
    let randomQuestions = question.multipleChoiceTextAnswer.filter(option => !option.locked);
    randomQuestions = randomQuestions.sort(() => Math.random() - 0.5);
    // Now I have two arrays: one with the random positions and one with the blocked positions.
    // That's why we will have to leave the desired answers fixed in the array and the rest of the questions will go in a random order
    if (lockedQuestions.length > 0) {
      const totalOfQuestions = question.multipleChoiceTextAnswer.length;
      const lockedPositions = [];
      const randomPositions = [];
      lockedQuestions.forEach(item => {
        answersArray[item.order] = item;
        lockedPositions.push(item.order);
      });
      for (let i = 1; i <= totalOfQuestions; i++) {
        const filter = lockedPositions.filter(position => position === i);
        if (filter.length === 0) {
          randomPositions.push(i);
        }
      }
      if (randomPositions.length > 0) {
        randomPositions.forEach(i => {
          const questionNotLocked = randomQuestions.pop();
          questionNotLocked.order = i;
          answersArray[i] = questionNotLocked;
        });
      }
    } else {
      randomQuestions.forEach((item, index) => {
        item.order = index + 1;
        answersArray[index + 1] = item;
      });
    }
    // Delete answerArray[0]
    answersArray.shift();
    question.multipleChoiceTextAnswer = answersArray;
  }
  return question;
};
