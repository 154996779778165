/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://26ystdpih5cinias4c2ozkdj7q.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-xfvop3raczb7tafefy2gsyc7z4",
    "aws_cloud_logic_custom": [
        {
            "name": "wordpressBridge",
            "endpoint": "https://ufl3kmgc8k.execute-api.us-west-2.amazonaws.com/production",
            "region": "us-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-west-2:9101639d-b471-4da3-829f-48574cd08675",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_HnUv6gMNe",
    "aws_user_pools_web_client_id": "u5gigjfboc7lcp3sqma4b8e9h",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "evcreatorsdev-20210729123707-hostingbucket-production",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "http://evcreatorsdev-20210729123707-hostingbucket-production.s3-website-us-west-2.amazonaws.com",
    "aws_user_files_s3_bucket": "evcreatorsdevf04d2764bfda42f49895605c1b36e5f0120539-production",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
