import { getStateWithOnlyOneLevel } from '../utils/StateSelectorHelper';

const getState = state => getStateWithOnlyOneLevel(state, 'User');

export const getUserData = state => getState(state).get('userData');
export const getUserOrganizationId = state => getState(state).get('organizationId');
export const getUserLogoReducer = state => getState(state).get('userLogo');
export const getLoadingUserLogo = state => getState(state).get('loadingUserLogo');
export const getCourseIdReducer = state => getState(state).get('courseId');
export const getSelectedTopicId = state => getState(state).get('selectedTopicId');
export const getSelectedSubtopicId = state => getState(state).get('selectedSubtopicId');
export const getSelectedSectionId = state => getState(state).get('selectedSectionId');
export const getPracticeExamId = state => getState(state).get('practiceExamId');
export const getStudyProgressReducer = state => getState(state).get('studyProgress');
export const getStudentDataReducer = state => getState(state).get('studentData');
export const getPreviewUser = state => getState(state).get('previewUser');
export const mylaunchDarklyAccessSelector = state => getState(state).get('mylaunchDarklyAccess');
export const getStripeConnectReducer = state => getState(state).get('stripeConnect');
export const getUserAnswersSelector = state => getState(state).get('userAnswers');
export const getLessonsReadSelector = state => getState(state).get('lessonsRead');