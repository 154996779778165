import { getStateWithOnlyOneLevel } from '../utils/StateSelectorHelper';

const getState = state => getStateWithOnlyOneLevel(state, 'Organization');

export const getOrganizationDataReducer = state => getState(state).get('organizationData');
export const getOrganizationLogoReducer = state => getState(state).get('organizationLogo');
export const getOrganizationLogoBlobUrl = state => getState(state).get('organizationLogoBlobUrl');
export const getOrganizationFavicon = state => getState(state).get('organizationFavicon');
export const getLoadingOrganizationData = state => getState(state).get('loadingOrganizationData');
export const getLoadingOrganizationLogo = state => getState(state).get('loadingOrganizationLogo');
