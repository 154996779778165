import { getStateWithOnlyOneLevel } from '../../utils/StateSelectorHelper';

function getState(state) {
  return getStateWithOnlyOneLevel(state, 'MyAccount');
}

export const getBillingReducer = state => getState(state).get('billing');
export const getPaymentReducer = state => getState(state).get('payment');
export const getPaymentDateReducer = state => getState(state).get('paymentDate');
export const getPaymentPriceReducer = state => getState(state).get('paymentPrice');
export const getPaymentAmountReducer = state => getState(state).get('paymentAmount');
export const getPaymentTypeReducer = state => getState(state).get('paymentType');
export const getBillingInfoReducer = state => getState(state).get('billingInfo');
export const getOpenSubscriptionReducer = state => getState(state).get('openSubscription');
