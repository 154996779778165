import { Map } from 'immutable';
import * as t from '../actions/actionTypes/myAccountActionTypes';
import * as loginActions from '../actions/actionTypes/loginActionTypes';
import * as courseActions from '../actions/actionTypes/courseActionTypes';

const initialState = Map({
  openSubscription: false,
  payment: null,
  paymentDate: null,
  paymentPrice: null,
  paymentAmount: null,
  paymentType: null,
  billingInfo: null,
});

const MyAccount = function (state = initialState, action) {
  switch (action.type) {
    case t.SET_OPEN_SUBSCRIPTION:
      return state.set('openSubscription', action.value);
    case t.SET_PAYMENT:
      return state.set('payment', action.value);
    case t.PAYMENT_DATE:
      return state.set('paymentDate', action.value);
    case t.PAYMENT_PRICE:
      return state.set('paymentPrice', action.value);
    case t.PAYMENT_AMOUNT:
      return state.set('paymentAmount', action.value);
    case t.PAYMENT_TYPE:
      return state.set('paymentType', action.value);
    case t.SET_BILLING_INFO:
      return state.set('billingInfo', action.value);
    case loginActions.SIGN_OUT_USER:
      return initialState;
    case courseActions.SWITCH_COURSE:
      return initialState;
    default:
      return state;
  }
};

export default MyAccount;
