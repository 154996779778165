import * as t from './actionTypes/studySessionActionTypes';

export const startStudySession = payload => ({
  type: t.START_STUDY_SESSION,
  payload
});

export const studySessionData = value => ({
  type: t.STUDY_SESSION_DATA,
  value
});

export const setStackedQuestions = payload => ({
  type: t.SET_STACKED_QUESTIONS,
  payload
});

export const setStackedAnswers = value => ({
  type: t.SET_STACKED_ANSWERS,
  value
});

export const setNotElapsedQuestions = value => ({
  type: t.SET_NOT_ELAPSED_QUESTIONS,
  value
});

export const setLoadingQuestions = value => ({
  type: t.SET_LOADING_QUESTIONS,
  value
});

export const setActualQuestion = (
  questionId,
  redirect,
  resetStudySession,
  status,
  firstQuestion
) => ({
  type: t.SET_ACTUAL_QUESTION,
  questionId,
  redirect,
  resetStudySession,
  status,
  firstQuestion
});

export const actualQuestion = value => ({
  type: t.ACTUAL_QUESTION,
  value
});

export const difficultFeedback = payload => ({
  type: t.DIFFICULT_FEEDBACK,
  payload
});

export const studySessionResults = value => ({
  type: t.STUDY_SESSION_RESULTS,
  value
});

export const exitStudySession = payload => ({
  type: t.EXIT_STUDY_SESSION,
  payload
});

export const startOpenStudyMode = payload => ({
  type: t.START_OPEN_STUDY_MODE,
  payload
});

export const sendQuestionStudySessionFeedback = value => ({
  type: t.SEND_QUESTION_STUDY_SESSION_FEEDBACK,
  value
});

export const openStudyModeResults = payload => ({
  type: t.OPEN_STUDY_MODE_RESULTS,
  payload
});

export const enableNotifications = value => ({
  type: t.ENABLE_NOTIFICATIONS,
  value
});

export const getStudyModes = payload => ({
  type: t.GET_STUDY_MODES,
  payload
});

export const studyModes = value => ({
  type: t.STUDY_MODES,
  value
});

export const actualAnswer = value => ({
  type: t.ACTUAL_ANSWER,
  value
});

export const respondedCorrectly = value => ({
  type: t.RESPONDED_CORRECTLY,
  value
});

export const showSubmit = value => ({
  type: t.SHOW_SUBMIT,
  value
});

export const showResults = value => ({
  type: t.SHOW_RESULTS,
  value
});

export const showFinalResults = value => ({
  type: t.SHOW_FINAL_RESULTS,
  value
});

export const studentAnswer = value => ({
  type: t.STUDENT_ANSWER,
  value
});

export const expiredPreview = value => ({
  type: t.EXPIRED_PREVIEW,
  value
});

export const preloadedQuestions = value => ({
  type: t.PRELOADED_QUESTIONS,
  value
});

export const setBtnSubmit = value => ({
  type: t.SET_BTN_SUBMIT,
  value
});

export const saveQuestionAnswer = payload => ({
  type: t.SAVE_QUESTION_ANSWER,
  payload
});

export const storedSelfGraded = value => ({
  type: t.STORED_SELF_GRADED,
  value
});

export const validateUpdatedQuestion = payload => ({
  type: t.VALIDATE_UPDATED_QUESTION,
  payload
});

export const preloadFirstQuestions = payload => ({
  type: t.PRELOAD_FIRST_QUESTIONS,
  payload
});

export const preloadedFirstQuestions = value => ({
  type: t.PRELOADED_FIRST_QUESTIONS,
  value
});

export const loadedQuestions = value => ({
  type: t.LOADED_QUESTIONS,
  value
});

export const anchoredNumber = value => ({
  type: t.ANCHORED_NUMBER,
  value
});

export const anchoredAnswers = value => ({
  type: t.ANCHORED_ANSWERS,
  value
});
