import epDefaultImage from '../assets/images/epDefaultImage.png';

// eslint-disable-next-line func-names
const getImageObject = async function () {
  const response = await fetch(epDefaultImage);
  const blob = await response.blob();
  return blob;
};

export default getImageObject;
