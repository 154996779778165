import * as types from '../actions/actionTypes/userActionTypes';
// AWS
import {
  CognitoIdentityProviderClient,
  AdminUpdateUserAttributesCommand,
  AdminDeleteUserCommand,
  ListUsersCommand,
  AdminRemoveUserFromGroupCommand
} from '@aws-sdk/client-cognito-identity-provider';
import { SecretsManagerClient, GetSecretValueCommand } from '@aws-sdk/client-secrets-manager';
import { SESClient, SendEmailCommand } from '@aws-sdk/client-ses';
import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import { Storage, API, graphqlOperation } from 'aws-amplify';
// Actions
import * as userActions from '../actions/userActions';
import * as notificationsActions from '../actions/errorHandlerActions';
import { getOrganizationData } from '../actions/organizationActions';
// Selectors
import { getUserData, getStudentDataReducer, getCourseIdReducer } from '../selectors/userSelectors';
import {
  getOrganizationDataReducer,
  getOrganizationLogoReducer
} from '../selectors/organizationSelector';
// Queries
import * as studentQueries from '../graphql/queries/studentQueries';
import * as queriesOrg from '../graphql/queries/organizationQueries';
import * as queries from '../graphql/queries';
// Mutations
import * as studentMutations from '../graphql/mutationsStudent';
import {
  updateCustomer,
  sendEmailSES,
  loginCognitoService,
  changePasswordCognitoService,
  getPasswordPoliciesCognitoService
} from '../graphql/queries/callsToLambdaFunctions';
// Another files
import GraphOp from '../sagas/common/GraphOp';
import getAWSConfigObject from '../utils/getAWSConfigObject';
import { sendEmailProcess } from '../utils/sendEmailProcess';
import GetDefaultBlankImage from '../utils/getDefaultBlankImage';
import { envName } from '../../src/components/Common/const';

function* loginSagas(action) {
  const {
    email,
    mfaCode,
    setHasMfa,
    params,
    poolData,
    setErrorSignin,
    setLoadingSignin,
    handleErrorLogin,
    setOpenChangePasswordModal,
    setRedirectInformation
  } = action.payload;
  try {
    const [org] = yield all([select(getOrganizationDataReducer)]);
    const organizationId = org && org.id ? org.id : null;
    let response;
    let hasMfa = false;
    // If you are a team member and have the MFA activated we have to request it, since the userpool is shared.
    if (organizationId && email) {
      response = yield GraphOp(queriesOrg.stdGetTeamMemberWithEmailQuerie, {
        organizationId,
        email
      });
      if (
        response &&
        response.data &&
        response.data.listTeamMembers &&
        response.data.listTeamMembers.items &&
        response.data.listTeamMembers.items[0] &&
        response.data.listTeamMembers.items[0].mfaEnabled
      ) {
        hasMfa = true;
      }
    }
    if (hasMfa && !mfaCode) {
      setHasMfa(true);
      setLoadingSignin(false);
    } else {
      // Pool data to get user
      // Params from loginView
      response = yield GraphOp(loginCognitoService, { params, poolData });
      if (!response.data.loginCognitoService) {
        setLoadingSignin(false);
        throw new Error('Error logging in, please try again. Or contact with support.');
      } else {
        const responseParsed = JSON.parse(response.data.loginCognitoService);
        const userResponse = responseParsed?.userResponse;
        const result = responseParsed?.result;
        const showModal = responseParsed?.showModal;
        yield put(getOrganizationData({}));
        // if show modal is the first time the user logs in or reset the password workflow
        // the redirect function is called from the modal
        if (showModal) {
          setLoadingSignin(false);
          setOpenChangePasswordModal(showModal);
          setRedirectInformation({ result, userResponse });
        } else {
          setRedirectInformation({ result, userResponse, redirect: true });
        }
      }
    }
  } catch (error) {
    setLoadingSignin(false);
    if (error?.errors?.length > 0) {
      const errorMessage = JSON.parse(error.errors[0].message);
      if (errorMessage.name) {
        handleErrorLogin(errorMessage.name);
      }
      if (errorMessage?.name === 'NotAuthorizedException') {
        setLoadingSignin(false);
        return setErrorSignin('Invalid email or password');
      }
    }
    yield put(notificationsActions.handleCatchError(error, 'loginSagas-Student'));
    setErrorSignin('Error logging in, please try again. Or contact with support.');
  }
}

function* getPasswordPoliciesSagas(action) {
  const { params, setPasswordPolicy } = action.payload;
  try {
    const response = yield GraphOp(getPasswordPoliciesCognitoService, { params });
    const responseParsed = JSON.parse(response.data.getPasswordPoliciesCognitoService);
    if (
      responseParsed &&
      responseParsed.data &&
      responseParsed.data.getPasswordPoliciesCognitoService
    ) {
      if (
        responseParsed.data.getPasswordPoliciesCognitoService &&
        responseParsed.data.getPasswordPoliciesCognitoService.UserPool &&
        responseParsed.data.getPasswordPoliciesCognitoService.UserPool.Policies &&
        responseParsed.data.getPasswordPoliciesCognitoService.UserPool.Policies.PasswordPolicy
      ) {
        setPasswordPolicy({
          ...responseParsed.data.getPasswordPoliciesCognitoService.UserPool.Policies.PasswordPolicy,
          NoWhiteSpaces: true
        });
      } else {
        setPasswordPolicy({
          MinimumLength: 8,
          RequireUppercase: true,
          RequireLowercase: true,
          NoWhiteSpaces: true
        });
      }
    } else {
      setPasswordPolicy({
        MinimumLength: 8,
        RequireUppercase: true,
        RequireLowercase: true,
        NoWhiteSpaces: true
      });
    }
  } catch (error) {
    yield put(notificationsActions.handleCatchError(error, 'getPasswordPoliciesSagas-Student'));
    setPasswordPolicy({
      MinimumLength: 8,
      RequireUppercase: true,
      RequireLowercase: true,
      NoWhiteSpaces: true
    });
  }
}

function* changePasswordSagas(action) {
  const {
    userParams,
    setLoadingPasswordChange,
    setErrorPasswordChange,
    setErrorValidationCode,
    setSuccessPasswordChange,
    setErrorSignin,
    sendConfirmationPasswordEmail,
    setVerificationCodeSent,
    setForgetPasswordMode,
    setLoadingValidationCode
  } = action.payload;
  try {
    if (!userParams) {
      throw new Error('Error changing password, missing user params.');
    }
    const response = yield GraphOp(changePasswordCognitoService, { userParams });
    if (!response.data.changePasswordCognitoService) {
      throw new Error('Error changing password, please try again. Or contact with support.');
    }
    const responseParsed = JSON.parse(response.data.changePasswordCognitoService);
    if (responseParsed) {
      setLoadingPasswordChange(false);
      setErrorPasswordChange(null);
      setErrorValidationCode(null);
      setErrorSignin(null);
      setSuccessPasswordChange(true);
      sendConfirmationPasswordEmail();
      setVerificationCodeSent(false);
      setForgetPasswordMode(false);
    } else {
      setLoadingPasswordChange(false);
      setSuccessPasswordChange(false);
      setLoadingValidationCode(false);
    }
  } catch (error) {
    yield put(notificationsActions.handleCatchError(error, 'changePasswordSagas-Student'));
    setLoadingPasswordChange(false);
    setSuccessPasswordChange(false);
    if (error.message) {
      setErrorPasswordChange(error.message);
    } else {
      setErrorPasswordChange('Error changing password, please try again. Or contact with support.');
    }
    throw new Error('Error changing password, please try again. Or contact with support.');
  }
}

function* sendEmailSagas(action) {
  const mailParams = sendEmailProcess(action.payload);
  // destructure params if for forget password workflow
  const {
    setForgetPasswordCode,
    vertificationCode,
    setVerificationCodeSent,
    setErrorValidationCode,
    setLoadingValidationCode,
    setLoadingButton
  } = action.payload;
  // mailParams is a stringified JSON object containing the mail parameters
  // execute lambda function to send the email and return the result
  // use try/catch to handle errors
  try {
    const data = yield GraphOp(sendEmailSES, {
      mailParams
    });
    // If verification code is the forgot password workflow
    if (vertificationCode) {
      setForgetPasswordCode(vertificationCode);
      setVerificationCodeSent(true);
      setLoadingValidationCode(false);
      setErrorValidationCode(null);
      setLoadingButton(false);
    }
    return data;
  } catch (err) {
    yield put(notificationsActions.handleCatchError(err, 'sendEmailSagas'));
    yield put(
      notificationsActions.setNotification({
        message: 'Problem Sending email, please try again or contact with support.',
        severity: 'error'
      })
    );
    // If verification code is the forgot password workflow
    if (vertificationCode) {
      setLoadingValidationCode(false);
      setErrorValidationCode(err.message);
      setLoadingButton(false);
    }
    return err;
  }
}

function* findMylaunchDarklyAccessSagas() {
  try {
    if (envName) {
      const response = yield GraphOp(queries.getLaunchDarklyAccess, { envName });
      if (response && response.data && response.data.getLaunchDarklyAccess) {
        yield all([put(userActions.setMyLaunchDatklyAccess(response.data.getLaunchDarklyAccess))]);
      }
    }
  } catch (err) {
    yield yield put(notificationsActions.handleCatchError(err, 'GetMylauchDarklyAccessSagas'));
  }
}

function* getUserLogoSagas() {
  try {
    const [userData] = yield all([select(getUserData), put(userActions.loadingUserLogo(true))]);
    const userId = userData && userData.username ? userData.username : null;
    if (userId) {
      const filePath = `${userId}/profileImage`;
      let avatarUrl = yield call([Storage, 'get'], filePath);
      const photoResponse = yield fetch(avatarUrl, { method: 'GET' });
      if (photoResponse && photoResponse.status !== 200) {
        const defaultImage = yield call(GetDefaultBlankImage);
        yield call([Storage, 'put'], filePath, defaultImage);
        avatarUrl = yield call([Storage, 'get'], filePath);
      }
      yield all([put(userActions.setUserLogo(avatarUrl))]);
    }
    yield all([put(userActions.loadingUserLogo(false))]);
  } catch (err) {
    yield all([put(userActions.loadingUserLogo(false)), yield put(userActions.setUserLogo(null))]);
    yield put(notificationsActions.handleCatchError(err, 'getUserLogoSagas'));
  }
}

function* uploadUserLogoSagas(action) {
  try {
    const [userData] = yield all([select(getUserData), put(userActions.loadingUserLogo(true))]);
    const userId = userData && userData.username ? userData.username : null;
    if (userId) {
      const filePath = `${userId}/profileImage`;
      const image = action.value;
      yield call([Storage, 'put'], filePath, image);
      yield all([put(userActions.getUserLogo())]);
    }
    yield put(userActions.loadingUserLogo(false));
  } catch (err) {
    yield all([
      put(
        notificationsActions.setNotification({
          message: 'Unable to complete request. Please contact us for support.',
          severity: 'error'
        })
      ),
      put(userActions.loadingUserLogo(false))
    ]);
    yield put(notificationsActions.handleCatchError(err, 'uploadUserLogoSagas'));
  }
}

function* getStudentDataSagas(action) {
  try {
    const { studentId, organizationId } = action.value;
    if (studentId && organizationId) {
      const response = yield GraphOp(studentQueries.stdGetStudentDataQuerie, {
        studentId,
        organizationId
      });
      const studentData =
        response && response.data && response.data.getStudent ? response.data.getStudent : null;
      if (studentData && studentData.id) {
        window.analytics.identify(studentData.id, {
          name: studentData.name,
          email: studentData.email
        });
        yield put(userActions.setStudentData(studentData));
      }
    }
  } catch (err) {
    yield put(notificationsActions.handleCatchError(err, 'getStudentDataSagas'));
  }
}

function* updateStudentEmailSagas(action) {
  try {
    const { email, organizationId, studentId, stripeId } = action.value;
    //first update the student Data
    const studentResponse = yield GraphOp(studentMutations.updateStudentEmail, {
      studentId: studentId,
      organizationId: organizationId,
      email: email
    });
    yield put(userActions.setStudentData(studentResponse.data.updateStudent));
    //second update userData on reducer
    let userData = yield select(getUserData);
    userData.attributes.email = email;
    userData.attributes.email_verified = false;
    yield put(userActions.setUser(userData));

    //update the email on stripe
    const [studentData, organizationData] = yield all([
      select(getStudentDataReducer),
      select(getOrganizationDataReducer)
    ]);
    let organizationCustomer;

    if (
      studentData &&
      studentData.organizationCustomers &&
      studentData.organizationCustomers.length > 0 &&
      organizationData
    ) {
      organizationCustomer = studentData.organizationCustomers.find(
        oc => oc.organizationID === organizationData.id
      );
    }

    if (organizationCustomer && organizationCustomer.customerID) {
      let billingInfo = {};
      billingInfo.email = email;
      yield GraphOp(updateCustomer, {
        customerId: organizationCustomer.customerID,
        stripeAccount: stripeId,
        billingInfo: JSON.stringify(billingInfo),
        envName: envName
      });
    } else {
      yield put(
        notificationsActions.setNotification({
          message: 'Unable to complete request. Please contact us for support.',
          severity: 'error'
        })
      );
    }
  } catch (err) {
    yield put(notificationsActions.handleCatchError(err, 'updateStudentEmailSagas'));
  }
}

function* updateStudentNameSagas(action) {
  let { setNameToStripe, setLoading } = action.value;
  try {
    const { name, organizationId, studentId, stripeId } = action.value;
    //first update the student Data
    let studentResponse = yield GraphOp(studentMutations.updateStudentName, {
      studentId: studentId,
      organizationId: organizationId,
      name: name
    });
    if (studentResponse && studentResponse.data && studentResponse.data.updateStudent) {
      yield put(userActions.setStudentData(studentResponse.data.updateStudent));
      studentResponse = studentResponse.data.updateStudent;
    }

    //update the name on stripe
    const [studentData, organizationData] = yield all([
      select(getStudentDataReducer),
      select(getOrganizationDataReducer)
    ]);
    let organizationCustomer;

    if (
      studentData &&
      studentData.organizationCustomers &&
      studentData.organizationCustomers.length > 0 &&
      organizationData
    ) {
      organizationCustomer = studentData.organizationCustomers.find(
        oc => oc.organizationID === organizationData.id
      );
    }

    if (organizationCustomer && organizationCustomer.customerID) {
      let billingInfo = {};
      billingInfo.name = name;

      let response = yield GraphOp(updateCustomer, {
        customerId: organizationCustomer.customerID,
        stripeAccount: stripeId,
        billingInfo: JSON.stringify(billingInfo),
        envName: envName
      });

      response = JSON.parse(response.data.updateCustomer);

      if (response && response.id) {
        //update name successful
        yield put(
          notificationsActions.setNotification({
            message: 'Name updated correctly',
            severity: 'success'
          })
        );
      } else {
        yield put(notificationsActions.handleCatchError('Error updating name'));
      }
    } else {
      if (studentResponse && studentResponse.id) {
        yield put(
          notificationsActions.setNotification({
            message: 'Name updated correctly',
            severity: 'success'
          })
        );
      } else {
        yield put(
          notificationsActions.setNotification({
            message: 'Unable to complete request. Please contact us for support.',
            severity: 'error'
          })
        );
      }
    }
    if (setNameToStripe) setNameToStripe(false);
    if (setLoading) setLoading(false);
  } catch (err) {
    if (setNameToStripe) setNameToStripe(false);
    if (setLoading) setLoading(false);
    yield put(notificationsActions.handleCatchError(err, 'updateStudentNameSagas'));
  }
}

function* createFreeAccessStudentSagas(action) {
  const {
    email,
    courses,
    userId,
    organizationId,
    courseName,
    registrationLink,
    logIn,
    referrerUrl,
    refreshToken,
    freeAccess,
    coursePreview,
    questionsLeft,
    backgroundEmail,
    organizationName,
    organizationSupportEmail
  } = action.payload;
  try {
    const ids = courses && courses.length > 0 ? courses : [];
    yield GraphOp(studentMutations.createStudentMutation, {
      organizationId,
      email,
      id: userId,
      courseIDs: ids
    });

    let orgCustomers = [
      {
        organizationID: organizationId,
        customerID: null,
        subscriptions: [
          {
            courseID: courses,
            subscriptionID: null,
            active: true,
            freeAccess: freeAccess,
            dateSubscribed: new Date().toISOString(),
            coursePreview: coursePreview,
            questionsLeft: questionsLeft
          }
        ]
      }
    ];

    const studentDataUpdated = yield call(
      [API, 'graphql'],
      graphqlOperation(studentMutations.updateOrganizationCustomer, {
        organizationID: organizationId,
        id: userId,
        organizationCustomers: orgCustomers
      })
    );

    yield put(userActions.setStudentData(studentDataUpdated.data.updateStudent));

    if (referrerUrl === 'everprep.com') {
      window.analytics.track('Sign up form → Completion');
    }

    if (refreshToken) {
      localStorage.removeItem('RefreshToken');
    }

    if (logIn) logIn();

    //prepare the data to send the mail

    const [orgLogo] = yield all([select(getOrganizationLogoReducer)]);

    let link = registrationLink.startsWith('http://')
      ? registrationLink
      : `http://${registrationLink}`;
    //Once the student is created, send him an email
    const payload = {
      type: 'studentSignsUp',
      email: email,
      backgroundEmail: backgroundEmail,
      organizationLogo: orgLogo,
      organizationSupportEmail: organizationSupportEmail,
      organizationName: organizationName,
      organizationId: organizationId,
      registrationLink: link,
      courseName: courseName
    };
    // Process the mail and send it
    const mailParams = sendEmailProcess(payload);

    // Create the promise and SES service object
    new SESClient(getAWSConfigObject())
      .send(new SendEmailCommand(mailParams))
      .then()
      .catch(() => {
        put(
          notificationsActions.handleCatchError(
            'An error occurred when sending the email',
            'createStudentSagas'
          )
        );
      });
  } catch (err) {
    yield all([
      put(
        notificationsActions.setNotification({
          message: err.message ? err.message : 'Server error. Please try again',
          severity: 'error'
        })
      )
    ]);
    yield put(notificationsActions.handleCatchError(err, 'createStudentSagas'));
  }
}

function* handlePreviewUserQuestionsSagas() {
  try {
    const [studentData, courseId, organizationData] = yield all([
      select(getStudentDataReducer),
      select(getCourseIdReducer),
      select(getOrganizationDataReducer)
    ]);
    const organizationID = organizationData && organizationData.id ? organizationData.id : null;
    const id = studentData && studentData.id ? studentData.id : null;
    if (studentData && studentData.organizationCustomers && organizationID && id) {
      // First of all we check for the orgCustomer of this org.
      let orgCustomers = studentData.organizationCustomers.find(
        item => item.organizationID === organizationData.id
      );
      if (orgCustomers) {
        if (orgCustomers.subscriptions && orgCustomers.subscriptions.length > 0) {
          orgCustomers.subscriptions.forEach(sub => {
            if (sub && sub.courseID && sub.courseID === courseId && sub.active === true) {
              // We take off one question from the preview.
              // If we reach the zero questions we change the subscription state .
              sub.questionsLeft--;
              if (sub.questionsLeft === 0) sub.active = false;
            }
          });
        }
        const studentDataUpdated = yield call(
          [API, 'graphql'],
          graphqlOperation(studentMutations.updateOrganizationCustomer, {
            organizationID,
            id,
            organizationCustomers: orgCustomers
          })
        );
        yield put(userActions.setStudentData(studentDataUpdated.data.updateStudent));
      }
    }
  } catch (err) {
    yield put(notificationsActions.handleCatchError(err, 'handlePreviewUserQuestionsSagas'));
  }
}

function* deleteStudentSagas(action) {
  try {
    const { organizationId, id, userPoolId, email } = action.payload;
    let deleteFromCognito = false;
    //Delete student from database
    yield GraphOp(studentMutations.deleteStudent, {
      organizationId,
      id
    });
    //We ask if have a team member account
    const isTeamMember = yield GraphOp(queriesOrg.stdIsTeamMemberQuerie, {
      userId: id,
      organizationId
    });
    //Delete the student's cognito account if the student is not a team person
    if (!isTeamMember.data.getTeamMember) deleteFromCognito = true;
    const cognitoClient = new CognitoIdentityProviderClient(getAWSConfigObject());

    //remove group from cognito
    const paramsRemoveGroup = {
      GroupName: 'USER_ROLE_STUDENT',
      UserPoolId: userPoolId,
      Username: id
    };
    cognitoClient.send(new AdminRemoveUserFromGroupCommand(paramsRemoveGroup));

    if (deleteFromCognito) {
      //remove or update the account on main user pool
      const secretsClient = new SecretsManagerClient(getAWSConfigObject());
      secretsClient.send(
        new GetSecretValueCommand({ SecretId: 'NO_CONTEXT_POOL_CREDENTIALS' }),
        (error, secret) => {
          if (error) {
            put(notificationsActions.handleCatchError(error, 'Delete person from main user pool'));
          } else {
            let mainUserPoolId = JSON.parse(secret.SecretString)['NO_CONTEXT_USER_POOL_ID'];
            let existingMainUser;
            let listParams = {};
            listParams.UserPoolId = mainUserPoolId;
            listParams.Filter = `email = \"${email}\"`; //eslint-disable-line
            cognitoClient.send(new ListUsersCommand(listParams), (err, data) => {
              if (err) {
                put(
                  notificationsActions.handleCatchError(err, 'Delete person from main user pool')
                );
              } else {
                if (data.Users && data.Users.length > 0) {
                  existingMainUser = data.Users[0];
                  let newAttributes = {};
                  let auxi = existingMainUser.Attributes.filter(
                    item => item.Name === 'custom:UserPools'
                  );
                  let obj = auxi.shift();
                  //here i need to see if the list of user pools has more than 1 user pool
                  //if has only 1 -> delete the account
                  //if has more than 1 -> remove it and update the user
                  if (obj.Value.includes(',')) {
                    //if has comma, it means that has more than 1 user pool
                    obj.Value = obj.Value.replace(',' + userPoolId, '');
                    newAttributes = [obj];
                    let paramsToUpdate = {
                      UserPoolId: mainUserPoolId, //MAIN USER POOL
                      Username: existingMainUser.Username,
                      UserAttributes: newAttributes
                    };
                    cognitoClient.send(
                      new AdminUpdateUserAttributesCommand(paramsToUpdate),
                      err => {
                        if (err) {
                          put(
                            notificationsActions.handleCatchError(
                              err,
                              'Delete person from main user pool'
                            )
                          );
                        }
                      }
                    );
                  } else {
                    //delete the account
                    const params = {};
                    params.Username = existingMainUser.Username;
                    params.UserPoolId = mainUserPoolId;
                    cognitoClient.send(new AdminDeleteUserCommand(params), err => {
                      if (err) {
                        put(
                          notificationsActions.handleCatchError(
                            err,
                            'Delete person from main user pool'
                          )
                        );
                      }
                    });
                  }
                }
              }
            });
          }
        }
      );

      //remove account from org user pool
      const params = {};
      params.Username = id;
      params.UserPoolId = userPoolId;
      cognitoClient.send(new AdminDeleteUserCommand(params), err => {
        if (err) {
          put(notificationsActions.handleCatchError(err, 'Delete person from cognito'));
        }
      });
    }
  } catch (err) {
    yield put(notificationsActions.handleCatchError(err, 'deleteStudentSagas'));
  }
}

function* checkEmailRegisteredSagas(action) {
  const { email, setUserRegistered, setLoading, setNameUserRegistered } = action.payload;
  try {
    if (setLoading) setLoading(true);
    const [organizationData] = yield all([select(getOrganizationDataReducer)]);
    const organizationId = organizationData && organizationData.id ? organizationData.id : null;
    let allTheStudents = [];
    if (organizationId) {
      let response = yield GraphOp(studentQueries.stdGetStudentByEmailQuerie, {
        studentEmail: email,
        organizationId: organizationId
      });
      if (
        response &&
        response.data &&
        response.data.getOrganization &&
        response.data.getOrganization.students
      ) {
        if (
          response.data.getOrganization.students.items &&
          response.data.getOrganization.students.items.length > 0
        ) {
          allTheStudents = [...response.data.getOrganization.students.items];
        }
        let nextToken = response.data.getOrganization.students.nextToken
          ? response.data.getOrganization.students.nextToken
          : null;
        while (nextToken) {
          if (allTheStudents.length > 0) nextToken = null;
          else {
            // If has nextToken it means that are more users to get
            response = yield GraphOp(studentQueries.stdGetStudentByEmailQuerie, {
              studentEmail: email,
              organizationId,
              nextToken
            });
            if (
              response &&
              response.data &&
              response.data.getOrganization &&
              response.data.getOrganization.students
            ) {
              if (
                response.data.getOrganization.students.items &&
                response.data.getOrganization.students.items.length > 0
              ) {
                allTheStudents = allTheStudents.concat(
                  response.data.getOrganization.students.items
                );
              }
              nextToken = response.data.getOrganization.students.nextToken
                ? response.data.getOrganization.students.nextToken
                : null;
            }
          }
        }
      }
    }
    if (allTheStudents && allTheStudents.length > 0) {
      if (setUserRegistered) setUserRegistered(true);
      if (setNameUserRegistered) setNameUserRegistered(allTheStudents[0].name);
      yield all([
        put(userActions.setStudentData(allTheStudents[0])),
        put(
          notificationsActions.setNotification({
            message:
              'This email address is already associated with an account. Please try logging in.',
            severity: 'error'
          })
        )
      ]);
    } else {
      if (setUserRegistered) setUserRegistered(false);
      if (setNameUserRegistered) setNameUserRegistered(null);
      yield put(userActions.setStudentData(null));
    }
    if (setLoading) setLoading(false);
  } catch (err) {
    yield put(notificationsActions.handleCatchError(err, 'checkEmailRegisteredSagas'));
    if (setLoading) setLoading(false);
    if (setUserRegistered) setUserRegistered(false);
    if (setNameUserRegistered) setNameUserRegistered(null);
  }
}
// Watchers
function* loginWatcher() {
  yield takeLatest(types.LOGIN, loginSagas);
}
function* getPasswordPoliciesWatcher() {
  yield takeLatest(types.GET_PASSWORD_POLICIES, getPasswordPoliciesSagas);
}
function* changePasswordWatcher() {
  yield takeLatest(types.CHANGE_PASSWORD, changePasswordSagas);
}
function* sendEmailWatcher() {
  yield takeLatest(types.SEND_EMAIL, sendEmailSagas);
}
function* findMylaunchDarklyAccessWatcher() {
  yield takeLatest(types.FIND_MY_LAUNCH_DARKLY_ACCESS, findMylaunchDarklyAccessSagas);
}
function* getUserLogoWatcher() {
  yield takeLatest(types.GET_USER_LOGO, getUserLogoSagas);
}
function* uploadUserLogoWatcher() {
  yield takeLatest(types.UPLOAD_USER_LOGO, uploadUserLogoSagas);
}
function* getStudentDataWatcher() {
  yield takeLatest(types.GET_STUDENT_DATA, getStudentDataSagas);
}
function* updateStudentEmailWatcher() {
  yield takeLatest(types.UPDATE_STUDENT_EMAIL, updateStudentEmailSagas);
}
function* updateStudentNameWatcher() {
  yield takeLatest(types.UPDATE_STUDENT_NAME, updateStudentNameSagas);
}
function* createFreeAccessStudentWatcher() {
  yield takeLatest(types.CREATE_FREE_ACCESS_STUDENT, createFreeAccessStudentSagas);
}
function* deleteStudentWatcher() {
  yield takeLatest(types.DELETE_STUDENT, deleteStudentSagas);
}
function* checkEmailRegisteredWatcher() {
  yield takeLatest(types.CHECK_EMAIL_REGISTERED, checkEmailRegisteredSagas);
}
function* handlePreviewUserQuestionWatcher() {
  yield takeLatest(types.HANDLE_PREVIEW_USER_QUESITONS, handlePreviewUserQuestionsSagas);
}

// Exports the sagas
export default function* sagas() {
  yield all([
    loginWatcher(),
    getPasswordPoliciesWatcher(),
    changePasswordWatcher(),
    sendEmailWatcher(),
    getUserLogoWatcher(),
    uploadUserLogoWatcher(),
    getStudentDataWatcher(),
    updateStudentEmailWatcher(),
    updateStudentNameWatcher(),
    deleteStudentWatcher(),
    checkEmailRegisteredWatcher(),
    createFreeAccessStudentWatcher(),
    handlePreviewUserQuestionWatcher(),
    findMylaunchDarklyAccessWatcher()
  ]);
}
