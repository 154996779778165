// @TODO: Reconnect intercom on stdGetOrganizationDataQuerie
// intercom {
//  appId
//  secretCode
//  accessToken
//}

export const stdGetOrganizationDataQuerie = /* GraphQL */ `
  query stdGetOrganizationDataQuerie($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      stripeOnboardingComplete
      stripeId
      supportEmail
      termsAndConditions {
        url
      }
      privacyPolicy {
        url
      }
      termsAndConditions {
        url
        isFile
      }
      privacyPolicy {
        url
        isFile
      }
    }
  }
`;

export const stdGetOrganizationMinimunDataQuerie = /* GraphQL */ `
  query stdGetOrganizationMinimunDataQuerie($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      supportEmail
      appSetting {
        customColor
        presetActive
        presetColor
        section
      }
    }
  }
`;

export const stdGetBundlesQuerie = /* GraphQL */ `
  query stdGetBundlesQuerie($organizationId: ID!) {
    getOrganization(id: $organizationId) {
      subscriptionBundles {
        name
        subtitle
        available
        activeCourses
        lastEdited
        stripeProductID
        pricing {
          subscriptions {
            active
            period
            autoRenewal
            price
          }
          freeTrial {
            active
            duration
            period
          }
        }
      }
    }
  }
`;

export const stdGetCoursesStudentQuerie = /* GraphQL */ `
  query stdGetCoursesStudentQuerie($organizationID: ID!, $id: ID!) {
    getStudent(organizationID: $organizationID, id: $id) {
      id
      courseIDs
    }
  }
`;

export const stdGetOrganizationNotificationsQuerie = /* GraphQL */ `
  query stdGetOrganizationNotificationsQuerie($organizationID: ID!) {
    getOrganization(id: $organizationID) {
      notifications {
        id
        text
        type
        courseId
        courseName
        createdAt
        examId
        organizationName
        questionId
        questionName
        readedBy
      }
    }
  }
`;

export const stdIsTeamMemberQuerie = /* GraphQL */ `
  query stdIsTeamMemberQuerie($userId: ID!, $organizationId: ID!) {
    getTeamMember(id: $userId, organizationID: $organizationId) {
      id
    }
  }
`;

export const stdGetAllCoursesOrganizationQuerie = /* GraphQL */ `
  query stdGetAllCoursesOrganizationQuerie($organizationId: ID!, $nextToken: String) {
    listCourses(
      organizationID: $organizationId
      nextToken: $nextToken
      limit: 1500
      filter: { status: { eq: ACTIVE } }
    ) {
      nextToken
      items {
        id
        name
        status
        url
        actionRequired
      }
    }
  }
`;

export const stdGetTeamMemberWithEmailQuerie = /* GraphQL */ `
  query stdGetTeamMemberWithEmailQuerie($email: String!, $organizationId: ID) {
    listTeamMembers(organizationID: $organizationId, filter: { email: { eq: $email } }) {
      items {
        id
        name
        email
        mfaEnabled
      }
    }
  }
`;
