export const storeFirstAnswer = /* GraphQL */ `
  mutation createStudentAnswer(
    $studentId: ID!
    $courseId: ID!
    $questionId: ID!
    $answerHistory: [Int]
    $answerHistoryVersion: [VersionedHistoryInput]
  ) {
    createStudentAnswer(
      input: {
        studentID: $studentId
        courseID: $courseId
        questionID: $questionId
        answerHistory: $answerHistory
        answerHistoryVersion: $answerHistoryVersion
      }
    ) {
      id
    }
  }
`;

export const storeUpdatedAnswer = /* GraphQL */ `
  mutation updateStudentAnswer(
    $answerId: ID!
    $studentId: ID!
    $courseId: ID!
    $answerHistory: [Int]
    $answerHistoryVersion: [VersionedHistoryInput]
  ) {
    updateStudentAnswer(
      input: {
        id: $answerId
        studentID: $studentId
        courseID: $courseId
        answerHistory: $answerHistory
        answerHistoryVersion: $answerHistoryVersion
      }
    ) {
      id
    }
  }
`;

export const createStudentAnswerMutation = /* GraphQL */ `
  mutation createStudentAnswer(
    $studentId: ID!
    $courseId: ID!
    $questionId: ID!
    $eFactor: Float
    $nextNormal: Float
    $nextHard: Float
    $nextEasy: Float
    $nextAppearance: AWSDateTime
    $correctAnswer: Boolean
    $answerHistory: [Int]
  ) {
    createStudentAnswer(
      input: {
        studentID: $studentId
        courseID: $courseId
        questionID: $questionId
        eFactor: $eFactor
        normal: $nextNormal
        hard: $nextHard
        easy: $nextEasy
        nextAppearance: $nextAppearance
        correctAnswer: $correctAnswer
        answerHistory: $answerHistory
      }
    ) {
      id
      studentID
      courseID
      questionID
      eFactor
      normal
      hard
      easy
      nextAppearance
      correctAnswer
      answerHistory
      updatedAt
    }
  }
`;

export const updateStudentAnswerMutation = /* GraphQL */ `
  mutation updateStudentAnswerMutation(
    $answerId: ID!
    $studentId: ID!
    $courseId: ID!
    $correctAnswer: Boolean
    $nextAppearance: AWSDateTime
    $eFactor: Float
    $nextHard: Float
    $nextNormal: Float
    $nextEasy: Float
    $answerHistory: [Int]
    $answerHistoryVersion: [VersionedHistoryInput]
  ) {
    updateStudentAnswer(
      input: {
        id: $answerId
        studentID: $studentId
        courseID: $courseId
        correctAnswer: $correctAnswer
        nextAppearance: $nextAppearance
        eFactor: $eFactor
        hard: $nextHard
        normal: $nextNormal
        easy: $nextEasy
        answerHistory: $answerHistory
        answerHistoryVersion: $answerHistoryVersion
      }
    ) {
      id
      questionID
      studentID
      courseID
      nextAppearance
      hard
      normal
      easy
      eFactor
      updatedAt
      answerHistory
    }
  }
`;

export const addNotificationMutation = /* GraphQL */ `
  mutation addNotificationMutation($organizationId: ID!, $notifications: [NotificationInput]) {
    updateOrganization(input: { id: $organizationId, notifications: $notifications }) {
      id
    }
  }
`;

export const updateLastStudySession = /* GraphQL */ `
  mutation updateLastStudySession(
    $studentId: ID!
    $organizationId: ID!
    $studySessions: [LastStudySessionInput]
  ) {
    updateStudent(
      input: { id: $studentId, organizationID: $organizationId, lastStudySessions: $studySessions }
    ) {
      id
    }
  }
`;
