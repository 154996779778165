export const GET_CONTAINER = 'content/GET_TOPIC_CONTAINER';
export const SET_CONTAINER = 'content/SET_TOPIC_CONTAINER';
export const LOADING_CONTAINER = 'content/LOADING_CONTAINER';
export const SET_CONTAINERS_LENGTH = 'content/SET_CONTAINERS_LENGTH';
export const GET_NEXT_TOPIC_CONTENT = 'content/GET_NEXT_TOPIC_CONTENT';
export const SET_NEXT_TOPIC_CONTENT = 'content/SET_NEXT_TOPIC_CONTENT';
export const MARK_READ = 'content/MARK_READ';
export const LESSON_SESSION = 'content/LESSON_SESSION';
export const START_LESSON_SESSION = 'content/START_LESSON_SESSION';
export const EXIT_LESSON_SESSION = 'content/EXIT_LESSON_SESSION';
export const TRACK_STUDENT_STATS = 'content/TRACK_STUDENT_STATS';
