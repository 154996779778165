export const SET_USER = 'SET_USER';

export const SET_SELECTED_COURSE_ID = 'myAccount/SET_SELECTED_COURSE_ID';
export const SET_ORGANIZATION_ID = 'myAccount/SET_ORGANIZATION_ID';

export const SET_USER_LOGO = 'course/SET_USER_LOGO';
export const GET_USER_LOGO = 'course/GET_USER_LOGO';
export const UPDATE_USER_EMAIL = 'user/UPDATE_USER_EMAIL';
export const UPLOAD_USER_LOGO = 'myAccount/UPLOAD_USER_LOGO';
export const LOADING_USER_LOGO = 'course/LOADING_USER_LOGO';

export const SET_SELECTED_TOPIC_ID = 'course/SET_SELECTED_TOPIC_ID';
export const SET_SELECTED_SECTION_ID = 'course/SET_SELECTED_SECTION_ID';

export const SET_STUDY_PROGRESS = 'course/SET_STUDY_PROGRESS';

export const GET_STUDENT_DATA = 'GET_STUDENT_DATA';
export const SET_STUDENT_DATA = 'SET_STUDENT_DATA';
export const UPDATE_STUDENT_EMAIL = 'UPDATE_STUDENT_EMAIL';
export const UPDATE_STUDENT_NAME = 'UPDATE_STUDENT_NAME';

export const DELETE_STUDENT = 'DELETE_STUDENT';

export const CHECK_EMAIL_REGISTERED = 'CHECK_EMAIL_REGISTERED';

export const PREVIEW_USER = 'PREVIEW_USER';
export const HANDLE_PREVIEW_USER_QUESITONS = 'HANDLE_PREVIEW_USER_QUESITONS';

export const CREATE_FREE_ACCESS_STUDENT = 'CREATE_FREE_ACCESS_STUDENT';

export const SET_MY_LAUNCH_DARKLY_ACCESS = 'SET_MY_LAUNCH_DARKLY_ACCESS';
export const FIND_MY_LAUNCH_DARKLY_ACCESS = 'FIND_MY_LAUNCH_DARKLY_ACCESS';

export const SET_STRIPE_CONNECT = 'SET_STRIPE_CONNECT';
export const GET_STRIPE_CONNECT = 'GET_STRIPE_CONNECT';
export const USER_ANSWERS = 'USER_ANSWERS';
export const SEND_EMAIL = 'SEND_EMAIL';
export const LESSONS_READ = 'LESSONS_READ';
export const LOGIN = 'LOGIN';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const GET_PASSWORD_POLICIES = 'GET_PASSWORD_POLICIES';