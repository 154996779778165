import { put, takeLatest, all, call, select } from 'redux-saga/effects';
import { API, graphqlOperation } from 'aws-amplify';
// Actions
import * as myAccountActions from '../actions/myAccountActions';
import * as myAccountActionTypes from '../actions/actionTypes/myAccountActionTypes';
import * as userActions from '../actions/userActions';
import * as courseActions from '../actions/actionTypes/courseActionTypes';
import * as notificationsActions from '../actions/errorHandlerActions';
import {
  setCourseData,
  setStripeCourseProduct,
  setCourseFreeTrial,
  setPercentageLoadingScreen,
  setLoadingScreen,
  getCourseData
} from '../actions/courseActions';
import { sendReportEmailToOrganization, getOrganizationData } from '../actions/organizationActions';
// Queries
import * as studentQueries from '../graphql/queries/studentQueries';
import {
  stdGetCoursePricingQuerie,
  stdGetCourseFreeTrialQuerie
} from '../graphql/queries/courseQueries';
//selectors
import { getStudentDataReducer, getCourseIdReducer } from '../selectors/userSelectors';
import { getOrganizationDataReducer } from '../selectors/organizationSelector';
import { getStripeProductSelector, getCourseDataReducer } from '../selectors/Course/courseSelector';
import {
  getPaymentPriceReducer,
  getPaymentReducer
} from '../selectors/myAccount/myAccountMainViewSelector';
// Mutations
import * as studentMutations from '../graphql/mutationsStudent';
import {
  removePaymentMethod,
  createStripeCustomer,
  updateCustomer,
  stdGetStripeBillingQuerie,
  stdRetriveStripeSubscriptionsQuerie,
  stdRetrievePaymentInformationQuerie,
  stdGetProductFromStripeQuerie,
  cancelStripeSubscription,
  stdGetStripeCheckoutSessionQuerie,
  stdRetrieveStripePaymentMethodQuerie,
  stdGetStripeSubscriptionListQuerie,
  stdCreateStudentAccountAndSubscriptionQuerie
} from '../graphql/queries/callsToLambdaFunctions';
// External files
import getErrorDescription from '../utils/handleStripeErrors';
import { envName } from '../../src/components/Common/const';
import GraphOp from '../sagas/common/GraphOp';
//Loaders
import { startLoading, stopLoading } from '../actions/loaderHandlerActions';

const handleOrganizationCustomers = payload => {
  const { stripeSubscriptionItem, metadata, stripeCustomer, studentData, handleOrgCustomers } =
    payload;
  if (handleOrgCustomers) {
    // This function is dispatched a lot of times so this validation will cover that
    const studentDataAux = studentData;
    //delete studentDataAux.subscriptionID
    let organizationCustomersReturn = [];
    // 1 : No organizationCustomersObject (first subscription ever)
    if (
      !studentDataAux.organizationCustomers ||
      studentDataAux.organizationCustomers?.length === 0
    ) {
      const subscriptions = [];
      subscriptions.push(stripeSubscriptionItem);
      organizationCustomersReturn = [
        {
          organizationID: metadata.organizationId,
          customerID: stripeCustomer,
          subscriptions: subscriptions
        }
      ];
      return organizationCustomersReturn;
    }
    // 2: organziationCustomersObject created
    if (studentDataAux.organizationCustomers) {
      let isChanged = false;
      studentDataAux.organizationCustomers.forEach(item => {
        if (item.organizationID === metadata.organizationId) {
          // here we check if we are creating a new subscription or modifying an existing one
          let subscriptionToRemove;
          if (item.subscriptions && item.subscriptions.length > 0) {
            subscriptionToRemove = item.subscriptions.filter(
              object =>
                object.active && object.courseID === metadata.courseId && !object.coursePreview
            );
          }
          if (
            subscriptionToRemove.length === 1 &&
            subscriptionToRemove[0]?.subscriptionID !== stripeSubscriptionItem?.subscriptionID
          ) {
            isChanged = true;
          }
          const stripeSubscriptionList = [];
          item.subscriptions.forEach(obj => {
            let subID;
            if (obj?.subscriptionID === subscriptionToRemove[0]?.subscriptionID) {
              subID = true;
            }
            const stripeSubscriptionObject = {
              courseID: obj.courseID,
              subscriptionID: obj?.subscriptionID,
              dateSubscribed: obj.dateSubscribed,
              autoRenewal: obj.autoRenewal,
              coursePreview: obj.coursePreview,
              questionsLeft: obj.questionsLeft
            };
            if (subID) stripeSubscriptionObject.active = false;
            else stripeSubscriptionObject.active = obj.freeAccess ? false : obj.active;
            stripeSubscriptionList.push(stripeSubscriptionObject);
          });
          stripeSubscriptionList.push(stripeSubscriptionItem); // 2.a : if it exists for our ORG, we add a new subscription
          organizationCustomersReturn = [
            {
              organizationID: metadata.organizationId,
              customerID: stripeCustomer,
              subscriptions: stripeSubscriptionList
            },
            isChanged,
            subscriptionToRemove[0]?.subscriptionID ? subscriptionToRemove[0].subscriptionID : ''
          ];
          return organizationCustomersReturn;
        }
      });
      if (organizationCustomersReturn.length > 0) {
        return organizationCustomersReturn;
      }

      const thisOrgCustomer = studentDataAux.organizationCustomers.filter(item => {
        return item.organizationID === metadata.organizationId;
      });
      if (!thisOrgCustomer) {
        // 3 : If we dont have a orgCustomer for this org
        const organizationCustomers = [
          {
            organizationID: metadata.organizationId,
            customerID: stripeCustomer,
            subscriptions: [stripeSubscriptionItem]
          }
        ];
        studentDataAux.organizationCustomers.push(organizationCustomers);
        organizationCustomersReturn = studentDataAux.organizationCustomers;
      }
    }
    return organizationCustomersReturn;
  }
};

function* getStripeCourseProductSagas(action) {
  const { courseId, setLoading, getFreeTrial, setCourseName } = action.payload;
  try {
    if (setLoading) setLoading(true);
    let [organizationData, priceActualSubscription] = yield all([
      select(getOrganizationDataReducer),
      select(getPaymentPriceReducer)
    ]);
    const organizationId = organizationData && organizationData.id ? organizationData.id : null;
    const stripeAccount =
      organizationData && organizationData.stripeId ? organizationData.stripeId : null;
    let courseData = null;
    let stripeId = null;
    if (courseId && organizationId) {
      const response = yield GraphOp(stdGetCoursePricingQuerie, {
        courseId,
        organizationId
      });
      if (response && response.data && response.data.getCourse) {
        courseData = response && response.data && response.data.getCourse;
        if (courseData.pricing && courseData.pricing.active) {
          courseData.activeCoursePricing = courseData.pricing.active;
        }
        if (courseData.stripeProductID) stripeId = courseData.stripeProductID;
        if (setCourseName && courseData && courseData.name) setCourseName(courseData.name);
      }
    }
    if (courseData && courseData.activeCoursePricing) {
      if (stripeId && stripeAccount) {
        let stripeProduct;
        try {
          stripeProduct = yield GraphOp(stdGetProductFromStripeQuerie, {
            productId: stripeId,
            stripeAccount,
            envName
          });
        } catch (e) {
          yield all([
            put(
              notificationsActions.setNotification({
                message: 'There was an error trying to obtain the course information.',
                severity: 'error'
              })
            ),
            put(
              notificationsActions.handleCatchError(
                e,
                `getStripeCourseProductSagas > courseId: ${courseId} > Lambda error`
              )
            )
          ]);
        }
        if (
          stripeProduct &&
          stripeProduct.data &&
          stripeProduct.data.getproductfromstripe &&
          stripeProduct.data.getproductfromstripe.id
        ) {
          // Filter prices: only display prices created through creators panel
          let filteredPrices = [];
          // Prices created through creators panel
          if (organizationId && courseId) {
            let stripePrices = stripeProduct.data.getproductfromstripe.prices;
            if (
              stripePrices &&
              stripePrices.length > 0 &&
              courseData &&
              courseData.pricing &&
              courseData.pricing.subscriptions
            ) {
              let coursePrices = courseData.pricing.subscriptions;
              if (coursePrices && coursePrices.length > 0) {
                stripePrices.forEach(stripePrice => {
                  if (stripePrice && stripePrice.priceId) {
                    const thisPrice = coursePrices.find(
                      cp =>
                        cp &&
                        (cp.stripePriceId === stripePrice.priceId ||
                          stripePrice.priceId === priceActualSubscription)
                    );
                    if (thisPrice) filteredPrices.push(stripePrice);
                  }
                });
              }
            }
            // Set filtered prices
            stripeProduct.data.getproductfromstripe.prices = filteredPrices;
            yield put(setStripeCourseProduct(stripeProduct.data.getproductfromstripe));
            if (getFreeTrial && courseData && courseData.pricing) {
              yield put(setCourseFreeTrial(courseData.pricing.freeTrial));
            }
          }
        }
      } else {
        yield put(
          notificationsActions.handleCatchError(
            'Missing stripeId or stripeAccount',
            `getStripeCourseProductSagas > courseId: ${courseId} > no id`
          )
        );
      }
    } else {
      yield put(
        notificationsActions.handleCatchError(
          'The Stripe Product is not active',
          `getStripeCourseProductSagas > courseId: ${courseId} > missin course data or activeCoursePricing`
        )
      );
      yield put(setStripeCourseProduct(null));
    }
    if (setLoading) setLoading(false);
  } catch (err) {
    if (setLoading) setLoading(false);
    yield put(
      notificationsActions.setNotification({
        message: 'Unable to complete request. Please contact us for support.',
        severity: 'error'
      })
    );
    yield put(notificationsActions.handleCatchError(err, 'getStripeCourseProductSagas'));
  }
}

function* createStripeCustomerAndPaymentSagas(action) {
  const {
    metadata,
    logIn,
    setValueStep,
    email,
    deleteCognitoAccount,
    userPoolId,
    setPercentage,
    priceValueId,
    referrerUrl,
    customerID,
    type,
    setLoadingScreen
  } = action.payload;
  const stripeId = metadata && metadata.stripeId ? metadata.stripeId : '';
  const courseId = metadata && metadata.courseId ? metadata.courseId : '';
  const organizationId = metadata && metadata.organizationId ? metadata.organizationId : '';
  try {
    if (!logIn) yield put(startLoading());
    const [paymentMethod, courseData] = yield all([
      select(getPaymentReducer),
      select(getCourseDataReducer)
    ]);
    let subscriptionObj;
    let cardObject;
    if (customerID) {
      subscriptionObj = yield GraphOp(stdGetStripeSubscriptionListQuerie, {
        customerId: customerID,
        stripeAccount: stripeId,
        courseId,
        envName,
        type
      });
      if (
        subscriptionObj &&
        subscriptionObj.data &&
        subscriptionObj.data.getStripeSubscriptionList
      ) {
        subscriptionObj = JSON.parse(subscriptionObj.data.getStripeSubscriptionList);
      }
    }
    if (
      subscriptionObj &&
      subscriptionObj.status &&
      (subscriptionObj.status === 'active' ||
        subscriptionObj.status === 'trialing' ||
        subscriptionObj.status === 'succeeded')
    ) {
      if (setPercentage) setPercentage(60);
      yield put(setPercentageLoadingScreen(60));
      const payload = {};
      payload.fromStripe = false;
      payload.subscriptionId = subscriptionObj.id;
      payload.stripeId = stripeId;
      payload.fromCreateStripePayment = true;
      payload.courseID = courseId;
      payload.organizationId = organizationId;
      yield put(myAccountActions.getPaymentDate(payload));
      let subChanged;
      if (setPercentage) setPercentage(100);
      yield put(setPercentageLoadingScreen(80));
      // here we get the card data from stripe and save it in the reducer
      if (customerID) {
        cardObject = yield GraphOp(stdRetrieveStripePaymentMethodQuerie, {
          stripeAccount: stripeId,
          customerId: customerID,
          envName: envName,
          paymentMethod:
            paymentMethod && paymentMethod.sourceId
              ? paymentMethod.sourceId
              : subscriptionObj && subscriptionObj.paymentMethod
              ? subscriptionObj.paymentMethod
              : ''
        });
        if (cardObject && cardObject.data && cardObject.data.retrieveStripePaymentMethod) {
          cardObject = JSON.parse(cardObject.data.retrieveStripePaymentMethod);
          yield put(myAccountActions.setPayment(cardObject));
        }
      }
      if (courseData) {
        courseData.activeSubscription = true;
        yield put(setCourseData(courseData));
      }
      //Lastly we set the product price in the reducer
      if (priceValueId) yield put(myAccountActions.paymentPrice(priceValueId));
      yield put(setPercentageLoadingScreen(100));
      if (subChanged) {
        yield put(
          notificationsActions.setNotification({
            message: 'Subscription changed successfully',
            severity: 'success'
          })
        );
      } else if (!logIn) window.analytics.track('Subscription successful');
    } else if (subscriptionObj.status === 'incomplete') {
      throw new Error('Error creating the subscription: the initial payment attempt failed');
    } else if (subscriptionObj.status === 'incomplete_expired') {
      throw new Error(
        'Error creating the subscription: the first invoice has not been paid within 23 hours'
      );
    } else if (subscriptionObj.status === 'past_due') {
      if (subscriptionObj.collection_method === 'charge_automatically') {
        throw new Error('The renew payment failed');
      } else {
        throw new Error('Your invoice has not been paid before the due date');
      }
    } else if (subscriptionObj.status === 'canceled' || subscriptionObj.status === 'unpaid') {
      if (subscriptionObj.collection_method === 'charge_automatically') {
        throw new Error('Error on the subscription: the payment retry attempts were exhausted');
      } else {
        throw new Error('Error on the subscription: not paid after the additional deadline');
      }
    } else {
      throw new Error('Error creating the subscription');
    }
    if (!logIn) yield put(stopLoading());
    if (!logIn && customerID) window.analytics.track('Renew Subscription');
    if (referrerUrl === 'everprep.com') {
      window.analytics.track('Sign up form → Completion');
    }
    // If a user is being created from signUp, the logIn is called to enter and display the course
    if (logIn) logIn();
    localStorage.removeItem('name');
    localStorage.removeItem('email');
    localStorage.removeItem('password');
  } catch (err) {
    if (!logIn) yield put(stopLoading());
    yield put(setLoadingScreen(false));
    if (
      err &&
      err.errors &&
      err.errors[0].message &&
      err.errors[0].message.includes('You cannot combine currencies on a single customer')
    ) {
      yield put(
        notificationsActions.setNotification({
          message:
            "You can't subscribe to a product with a different currency. Please contact support.",
          severity: 'error'
        })
      );
    } else {
      if (deleteCognitoAccount) {
        //if there are errors in the credit card data when creating a user from signUp
        const payload = {
          organizationId,
          id: metadata.studentId,
          email: email,
          userPoolId: userPoolId
        };
        //remove the student from the database
        yield put(userActions.deleteStudent(payload));
        setValueStep(1);
      }
      yield put(
        notificationsActions.setNotification({
          message:
            err && err.message
              ? err.message
              : 'Error creating Stripe Customer. Please contact support.',
          severity: 'error'
        })
      );
      yield put(notificationsActions.handleCatchError(err, 'createStripeCustomerAndPaymentSagas'));
    }
  }
}

function* createStripeCheckoutSessionSagas(action) {
  let { studentId, priceValueId, metadata, stripeConnect, fromSignUp, setLoader } = action.payload;
  try {
    const [stripeProduct, paymentMethod] = yield all([
      select(getStripeProductSelector),
      select(getPaymentReducer)
    ]);
    // First we check if the organization has already a customer
    let currentOrganizationCustomer = null;
    let studentData = null;
    let customerId = null;
    let alreadyHadAccess = false;
    const organizationId = metadata && metadata.organizationId ? metadata.organizationId : null;
    const courseId = metadata && metadata.courseId ? metadata.courseId : null;
    if (studentId && organizationId) {
      const response = yield GraphOp(studentQueries.stdGetStudentDataQuerie, {
        organizationId,
        studentId
      });
      studentData =
        response && response.data && response.data.getStudent ? response.data.getStudent : null;
    }
    if (
      organizationId &&
      studentData &&
      studentData.organizationCustomers &&
      studentData.organizationCustomers.length > 0
    ) {
      currentOrganizationCustomer = studentData.organizationCustomers.filter(item => {
        return item && item.organizationID === organizationId;
      });
      if (currentOrganizationCustomer && currentOrganizationCustomer[0] && courseId) {
        currentOrganizationCustomer = currentOrganizationCustomer[0];
        customerId = currentOrganizationCustomer.customerID;
        // First we check if the student already had access to the same course
        if (
          currentOrganizationCustomer.subscriptions &&
          currentOrganizationCustomer.subscriptions.length > 0
        ) {
          const subAux = currentOrganizationCustomer.subscriptions.filter(
            i => i && i.courseID === courseId
          );
          if (subAux && subAux.length > 0) {
            subAux.forEach(sub => {
              if (sub && !sub.active) alreadyHadAccess = true;
            });
          }
        }
      }
    }
    // If we already have a stripeCustomer, we are going to use that id, otherwise we are going to create a customer
    let stripeCustomer = null;
    if (!currentOrganizationCustomer || !currentOrganizationCustomer.customerID) {
      const custId =
        currentOrganizationCustomer && currentOrganizationCustomer.customerID
          ? currentOrganizationCustomer.customerID
          : null;
      if (custId) {
        stripeCustomer = yield GraphOp(updateCustomer, {
          customerId: custId,
          stripeAccount: metadata.stripeId,
          billingInfo: JSON.stringify(studentData)
        });
        if (stripeCustomer && stripeCustomer.data && stripeCustomer.data.updateCustomer) {
          stripeCustomer = JSON.parse(stripeCustomer.data.updateCustomer);
        }
      } else {
        stripeCustomer = yield GraphOp(createStripeCustomer, {
          billingInfo: JSON.stringify(studentData),
          metadata: JSON.stringify(metadata) //In the metadata is also the stripeAccount ID
        });
        if (stripeCustomer && stripeCustomer.data && stripeCustomer.data.createStripeCustomer) {
          stripeCustomer = JSON.parse(stripeCustomer.data.createStripeCustomer);
        }
      }
      customerId = stripeCustomer && stripeCustomer.id ? stripeCustomer.id : '';
      if (!(fromSignUp === 'account' || fromSignUp === 'payment')) {
        const stripeSubscriptionItem = {};
        // UPDATING ORGANIZATION CUSTOMER
        const payload = {
          stripeSubscriptionItem,
          metadata,
          stripeCustomer: customerId,
          studentData,
          handleOrgCustomers: true
        };
        let orgCustomers = handleOrganizationCustomers(payload);
        orgCustomers = orgCustomers.slice(0, 1);
        let thisOrganizationCustomer;
        if (orgCustomers && orgCustomers.length > 0) {
          thisOrganizationCustomer = orgCustomers.find(
            item => item && item.organizationID && item.organizationID === organizationId
          );
        }
        if (
          thisOrganizationCustomer &&
          thisOrganizationCustomer.subscriptions &&
          thisOrganizationCustomer.subscriptions.length > 0
        ) {
          const updatedStudent = yield GraphOp(studentMutations.updateOrganizationCustomer, {
            organizationID: organizationId,
            id: studentData.id,
            organizationCustomers: orgCustomers
          });
          if (updatedStudent && updatedStudent.data && updatedStudent.data.updateStudent) {
            yield put(userActions.setStudentData(updatedStudent.data.updateStudent));
          }
        }
      } else {
        localStorage.setItem('fromSignUp', fromSignUp);
      }
      //Lastly we set the product price in the reducer
      if (priceValueId) yield put(myAccountActions.paymentPrice(priceValueId));
    } else {
      if (fromSignUp) localStorage.setItem('fromSignUp', fromSignUp);
    }
    if (customerId) {
      let price;
      if (
        stripeProduct &&
        stripeProduct.prices &&
        stripeProduct.prices.length > 0 &&
        priceValueId
      ) {
        price = stripeProduct.prices.find(item => item && item.priceId === priceValueId);
      }
      let freeTrial = null;
      if (courseId && organizationId) {
        const response = yield GraphOp(stdGetCourseFreeTrialQuerie, {
          courseId,
          organizationId
        });
        freeTrial =
          response &&
          response.data &&
          response.data.getCourse.pricing &&
          response.data.getCourse.pricing.freeTrial
            ? response.data.getCourse.pricing.freeTrial
            : null;
      }
      let timeEnd;
      if (freeTrial && freeTrial.active) {
        // We check if the course has a free trial set up and it is the user's first subscription to this course
        if (!alreadyHadAccess && freeTrial.period) {
          timeEnd = new Date();
          switch (freeTrial.period) {
            case 'DAY':
              timeEnd.setDate(timeEnd.getDate() + parseInt(freeTrial.duration));
              metadata.duration = freeTrial.duration;
              break;
            case 'MONTH':
              timeEnd.setMonth(timeEnd.getMonth() + parseInt(freeTrial.duration));
              break;
            default:
              break;
          }
          timeEnd = timeEnd.getTime() / 1000;
        }
      }
      if (
        customerId &&
        paymentMethod &&
        paymentMethod.sourceId &&
        paymentMethod.sourceId.charAt(0) === 's' &&
        metadata &&
        metadata.stripeId
      ) {
        yield GraphOp(removePaymentMethod, {
          customerId,
          sourceId: paymentMethod.sourceId,
          stripeAccount: metadata.stripeId,
          envName
        });
      }
      metadata.timeEnd = timeEnd;
      metadata.fromSignUp = fromSignUp;
      metadata.organizationId = organizationId;
      const myDomain = `${window.location.origin}`;
      const response = yield GraphOp(stdGetStripeCheckoutSessionQuerie, {
        metadata: JSON.stringify(metadata),
        customerId,
        price: JSON.stringify(price),
        stripeAccount: metadata.stripeId,
        myDomain: myDomain,
        type: 'subscription',
        stripeConnect,
        registerAtTheEnd: false
      });
      if (
        response &&
        response.data &&
        response.data.getStripeCheckoutSession &&
        response.data.getStripeCheckoutSession !== '{}'
      ) {
        window.open(response.data.getStripeCheckoutSession, '_self');
      } else {
        yield all([
          put(
            notificationsActions.handleCatchError(
              'No response from the stdGetStripeCheckoutSessionQuerie',
              'createStripeCheckoutSessionSagas'
            )
          ),
          put(
            notificationsActions.setNotification({
              message: 'Error generating session. Please contact support.',
              severity: 'error'
            })
          )
        ]);
      }
    }
    if (setLoader) setLoader(false);
  } catch (err) {
    if (setLoader) setLoader(false);
    yield all([
      put(notificationsActions.handleCatchError(err, 'createStripeCheckoutSessionSagas')),
      put(
        notificationsActions.setNotification({
          message:
            err && err.message
              ? err.message
              : 'Error creating Stripe Customer. Please contact support.',
          severity: 'error'
        })
      )
    ]);
  }
}

function* getBillingPortalSagas(action) {
  const { customerId, stripeAccount, setLoader } = action.value;
  try {
    if (setLoader) setLoader(true);
    if (customerId && stripeAccount) {
      const myDomain = `${window.location.origin}/courseHome`;
      const sessionPortal = yield GraphOp(stdGetStripeBillingQuerie, {
        customerId: customerId,
        stripeAccount: stripeAccount,
        myDomain
      });
      if (sessionPortal && sessionPortal.data && sessionPortal.data.getStripeBilling) {
        window.open(sessionPortal.data.getStripeBilling, '_self');
      }
    }
    if (setLoader) setLoader(false);
  } catch (err) {
    if (setLoader) setLoader(false);
    yield put(notificationsActions.handleCatchError(err, 'getBillingPortalSagas'));
  }
}

function* getPaymentDataSagas(action) {
  const { customerId, setLoading } = action.value;
  try {
    if (setLoading) setLoading(true);
    const [paymentMethod, organizationData] = yield all([
      select(getPaymentReducer),
      select(getOrganizationDataReducer)
    ]);
    const stripeId =
      organizationData && organizationData.stripeId ? organizationData.stripeId : null;
    if (customerId && stripeId) {
      let response = yield GraphOp(stdRetrievePaymentInformationQuerie, {
        customerId,
        stripeAccount: stripeId,
        envName
      });
      if (
        response &&
        response.data &&
        response.data.retrievePaymentInformation !== 'customer not found'
      ) {
        response = JSON.parse(response.data.retrievePaymentInformation);
        if (response && response.default_source) {
          if (response.sources && response.sources.data && response.sources.data.length > 0) {
            const creditsCardInformation = response.sources.data;
            const currentCreditCard = creditsCardInformation[0] && creditsCardInformation[0].card;
            if (
              currentCreditCard &&
              currentCreditCard.brand &&
              currentCreditCard.exp_month &&
              currentCreditCard.exp_year &&
              currentCreditCard.last4
            ) {
              const cardObject = {};
              cardObject.type = currentCreditCard.brand;
              cardObject.expires = `${currentCreditCard.exp_month}/${currentCreditCard.exp_year}`;
              cardObject.lastDigits = currentCreditCard.last4;
              cardObject.sourceId = creditsCardInformation[0]?.id;
              yield put(myAccountActions.setPayment(cardObject));
            }
            let billingInfoData = {};
            if (response && response.address && response.name) {
              billingInfoData = {
                address: response.address,
                name: response.name
              };
            }
            if (billingInfoData) yield put(myAccountActions.setBillingInfo(billingInfoData));
          }
        } else {
          const sourceId = paymentMethod && paymentMethod.sourceId ? paymentMethod.sourceId : '';
          let cardObject = yield GraphOp(stdRetrieveStripePaymentMethodQuerie, {
            stripeAccount: stripeId,
            customerId: customerId,
            envName: envName,
            paymentMethod: sourceId
          });
          if (cardObject && cardObject.data && cardObject.data.retrieveStripePaymentMethod) {
            cardObject = JSON.parse(cardObject.data.retrieveStripePaymentMethod);
            if (cardObject) yield put(myAccountActions.setPayment(cardObject));
          }
        }
      }
    }
    if (setLoading) setLoading(false);
  } catch (err) {
    if (setLoading) setLoading(false);
    yield all([
      put(
        notificationsActions.setNotification({
          message: 'Unable to complete request. Please contact us for support.',
          severity: 'error'
        })
      ),
      put(notificationsActions.handleCatchError(err, 'getPaymentDataSagas'))
    ]);
  }
}

function* updatePaymentSagas(action) {
  try {
    yield put(startLoading());
    let { source, stripeId, billingInfo } = action.value;

    const [studentData, paymentMethod] = yield all([
      select(getStudentDataReducer),
      select(getPaymentReducer)
    ]);
    billingInfo.sourceId = paymentMethod?.sourceId;

    if (
      source &&
      source.id &&
      studentData &&
      studentData.organizationCustomers[0] &&
      studentData.organizationCustomers[0].customerID
    ) {
      let response = yield call(
        [API, 'graphql'],
        graphqlOperation(updateCustomer, {
          sourceId: source.id,
          customerId: studentData.organizationCustomers[0].customerID,
          stripeAccount: stripeId,
          billingInfo: JSON.stringify(billingInfo),
          envName: envName
        })
      );

      response = JSON.parse(response.data.updateCustomer);

      if (response && response.id) {
        //update payment successful
        let payload = {};
        payload.customerId = studentData.organizationCustomers[0].customerID;
        yield put(myAccountActions.getPayment(payload));
      } else {
        //card error

        yield put(
          notificationsActions.setNotification({
            message: getErrorDescription(response.decline_code),
            severity: 'error'
          })
        );
      }
    } else {
      yield put(
        notificationsActions.setNotification({
          message: 'Unable to complete request. Please contact us for support.',
          severity: 'error'
        })
      );
    }
    yield put(stopLoading());
    window.analytics.track('Edit card details');
  } catch (err) {
    yield put(stopLoading());
    yield put(
      notificationsActions.setNotification({
        message: 'Unable to complete request. Please contact us for support.',
        severity: 'error'
      })
    );
    yield put(notificationsActions.handleCatchError(err, 'updatePaymentSagas'));
  }
}

function* handleCoursePreviewSubscriptionActiveSaga(action) {
  try {
    const { organizationCustomers, studentData, organizationId } = action.payload;
    //We will cancel the coursePreview subscription if the user has paid for a paid subscription.

    let thisOrganizationCustomer;
    if (organizationCustomers && organizationCustomers.length > 0) {
      thisOrganizationCustomer = organizationCustomers.find(
        item => item && item.organizationID && item.organizationID === organizationId
      );
    }
    let updatedStudent;
    if (
      thisOrganizationCustomer &&
      thisOrganizationCustomer.subscriptions &&
      thisOrganizationCustomer.subscriptions.length > 0
    ) {
      updatedStudent = yield call(
        [API, 'graphql'],
        graphqlOperation(studentMutations.updateOrganizationCustomer, {
          organizationID: organizationId,
          id: studentData.id,
          organizationCustomers: organizationCustomers
        })
      );
      yield put(userActions.setStudentData(updatedStudent.data.updateStudent));
    }
  } catch (err) {
    yield put(notificationsActions.handleCatchError(err, 'handleCoursePreviewSubscriptionActive'));
  }
}

function* sendStudentFeedbackSagas(action) {
  const { organizationId, studentId, feedback, type, setLoading, closeModal, emailPayload } =
    action.value;
  try {
    // Get feedbacks
    if (studentId && organizationId) {
      if (setLoading) setLoading(true);
      const response = yield GraphOp(studentQueries.stdGetStudentFeedbacksQuerie, {
        organizationId,
        studentId
      });
      // Map feedbacks
      let feedbacksArray = [];
      if (
        response &&
        response.data &&
        response.data.getStudent &&
        response.data.getStudent.feedbacks &&
        response.data.getStudent.feedbacks.length > 0
      ) {
        response.data.getStudent.feedbacks.forEach(item => {
          if (item) feedbacksArray.push(item);
        });
      }
      // Add the new feedback
      feedbacksArray.push({
        feedback,
        type,
        status: 'ACTIVE',
        createdAt: new Date().toISOString()
      });
      // Update student with the new feedback in it
      yield GraphOp(studentMutations.createStudentFeedback, {
        studentId,
        organizationId,
        feedback: feedbacksArray
      });
      if (setLoading) setLoading(false);
    }
    if (closeModal) closeModal();
    if (emailPayload) {
      emailPayload.type = 'OTHERS';
      yield all([put(sendReportEmailToOrganization(emailPayload))]);
    }
  } catch (err) {
    if (setLoading) setLoading(false);
    yield put(
      notificationsActions.setNotification({
        message: 'Unable to complete request. Please contact us for support.',
        severity: 'error'
      })
    );
    yield put(notificationsActions.handleCatchError(err, 'sendStudentFeedbackSagas'));
  }
}

function* getPaymentDateSagas(action) {
  try {
    const [studentData, courseId, organizationData] = yield all([
      select(getStudentDataReducer),
      select(getCourseIdReducer),
      select(getOrganizationDataReducer)
    ]);
    let stripeSub;
    const { courseID, organizationId } = action.value;
    const orgId = organizationId ? organizationId : organizationData?.id;
    if (action && action.value && action.value.stripeId && action.value.subscriptionId) {
      const stripeAccount = action.value.stripeId;
      const subscriptionId = action.value.subscriptionId;
      const fromCreateStripePayment = action.value.fromCreateStripePayment;

      const obj = {
        subscriptionId: subscriptionId,
        stripeAccount: stripeAccount,
        envName: envName,
        fromWebhook: false
      };
      let response;
      try {
        response = yield GraphOp(stdRetriveStripeSubscriptionsQuerie, obj);
      } catch (e) {
        yield all([
          put(
            notificationsActions.setNotification({
              message: 'There was an error trying to obtain the subscription please try again.',
              severity: 'error'
            })
          ),
          put(
            notificationsActions.handleCatchError(
              e,
              'getPaymentDateSagas > RetriveStripeSubscriptions'
            )
          )
        ]);
      }
      stripeSub = response;
      if (
        response &&
        response.data &&
        ((response.data.retriveStripeSubscriptions !== 'subscription not found' &&
          response.data.retriveStripeSubscriptions !== 'not found') ||
          response.object === 'payment_intent')
      ) {
        response = JSON.parse(response.data.retriveStripeSubscriptions);
        let lastPayment = null;
        let nextPayment = null;
        if (response.current_period_start) {
          lastPayment = response.current_period_start;
        } else if (response.created) {
          lastPayment = response.created;
        }
        if (
          (response.status === 'active' || response.status === 'trialing') &&
          response.current_period_end
        ) {
          nextPayment = response.current_period_end;
        }
        yield put(myAccountActions.paymentDate({ lastPayment, nextPayment }));
        if (response.plan && response.plan.id) {
          yield put(myAccountActions.paymentPrice(response.plan.id));
        } else if (response.amount) {
          yield put(myAccountActions.paymentPrice(response.amount));
        }

        if (
          response.items &&
          response.items.data &&
          response.items.data.length > 0 &&
          response.items.data[0].plan
        ) {
          yield put(
            myAccountActions.paymentAmount({
              amount: response.items.data[0].plan.amount,
              interval: response.items.data[0].plan.interval
            })
          );
        } else {
          yield put(
            myAccountActions.paymentAmount({
              amount: response.amount,
              interval: null
            })
          );
        }

        if (response && response.object) {
          yield put(myAccountActions.paymentType(response.object));
        }

        // Add the subscription information to the student data
        if (studentData?.organizationCustomers?.length > 0) {
          let thisOrganizationCustomer = studentData.organizationCustomers.find(
            item => item.organizationID === orgId
          );

          if (thisOrganizationCustomer) {
            if (response.status === 'trialing') {
              thisOrganizationCustomer.isFreeTrial = true;
              thisOrganizationCustomer.endTrial = new Date(response.trial_end * 1000);
            }
            if (!fromCreateStripePayment) {
              yield put(userActions.setStudentData(studentData));
            }
          }
        }
      } else {
        yield put(myAccountActions.paymentDate(null));
        yield put(myAccountActions.paymentType(null));
        yield put(myAccountActions.paymentPrice(null));
      }
    } else {
      yield put(myAccountActions.paymentDate(null));
      yield put(myAccountActions.paymentType(null));
      yield put(myAccountActions.paymentPrice(null));
      let thisOrganizationCustomer = studentData.organizationCustomers.find(
        item => item.organizationID === orgId
      );
      //look for free access
      let courseselected = courseID ? courseID : courseId;
      let freeAccessSubscription = thisOrganizationCustomer?.subscriptions?.find(
        sub => sub.freeAccess && sub.courseID === courseselected && sub.active
      );
      if (freeAccessSubscription) {
        let dateSubscribed = new Date(freeAccessSubscription?.dateSubscribed);
        dateSubscribed.setMonth(dateSubscribed.getMonth() + 3);
        thisOrganizationCustomer.endFreeAccess = dateSubscribed;
        yield put(userActions.setStudentData(studentData));
      }
    }
    let payload = {};
    payload.stripeSubscription = stripeSub;
    yield put(myAccountActions.checkSubscription(payload));
  } catch (err) {
    yield put(
      notificationsActions.setNotification({
        message: 'Unable to complete request. Please contact us for support.',
        severity: 'error'
      })
    );
    yield put(notificationsActions.handleCatchError(err, 'getPaymentDateSagas'));
  }
}

function* cancelSubscriptionSagas(action) {
  const { stripeAccount, enableAutoRenewal, handleModal, handleLoader } = action.payload;
  if (handleLoader) handleLoader(true);
  try {
    // we call this function in two occasions, when we want to cancel the subscription (cancel the auto-renewal)
    // and when we want to cancel the cancellation (re-enable the auto-renewal).
    const [studentData, organizationData, courseId] = yield all([
      select(getStudentDataReducer),
      select(getOrganizationDataReducer),
      select(getCourseIdReducer)
    ]);
    const orgId = organizationData && organizationData.id ? organizationData.id : null;
    const studentId = studentData && studentData.id ? studentData.id : null;
    if (
      orgId &&
      studentId &&
      studentData &&
      studentData.organizationCustomers &&
      studentData.organizationCustomers.length > 0
    ) {
      // We are looking for the customer of this organization
      const thisOrgCustomer = studentData.organizationCustomers.find(
        item => item && item.organizationID === orgId
      );
      if (
        thisOrgCustomer &&
        thisOrgCustomer.subscriptions &&
        thisOrgCustomer.subscriptions.length > 0 &&
        courseId
      ) {
        // We are looking for an active subscription to this course.
        const currentCustomer = thisOrgCustomer.subscriptions.find(
          sub => sub && sub.active && sub.courseID === courseId
        );
        if (currentCustomer && currentCustomer.subscriptionID) {
          let response = yield GraphOp(cancelStripeSubscription, {
            subscriptionId: currentCustomer.subscriptionID,
            stripeAccount,
            autoRenewal: enableAutoRenewal
          });
          if (response && response.data && response.data.cancelStripeSubscription) {
            response = JSON.parse(response.data.cancelStripeSubscription);
            // If the cancellation was successful i need to update it on backend
            currentCustomer.autoRenewal = enableAutoRenewal;
            delete thisOrgCustomer.isFreeTrial;
            delete thisOrgCustomer.endTrial;
            const cleanedCustomers = [];
            // Update subscription
            studentData.organizationCustomers.forEach(item => {
              if (item) {
                if (
                  item.organizationID === orgId &&
                  item.subscriptions &&
                  item.subscriptions.length > 0
                ) {
                  const auxSubs = [];
                  item.subscriptions.forEach(sub => {
                    if (sub && sub.subscriptionID) {
                      if (sub.subscriptionID === currentCustomer.subscriptionID) {
                        auxSubs.push(currentCustomer);
                      } else auxSubs.push(sub);
                    }
                  });
                  item.subscriptions = auxSubs;
                  cleanedCustomers.push(item);
                } else {
                  cleanedCustomers.push(item);
                }
              }
            });
            if (cleanedCustomers && cleanedCustomers.length > 0 && studentId) {
              yield GraphOp(studentMutations.updateOrganizationCustomer, {
                organizationID: orgId,
                id: studentId,
                organizationCustomers: cleanedCustomers
              });
            }
            const payload = {};
            if (studentId && orgId) {
              payload.studentId = studentId;
              payload.organizationId = orgId;
              yield put(userActions.getStudentData(payload));
            }
            // Reload course data
            payload.courseId = courseId;
            payload.fromMyAccount = true;
            const msg = enableAutoRenewal
              ? 'Your cancellation was stopped!'
              : 'Your subscription has been successfully cancelled!';
            yield all([
              put(getCourseData(payload)),
              put(
                notificationsActions.setNotification({
                  message: msg,
                  severity: 'success'
                })
              )
            ]);
          }
          window.analytics.track('Cancel Subscription');
        }
      }
    }
    if (handleLoader) handleLoader(false);
    if (handleModal) handleModal(false);
  } catch (err) {
    if (handleLoader) handleLoader(false);
    yield all([
      put(
        notificationsActions.setNotification({
          message: 'Unable to complete request. Please contact us for support.',
          severity: 'error'
        })
      ),
      put(notificationsActions.handleCatchError(err, 'cancelSubscriptionSagas'))
    ]);
  }
}

function* removePaymentMethodSagas() {
  try {
    const [studentData, organizationData, paymentMethod] = yield all([
      select(getStudentDataReducer),
      select(getOrganizationDataReducer),
      select(getPaymentReducer),
      put(startLoading())
    ]);
    const orgId = organizationData && organizationData.id ? organizationData.id : null;
    const stripeAccount =
      organizationData && organizationData.stripeId ? organizationData.stripeId : null;
    const sourceId = paymentMethod && paymentMethod.sourceId ? paymentMethod.sourceId : null;
    if (
      orgId &&
      stripeAccount &&
      sourceId &&
      studentData &&
      studentData.organizationCustomers &&
      studentData.organizationCustomers.length > 0
    ) {
      const currentOrganizationCustomer = studentData.organizationCustomers.find(
        item => item && item.organizationID === orgId
      );
      const customerId =
        currentOrganizationCustomer && currentOrganizationCustomer.customerID
          ? currentOrganizationCustomer.customerID
          : null;
      if (customerId) {
        let response = yield GraphOp(removePaymentMethod, {
          sourceId,
          customerId,
          stripeAccount
        });
        if (response && response.data && response.data.removePaymentMethod) {
          response = JSON.parse(response.data.removePaymentMethod);
          // Remove payment successfull
          if (response) {
            yield;
            yield all([
              put(
                notificationsActions.setNotification({
                  message: 'The payment method has been successfully removed!',
                  severity: 'success'
                })
              ),
              put(getCourseData({ fromMyAccount: true }))
            ]);
          }
        } else {
          yield put(
            notificationsActions.setNotification({
              message: 'Unable to complete request. Please contact us for support.',
              severity: 'error'
            })
          );
        }
      }
    }
    yield put(stopLoading());
  } catch (err) {
    yield put(stopLoading());
    yield put(
      notificationsActions.setNotification({
        message: 'Unable to complete request. Please contact us for support.',
        severity: 'error'
      })
    );
    yield put(notificationsActions.handleCatchError(err, 'removePaymentMethodSagas'));
  }
}

function* checkSubscriptionSagas(action) {
  try {
    let stripeSubscription;
    if (action && action.payload && action.payload.stripeSubscription) {
      stripeSubscription = action.payload.stripeSubscription;
    }
    const [studentData, courseId, organization] = yield all([
      select(getStudentDataReducer),
      select(getCourseIdReducer),
      select(getOrganizationDataReducer)
    ]);
    const stripeAccount = organization && organization.stripeId ? organization.stripeId : null;
    const orgId = organization && organization.id ? organization.id : null;
    if (studentData && courseId && organization && orgId) {
      let expiredSubscriptionType;
      let thisOrganizationCustomer = null;
      // Get the last sub from the student
      if (
        studentData &&
        studentData.organizationCustomers &&
        studentData.organizationCustomers.length > 0
      ) {
        thisOrganizationCustomer = studentData.organizationCustomers.find(
          item => item && item.organizationID === orgId
        );
      }
      if (
        thisOrganizationCustomer &&
        thisOrganizationCustomer.subscriptions &&
        thisOrganizationCustomer.subscriptions.length > 0
      ) {
        // Sort subs according date to the newest
        // First i remove the null dateSubscribed
        thisOrganizationCustomer.subscriptions = thisOrganizationCustomer.subscriptions.filter(
          sub => sub.dateSubscribed
        );
        if (thisOrganizationCustomer && thisOrganizationCustomer.length > 0) {
          thisOrganizationCustomer.subscriptions = thisOrganizationCustomer.subscriptions.sort(
            function (a, b) {
              return new Date(b.dateSubscribed) - new Date(a.dateSubscribed);
            }
          );
        }
        // LastSub is the last subscription from the student
        // It can be a stripe subscription (the user has paid) or free access (the user did not pay)
        let lastSub = null;
        if (
          thisOrganizationCustomer &&
          thisOrganizationCustomer.subscriptions &&
          thisOrganizationCustomer.subscriptions.length > 0
        ) {
          lastSub = thisOrganizationCustomer.subscriptions.find(
            sub => sub && sub.courseID === courseId
          );
        }
        let activeInDynamo = lastSub && lastSub.active ? true : false;
        let activeInStripe = true;
        // Review Stripe subscription
        if (lastSub && lastSub.subscriptionID && stripeAccount) {
          if (!stripeSubscription) {
            try {
              stripeSubscription = yield GraphOp(stdRetriveStripeSubscriptionsQuerie, {
                subscriptionId: lastSub.subscriptionID,
                stripeAccount
              });
            } catch (e) {
              yield all([
                put(
                  notificationsActions.setNotification({
                    message:
                      'There was an error trying to obtain the subscription please try again.',
                    severity: 'error'
                  })
                ),
                put(
                  notificationsActions.handleCatchError(
                    e,
                    'checkSubscriptionSagas > RetriveStripeSubscriptions'
                  )
                )
              ]);
            }
          }
          // We have to check if the sub is active on stripe, otherwise we need to update it
          if (
            stripeSubscription &&
            stripeSubscription.data &&
            stripeSubscription.data.retriveStripeSubscriptions &&
            stripeSubscription.data.retriveStripeSubscriptions !== 'subscription not found' &&
            stripeSubscription.data.retriveStripeSubscriptions !== 'not found'
          ) {
            stripeSubscription = JSON.parse(stripeSubscription.data.retriveStripeSubscriptions);
            if (
              stripeSubscription &&
              stripeSubscription.status &&
              stripeSubscription.status !== 'active' &&
              stripeSubscription.status !== 'trialing' &&
              stripeSubscription.status !== 'succeeded'
            ) {
              // If the subscription is cancelled, we have to check if the date has passed.
              if (
                stripeSubscription.status === 'canceled' &&
                stripeSubscription.current_period_end
              ) {
                const endSubscription = stripeSubscription.current_period_end * 1000;
                const today = new Date();
                const currentTimeStamp = today.getTime();
                if (currentTimeStamp > endSubscription) {
                  // Here it means the sub is no longer active so we need to update our record on database
                  activeInStripe = false;
                  expiredSubscriptionType = 'stripeSubsExpired';
                }
              } else {
                // Here it means the sub is no longer active so we need to update our record on database
                activeInStripe = false;
                expiredSubscriptionType = 'stripeSubsExpired';
              }
            }
          } else {
            activeInStripe = false;
            expiredSubscriptionType = 'stripeSubsExpired';
          }
        } else {
          activeInStripe = false;
          expiredSubscriptionType = 'stripeSubsExpired';
        }
        // Update courseData
        const courseData = yield select(getCourseDataReducer);
        if (courseData) {
          courseData.activeSubscription = activeInStripe;
          courseData.expiredSubscriptionType = expiredSubscriptionType;
          yield put(setCourseData(courseData));
        }
        if (activeInDynamo !== activeInStripe) {
          if (
            orgId &&
            lastSub &&
            lastSub.subscriptionID &&
            studentData &&
            studentData.id &&
            studentData.organizationCustomers &&
            studentData.organizationCustomers.length > 0
          ) {
            const updatedCustomersArray = [];
            studentData.organizationCustomers.forEach(item => {
              if (
                item &&
                item.organizationID &&
                item.organizationID === orgId &&
                item.subscriptions &&
                item.subscriptions.length > 0
              ) {
                const subscriptionsAux = [];
                item.subscriptions.forEach(s => {
                  if (s && s.subscriptionID === lastSub.subscriptionID) {
                    s.active = activeInStripe;
                    subscriptionsAux.push(s);
                  } else subscriptionsAux.push(s);
                });
                item.subscriptions = subscriptionsAux;
                updatedCustomersArray.push(item);
              } else updatedCustomersArray.push(item);
            });
            const updatedStudent = yield GraphOp(studentMutations.updateOrganizationCustomer, {
              organizationID: orgId,
              id: studentData.id,
              organizationCustomers: updatedCustomersArray
            });
            if (updatedStudent && updatedStudent.data && updatedStudent.data.updateStudent) {
              yield put(userActions.setStudentData(updatedStudent.data.updateStudent));
            }
          }
        }
      } else {
        expiredSubscriptionType = 'stripeSubsExpired';
      }
    }
  } catch (err) {
    yield put(notificationsActions.handleCatchError(err, 'checkSubscriptionSagas'));
  }
}

function* handleStudentSubscriptionSagas(action) {
  const {
    // Common to both calls
    metadata,
    poolData,
    mainUserPoolId,
    handleFinish,
    // createAccount exclusives
    setLoaderButton,
    setStudentId,
    needsCreation,
    autoRenewal
  } = action.payload;
  let customErrorMsg = null;
  try {
    yield put(setPercentageLoadingScreen(0));
    const sucessMsg = needsCreation
      ? 'Account successfully created!'
      : 'Purchase successfully completed!';
    // Constants
    const organizationId = metadata && metadata.organizationId ? metadata.organizationId : null;
    const courseId = metadata && metadata.courseId ? metadata.courseId : null;
    const stripeAccount = metadata && metadata.stripeId ? metadata.stripeId : null;
    let studentId =
      metadata && metadata.studentData && metadata.studentData.id ? metadata.studentData.id : null;
    let studentActiveCourses = [];
    if (studentId && organizationId) {
      const response = yield GraphOp(studentQueries.stdGetStudentActiveCoursesQuerie, {
        studentId,
        organizationId
      });
      studentActiveCourses =
        response &&
        response.data &&
        response.data.getStudent &&
        response.data.getStudent.activeCoursesByPeriod &&
        response.data.getStudent.activeCoursesByPeriod.length > 0
          ? response.data.getStudent.activeCoursesByPeriod
          : [];
    }
    metadata.studentActiveCourses = studentActiveCourses;
    // From create account
    if (needsCreation) {
      metadata.autoRenewal = autoRenewal;
    } else {
      // From purchase or renew subscription
      metadata.autoRenewal = true;
      const autoRenewalLocalStorage = localStorage.getItem('autoRenewal');
      localStorage.removeItem('autoRenewal');
      if (autoRenewalLocalStorage && autoRenewalLocalStorage === 'false') {
        metadata.autoRenewal = false;
      }
    }
    const cancelAtTheEnd = !metadata.autoRenewal;
    if (organizationId) {
      let response = yield GraphOp(stdCreateStudentAccountAndSubscriptionQuerie, {
        metadata: JSON.stringify(metadata),
        poolData: JSON.stringify(poolData),
        mainUserPoolId
      });
      if (response && response.data && response.data.createStudentAccountAndSubscription) {
        yield put(setPercentageLoadingScreen(25));
        response = JSON.parse(response.data.createStudentAccountAndSubscription);
        if (
          response &&
          response.userId &&
          response.$metadata &&
          response.$metadata.httpStatusCode === 200
        ) {
          studentId = response.userId;
          const studentResponse = yield GraphOp(studentQueries.stdGetStudentDataQuerie, {
            studentId,
            organizationId
          });
          const studentData =
            studentResponse && studentResponse.data && studentResponse.data.getStudent
              ? studentResponse.data.getStudent
              : null;
          if (!studentData) throw new Error('Error obtaining student');
          else {
            // If we have to cancel at the end we should get this last subscription and set it up accordingly in Stripe
            if (
              cancelAtTheEnd &&
              studentData.organizationCustomers &&
              studentData.organizationCustomers.length > 0
            ) {
              const thisOrgCustomer = studentData.organizationCustomers.find(
                item => item && item.organizationID === organizationId
              );
              if (
                thisOrgCustomer &&
                thisOrgCustomer.subscriptions &&
                thisOrgCustomer.subscriptions.length > 0 &&
                courseId
              ) {
                const currentCustomer = thisOrgCustomer.subscriptions.find(
                  sub => sub && sub.active && sub.courseID === courseId
                );
                // With this data we cancel the subscription at the end of the period we paid for.
                if (currentCustomer && currentCustomer.subscriptionID && stripeAccount) {
                  yield GraphOp(cancelStripeSubscription, {
                    subscriptionId: currentCustomer.subscriptionID,
                    stripeAccount,
                    autoRenewal: false
                  });
                }
              }
            }
            studentId = studentData && studentData.id ? studentData.id : '';
            if (setStudentId) setStudentId(studentId);
            yield all([
              put(userActions.setStudentData(studentData)),
              put(getOrganizationData({ organizationId })),
              put(notificationsActions.setNotification({ message: sucessMsg, severity: 'success' }))
            ]);
            if (handleFinish) handleFinish();
          }
        } else {
          throw new Error('Error creating student');
        }
      } else {
        customErrorMsg = needsCreation
          ? 'Error creating student. Please contact support'
          : 'Error ending registration. Please contact support';
        throw new Error(customErrorMsg);
      }
    } else {
      customErrorMsg = 'Missing organization id';
      throw new Error(customErrorMsg);
    }
  } catch (err) {
    if (setLoaderButton) setLoaderButton(false);
    yield all([
      put(
        notificationsActions.setNotification({
          message: customErrorMsg
            ? customErrorMsg
            : 'An error has occurred. Please contact support',
          severity: 'error'
        })
      ),
      put(notificationsActions.handleCatchError(err, 'handleStudentSubscriptionSagas')),
      put(setLoadingScreen(false)),
      put(setPercentageLoadingScreen(0))
    ]);
  }
}
// Watchers
function* createStripeCustomerWatcher() {
  yield takeLatest(
    myAccountActionTypes.CREATE_STRIPE_CUSTOMER_AND_PAYMENT,
    createStripeCustomerAndPaymentSagas
  );
}
function* createStripeCheckoutSessionWatcher() {
  yield takeLatest(courseActions.CREATE_CHECKOUT_SESSION, createStripeCheckoutSessionSagas);
}
function* getStripeCourseProductWatcher() {
  yield takeLatest(courseActions.GET_STRIPE_COURSE_PRODUCT, getStripeCourseProductSagas);
}
function* getBillingPortalWatcher() {
  yield takeLatest(myAccountActionTypes.GET_BILLING_PORTAL, getBillingPortalSagas);
}
function* getPaymentDataWatcher() {
  yield takeLatest(myAccountActionTypes.GET_PAYMENT, getPaymentDataSagas);
}
function* updatePaymentWatcher() {
  yield takeLatest(myAccountActionTypes.UPDATE_PAYMENT, updatePaymentSagas);
}
function* sendStudentFeedbackWatcher() {
  yield takeLatest(myAccountActionTypes.SEND_STUDENT_FEEDBACK, sendStudentFeedbackSagas);
}
function* getPaymentDateWatcher() {
  yield takeLatest(myAccountActionTypes.GET_PAYMENT_DATE, getPaymentDateSagas);
}
function* cancelSubscriptionWatcher() {
  yield takeLatest(myAccountActionTypes.CANCEL_SUBSCRIPTION, cancelSubscriptionSagas);
}

function* removePaymentMethodWatcher() {
  yield takeLatest(myAccountActionTypes.REMOVE_PAYMENT_METHOD, removePaymentMethodSagas);
}

function* checkSubscriptionWatcher() {
  yield takeLatest(myAccountActionTypes.CHECK_SUBSCRIPTION, checkSubscriptionSagas);
}

function* handleCoursePreviewSubscriptionActiveWatcher() {
  yield takeLatest(
    myAccountActionTypes.HANDLE_COURSE_PREVIEW_SUBSCRIPTION_ACTIVE,
    handleCoursePreviewSubscriptionActiveSaga
  );
}

function* handleStudentSubscriptionWatcher() {
  yield takeLatest(
    myAccountActionTypes.HANDLE_STUDENT_SUBSCRIPTION,
    handleStudentSubscriptionSagas
  );
}

export default function* sagas() {
  yield all([
    getPaymentDataWatcher(),
    getBillingPortalWatcher(),
    sendStudentFeedbackWatcher(),
    updatePaymentWatcher(),
    getStripeCourseProductWatcher(),
    createStripeCustomerWatcher(),
    getPaymentDateWatcher(),
    cancelSubscriptionWatcher(),
    removePaymentMethodWatcher(),
    checkSubscriptionWatcher(),
    createStripeCheckoutSessionWatcher(),
    handleCoursePreviewSubscriptionActiveWatcher(),
    handleStudentSubscriptionWatcher()
  ]);
}
