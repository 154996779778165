import * as type from './actionTypes/myAccountActionTypes';

export const setOpenSubscription = value => ({
  type: type.SET_OPEN_SUBSCRIPTION,
  value
});

export const getBillingPortal = value => ({
  type: type.GET_BILLING_PORTAL,
  value
});

export const setPayment = value => ({
  type: type.SET_PAYMENT,
  value
});

export const getPayment = value => ({
  type: type.GET_PAYMENT,
  value
});

export const updatePayment = value => ({
  type: type.UPDATE_PAYMENT,
  value
});

export const cancelSubscription = payload => ({
  type: type.CANCEL_SUBSCRIPTION,
  payload
});

export const sendStudentFeedback = value => ({
  type: type.SEND_STUDENT_FEEDBACK,
  value
});

export const createStripeCustomerAndPayment = payload => ({
  type: type.CREATE_STRIPE_CUSTOMER_AND_PAYMENT,
  payload
});

export const paymentDate = value => ({
  type: type.PAYMENT_DATE,
  value
});

export const getPaymentDate = value => ({
  type: type.GET_PAYMENT_DATE,
  value
});

export const paymentPrice = value => ({
  type: type.PAYMENT_PRICE,
  value
});

export const paymentAmount = value => ({
  type: type.PAYMENT_AMOUNT,
  value
});

export const paymentType = value => ({
  type: type.PAYMENT_TYPE,
  value
});

export const removePaymentMethod = value => ({
  type: type.REMOVE_PAYMENT_METHOD,
  value
});

export const setBillingInfo = value => ({
  type: type.SET_BILLING_INFO,
  value
});

export const checkSubscription = payload => ({
  type: type.CHECK_SUBSCRIPTION,
  payload
});

export const handleCoursePreviewSubscriptionActive = payload => ({
  //This will handle the scenario were the user purchases a paid sub with an active CP sub
  type: type.HANDLE_COURSE_PREVIEW_SUBSCRIPTION_ACTIVE,
  payload
});

export const handleStudentSubscription = payload => ({
  type: type.HANDLE_STUDENT_SUBSCRIPTION,
  payload
});
