import handleCatchError from './handleCatchError';
import { SecretsManagerClient, GetSecretValueCommand } from '@aws-sdk/client-secrets-manager';
import getAWSConfigObject from './getAWSConfigObject';

export default async function getStripeOrganizationId() {
  try {
    const everprepOrganizations = [];
    const client = new SecretsManagerClient(getAWSConfigObject());
    const secret = await client.send(new GetSecretValueCommand({ SecretId: 'EP_ORGANIZATION_ID' }));
    if (secret && secret.SecretString) {
      everprepOrganizations.push(JSON.parse(secret.SecretString).EVERPREP_PROD);
      everprepOrganizations.push(JSON.parse(secret.SecretString).EVERPREP_STAGE);
      everprepOrganizations.push(JSON.parse(secret.SecretString).SERFE_DEVELOPERS);
    }
    return everprepOrganizations;
  } catch (err) {
    handleCatchError(err, 'verifyStripeConnect');
  }
}
