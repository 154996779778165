import {
  CloudWatchLogsClient,
  CreateLogStreamCommand,
  PutLogEventsCommand
} from '@aws-sdk/client-cloudwatch-logs';
import awsExports from '../aws-exports';
import getAWSConfigObject from './getAWSConfigObject';

const cloudwatchClient = new CloudWatchLogsClient(getAWSConfigObject());

const showConsoleLog = process.env.REACT_APP_IN_DEVELOPMENT === 'true' ? true : false;

const DateFormat = () => {
  const date = new Date();
  const dateFormat = new Intl.DateTimeFormat('en').format(date);

  const hs = new Intl.DateTimeFormat('en', { hour: '2-digit' }).format(date);
  const min = new Intl.DateTimeFormat('en', { minute: '2-digit' }).format(date);
  const sec = new Intl.DateTimeFormat('en', { second: '2-digit' }).format(date);

  return dateFormat + ' > ' + hs + ' ' + min + 'min ' + sec + 'secs';
};

const handleCatchError = async (catchedError, nameOfFunction) => {
  const time = DateFormat();

  const userObject = JSON.parse(localStorage.getItem('ajs_user_traits'));
  const user = userObject ? userObject.email : 'No User Found';
  const UserPoolId = JSON.parse(localStorage.getItem('UserPoolId'));
  let logStreamName = `${time} > error in ${nameOfFunction} > user > ${user} > pool > ${UserPoolId}`;

  const REGEX = /.*-(\w+)/;
  const env = awsExports.aws_content_delivery_bucket.match(REGEX)[1];

  let logGroupName = `EverprepStudentsError-${env}`;

  const streamParams = {
    logGroupName: logGroupName,
    logStreamName: logStreamName
  };

  const logdate = new Date().getTime();

  if (catchedError !== 'mainLayout (currentAuthenticatedUser) -- No token found') {
    let caller_line = {};
    let index = {};
    let metadata = 'No metadata available';

    if (catchedError.stack) {
      caller_line = catchedError.stack.split('\n')[4];
      if (caller_line) {
        index = caller_line.indexOf('at ');
        metadata = caller_line.slice(index + 2, caller_line.length); //Some extra information for the error
      }
    }

    const eventParams = {
      logEvents: [
        {
          message: catchedError?.message + ' Metadata : ' + metadata,
          timestamp: logdate
        }
      ],
      logGroupName: logGroupName,
      logStreamName: logStreamName
    };

    cloudwatchClient.send(new CreateLogStreamCommand(streamParams), err => {
      if (err) {
        //eslint-disable-next-line no-console
        console.log('Error tracking event >', err);
      } else {
        cloudwatchClient.send(new PutLogEventsCommand(eventParams), eventError => {
          if (eventError) {
            //eslint-disable-next-line no-console
            console.log('Error tracking event >', eventError);
          }
        });
      }
    });
  }

  if (
    showConsoleLog &&
    catchedError !== 'mainLayout (currentAuthenticatedUser) -- No token found'
  ) {
    //eslint-disable-next-line no-console
    console.log(catchedError);
    if (nameOfFunction) {
      //eslint-disable-next-line no-console
      console.log('error in ' + nameOfFunction);
    }
  }
  return null;
};

export default handleCatchError;
