import { envName } from '../components/Common/const';

const projectVersioned = () => {
  // here we set the version from the package.json file
  const version = require('../../package.json').version; // eslint-disable-line global-require
  // here we set the environment we are running, if development, stage or production
  const environment = envName;
  if (environment) {
    switch (environment) {
      case 'serfe':
        return `${version} - Development`;
      case 'stage':
        return `${version} - Stage`;
      default:
        return `${version}`;
    }
  } else return '';
};

export default projectVersioned;
