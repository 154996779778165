import React from 'react';
import { Grid, CircularProgress } from '@mui/material';

import { useStyles } from '../../assets/jss/components/LoadingScreen/loadingScreenStyles';

export default function LoadingScreen() {
  const classes = useStyles();

  return (
    <Grid className={classes.root}>
      <CircularProgress className={classes.circular} />
    </Grid>
  );
}
