import { getStateWithOnlyOneLevel } from '../../utils/StateSelectorHelper';

const getState = state => getStateWithOnlyOneLevel(state, 'Course');

export const getCourseDataReducer = state => getState(state).get('courseData');
export const getCourseNameReducer = state => getState(state).get('courseName');
export const getSectionsReducer = state => getState(state).get('sections');
export const getTopicsContentReducer = state => getState(state).get('topicsContent');
export const getLoadingTopicsContentReducer = state => getState(state).get('loadingTopicsContent');
export const getTopicDataReducer = state => getState(state).get('topicData');
export const getLoadingTopicDataReducer = state => getState(state).get('loadingTopicData');
export const getStudyPerformanceReducer = state => getState(state).get('studyPerformance');
export const getLoginStreakReducer = state => getState(state).get('loginStreak');
export const getQuestionsAnsweredReducer = state => getState(state).get('questionsAnswered');
export const getTimeStudiedReducer = state => getState(state).get('timeStudied');
export const getAvailableCoursesReducer = state => getState(state).get('availableCourses');
export const getLoadingCoursesReducer = state => getState(state).get('loadingAvailableCourses');
export const getErrorCoursesReducer = state => getState(state).get('errorAvailableCourses');
export const getStripeProductSelector = state => getState(state).get('stripeProduct');
export const getSelectedTab = state => getState(state).get('selectedTab');
export const getCourseFreeTrialSelector = state => getState(state).get('freeTrial');
export const getPercentageLoadingScreen = state => getState(state).get('percentageLoadingScreen');
export const getLoadingScreen = state => getState(state).get('loadingScreen');
export const getLoadingStudyModes = state => getState(state).get('loadingStudyModes');
export const getCourseQuestionsSelector = state => getState(state).get('courseQuestions');
export const getCourseContentsSelector = state => getState(state).get('courseContents');
export const getGenerateStripePaymentErrorSelector = state => getState(state).get('generateStripePaymentError');
export const getCoursesAvailableForPurchaseSelector = state => getState(state).get('coursesAvailableForPurchase');