export const stdGetOpenExamDataQuerie = /* GraphQL */ `
  query stdGetOpenExamDataQuerie($courseID: ID!) {
    listExams(filter: { courseID: { eq: $courseID }, status: { eq: ACTIVE } }, limit: 1500) {
      items {
        id
        time
        title
        totalQuestions
        status
        timeLimit
        maxAttempts
        createdAt
        questionsIDs
      }
    }
  }
`;

export const stdGetExamHistoryQuerie = /* GraphQL */ `
  query stdGetExamHistoryQuerie($studentID: ID!, $organizationID: ID!) {
    getStudent(id: $studentID, organizationID: $organizationID) {
      examHistory {
        date
        examID
        courseID
        score
        time
        title
        accuracy
        totalQuestions
        studentAnswers {
          examID
          questionID
          studentID
          orderTypeAnswer {
            title
            order
            correctOption
          }
          matchingAnswer {
            title
            order
            match
            correctOrder
          }
          freeTypeAnswer {
            explanation
            answer
          }
          flashcardAnswer {
            answer
          }
          fillInTheBlankAnswer {
            explanation
            answer
          }
          multipleChoiceTextAnswer {
            order
            locked
            correct
            choice
          }
          multipleChoiceImageAnswer {
            order
            correct
            choiceId
            link
          }
        }
      }
    }
  }
`;

export const stdGetExamDataQuerie = /* GraphQL */ `
  query stdGetExamDataQuerie($courseID: ID!, $examID: ID!) {
    getExam(courseID: $courseID, id: $examID) {
      id
      title
      totalQuestions
      status
      questionsIDs
      attemptType
      maxAttempts
      time
      timeLimit
      totalQuestions
      revisable
      questionSource
      examTopics {
        id
        percentage
        questions
      }
      repeatedQuestions
    }
  }
`;

export const stdGetExamProgressQuerie = /* GraphQL */ `
  query stdGetExamProgressQuerie($studentID: ID!, $organizationID: ID!) {
    getStudent(id: $studentID, organizationID: $organizationID) {
      examProgress {
        available
        examID
        examQuestionsIDs
        timeElapsed
        started
        remainingAttempts
        lastQuestionAnswered
        studentAnswers {
          studentID
          questionID
          orderTypeAnswer {
            order
            title
            correctOption
          }
          multipleChoiceTextAnswer {
            choice
            correct
            locked
            order
          }
          multipleChoiceImageAnswer {
            choiceId
            correct
            order
            link
          }
          matchingAnswer {
            match
            order
            title
            correctOrder
          }
          freeTypeAnswer {
            answer
            explanation
          }
          flashcardAnswer {
            answer
          }
          fillInTheBlankAnswer {
            explanation
            answer
          }
          examID
        }
      }
    }
  }
`;

export const stdGetExamFeedbacksQuerie = /* GraphQL */ `
  query stdGetExamFeedbacksQuerie($courseId: ID!, $examId: ID!) {
    getExam(courseID: $courseId, id: $examId) {
      feedbacks {
        feedbackId
        feedback
        createdAt
        status
        type
        updatedAt
        reporterUserId
        receptorUserId
        reporterUserName
        reporterUserEmail
        page
      }
    }
  }
`;

export const stdGetQuestionSetNameQuerie = /* GraphQL */ `
  query stdGetQuestionSetNameQuerie($courseID: ID!, $contentId: ID!) {
    getTopic(courseID: $courseID, id: $contentId) {
      id
      name
    }
  }
`;
