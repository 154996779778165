import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import localforage from 'localforage';
import rootSaga from '../sagas';
import rootReducer from '../reducers';
import { createReduxHistoryContext } from 'redux-first-history';
import immutableTransform from '../utils/redux-persist-transform-immutable';

// Previously only the persist was configured for user only, I modify to keep all the variables
const persistConfig = {
  transforms: [immutableTransform()],
  key: 'root',
  storage: localforage,
  whitelist: ['User', 'Organization', 'StudySession', 'Course', 'Exam', 'App', 'Content'],
  blacklist: ['LoaderHandler']
};

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory()
});
const persistedReducer = persistReducer(persistConfig, rootReducer(routerReducer));

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(routerMiddleware, sagaMiddleware))
);

export const history = createReduxHistory(store);
sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
