import { Map } from 'immutable';
import * as t from '../actions/actionTypes/examActionTypes';
import * as loginActions from '../actions/actionTypes/loginActionTypes';

const initialState = Map({
  examData: null,
  openExams: [],
  historyExams: [],
  latestExamPerformance: null,
  stackedQuestions: [],
  actualQuestion: null,
  time: null,
  timeElapsed: null,
  examProgress: [],
  stackedAnswers: [],
  currentHistoryExam: null,
  loadingOpenExam: false,
  loadingHistoryExams: false,
  finished: false,
  started: null,
  examResults: null,
  questionsAndAnswers: [],
  preloadedQuestions: [],
  previousQuestions: [],
  preloadedExamFirstQuestions: [],
  certificate: null
});

const Exam = function (state = initialState, action) {
  switch (action.type) {
    case t.SET_CERTIFICATE:
      return state.set('certificate', action.value);
    case t.SET_OPEN_EXAMS:
      return state.set('openExams', action.value);
    case t.SET_HISTORY_EXAMS:
      return state.set('historyExams', action.value);
    case t.SET_CURRENT_EXAM_HISTORY:
      return state.set('currentHistoryExam', action.value);
    case t.SET_LATEST_EXAM_PERFORMANCE:
      return state.set('latestExamPerformance', action.value);
    case t.SET_EXAM_DATA:
      return state.set('examData', action.value);
    case t.SET_STACKED_ANSWERS:
      return state.set('stackedAnswers', action.value);
    case t.SET_STACKED_QUESTIONS:
      return state.set('stackedQuestions', action.value);
    case t.SET_ACTUAL_QUESTION:
      return state.set('actualQuestion', action.value);
    case t.ACTUAL_QUESTION:
      return state.set('actualQuestion', action.value);
    case t.SET_EXAM_PROGRESS:
      return state.set('examProgress', action.value);
    case t.EXAM_PROGRESS:
      return state.set('examProgress', action.value);
    case t.LOADING_OPEN_EXAM:
      return state.set('loadingOpenExam', action.value);
    case t.LOADING_HISTORY_EXAM:
      return state.set('loadingHistoryExams', action.value);
    case t.FINISHED:
      return state.set('finished', action.value);
    case t.STARTED:
      return state.set('started', action.value);
    case t.EXAM_RESULTS:
      return state.set('examResults', action.value);
    case t.PRELOADED_QUESTIONS:
      return state.set('preloadedQuestions', action.value);
    case t.PREVIOUS_QUESTIONS:
      return state.set('previousQuestions', action.value);
    case t.QUESTIONS_AND_ANSWERS:
      return state.set('questionsAndAnswers', action.value);
    case t.PRELOADED_EXAM_FIRST_QUESTIONS:
      return state.set('preloadedExamFirstQuestions', action.value);
    case loginActions.SIGN_OUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default Exam;
