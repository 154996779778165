import axios from 'axios';
import handleCatchError from './handleCatchError';
const url = 'https://ipapi.co/json/';

const apiCall = async function () {
  const response = await axios
    .get(url)
    .then(response => {
      if (response && response.data && response.data.ip) return response.data.ip;
    })
    .catch(err => handleCatchError(err, 'axiosApiCallGetIp'));
  return response;
};

export default apiCall;
