export default function getOrdinalNumber(number) {
  switch (number) {
    case 1:
      return '1st';
    case 2:
      return '2nd';
    case 3:
      return '3rd';
    case 4:
      return '4th';
    case 5:
      return '5th';
    case 6:
      return '6th';
    case 7:
      return '7th';
    case 8:
      return '8th';
    case 9:
      return '9th';
    case 10:
      return '10th';
    case 11:
      return '11th';
    case 12:
      return '12th';
    case 13:
      return '13th';
    case 14:
      return '14th';
    case 15:
      return '15th';
    case 16:
      return '16th';
    case 17:
      return '17th';
    case 18:
      return '18th';
    case 19:
      return '19th';
    case 20:
      return '20th';
    case 21:
      return '21st';
    case 22:
      return '22nd';
    case 23:
      return '23rd';
    case 24:
      return '24th';
    default:
      const lastDigit = number % 10;
      const lastTwoDigits = number % 100;
      if (lastTwoDigits === 11 || lastTwoDigits === 12 || lastTwoDigits === 13) {
        return `${number}th`;
      }
      switch (lastDigit) {
        case 1:
          return `${number}st`;
        case 2:
          return `${number}nd`;
        case 3:
          return `${number}rd`;
        default:
          return `${number}th`;
      }
  }
}
