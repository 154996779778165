const getErrorDescription = errorType => {
  switch (errorType) {
    case 'authentication_required':
      return 'The card was declined as the transaction requires authentication.';
    case 'approve_with_id':
      return 'The payment cannot be authorized.';
    case 'call_issuer':
      return 'The card has been declined for an unknown reason.';
    case 'card_not_supported':
      return 'The card does not support this type of purchase.';
    case 'card_velocity_exceeded':
      return 'The customer has exceeded the balance or credit limit available on their card.';
    case 'currency_not_supported':
      return 'The card does not support the specified currency.';
    case 'do_not_honor':
      return 'The card has been declined for an unknown reason.';
    case 'do_not_try_again':
      return 'The card has been declined for an unknown reason.';
    case 'duplicate_transaction':
      return 'A transaction with identical amount and credit card information was submitted very recently.';
    case 'expired_card':
      return 'The card has expired.';
    case 'fraudulent':
      return 'The payment has been declined as Stripe suspects it is fraudulent.';
    case 'generic_decline':
      return 'The card has been declined for an unknown reason.';
    case 'incorrect_number':
      return 'The card number is incorrect.';
    case 'incorrect_cvc':
      return 'The CVC number is incorrect.';
    case 'incorrect_pin':
      return 'The PIN entered is incorrect.';
    case 'incorrect_zip':
      return 'The ZIP/postal code is incorrect.';
    case 'insufficient_funds':
      return 'The card has insufficient funds to complete the purchase.';
    case 'invalid_account':
      return 'The card, or account the card is connected to, is invalid.';
    case 'invalid_amount':
      return 'The payment amount is invalid, or exceeds the amount that is allowed.';
    case 'invalid_cvc':
      return 'The CVC number is incorrect.';
    case 'invalid_expiry_month':
      return 'The expiration month is invalid.';
    case 'invalid_expiry_year':
      return 'The expiration year is invalid.';
    case 'invalid_number':
      return 'The card number is incorrect.';
    case 'invalid_pin':
      return 'The PIN entered is incorrect.';
    case 'issuer_not_available':
      return 'The card issuer could not be reached, so the payment could not be authorized.';
    case 'lost_card':
      return 'The payment has been declined because the card is reported lost.';
    case 'merchant_blacklist':
      return 'The payment has been declined because it matches a value on the Stripe user’s block list.';
    case 'new_account_information_available':
      return 'The card, or account the card is connected to, is invalid.';
    case 'no_action_taken':
      return 'The card has been declined for an unknown reason.';
    case 'not_permitted':
      return 'The payment is not permitted.';
    case 'offline_pin_required':
      return 'The card has been declined as it requires a PIN.';
    case 'online_or_offline_pin_required':
      return 'The card has been declined as it requires a PIN.';
    case 'pickup_card':
      return 'The card cannot be used to make this payment (it is possible it has been reported lost or stolen).';
    case 'pin_try_exceeded':
      return 'The allowable number of PIN tries has been exceeded.';
    case 'processing_error':
      return 'An error occurred while processing the card.';
    case 'reenter_transaction':
      return 'The payment could not be processed by the issuer for an unknown reason.';
    case 'restricted_card':
      return 'The card cannot be used to make this payment (it is possible it has been reported lost or stolen).';
    case 'revocation_of_all_authorizations':
      return 'The card has been declined for an unknown reason.';
    case 'revocation_of_authorization':
      return 'The card has been declined for an unknown reason.';
    case 'security_violation':
      return 'The card has been declined for an unknown reason.';
    case 'service_not_allowed':
      return 'The card has been declined for an unknown reason.';
    case 'stolen_card':
      return 'The payment has been declined because the card is reported stolen.';
    case 'stop_payment_order':
      return 'The card has been declined for an unknown reason.';
    case 'testmode_decline':
      return 'A Stripe test card number was used.';
    case 'transaction_not_allowed':
      return 'The card has been declined for an unknown reason.';
    case 'try_again_later':
      return 'The card has been declined for an unknown reason.';
    case 'withdrawal_count_limit_exceeded':
      return 'The customer has exceeded the balance or credit limit available on their card.';

    default:
      return 'The card has been declined for an unknown reason.';
  }
};

export default getErrorDescription;
